import React from 'react';
import { Skeleton, SkeletonSquare } from '@fiverr-private/feedback';
import { Container, Stack, AspectRatio } from '@fiverr-private/layout_components';

const LoadingGigDoubleV2Card = () => (
    <Skeleton width="auto">
        <Stack
            width="100%"
            gap={{ default: '4', sm: '6' }}
            direction="column"
            padding={{ default: '4', sm: '6' }}
            borderRadius="lg"
        >
            <Stack gap={{ default: '3', sm: '5' }} direction={{ default: 'column', sm: 'row' }}>
                <Container display={{ default: 'flex', sm: 'none' }}>
                    <SkeletonSquare width={64} height={64} />
                </Container>
                <Container display={{ default: 'none', sm: 'flex' }}>
                    <SkeletonSquare width={96} height={96} />
                </Container>
                <Stack gap="1" direction="column" width="75%">
                    <SkeletonSquare height={32} width="60%" />
                    <SkeletonSquare height={26} width="60%" />
                    <SkeletonSquare height={24} width="30%" />
                </Stack>
            </Stack>

            <Stack direction={{ default: 'columnReverse', sm: 'row' }}>
                <Container width="100%">
                    <AspectRatio ratio="16/9" width="100%" backgroundColor="grey_300" borderRadius="sm" />
                </Container>
                <Stack direction="column" gap={{ default: '1', sm: '2' }} width="100%">
                    <Container display={{ default: 'none', sm: 'flex' }}>
                        <SkeletonSquare height={24} width="60%" />
                    </Container>
                    <SkeletonSquare height={24} width="70%" />
                    <SkeletonSquare height={24} width="75%" />
                    <SkeletonSquare height={24} width="80%" />
                </Stack>
            </Stack>

            <Container display={{ default: 'flex', sm: 'none' }}>
                <SkeletonSquare height={24} width="90%" />
            </Container>

            <SkeletonSquare height={24} width="40%" />
        </Stack>
    </Skeleton>
);

export default LoadingGigDoubleV2Card;
