export const HOVER_EVENT_TYPES = {
    MOUSE_ENTER: 'mouseenter',
    MOUSE_LEAVE: 'mouseleave',
};

export const ELEMENT_METADATA = {
    PLAY_VIDEO_ICON: 'play_video_icon',
    PAUSE_VIDEO_ICON: 'pause_video_icon',
    MUTE_VIDEO: 'mute_video',
    UNMUTE_VIDEO: 'unmute_video',
};
