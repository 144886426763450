import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Collapse } from '@fiverr-private/transition';
import { Dropdown } from '@fiverr-private/orca';
import { FILTER_DISPLAY_TYPES } from '../../../../common/floating_topbar/menus/consts';
import { useBusinessListingsContext } from '../../Context';
import { Budget, CheckboxList, RadioList, SearchFilter, ScFilter } from '../filter_components';
import { isSubCategory, isSubcategoryFilterActive, shouldConvertFilterToSearch } from '../filter_components/utils';
import Types from '../types';
import styles from './index.module.scss';

const BOTTOM_FADE_GRADIENT_VISIBLE_HEIGHT = 80;

const getFilterComponent = (filter) => {
    if (isSubCategory(filter)) {
        return ScFilter;
    }

    const { display_type } = filter;

    if (display_type === FILTER_DISPLAY_TYPES.RADIO) {
        return RadioList;
    }

    if (display_type === FILTER_DISPLAY_TYPES.INPUT_RANGE) {
        return Budget;
    }

    if (display_type === FILTER_DISPLAY_TYPES.CHECKBOX_GROUP || display_type === FILTER_DISPLAY_TYPES.AUTO_COMPLETE) {
        if (shouldConvertFilterToSearch(filter)) {
            return SearchFilter;
        } else {
            return CheckboxList;
        }
    }

    return null;
};

const isFilterActive = (filter, activeFilters) =>
    !!activeFilters[filter.id] || (isSubCategory(filter) && isSubcategoryFilterActive(activeFilters));

const countActiveOptionsToDisplayForFilter = (filter, activeOptions) => {
    const { display_type } = filter;

    if (isEmpty(activeOptions) || display_type === FILTER_DISPLAY_TYPES.INPUT_RANGE) {
        return 0;
    }

    return Array.isArray(activeOptions) ? activeOptions.length : 1;
};

const CollapsibleSection = ({
    title,
    filter,
    disableClicks,
    addTopPadding = true,
    isDisabled = false,
    sidebarContainerRef,
}) => {
    const { activeFilters } = useBusinessListingsContext();

    const [animationInitiated, setAnimationInitiated] = useState(false);
    const [open, setOpen] = useState(isFilterActive(filter, activeFilters));

    const collapsibleSectionRef = useRef();

    const activeOptions = activeFilters[filter.id];
    const activeOptionsCount = countActiveOptionsToDisplayForFilter(filter, activeOptions);

    const ComponentForCurrentFilter = getFilterComponent(filter);

    if (!ComponentForCurrentFilter) {
        return null;
    }

    const sidebarElement = sidebarContainerRef.current;
    const collapsibleSectionElement = collapsibleSectionRef.current;

    const isCollapsibleElementFullyVisibleInSidebar = () => {
        const sectionRect = collapsibleSectionElement.getBoundingClientRect();
        const sidebarRect = sidebarElement.getBoundingClientRect();

        const isSectionInView = sectionRect.top >= sidebarRect.top && sectionRect.bottom <= sidebarRect.bottom;

        return isSectionInView;
    };

    const isCollapsibleElementLongerThanSidebar = () =>
        collapsibleSectionElement.clientHeight > sidebarElement.clientHeight;

    const scrollToBottom = () => {
        setTimeout(() => {
            if (!sidebarElement || !collapsibleSectionElement) return;
            if (isCollapsibleElementFullyVisibleInSidebar()) return;

            if (isCollapsibleElementLongerThanSidebar()) {
                sidebarElement.scroll({ top: collapsibleSectionElement.offsetTop, left: 0, behavior: 'smooth' });

                return;
            }

            const pixelsToScroll =
                collapsibleSectionElement.offsetTop +
                collapsibleSectionElement.clientHeight -
                sidebarElement.clientHeight +
                BOTTOM_FADE_GRADIENT_VISIBLE_HEIGHT;

            sidebarElement.scroll({ top: pixelsToScroll, left: 0, behavior: 'smooth' });
        }, 100);
    };

    const onCollapseClick = () => {
        if (!open) {
            scrollToBottom();
        }

        setAnimationInitiated(true);
        setOpen(!open);
    };

    const handleMoreClick = () => {
        if (open) {
            scrollToBottom();
        }
    };

    const collapseContent = () => (
        <div>
            <ComponentForCurrentFilter
                onMoreClick={handleMoreClick}
                filter={filter}
                activeFilters={activeFilters}
                disableClicks={disableClicks}
                isDisabled={isDisabled}
            />
        </div>
    );

    return (
        <div ref={collapsibleSectionRef} className={classNames(styles.container)}>
            <div className={classNames(styles.header, 'p-b-16', { 'p-t-16': addTopPadding })} onClick={onCollapseClick}>
                <span className={classNames(isDisabled ? 'co-text-medium' : 'co-text-darkest', 'text-semi-bold')}>
                    {title}
                    {activeOptionsCount > 0 && ` (${activeOptionsCount})`}
                </span>
                <span>
                    <Dropdown.Button
                        label={''}
                        isOpen={open}
                        iconClassName={classNames(styles.dropdownIcon, {
                            [styles.disabledIcon]: isDisabled,
                        })}
                    />
                </span>
            </div>
            {animationInitiated && (
                <Collapse in={open} endingHeight={'auto'} duration="fast1">
                    {collapseContent()}
                </Collapse>
            )}
            {!animationInitiated && open && collapseContent()}
        </div>
    );
};

CollapsibleSection.propTypes = {
    title: PropTypes.string.isRequired,
    filter: Types.filter.isRequired,
    disableClicks: PropTypes.func,
    addTopPadding: PropTypes.bool,
    isDisabled: PropTypes.bool,
    sidebarContainerRef: PropTypes.ref,
};

export default CollapsibleSection;
