import React from 'react';
import { shape, string, array } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { BI_CONSTS } from '@fiverr-private/listing_lib';
import { Breadcrumb, BreadcrumbsLink, BreadcrumbsHomeIcon } from '@fiverr-private/navigation';
import { createSourceInfo } from '../../../../../shared/utils/bi';
import ExplanationVideo from '../../../../../shared/components/Video/ExplanationVideo';
import { VIDEO_PROP_TYPES } from '../../../../../shared/components/Video/constants';

import './index.scss';

const SubcatHeader = ({ data, breadcrumbs }) => {
    const { title, video, showHowFiverrWorks } = data;

    const sourceInfo = createSourceInfo({
        videoName: video.name,
        mixpanelPageName: BI_CONSTS.MIXPANEL_PAGE_NAME,
        bigQueryPageName: BI_CONSTS.BIGQUERY_PAGE_NAME,
    });

    const [home, ...steps] = breadcrumbs || [];

    return (
        <header className="subcategory-header">
            <div className="title-wrapper">
                <div className="flex flex-col">
                    {breadcrumbs && (
                        <Breadcrumb>
                            <BreadcrumbsLink href={home.url}>
                                <BreadcrumbsHomeIcon />
                            </BreadcrumbsLink>
                            {steps.slice(0, -1).map((breadcrumb) => (
                                <BreadcrumbsLink key={breadcrumb.url} href={breadcrumb.url}>
                                    {breadcrumb.title}
                                </BreadcrumbsLink>
                            ))}
                        </Breadcrumb>
                    )}
                    <h1>{title}</h1>
                    <ExplanationVideo
                        subcat={data}
                        showButton={showHowFiverrWorks}
                        video={video}
                        videoTitle={<I18n k={video.titleKey} />}
                        sourceInfo={sourceInfo}
                    />
                </div>
            </div>
        </header>
    );
};

SubcatHeader.propTypes = {
    breadcrumbs: array,
    data: shape({
        title: string,
        subtitle: string,
        video: VIDEO_PROP_TYPES.video,
    }),
};

export default SubcatHeader;
