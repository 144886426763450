import React from 'react';
import classnames from 'classnames';
import { URL_TARGET } from '../../Components/constants';
import commonStyles from '../../styles/utils.module.scss';
import collectWrapperStyles from '../BasicGigCard/components/CollectWrapper/index.module.scss';
import gigTitleStyles from '../Components/GigTitle/index.module.scss';

export const getGigTitleProps = (props, gigPageLinkHoverState = {}) => {
    const { hidePrefix, title, showTranslatedUGC, translatedTitle, localizedTitle, url, urlTarget, seller } = props;
    const { isHovering, onMouseEnter, onMouseLeave } = gigPageLinkHoverState;

    return {
        hidePrefix,
        title,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        url,
        urlTarget,
        sellerType: seller?.type,
        isHovering,
        onMouseEnter,
        onMouseLeave,
        className: classnames('m-t-16', { [gigTitleStyles.hovering]: isHovering }),
        titleClassName: 'tbody-4',
    };
};

export const getVideoStripSellerInfoProps = (props) => {
    const { seller = {}, sellerInNewTab } = props;
    const { name, displayName, url, imgSrc, level } = seller;
    const urlTarget = sellerInNewTab ? URL_TARGET.blank : URL_TARGET.self;

    return { name, displayName, url, imgSrc, level, urlTarget, type: seller.type };
};

export const getVideoStripCollectWrapperProps = (props) => {
    const { id, collectProps = {}, triggerData = {} } = props;

    // eslint-disable-next-line react/prop-types
    const HeartButton = ({ className }) => <div className={classnames(collectWrapperStyles.collectBtn, className)} />;

    const collectedIcon = <HeartButton className={collectWrapperStyles.collected} />;
    const notCollectedIcon = <HeartButton />;

    return {
        id,
        collectProps,
        className: classnames(collectWrapperStyles.collectWrapper, commonStyles.zIndex1),
        collectedIcon,
        notCollectedIcon,
        triggerData,
    };
};

export const getPromotedPriceProps = (props, gigPageLinkHoverState) => {
    const { price, url, urlTarget, shouldDisplayFees } = props;
    return {
        className: classnames('m-t-0', 'text-display-6', 'responsive-details-text'),
        price,
        url,
        urlTarget,
        shouldDisplayFees,
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};
