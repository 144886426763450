import styles from './index.module.scss';

export const GIG_TITLE_PREFIX_KEY = 'gig_listings.gig_cards.i_will';
export const AGENCY_TITLE_PREFIX_KEY = 'gig_listings.gig_cards.our_agency_will';

export const MAX_ROWS = {
    TWO: 2,
    THREE: 3,
};

export const MAX_ROWS_MAPPER = {
    [MAX_ROWS.TWO]: styles.twoRowEllipsis,
    [MAX_ROWS.THREE]: styles.threeRowEllipsis,
};
