import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

const LoadingGigCard = () => (
    <div className={styles.loadingGigCard}>
        <div className={classNames(styles.header, 'p-b-12')}>
            <div className={styles.avatar} />
            <div className={classNames(styles.sellerDetails, 'p-t-4', 'p-b-4')} />
        </div>
        <div className={classNames(styles.title, 'm-b-24')}>
            <div className="m-b-12" />
            <div className="m-b-12" />
            <div className="m-b-0" />
        </div>
        <div className={classNames(styles.content, 'm-b-24')} />
        <div className={styles.footer} />
    </div>
);

export default LoadingGigCard;
