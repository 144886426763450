import React from 'react';
import PropTypes from 'prop-types';

const InlineVideoProgress = ({ progress }) => (
    <svg className="video-player-progress" viewBox="-2 -4 40 40">
        <circle className="progress-bar-background" r="14" cx="18" cy="14" />
        <circle style={{ strokeDasharray: progress }} className="progress-bar" r="14" cx="18" cy="14" />
    </svg>
);

InlineVideoProgress.propTypes = {
    progress: PropTypes.string,
};

export default InlineVideoProgress;
