import React from 'react';
import classnames from 'classnames';
import { chain, isEmpty } from 'lodash';
import { Tag, Tooltip } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { VettedProBadge } from '@fiverr-private/badges';
import { ComplianceBadge } from '@fiverr-private/compliance';
import { getContext } from '@fiverr-private/fiverr_context';
import { SellerLevelBadge, MAP_SELLER_LEVEL_TO_BADGE } from '../SellerLevel';
import propTypes from './types';
import { BADGES } from './constants';

import './index.scss';

export const PRIORITY = [BADGES.CHOICE, BADGES.PRO, BADGES.BUSINESS];

const badgeMap = {
    [BADGES.CHOICE]: {
        text: (
            <I18n
                k="listings.badges.choice.caption"
                templates={{
                    choice: (text) => <span className="co-green">{text}</span>,
                }}
            />
        ),
        tooltip: <I18n k="listings.badges.choice.tooltip" />,
    },
    [BADGES.BUSINESS]: {
        text: <I18n k="listings.badges.business.caption" />,
        tooltip: '',
    },
};

const SellerBadge = ({
    className,
    badges = {},
    badge: definedBadge,
    dataTestId,
    sellerLevel,
    sellerId,
    sellerUserName,
    showComplianceBadge,
}) => {
    const { isMobile } = getContext();
    const badge = isEmpty(badges)
        ? definedBadge
        : chain(badges)
              .keys()
              .orderBy((key) => PRIORITY.indexOf(key))
              .find((badge) => badges[badge] === true)
              .value();

    const availableSellerLevels = Object.keys(MAP_SELLER_LEVEL_TO_BADGE);
    const isLevelBadgeAvailable = availableSellerLevels.includes(sellerLevel);

    const getComplianceBadgeComponent = ({ isCompact = isMobile } = {}) =>
        showComplianceBadge ? (
            <div
                onClick={(event) => {
                    event.stopPropagation();
                }}
                className={classnames('flex', className, 'm-l-8')}
            >
                <ComplianceBadge requeseteeId={sellerId} username={sellerUserName} isCompact={isCompact} />
            </div>
        ) : null;

    if (badge === BADGES.PRO) {
        return (
            <div className={classnames('flex', className)}>
                <VettedProBadge appendTooltipToBody dataTestId={dataTestId} />
                {getComplianceBadgeComponent({ isCompact: true })}
            </div>
        );
    }

    const { text = '', tooltip = '' } = badgeMap[badge] || {};

    if (!text) {
        if (isLevelBadgeAvailable) {
            return (
                <div className={classnames('flex', className)}>
                    <SellerLevelBadge sellerLevel={sellerLevel} />
                    {getComplianceBadgeComponent()}
                </div>
            );
        }

        return getComplianceBadgeComponent();
    }

    const contentChild = (
        <Tag className={classnames('seller-level-tag', badge, className)} borderRadius={Tag.BORDER_RADIUSES.ROUNDED}>
            {text}
        </Tag>
    );

    const renderedContent = tooltip ? (
        <Tooltip boxContentClassName="seller-badge-tooltip-box" content={tooltip}>
            {contentChild}
        </Tooltip>
    ) : (
        <>{contentChild}</>
    );

    return (
        <div className={classnames('flex')}>
            {renderedContent}
            {getComplianceBadgeComponent()}
        </div>
    );
};

SellerBadge.propTypes = propTypes;
SellerBadge.BADGES = BADGES;

export default SellerBadge;
