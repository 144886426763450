import React, { useRef } from 'react';
import { BannerWrapper } from '@fiverr-private/gig_listings';
import { useAppContext } from '../../../context/listingsContext';
import { useProjectManagementBannerAllocation } from './hooks/useProjectManagementBannerAllocation';
import { ProjectManagementBanner } from './ProjectManagementBanner';

interface ProjectManagementBannerProps {}

export const ProjectManagementBannerResolver: React.FC<ProjectManagementBannerProps> = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { shouldShowProjectManagementBanner } = useAppContext();
    const { isInTest } = useProjectManagementBannerAllocation(ref);

    if (isInTest.inGroupB && shouldShowProjectManagementBanner) {
        return <ProjectManagementBanner />;
    }

    return <div ref={ref} />;
};

export const ProjectManagementBannerResolverWithWrapper: React.FC<ProjectManagementBannerProps> = (props) =>
    BannerWrapper(<ProjectManagementBannerResolver {...props} />)({
        className: 'project-management-banner-listings',
        bannerRow: 3,
    });
