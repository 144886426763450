import React, { FC, ReactNode, RefObject } from 'react';
import classNames from 'classnames';
import { Filter } from '../../Types';
import CollapsibleSection from '../CollapsibleSection';

import styles from './index.module.scss';

interface CollapsibleFiltersGroupProps {
    title: string;
    filters: Filter[];
    addTopPadding: boolean;
    sidebarContainerRef: RefObject<ReactNode>;
}

const CollapsibleFiltersGroup: FC<CollapsibleFiltersGroupProps> = ({
    title = '',
    filters,
    addTopPadding = true,
    sidebarContainerRef,
}) => (
    <div className={classNames('p-r-16', 'p-l-16')}>
        <div className={classNames({ 'p-t-32': addTopPadding }, 'p-b-4')}>
            <span className={classNames(styles.title, 'tbody-7', 'text-semi-bold', 'co-text-light')}>{title}</span>
        </div>
        {filters.map((filter) => (
            <CollapsibleSection
                key={`${filter.id}-side-filters-collapsible`}
                title={filter.alias}
                filter={filter}
                sidebarContainerRef={sidebarContainerRef}
            />
        ))}
    </div>
);

export default CollapsibleFiltersGroup;
