import { GIG_NEW_TAB, GIG_SAME_TAB } from './constants';

export const transformFeatures = (features = {}) => ({
    urlTarget: features.gigInNewTab ? GIG_NEW_TAB : GIG_SAME_TAB,
    showGalleryDecisionIndicators: !!features.showGalleryDecisionIndicators,
    useForcePriceRounding: !!features.useForcePriceRounding,
    sellerInNewTab: features.sellerInNewTab,
    showNewImageRatio: features.showNewImageRatio,
    shouldDisplayFees: features.shouldDisplayFees,
});
