import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { priceMarkupFormatter } from '../../../GigCardListings/ListingsTransformer/priceTransformer';
import {
    PRICE_LABEL_TYPES,
    PRICE_DEFAULT_LABELS,
} from '../../../GigCardListings/ListingsTransformer/priceTransformer/constants';
import { useGigListingsContext } from '../../../GigCardListings/context/GigListingsContext';
import { trackGigForClickType, createClickedElement } from '../../utils';
import isDefualtCurrency from '../../../GigCardListings/utils/defualtCurrency';
import ContextualLink from '../ContextualLink';
import { OPEN_GIG_SAME_TAB_TARGET, ELEMENTS_NAME, ELEMENTS_TYPE } from '../constants';
import './style.scss';

const Price = (props, context) => {
    const { url, price = 0, urlTarget, priceLabelType, showFrom, shouldDisplayFees } = props;
    const { currencyObj } = useGigListingsContext();
    const { name } = currencyObj;
    const defaultLabel = showFrom ? PRICE_DEFAULT_LABELS.FROM : PRICE_DEFAULT_LABELS.STARTING_AT;
    const priceLabel = priceLabelType === PRICE_LABEL_TYPES.DEFAULT ? defaultLabel : priceLabelType;
    const { trackEvent } = context;

    return (
        <ContextualLink
            href={url}
            target={urlTarget}
            className={classNames('price', { 'line-break': !isDefualtCurrency(name) })}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(price))}
        >
            {priceLabel && (
                <small className="text-body-3">
                    <I18n k={`gig_listings.gig_cards.price.${priceLabel}`} />
                </small>
            )}
            <span dangerouslySetInnerHTML={{ __html: priceMarkupFormatter({ currencyObj, price }) }} />
            {shouldDisplayFees && (
                <Text paddingLeft="1.5" fontWeight="normal" color="bodySecondary" as="span">
                    <I18n k="gig_listings.gig_cards.price.plus_fees" />
                </Text>
            )}
        </ContextualLink>
    );
};

const PriceType = PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]);

Price.propTypes = {
    url: PropTypes.string.isRequired,
    price: PriceType,
    priceLabelType: PropTypes.string,
    urlTarget: PropTypes.string,
    showFrom: PropTypes.bool,
    shouldDisplayFees: PropTypes.bool,
};

Price.defaultProps = {
    urlTarget: OPEN_GIG_SAME_TAB_TARGET,
    shouldDisplayFees: false,
};

Price.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
};

export const clickedElement = (price) => createClickedElement(ELEMENTS_NAME.GIG_PRICE, ELEMENTS_TYPE.LINK, price);

export default Price;
