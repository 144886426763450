import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { BI_EVENT_GROUPS, BI_ACTION_TYPES } from '../../../../../../shared/services/BIEvents/constants';

const getBasePayLoad = () => {
    const { userId, pageCtxId } = getContext();
    return {
        sub_group: 'fiverr_pro',
        feature: {
            name: 'fiverr_pro',
        },
        page: {
            ctx_id: pageCtxId,
            element: {
                name: 'pro_toggle',
            },
        },
        user: {
            id: userId,
        },
    };
};

const reportBigQueryImp = (params: Record<string, any>) => {
    const payload = merge(
        getBasePayLoad(),
        {
            group: BI_EVENT_GROUPS.USER_IMPRESSION,
            event_triggered_by: BI_ACTION_TYPES.IMPRESSION,
            action: { type: BI_ACTION_TYPES.IMPRESSION },
        },
        params
    );
    bigQuery.send(payload);
};

const reportBigQueryClick = (params: Record<string, any>) => {
    const payload = merge(
        getBasePayLoad(),
        {
            group: BI_EVENT_GROUPS.USER_ACTIONS,
            event_triggered_by: BI_ACTION_TYPES.CLICK,
            action: { type: BI_ACTION_TYPES.CLICK },
        },
        params
    );
    bigQuery.send(payload);
};

export const reportBannerIsShown = () => {
    reportBigQueryImp({
        type: 'pro_toggle_banner_is_shown',
        page: {
            component: {
                name: 'pro_toggle_banner',
            },
        },
    });
};

export const reportClickBanner = () => {
    reportBigQueryClick({
        type: 'click_on_pro_toggle_banner',
        page: {
            element: {
                type: 'banner',
            },
        },
    });
};

export const reportHourlyRateEducationTooltipShown = () => {
    reportBigQueryImp({
        type: 'viewed_new_hourly_rate_filter_tooltip_listings_page',
        page: {
            component: {
                name: 'new_hourly_rate_filter_tooltip',
                type: 'tooltip',
            },
        },
    });
};

export const reportClickHourlyRateEducationTooltipDismissed = () => {
    reportBigQueryClick({
        type: 'clicked_dismiss_new_hourly_rate_filter_tooltip_listings_page',
        page: {
            component: {
                name: 'dismiss_new_hourly_rate_filter_tooltip',
                type: 'tooltip',
            },
        },
    });
};
