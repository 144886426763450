import { MAX_RATINGS_COUNT } from './constants';

export const getCount = ({ count, translate }) => {
    if (!count) {
        return null;
    }

    if (count > MAX_RATINGS_COUNT) {
        return translate('gig_listings.gig_cards.count_format');
    }

    return count;
};
