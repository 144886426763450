import { isEmpty } from 'lodash';
import { removeEmptyEntries } from '../../../GigCardListings/utils/removeEmptyEntries';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../Components/constants';
import { sendMixPanelEvent } from '../sendMixpanelEvent';

export const EVENT_TYPES = {
    GIG_CLICK: 'gig.click',
    GIG_CARD_CLICK: 'clicked_on_gig_card',
    GIG_RIGHT_CLICK: 'gig.right_click',
    HOVER: 'hover',
    SLIDER_ARROW_CLICK: 'Gig Card Slider Arrow Click',
    CLICKED_ON_GIG_CARD: 'Clicked on Gig Card',
    GIG_CARD_PLAY_VIDEO: 'gig_card_play_video',
    GIG_CARD_PAUSE_VIDEO: 'gig_card_pause_video',
};

export const EVENT_GROUPS = {
    STUDIO_HOVER: 'gig_card_hover',
    USER_ACTIONS: 'user_actions',
};

export const EVENT_SUB_GROUPS = {
    WEBSITE_ACTIONS: 'website_actions',
};

export const EVENT_ACTION_TYPES = {
    CLICK: 'click',
    RIGHT_CLICK: 'right_click',
    MIDDLE_CLICK: 'middle_click',
    MOUSE_HOVER: 'mouse_hover',
    MOUSE_BLUR: 'mouse_blur',
    VIDEO_ENDED: 'video_ended',
};

export const CARD_TYPE = {
    GIG_CARD: 'gig_card',
    BASIC_GIG_CARD: 'basic_gig_card',
    AGENCY_CARD: 'agency_card',
    SELLER_FOCUSED_GIG_CARD: 'seller_focused_gig_card',
    AGENCY_CARD_V2: 'agency_card_v2',
    VIDEO_STRIP_CARD: 'video_strip_card',
};

export const EVENT_COMPONENT_NAMES = {
    [CARD_TYPE.GIG_CARD]: 'gig_card',
    [CARD_TYPE.AGENCY_CARD]: 'agency_card',
    [CARD_TYPE.AGENCY_CARD_V2]: 'agency_card_v2',
    [CARD_TYPE.VIDEO_STRIP_CARD]: 'video_strip_card',
};

export const EVENT_PAGE_NAME = {
    LISTING: 'listing',
};

export const EVENT_DOMAINS = {
    BUYERS: 'buyers',
    CATERPILLARS: 'caterpillars',
};

export const collectElementsMapper = {
    menu: {
        elementName: ELEMENTS_NAME.SAVE_IN,
        elementType: ELEMENTS_TYPE.BUTTON,
        elementText: 'Save in list',
    },
    heartBtn: {
        elementName: ELEMENTS_NAME.ADD_TO_FAVORITES,
        elementType: ELEMENTS_TYPE.TOGGLE,
        elementText: 'Add to favorites',
    },
};

const buildTrackingPayload = (type, gig, cardType, clickedElement, eventActionType, enrichment) => {
    const { id, position, auction, impressionId, models } = gig;
    const componentName = EVENT_COMPONENT_NAMES[cardType] ?? EVENT_COMPONENT_NAMES[CARD_TYPE.GIG_CARD];

    return {
        group: EVENT_GROUPS.USER_ACTIONS,
        sub_group: EVENT_SUB_GROUPS.WEBSITE_ACTIONS,
        type,
        gig: {
            id,
        },
        auction,
        page: {
            name: EVENT_PAGE_NAME.LISTING,
            element: {
                ...clickedElement,
            },
            component: {
                name: componentName,
                position,
            },
        },
        action: {
            type: eventActionType,
        },
        out_of_session: false,
        publisher: {
            domain: EVENT_DOMAINS.BUYERS,
            sub_domain: EVENT_DOMAINS.CATERPILLARS,
        },
        ...(!isEmpty(models) && {
            go: {
                model: {
                    id: models[0].id,
                },
            },
        }),
        ...(impressionId && { imp: { id: impressionId } }),
        ...enrichment,
    };
};

export const getCardType = (isNonExperiential, isExperiential) => {
    if (isNonExperiential) {
        return CARD_TYPE.SELLER_FOCUSED_GIG_CARD;
    }
    if (isExperiential) {
        return CARD_TYPE.BASIC_GIG_CARD;
    }
    return CARD_TYPE.GIG_CARD;
};

export const enrichGigTracker = (tracker, gig, presetCardType) => ({
    trackEvent: (
        type = EVENT_TYPES.GIG_CLICK,
        clickedElement = {},
        eventActionType = EVENT_ACTION_TYPES.CLICK,
        enrichment = {}
    ) => {
        const { id, position, isFiverrChoice = false, isPromotedGig = false, isExperiential, isNonExperiential } = gig;
        const cardType = presetCardType || getCardType(isNonExperiential, isExperiential);
        let payload = {
            type,
            cardType,
            gig: { id, position },
            ...(gig.seller && { seller: { id: gig.seller.id, type: gig.seller.type } }),
        };

        switch (type) {
            case EVENT_TYPES.CLICKED_ON_GIG_CARD:
                const { name: elementName = '', text: elementText = '' } = clickedElement;
                const enrichedPayload = {
                    id,
                    position,
                    isFiverrChoice,
                    isPromotedGig,
                    elementName,
                    elementText,
                    cardType,
                    ...(gig.seller && { sellerId: gig.seller.id, sellerType: gig.seller.type }),
                };
                const eventPayload = createMixPanelGigClickPayload(enrichedPayload);
                return tracker.trackMixpanelEvent({ eventName: type, eventPayload });
            case EVENT_TYPES.GIG_CARD_CLICK:
            case EVENT_TYPES.GIG_CARD_PLAY_VIDEO:
            case EVENT_TYPES.GIG_CARD_PAUSE_VIDEO:
                payload = buildTrackingPayload(type, gig, cardType, clickedElement, eventActionType, enrichment);
                return tracker.trackEvent(payload);
            case EVENT_TYPES.GIG_CLICK:
                return tracker.trackEvent(payload);
            default:
                return tracker.trackEvent(payload);
        }
    },
    trackImpression: (type, group) => {
        const { id, seller, position } = gig;
        const payload = { type, group, gig: { id, position }, seller: { id: seller.id } };

        tracker.trackImpression(payload);
    },
});

export const gigClick = (track, clickedElement) => {
    track(EVENT_TYPES.GIG_CLICK, clickedElement);
    track(EVENT_TYPES.GIG_CARD_CLICK, clickedElement);
    track(EVENT_TYPES.CLICKED_ON_GIG_CARD, clickedElement);
};

export const gigRightClick = (track, clickedElement) => {
    track(EVENT_TYPES.GIG_RIGHT_CLICK);
    track(EVENT_TYPES.GIG_CARD_CLICK, clickedElement, EVENT_ACTION_TYPES.RIGHT_CLICK);
};

export const gigMiddleClick = (track, clickedElement) => {
    track(EVENT_TYPES.GIG_CARD_CLICK, clickedElement, EVENT_ACTION_TYPES.MIDDLE_CLICK);
};

export const createClickedElement = (name, type, text, metadata) =>
    removeEmptyEntries({
        name,
        type,
        text,
        metadata,
    });

export const trackGigForClickType = (mouseClickType, track, clickedElement) => {
    if (mouseClickType === 0) {
        return gigClick(track, clickedElement);
    }

    if (mouseClickType === 1) {
        return gigMiddleClick(track, clickedElement);
    }

    if (mouseClickType === 2) {
        return gigRightClick(track, clickedElement);
    }
};

const formatEventData = ({ id, direction, seller }) => ({
    gig_id: id,
    direction,
    'Seller Id': seller.id,
});

export const mixPanelSliderArrowClick = (id, direction, seller) => {
    sendMixPanelEvent({
        eventName: EVENT_TYPES.SLIDER_ARROW_CLICK,
        eventPayload: formatEventData({ id, direction, seller }),
    });
};

const createMixPanelGigClickPayload = ({
    id,
    position,
    isFiverrChoice,
    isPromotedGig,
    elementName,
    elementText,
    sellerId,
    sellerType,
    cardType,
}) => ({
    'Gig Id': id,
    Position: position,
    'Fiverr Choice': isFiverrChoice || false,
    'Promoted Gig': isPromotedGig,
    'Clicked Element': elementName,
    'Clicked Element Text': elementText,
    'Seller Id': sellerId,
    'Seller Type': sellerType,
    'Card Type': cardType,
});
