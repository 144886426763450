import { prepareAssets, prepareFirstImageAsset } from '../../assetsHelper';
import { baseTransformer } from '../base';

export const cardTransformer = (props) => {
    if (props.isTouch) {
        const assets = props.showTranslatedUGC && props.localizedAssets?.length ? props.localizedAssets : props.assets;

        return {
            ...baseTransformer(props),
            asset: prepareFirstImageAsset(assets, props.isTouch, props.title),
        };
    }

    if (props.showTranslatedUGC && props.localizedAssets?.length) {
        return {
            ...baseTransformer(props),
            localizedAssets: prepareAssets(props.localizedAssets, props.isTouch, props.title),
        };
    }

    return {
        ...baseTransformer(props),
        assets: prepareAssets(props.assets, props.isTouch, props.title),
    };
};
