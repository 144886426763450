import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@fiverr-private/fit';
import './index.scss';

const SellerImage = ({ isOnline, imgSrc, alt }) => (
    <span className="seller-image">
        {isOnline && <span className="is-online" />}
        <Avatar className="seller-avatar" src={imgSrc} username={alt} />
    </span>
);

SellerImage.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    isOnline: PropTypes.bool,
};

SellerImage.defaultProps = {
    isOnline: false,
};

export default SellerImage;
