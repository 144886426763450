import { isEmpty } from 'lodash';
import { translate, hasTranslation } from '@fiverr-private/i18n-react';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../../constants';

export const T_PREFIX = 'search_perseus.business_listings';
export const NO_RESULTS = 'no_results';
export const FEW_RESULTS = 'few_results';

export const shouldShowIcon = (resultsCount) => resultsCount === 0;

export const getNoResultsContent = (hasActiveFilters, resultsCount, isBsm, isExpertListings) => {
    const hasFewResults = isExpertListings && resultsCount > 0 && resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS;

    const resultsKey = hasFewResults ? FEW_RESULTS : NO_RESULTS;
    const filtersKey = hasActiveFilters ? 'with_filters' : 'no_filters';

    const defaultPrefix = `${T_PREFIX}.${resultsKey}.${filtersKey}`;

    const getValue = (field, prefix = defaultPrefix) => {
        let key = `${prefix}.${field}`;
        const bsmKey = `${key}_bsm`;

        if (isBsm && hasTranslation(bsmKey)) {
            key = bsmKey;
        }

        return translate(key);
    };

    const title = getValue('title');
    const suggestion = getValue('suggestion');

    const help = getValue('help', `${T_PREFIX}.${NO_RESULTS}`);

    return {
        title,
        suggestion,
        help,
    };
};

export const hasBsm = (managedAccount) =>
    !!managedAccount &&
    ((!isEmpty(managedAccount.bsm) && managedAccount.type === 'strategic') || managedAccount.type === 'classic');
