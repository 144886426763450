import React from 'react';
import { slice } from 'lodash';
import { object, bool } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { Tooltip } from '@fiverr-private/tooltip';
import { white } from '@fiverr-private/sass/helpers';
import { ArrowRightIcon, ArrowsHorizontalIcon } from '@fiverr-private/icons';

import styles from '../../../../../styles/utils.module.scss';
import attributeStyles from './index.module.scss';

const MAX_TOOLTIP_ATTRIBUTES = 5;

const PairTag = ({ attribute = {} }) => (
    <div className={classNames(attributeStyles.attributeItem, 'flex', 'flex-items-center', 'm-t-4')}>
        <span className="m-r-8">{attribute.translatedFromLanguage}</span>
        <Icon className={styles.noShrink} color={white}>
            {attribute.hasOppositePair ? ArrowsHorizontalIcon : ArrowRightIcon}
        </Icon>
        <span className="m-l-8">{attribute.translatedToLanguage}</span>
    </div>
);

PairTag.propTypes = {
    attribute: object,
};

const SingleTag = ({ attribute = {} }) => (
    <div className={classNames(attributeStyles.attributeItem, 'm-t-4')}>{attribute.alias}</div>
);

SingleTag.propTypes = {
    attribute: object,
};

const MoreAttributesIndicator = ({ hiddenAttributes, isPairAttribute }) => {
    const calculateTooltipContent = () => {
        const attributes = slice(hiddenAttributes, 0, MAX_TOOLTIP_ATTRIBUTES);
        const hasMoreAttributes = hiddenAttributes.length > MAX_TOOLTIP_ATTRIBUTES;

        return (
            <>
                {attributes.map((attribute, index) =>
                    isPairAttribute ? (
                        <PairTag key={`attribute_${index}`} attribute={attribute} />
                    ) : (
                        <SingleTag key={`attribute_${index}`} attribute={attribute} />
                    )
                )}
                {hasMoreAttributes && <div className={classNames('tbody-5', styles.lineHeight16)}>...</div>}
            </>
        );
    };

    return (
        <div className={attributeStyles.attributesTooltipWrapper}>
            <Tooltip appendToBody content={calculateTooltipContent()}>
                <span>{`+${hiddenAttributes.length}`}</span>
            </Tooltip>
        </div>
    );
};

MoreAttributesIndicator.propTypes = {
    hiddenAttributes: object,
    isPairAttribute: bool,
};

export default MoreAttributesIndicator;
