import React from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { I18n } from '@fiverr-private/i18n-react';

import { sellerNamePrefixStyle } from '../SellerName.ve.css';

export const AdBy = () => {
    const { locale } = getContext();

    // by fragmenting the ad_by text, we try to avoid ad blockers
    if (locale === DEFAULT_FIVERR_LOCALE) {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>
                    <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.a" />
                </span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>
                    <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.d" />
                    &nbsp;
                </span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>
                    <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.by" />
                    &nbsp;
                </span>
            </>
        );
    }

    return (
        <span className={classNames('text-normal', sellerNamePrefixStyle)}>
            <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.ad_by" />
            &nbsp;
        </span>
    );
};
