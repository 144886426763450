import { URI } from '@fiverr-private/futile/lib/url/URI';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { localStorageService, maxios } from '@fiverr-private/listing_lib';
import { ROUTE_KEYS } from '../../../shared/utils/request/constants';
import request from './request';

const deleteRecentlyViewedGigs = async () => {
    const url = new URI(pathfinder('clear_recently_viewed_gigs')).toString();

    try {
        await maxios.delete(url, { routeKey: ROUTE_KEYS.CLEAR_RECENTLY_VIEWED_GIGS });
    } catch (error) {
        logger.error(error);
    }
};

export default {
    fetch: ({ sortBy }, activeFilters, appContext) => {
        const gigIds = localStorageService.getRecentlyViewedGigs();
        const { showTranslatedUGC = false, knownCrawler, locale } = appContext;
        const translationUgcData = {
            session_locale: locale,
            showTranslatedUGC,
            knownCrawler,
            translate_ugc: showTranslatedUGC.toString(),
        };

        return request.post('fetch_recently_viewed', ROUTE_KEYS.FETCH_RECENTLY_VIEWED, {
            gig_ids: gigIds,
            sortBy,
            activeFilters,
            ...translationUgcData,
        });
    },

    clearAllRecentlyViewedGigs: (loggedIn) => {
        if (loggedIn) {
            return deleteRecentlyViewedGigs();
        }

        return localStorageService.removeAllRecentlyViewedGigs();
    },

    clearRecentlyViewedGig: (loggedIn) => {
        if (loggedIn) {
            return undefined;
        }

        return localStorageService.removeSingleRecentlyViewedGig;
    },

    recentlyViewedPageURL: () =>
        pathfinder('generic_recommendations_page', {
            page_name: 'recently_viewed_page',
        }),
};
