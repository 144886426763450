import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { enrichGigTracker } from '../../utils';
import withTransformProps from '../../hoc/withTransformProps';
import { cardTransformer } from '../../utils/transformers';
import GigWrapper from '../../Components/GigWrapper/GigWrapper';
import Media from '../../Components/Media';
import DeliveryAssetTooltip from '../../Components/Media/DeliveryAssetTooltip';
import GigRating from '../Components/GigRating';
import Price from '../Components/Price';
import CollectWrapper from '../Components/CollectWrapper';
import GigTitle from '../Components/GigTitle';
import SellerSpeaks from '../Components/SellerSpeaks';
import { getGigRatingProps } from '../utils';
import { createClickedElement, EVENT_TYPES } from '../../utils/trackGig';
import ConsultationIndication from './components/ConsultationIndication';
import { BasicGigCardProps } from './BasicGigCard.types';
import SellerInfo from './components/SellerInfo';
import {
    getCollectWrapperProps,
    getGigTitleProps,
    getGigWrapperProps,
    getPriceProps,
    getSellerInfoProps,
    getSellerSpeaksProps,
    useGigPageLinkHoverState,
    useMediaProps,
} from './utils';
import BasicAiGigCard from './components/BasicAiGigCard/BasicAiGigCard';
import { GradientBadgeWrapper } from './components/BasicAiGigCard/GradientBadgeWrapper';

import './index.scss';

const BasicGigCard: React.FC<BasicGigCardProps> = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState<Record<string, any>>({});
    const [isBlackComponentOpen, setBlackComponentOpen] = useState(false);

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(props.tracker, props));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gigPageLinkHoverState = useGigPageLinkHoverState();
    const mediaProps = useMediaProps(props, gigPageLinkHoverState);
    const collectWrapperProps = getCollectWrapperProps(props);
    const sellerInfoProps = getSellerInfoProps(props);
    const gigWrapperProps = getGigWrapperProps(props, enrichedTracker);
    const gigTitleProps = getGigTitleProps(props, gigPageLinkHoverState);
    const sellerSpeaksProps = getSellerSpeaksProps(props);
    const ratingProps = getGigRatingProps(props);
    const priceProps = getPriceProps(props, gigPageLinkHoverState);
    const { trackEvent } = enrichedTracker;
    const { offerConsultation, models } = props;

    const showToggleButton = !isEmpty(models);

    const toggleBlackComponent: () => void = () => {
        const elementName = 'ai_model_gradient';
        const clickedElement = createClickedElement(elementName);
        trackEvent(EVENT_TYPES.GIG_CARD_CLICK, clickedElement);

        setBlackComponentOpen((prev: boolean) => !prev);
    };

    return (
        <GigWrapper {...gigWrapperProps}>
            <div className="basic-gig-card" onMouseLeave={() => setBlackComponentOpen(false)}>
                {mediaProps.showGalleryDecisionIndicators && mediaProps.showTooltip && (
                    <DeliveryAssetTooltip
                        showTooltip={mediaProps.showTooltip}
                        toggleTooltip={mediaProps.toggleTooltip}
                    />
                )}
                <Media {...mediaProps} />
                <CollectWrapper {...collectWrapperProps} />

                <SellerInfo {...sellerInfoProps} />
                <GigTitle {...gigTitleProps} />
                <SellerSpeaks {...sellerSpeaksProps} />
                <GigRating {...ratingProps} />
                <Price {...priceProps} />
                <ConsultationIndication shouldShow={offerConsultation} />

                {showToggleButton && (
                    <GradientBadgeWrapper models={models} toggleBlackComponent={toggleBlackComponent} />
                )}

                <BasicAiGigCard
                    sellerInfoProps={sellerInfoProps}
                    models={models}
                    trackEvent={trackEvent}
                    isBlackComponentOpen={isBlackComponentOpen}
                    setBlackComponentOpen={setBlackComponentOpen}
                />
            </div>
        </GigWrapper>
    );
};

export default withTransformProps(cardTransformer)(BasicGigCard);
