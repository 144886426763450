import { getContext } from '@fiverr-private/fiverr_context';
import { isInExperimentGroup } from '../../../../GigCardListings/ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS } from '../../../../constants/experiments';

const calcLevel = (isPro, seller) => {
    const { abTests } = getContext();
    const { level } = seller;
    const showSellerLevelAlsoForPro = isInExperimentGroup(
        EXPERIMENTS.HAWKS_OVERLAY_BADGES,
        [ALLOCATIONS.TEST_B],
        abTests
    );

    if (showSellerLevelAlsoForPro) {
        return level;
    }

    return !isPro ? level : undefined;
};

export const baseTransformer = (props) => {
    const { seller, isPro } = props;

    const level = calcLevel(isPro, seller);

    return {
        ...props,
        seller: {
            ...seller,
            level,
        },
    };
};
