import React, { Fragment, useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { LISTING_SOURCES } from '@fiverr-private/logo_maker_marketplace';
import { getContext } from '@fiverr-private/fiverr_context';
import { Container } from '@fiverr-private/layout_components';
import { NoResultsClearFilters, ProFilteredListingsTitle } from '@fiverr-private/listing_results';
import { Pagination } from '@fiverr-private/listing_pagination';
import { ALLOCATIONS, EXPERIMENTS } from '@fiverr-private/listing_experiments';
import LogoMakerBanner from '../common/logoMakerBanner';
import { SubCategoriesPropTypes } from '../../types/index';
import { NumberOfResults } from '../common/NumberOfResults/NumberOfResults';
import { DynamicGigListings, MobileFilters, RecommendationsCarousels, SortBy } from '../common';
import { TopBarFilters } from '../common/floating_topbar/TopBarFilters';
import StickyComponent from '../hoc/stickyComponent';
import NscTilesCarousel from '../common/nsc_tiles_carousel';
import TopFiltersCarousel from '../common/top_filters_carousel';
import SeoSubCategory from '../common/seo_sub_category';
import MachineTranslationButtonWrapper from '../common/machine_translation_button_wrapper';
import { DEFAULT_LOCALE } from '../../../../shared/constants';
import SeoMarkups from '../seo_markups';
import { AppContext } from '../context/listingsContext';
import { TRACKING_NAMES } from '../../constants';
import ExplicitLanguagePair from '../common/ExplicitLanguagePair';
import FtbFriendlyRecommendations from '../common/recommendations/FtbFriendly';
import { shouldShowExplicitLanguageFilter } from '../common/ExplicitLanguagePair/utils';
import RepeatedBuyersRecommendations from '../common/recommendations/RepeatedBuyers';
import { SellersWhoSpeakRecommendations } from '../common/recommendations/SellersWhoSpeak';
import { isSupportedFiverrLanguage } from '../common/recommendations/SellersWhoSpeak/utils';
import { getFilterTagsProps } from '../generic_listings/TagFilters/utils';
import { FLOWS } from '../../utils/constants';
import PromoteBanner from '../common/promoteBanner';
import LogoMakerFork from '../common/LogoMakerFork';
import {
    useAllocateAgenciesInListingsGDRowObserver,
    useCountFlowView,
    usePersonalizationData,
    usePromotedVideoStripListingRowObserver,
} from '../../hooks';
import { useReportEligibility } from '../../utils/reportEligibility';
import { ListingsImpressionContainer } from '../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import PricingFactorDropdown from '../common/floating_topbar/menus/content/pricing_factor_dropdown';
import { MobileWebHeroBanner } from '../common/hero_banner/mobile/MobileWebHeroBanner';
import { isUserCameFromFiverrPage } from '../../service/insideFiverrReferral';
import { ActiveFilters } from '../common/ActiveFilters/ActiveFilters';
import { ExposedFilters } from '../common/side_filters/ExposedFilters/ExposedFilters';
import { ProjectManagementBannerResolverWithWrapper } from '../common/banners/ProjectManagementBanner';
import Modalities from '../common/modalities/Modalities';
import useAllocateAdsPlacementsRowObserver from '../../hooks/useAllocateAdsPlacementsRowObserver';
import SubcatHeader from './header';

import './index.scss';

const SubCategories = (props) => {
    const [showLMFlow, setShowLMFlow] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { sorting, breadcrumbs, topBarSorting, loggedIn, baseUrl, flowName } = props;

    const {
        isTouch,
        listings: [listings],
        activeFilters = {},
        categoryIds,
        source,
        logoMakerConfig = {},
        significantLeafCategories,
        dominateSubCategoryId,
        inFetchPersonalizationContentRollout,
        assumedLanguage,
        flow,
        inOwlsSideFiltersTest,
        isLoadingListings,
        listingAttributes,
        pagination,
        displayData,
        appFilters,
        shouldShowPromoteBanner,
        shouldShowProjectManagementBanner,
        rollouts,
    } = useContext(AppContext);

    const {
        choiceModalities,
        recommendations,
        sellersWhoSpeak,
        context: { sessionLocale, sourceComponent, sourcePage },
        showListViewCards,
    } = listings;

    const { header, nestedSubCategoryName, categoryName, cachedSlug, seo_data = {} } = displayData;
    const {
        faqs,
        title,
        relatedLinksTitle,
        relatedLinks,
        relatedSkillsTitle,
        relatedSkillsLinks,
        answersLink,
        relatedGuides,
        seeMoreText,
        seeMoreLink,
    } = seo_data;
    const { filters = [], subCategory = {}, visualFilters = {}, shownExposedFilter } = appFilters;
    const hasFilters = filters?.length > 0;
    const noGigs = !listings.gigs.length > 0;
    const isNestedSubcategory = !!nestedSubCategoryName;
    const selectedSorting = topBarSorting.find((sort) => sort.selected) || topBarSorting[0];
    const { children = {} } = subCategory;
    const { subCategoryId } = categoryIds || {};
    const nestedSubCategoryItems = children[0]?.children;
    const displayTranslationButton = sessionLocale !== DEFAULT_LOCALE;
    const shouldShowPagination = !noGigs;
    const shouldDisplayMobileFilters = isTouch && hasFilters;
    const shouldDisplayModalities = !isEmpty(choiceModalities) && !isEmpty(choiceModalities.buckets);
    const shouldShowNumberOfResults = !inOwlsSideFiltersTest;
    const shouldShowSorter = !inOwlsSideFiltersTest;
    const { abTests = {}, referrer } = getContext();
    const shouldDisplayMWBanner =
        abTests[EXPERIMENTS.CAT_MW_SC_BANNER] === ALLOCATIONS.TEST_B && isTouch && !isUserCameFromFiverrPage(referrer);
    const shouldDisplayRepeatedBuyers = !isEmpty(recommendations) && !isEmpty(recommendations.repeatedBuyers);
    const shouldShowSellersWhoSpeak = !isEmpty(sellersWhoSpeak) && !!isSupportedFiverrLanguage(assumedLanguage);
    const shouldShowVisualFilters = visualFilters?.shouldDisplayVisualFilters;
    const shouldShowExposedFilters = inOwlsSideFiltersTest && !isTouch;
    const { personalizedContent } = usePersonalizationData({
        flow,
        shouldRun: inFetchPersonalizationContentRollout,
        fetchParams: {
            subcategory: {
                id: subCategoryId,
            },
        },
    });

    const logoMakerProps = {
        source,
        showListViewCards,
        page: TRACKING_NAMES.SUBCATEGORY_PAGE,
        listingsType: `${subCategory.name} SC`,
        sourceComponent,
        sourcePage,
        logoMakerConfig,
    };

    const shouldShowLogoMakerBanner = (() => {
        const { banner: { showBannerInSubcategory } = {} } = logoMakerConfig || {};

        return showBannerInSubcategory;
    })();

    const banners = [
        shouldShowProjectManagementBanner ? <ProjectManagementBannerResolverWithWrapper key="pjm-banner" /> : null,
        shouldShowLogoMakerBanner ? <LogoMakerBanner {...logoMakerProps} /> : null,
    ];

    const filterTagsProps = useMemo(
        () =>
            getFilterTagsProps({
                isSearch: false,
                dominateSubCategoryId,
                significantLeafCategories,
                appFilters,
                categoryIds,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const shouldDisplayNscTilesCarousel = !isNestedSubcategory && nestedSubCategoryItems?.length > 0;
    const shouldShowFtbFriendly = !isEmpty(recommendations) && !isEmpty(recommendations.ftbFriendly);

    const { video } = header;

    useReportEligibility({ visualFilters, filterTagsProps, isSearch: false, shownExposedFilter });
    useCountFlowView(FLOWS.CATEGORY);
    const promotedVideoStripListingRowObserver = usePromotedVideoStripListingRowObserver();
    const agenciesInListingsGDRowObserver = useAllocateAgenciesInListingsGDRowObserver();
    const adsPlacementRowObserver = useAllocateAdsPlacementsRowObserver();

    return (
        <div className={classNames('sub-categories-page', { 'top-filters': !isTouch, 'with-logo-maker': showLMFlow })}>
            {shouldDisplayMWBanner && <MobileWebHeroBanner video={video} />}
            {!loggedIn && <SeoMarkups breadcrumbs={breadcrumbs.slice(1)} faqs={seo_data.faqs} baseUrl={baseUrl} />}
            <div className="layout-row">
                <div className="subcategories-header-wrapper">
                    <SubcatHeader
                        breadcrumbs={breadcrumbs}
                        data={{
                            ...header,
                            categoryName,
                            scName: subCategory.name,
                            showHowFiverrWorks: !shouldDisplayMWBanner,
                        }}
                    />
                </div>
            </div>

            <LogoMakerFork
                listingsType={`${subCategory.name} SC`}
                listingSource={LISTING_SOURCES.SUBCATEGORY}
                showLMFlow={showLMFlow}
                setShowLMFlow={setShowLMFlow}
            />

            {!showLMFlow && (
                <div className="logo-design-flow">
                    {shouldShowExposedFilters ? (
                        <div className="layout-row">
                            <ExposedFilters />
                        </div>
                    ) : (
                        <Fragment>
                            {shouldDisplayNscTilesCarousel && (
                                <div className="layout-row">
                                    <NscTilesCarousel items={nestedSubCategoryItems} />
                                </div>
                            )}
                            {shouldShowVisualFilters && (
                                <div className="layout-row">
                                    <TopFiltersCarousel visualFilters={visualFilters} subCategoryId={subCategoryId} />
                                </div>
                            )}
                            {shouldShowExplicitLanguageFilter({ isTouch, subCategoryId }) && (
                                <div className="layout-row">
                                    <ExplicitLanguagePair filters={filters} />
                                </div>
                            )}
                        </Fragment>
                    )}
                    {shouldDisplayMobileFilters && (
                        <>
                            <StickyComponent>
                                <MobileFilters activeFilters={activeFilters} sorts={topBarSorting} filters={filters} />
                            </StickyComponent>
                            {displayTranslationButton && (
                                <MachineTranslationButtonWrapper gigsListings={[listings.gigs]} showSeparator={false} />
                            )}
                        </>
                    )}

                    {!isTouch && (
                        <Fragment>
                            {hasFilters && (
                                <TopBarFilters
                                    filters={filters}
                                    selectedFilters={appFilters.selectedFilters}
                                    selectedFiltersHierarchy={appFilters.selectedFiltersHierarchy}
                                    activeFilters={activeFilters}
                                    subCategory={subCategory}
                                    noGigs={noGigs}
                                    isDrawerOpen={isDrawerOpen}
                                    setIsDrawerOpen={setIsDrawerOpen}
                                    numOfResult={pagination?.total}
                                    topBarSorting={topBarSorting}
                                    selectedSorting={selectedSorting}
                                    rollouts={rollouts}
                                />
                            )}
                            <ActiveFilters setIsDrawerOpen={setIsDrawerOpen} />

                            {!noGigs && (
                                <div className="layout-row flex-items-end flex-between">
                                    <div className="flex flex-col">
                                        {displayTranslationButton && (
                                            <MachineTranslationButtonWrapper gigsListings={[listings.gigs]} />
                                        )}
                                        <div className="flex flex-items-center flex-wrap">
                                            {shouldShowNumberOfResults && (
                                                <NumberOfResults resultsCount={pagination?.total} />
                                            )}
                                            <PricingFactorDropdown showSeparator={shouldShowNumberOfResults} />
                                        </div>
                                    </div>

                                    {shouldShowSorter && <SortBy title={selectedSorting.alias} data={topBarSorting} />}
                                </div>
                            )}
                            {shouldShowPromoteBanner && (
                                <div className="layout-row content-row">
                                    {' '}
                                    <PromoteBanner />{' '}
                                </div>
                            )}
                        </Fragment>
                    )}

                    <ListingsImpressionContainer
                        rowObservers={[
                            promotedVideoStripListingRowObserver,
                            agenciesInListingsGDRowObserver,
                            adsPlacementRowObserver,
                        ]}
                    >
                        {shouldDisplayModalities && (
                            <div className="layout-row modalities-row">
                                <Modalities />
                            </div>
                        )}
                        {shouldDisplayRepeatedBuyers && (
                            <div className="layout-row repeated-buyers-recommendations-row">
                                <RepeatedBuyersRecommendations
                                    listings={listings}
                                    gigs={recommendations.repeatedBuyers}
                                    isLoading={isLoadingListings}
                                />
                            </div>
                        )}
                        {shouldShowFtbFriendly && (
                            <div className="layout-row ftb-friendly-recommendations-row">
                                <FtbFriendlyRecommendations
                                    isLoading={isLoadingListings}
                                    listings={listings}
                                    gigs={recommendations.ftbFriendly}
                                />
                            </div>
                        )}
                        {shouldShowSellersWhoSpeak && (
                            <div className="layout-row sellers-who-speak-recommendations-row">
                                <SellersWhoSpeakRecommendations
                                    listings={listings}
                                    assumedLanguage={assumedLanguage}
                                    gigs={sellersWhoSpeak}
                                    isLoading={isLoadingListings}
                                />
                            </div>
                        )}
                        <div className="layout-row content-row">
                            <ProFilteredListingsTitle
                                displayData={displayData}
                                categoryIds={categoryIds}
                                activeFilters={activeFilters}
                            />
                            {noGigs ? (
                                <NoResultsClearFilters
                                    displayData={displayData}
                                    listingAttributes={listingAttributes}
                                    flowName={flowName}
                                />
                            ) : (
                                <div className="content">
                                    <DynamicGigListings
                                        isLoading={isLoadingListings}
                                        listings={listings}
                                        banners={banners}
                                    />
                                </div>
                            )}
                        </div>
                    </ListingsImpressionContainer>

                    {shouldShowPagination && (
                        <Container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            paddingY={{ default: '6', sm: '12' }}
                        >
                            <Pagination
                                pagination={pagination}
                                listingAttributes={listingAttributes}
                                flowName={flowName}
                            />
                        </Container>
                    )}

                    {!isTouch && loggedIn && (
                        <RecommendationsCarousels
                            sorting={sorting}
                            activeFilters={activeFilters}
                            loggedIn={loggedIn}
                            shouldFetchData={!inFetchPersonalizationContentRollout}
                            personalizedContent={personalizedContent}
                        />
                    )}
                    {!loggedIn && (
                        <div className="layout-row">
                            <SeoSubCategory
                                faqs={faqs}
                                title={title}
                                answersLink={answersLink}
                                relatedLinksTitle={relatedLinksTitle}
                                relatedLinks={relatedLinks}
                                relatedSkillsTitle={relatedSkillsTitle}
                                relatedSkillsLinks={relatedSkillsLinks}
                                relatedGuides={relatedGuides}
                                seeMoreText={seeMoreText}
                                seeMoreLink={seeMoreLink}
                                cachedSlug={cachedSlug}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

SubCategories.propTypes = SubCategoriesPropTypes;

export default SubCategories;
