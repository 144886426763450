import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { INDICATOR_DOTS_NUMBER, DIRECTIONS } from '../Slider/constants';
import './index.scss';

class SlidesIndicator extends Component {
    constructor(props) {
        super(props);

        this.startIndex = 0;
        this.endIndex = INDICATOR_DOTS_NUMBER - 1;
    }

    renderDots() {
        this.renderIndexes();

        return this.buildDotsIndicator();
    }

    renderIndexes() {
        const { currentPage, direction } = this.props;

        if (direction === DIRECTIONS.PREV && currentPage + 1 === this.startIndex) {
            this.startIndex -= 1;
            this.endIndex -= 1;
        } else if (direction === DIRECTIONS.NEXT && currentPage - 1 === this.endIndex) {
            this.startIndex += 1;
            this.endIndex += 1;
        }
    }

    buildDotsIndicator() {
        const { slides, currentPage, direction } = this.props;

        const length = slides.length - 1;

        return slides.map((slide, index) => {
            const active = slide.index === currentPage;
            let edgeDot, hiddenDot;

            if (this.startIndex !== 0 && this.endIndex < length) {
                edgeDot = index === this.startIndex - 1 || index === this.endIndex + 1;
                hiddenDot = index < this.startIndex - 1 || index > this.endIndex + 1;
            } else if (this.startIndex === 0) {
                edgeDot = index === INDICATOR_DOTS_NUMBER;
                hiddenDot = index > INDICATOR_DOTS_NUMBER;

                if (currentPage === length && direction === DIRECTIONS.PREV) {
                    this.startIndex = slides.length - INDICATOR_DOTS_NUMBER;
                    this.endIndex = length;
                }
            } else if (this.endIndex >= length) {
                edgeDot = index === length - INDICATOR_DOTS_NUMBER;
                hiddenDot = index < length - INDICATOR_DOTS_NUMBER;

                if (currentPage === 0) {
                    this.startIndex = 0;
                    this.endIndex = INDICATOR_DOTS_NUMBER - 1;

                    hiddenDot = false;
                }
            }

            const classes = classnames('dot', { active }, { edge: edgeDot }, { hide: hiddenDot });

            return <li key={slide.index} className={classes}></li>;
        });
    }

    render() {
        const { slides } = this.props;

        if (slides.length <= 1) {
            return null;
        }

        return <ul className="slider-indicator">{this.renderDots()}</ul>;
    }
}

SlidesIndicator.defaultProps = {
    slides: [],
};

SlidesIndicator.propTypes = {
    slides: PropTypes.array,
    currentPage: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
};

export default SlidesIndicator;
