import { GIG_FEATURE_BADGE_TYPES, SELLER_LEVELS, TALENT } from '../../../../GigCardListings/utils/gigBadges/constants';
import styles from './index.module.scss';

export const GIG_CARD_TYPES = {
    [GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE]: {
        style: styles.choice,
        titleKey: 'gig_cards.badges.fiverr_choice.title',
        titleTemplateKey: {
            fiverr: null,
            choice: styles.choiceText,
        },
        tooltipKey: 'gig_cards.badges.fiverr_choice.tooltip',
    },
    [TALENT]: {
        style: styles.talent,
        titleKey: 'gig_cards.badges.talent.title',
        tooltipKey: 'gig_cards.badges.talent.tooltip',
    },
    [GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN]: {
        style: styles.buyItAgain,
        titleKey: 'gig_cards.badges.buy_it_again.caption',
    },
};

export const SELLER_LEVEL_TYPES = {
    [SELLER_LEVELS.TOP_RATED_SELLER]: {
        style: styles.topRated,
        titleKey: 'gig_cards.seller_level.top_rated',
        tooltipKey: 'gig_cards.seller_level.top_rated_seller_tooltip',
    },
    [SELLER_LEVELS.LEVEL_ONE_SELLER]: {
        style: null,
        titleKey: 'gig_cards.seller_level.level_one',
        tooltipKey: 'gig_cards.seller_level.level_one_seller_tooltip',
    },
    [SELLER_LEVELS.LEVEL_TWO_SELLER]: {
        style: null,
        titleKey: 'gig_cards.seller_level.level_two',
        tooltipKey: 'gig_cards.seller_level.level_two_seller_tooltip',
    },
};
