import React, { useReducer, useContext, createContext } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import { DEFAULT_LOCALE } from '@fiverr-private/listing_lib';
import { onTranslateBtnClick } from '../../service/translations/ugcTranslations';
import { createBiData } from '../../config/ugcTranslations';
import { isInCaterpillarsToggleRemovalTestWithProRemoved } from '../../experiments/onlineProToggleRemoval/utils';
import { shouldShowSideFilters } from '../../experiments/sideFilters/util/shouldShowSideFilters';
import { reducer } from './reducer';

// @deprecated - use the `useAppContext` hook instead
const AppContext = createContext();

const useAppContext = () => useContext(AppContext);

export const parseListingResult = ({
    knownCrawler,
    listings = [],
    appData = {},
    appFilters,
    activeFilters,
    professionFilterCarouselImages = [],
    requestContext,
    displayData = {},
    userData,
    currency,
    logoMakerConfig = {},
    subCategoryData,
    lowestGigsPrice,
    experimentsToAllocate = {},
    rollouts = {},
    listingAttributes,
    flowName,
    topBarSorting,
    errorContext,
    assumedLanguage,
    shouldShowHourlyRateBanners,
    shouldShowProjectManagementBanner,
    shouldShowExpressDelivery,
    shouldShowProEntryBanner,
    shouldShowPromoteBanner,
    showMediumBucketsModalities,
    inProfessionBasedFeature3Test,
    inProfessionBasedFeature4Test,
    inProfessionBasedUpdateRoleCardTest,
    complianceData,
}) => {
    const listingsIndex = listings[0] || {};

    const { isHeadQuery, showTranslatedUGC, features } = listingsIndex;

    const {
        flow,
        pagination,
        sorting = {},
        user,
        isBusiness,
        isBusinessUser,
        isExpertListings,
        searchApiPath,
        activeExperiments = {},
        clientExperimentsGroups = {},
        relatedSearches,
    } = appData;

    const { categoryId, searchComponentData = {} } = displayData;
    const {
        categoryIds,
        significantLeafCategories,
        dominantLeafCategory,
        dominateSubCategoryId,
        query,
        listingQuery,
        currentUser,
        userGuid,
        searchUrl,
    } = requestContext;
    const {
        abTests = {},
        isTouch,
        locale,
        userId,
        pageCtxId,
        queryParameters: { source: sourceComponent = '' } = {},
    } = getContext();

    const isDefaultLocale = locale === DEFAULT_LOCALE;

    return {
        flow,
        flowName,
        user,
        userGuid,
        currentUser, // TODO merge with user object
        isTouch,
        isHeadQuery,
        currency,
        pageCtxId,
        sourceComponent,
        assumedLanguage,
        locale,
        isDefaultLocale,
        knownCrawler,
        listings,
        appFilters,
        activeFilters,
        professionFilterCarouselImages,
        activeExperiments,
        clientExperimentsGroups,
        experimentsToAllocate,
        pagination,
        sorting,
        categoryIds,
        significantLeafCategories,
        dominantLeafCategory,
        dominateSubCategoryId,
        categoryId,
        query,
        listingQuery,
        displayQuery: searchComponentData?.modifiedQueryString || query,
        searchComponentData,
        searchUrl,
        searcherApiPath: searchApiPath,
        loading: false,
        sourceInfo: createBiData({ userId, userGuid, flow, pageCtxId }),
        showTranslatedUGC,
        wasTranslatedBefore: showTranslatedUGC,
        onTranslateBtnClick,
        isBusiness,
        isBusinessUser,
        userData,
        isExpertListings,
        logoMakerConfig,
        shouldShowHourlyRateBanners,
        shouldShowProjectManagementBanner,
        shouldShowExpressDelivery,
        shouldShowProEntryBanner,
        showMediumBucketsModalities,
        shouldShowPromoteBanner,
        shouldDisplayFees: features?.shouldDisplayFees || false,
        inProfessionBasedFeature3Test,
        inProfessionBasedFeature4Test,
        inProfessionBasedUpdateRoleCardTest,
        subCategoryData,
        relatedSearches,
        inReportNewImpressionsRollout: rollouts[ROLLOUTS.CAT_REPORT_NEW_IMPRESSIONS],
        inVipersProjectPartnerBannerRollout: rollouts[ROLLOUTS.VIPERS_PROJECT_PARTNER_BANNER],
        isBriefModalRollout: rollouts[ROLLOUTS.VIPERS_BRIEF_AND_MATCH_MODAL],
        inFetchPersonalizationContentRollout: rollouts[ROLLOUTS.CAT_FETCH_PERSONALIZATION_CONTENT_BY_FUNNEL],
        inMustangsVerifyAssetsRollout: rollouts[ROLLOUTS.MUSTANGS_VERIFY_ASSETS],
        inCaterpillarsToggleRemovalTestWithProRemoved: isInCaterpillarsToggleRemovalTestWithProRemoved(
            abTests,
            isBusiness,
            isTouch
        ),
        inLionsSSYLSeeMoreButtonRollout: rollouts[ROLLOUTS.LIONS_SSYL_SEE_MORE],
        inOwlsSideFiltersTest: shouldShowSideFilters(isBusiness, isTouch, abTests),
        lowestGigsPrice,
        inProjectPanthersBannerFipNoResultPageRollout: rollouts[ROLLOUTS.PROJECT_PANTHERS_BANNER_FIP_NO_RESULT_PAGE],
        rollouts,
        isLoadingListings: false,
        listingAttributes,
        displayData,
        appData,
        topBarSorting,
        errorContext,
        complianceData,
        inStorksComplianceGeneralRollout: appData?.rollouts?.[ROLLOUTS.STORKS_COMPLIANCE_GENERAL],
    };
};

const AppProvider = ({ children, ...listingResult }) => {
    const initialState = parseListingResult(listingResult);

    const [appState, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ ...appState, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext, useAppContext };
