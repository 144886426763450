import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { NewBadge } from '@fiverr-private/badges';
import { Heading, Text } from '@fiverr-private/typography';
import { Container } from '@fiverr-private/layout_components';
import number from '../../../../../../shared/utils/formatting/number';
import { DISPLAY_TYPES } from '../../../../config/filters';
import { TAG_TYPES } from './consts';

const BadgeByType = {
    [TAG_TYPES.NEW]: <NewBadge />,
};

export const FilterHeader = ({ filter, displayType, showClear, onClear, aiTitleClass }) =>
    displayType === DISPLAY_TYPES.TOGGLE_DETAILED ? (
        filter.options.map((option) => (
            <Container as="header" display="flex" direction="column" key={option.alias}>
                <Container display="flex" alignItems="baseline">
                    <Heading as="h5" marginRight="1" className={aiTitleClass}>
                        {option.alias}
                    </Heading>
                    {!!option.tag && (
                        <Text as="span" marginX="1">
                            {BadgeByType[option.tag]}
                        </Text>
                    )}
                    {!!option.count && <Text as="span" marginX="1">{`(${number(option.count)})`}</Text>}
                </Container>
                {!!option.description && (
                    <Text size="b_sm" color="bodySecondary" maxWidth="80%">
                        {option.description}
                    </Text>
                )}
            </Container>
        ))
    ) : (
        <Container as="header">
            <Heading as="h5">{filter.alias}</Heading>
            {showClear && (
                <Container as="span" onClick={onClear}>
                    <I18n k="search_perseus.general.clear" />
                </Container>
            )}
        </Container>
    );
