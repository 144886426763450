import { FILTER } from '@fiverr-private/listing_lib';
import { FlowName } from '@fiverr-private/listing_types';

export const MULTI = 'multi';
export const SINGLE = 'single';

export const DISPLAY_TYPES = {
    PRO: 'pro',
    AI_DELIVERY: 'ai_delivery',
    RADIO: 'radio',
    INPUT_RANGE: 'input_range',
    CHECKBOX: 'checkbox',
    TOGGLE: 'toggle',
    TOGGLE_DETAILED: 'toggle_detailed',
    CHECKBOX_GROUP: 'checkbox_group',
    SELECT: 'select',
    MULTI_PAIRS_SELECT: 'multi_pairs_select',
    AUTO_COMPLETE: 'autocomplete',
    NUMBER_INPUT: 'number_input',
    PRICE_BUCKETS: 'price_buckets',
};

export const FILTER_TYPES = {
    GLOBAL: 'global',
    SPECIFIC: 'specific',
    SUB_CATEGORY: 'sub_category',
    MORE: 'more',
    TOGGLERS: 'togglers',
};

export const NON_SHOWN_SELECTED_FILTERS = {
    [FILTER.PRICING_FACTOR.ID]: true,
    [FILTER.AI_DELIVERY.ID]: true,
};

export const TOP_GROUPING = {
    CATEGORY: 0,
    SERVICE: 1,
    SELLER: 2,
    DELIVERY_TIME: 3,
    BUDGET: 4,
    TOGGLERS: 5,
    VISUAL_FILTERS: 6,
    PRICING_FACTOR: 7,
    SORT_BY: 8,
    METADATA_FILTERS: 9,
    PROFESSION: 10,
    PROFICIENCIES: 11,
};

export const SORT_GROUPING = 'sort';

export const TOP_GROUPING_KEYS = {
    0: 'category',
    1: 'service',
    2: 'seller',
    3: 'delivery_time',
    4: 'budget',
    5: 'togglers',
    6: 'visual_filter',
};

export const SEARCH_FILTER = {
    [FILTER.DELIVERY_TIME.ID]: 'filtered_duration',
    [FILTER.PACKAGE_INCLUDES.ID]: 'filtered_factors',
    [FILTER.GIG_PRICE_RANGE.ID]: 'filtered_price',
};

export const TOP_GROUPING_MAP = {
    [FILTER.DELIVERY_TIME.ID]: TOP_GROUPING.DELIVERY_TIME,
    [FILTER.GIG_PRICE_RANGE.ID]: TOP_GROUPING.BUDGET,
    [FILTER.PRO.ID]: TOP_GROUPING.TOGGLERS,
    [FILTER.AI_DELIVERY.ID]: TOP_GROUPING.TOGGLERS,
    [FILTER.HAS_HOURLY.ID]: TOP_GROUPING.SELLER,
    [FILTER.IS_AGENCY.ID]: TOP_GROUPING.SELLER,
    [FILTER.SELLER_LEVEL.ID]: TOP_GROUPING.SELLER,
    [FILTER.SELLER_TIER.ID]: TOP_GROUPING.SELLER,
    [FILTER.IS_SELLER_ONLINE.ID]: TOP_GROUPING.TOGGLERS,
    [FILTER.SELLER_LANGUAGE.ID]: TOP_GROUPING.SELLER,
    [FILTER.SELLER_LOCATION.ID]: TOP_GROUPING.SELLER,
    [FILTER.PACKAGE_INCLUDES.ID]: TOP_GROUPING.SERVICE,
    [FILTER.PRICE_BUCKETS.ID]: TOP_GROUPING.BUDGET,
    [FILTER.PRICING_FACTOR.ID]: TOP_GROUPING.PRICING_FACTOR,
    [FILTER.PROFESSION.ID]: TOP_GROUPING.PROFESSION,
    [FILTER.PROFICIENCIES.ID]: TOP_GROUPING.PROFICIENCIES,
};

export const MAX_GIG_PRICE_RANGE = 50000;
export const MIN_GIG_PRICE_RANGE = 0;

export const NON_FACET_FILTER_IDS = [
    FILTER.DELIVERY_TIME.ID,
    FILTER.GIG_PRICE_RANGE.ID,
    FILTER.PRICE_BUCKETS.ID,
    FILTER.PRICING_FACTOR.ID,
];

export const EXCLUDED_NON_FACET_FILTERS_BY_FLOW: Partial<Record<FlowName, string[]>> = {
    roleSearchFlow: [FILTER.DELIVERY_TIME.ID, FILTER.PRICE_BUCKETS.ID],
};

export const SORT_BY_COMPONENT_ID = 'sort-by';

export const GLOBAL_FILTERS = [
    FILTER.DELIVERY_TIME.ID,
    FILTER.GIG_PRICE_RANGE.ID,
    FILTER.SELLER_LANGUAGE.ID,
    FILTER.SELLER_LOCATION.ID,
    FILTER.SELLER_LEVEL.ID,
    FILTER.SELLER_TIER,
    FILTER.IS_SELLER_ONLINE.ID,
    FILTER.PRO.ID,
];

export const TOUCH_EXCLUDED = [];

export const SELECTED_EXCLUDED = [
    FILTER.IS_SELLER_ONLINE.ID,
    FILTER.PRO.ID,
    FILTER.AI_DELIVERY.ID,
    FILTER.PRICING_FACTOR.ID,
];
export const SELECTED_EXCLUDED_TOGGLE_REMOVAL_TEST = [FILTER.AI_DELIVERY.ID, FILTER.PRO.ID, FILTER.PRICING_FACTOR.ID];

export const PATHFINDER = {
    NESTED_SUB_CATEGORY_PAGE: 'short_category_nested_sub_category',
    SUB_CATEGORY_PAGE: 'short_category_sub_category',
    CATEGORY_PAGE: 'category',
    SEARCH: 'search_gigs',
    ROOT: 'root',
    STORE: 'store',
    LOGO_MAKER_PAGE: 'logo_maker_buyer_landing_page',
    STATIC_PAGE: 'static_page',
    CREATE_BRIEF_AND_MATCH_PAGE: 'create_brief_and_match_page',
};

export const CATEGORY_FILTER_SOURCE = 'category_filters';
export const UNKNOWN_ALIAS = 'Unknown Alias';
export const MAX_LEAF_CATEGORIES_CHILDREN = 10;
