export default {
    VIDEO_APPROVED_STATUS: 0,
    AUDIO_APPROVED_STATUS: 1,
    DELIVERY_FAILED_FORMATTING_STATUS: 'fail',
    DEFAULT_IMAGE_TYPE: 't_main1',
    PLACEHOLDER: 'PLACEHOLDER',
    TYPES: {
        ImageAsset: 'image',
        VideoAsset: 'video',
        AudioAsset: 'audio',
        DeliveryAsset: 'delivery',
    },
    TYPE_KEYS: {
        ImageAsset: 'ImageAsset',
        VideoAsset: 'VideoAsset',
        AudioAsset: 'AudioAsset',
        DeliveryAsset: 'DeliveryAsset',
    },
    ORDER: {
        video: 0,
        image: 2,
        audio: 1,
    },
    DELIVERY_ORDER: {
        video: 0,
        image: 1,
        audio: 2,
    },
    URLS: {
        stream: '//view.vzaar.com/ID/video',
        thumbnail: 'https://fiverr-res.cloudinary.com/PLACEHOLDER,q_auto,f_auto/v1/vzrthumb/ID/image',
        audioDefaultThumbnail:
            'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto/search_perseus/default-audio-bg.png',
        deliveryImage: 'https://fiverr-res.cloudinary.com/PLACEHOLDER,q_auto,f_auto/deliveries/ID/original/FILE_NAME',
    },
};
