import { GIG_ENGINE_TYPES } from './constants';

/**
 * Whether or not a gig is considered a "rising talent".
 * @param  {String}  [gigType=''] The gig's type.
 * @return {Boolean}
 */
export const isRisingTalent = (gigType = '') => gigType === GIG_ENGINE_TYPES.LIQUID_ENGINE;

/**
 * Whether or not a gig is considered a buy again option.
 * @param  {String}  [gigType=''] The gig's type.
 * @return {Boolean}
 */
export const isBuyItAgain = (gigType = '') => gigType === GIG_ENGINE_TYPES.BUY_IT_AGAIN;

/**
 * Whether or not a gig is considered a budget based recommendation.
 * @param  {String}  [gigType=''] The gig's type.
 * @return {Boolean}
 */
export const isAverageSellingPrice = (gigType = '') => gigType === GIG_ENGINE_TYPES.AVERAGE_SELLING_PRICE;
