import React from 'react';
import { shape, number, string, object, func } from 'prop-types';
import { noop } from 'lodash';
import { NotableClientsCompactBar } from '@fiverr-private/seller_presence';
import { useGigListingsContext } from '../../../GigCardListings/context/GigListingsContext';
import useNotableClientsDisplay, { DISPLAY_BREAK_POINT } from './useNotableClientsDisplay';
import './index.scss';

const NotableClientsWrapper = ({
    sellerInfoContainerCurrent,
    seller,
    gigId,
    className,
    labelClassName,
    logoClassName,
    onLabelClick = noop,
}) => {
    const { notableClients, currentPage } = useGigListingsContext();
    const sellerNotableClients = notableClients?.[seller.id];
    const viewPoint = useNotableClientsDisplay(sellerInfoContainerCurrent, currentPage);

    if (viewPoint === DISPLAY_BREAK_POINT.NONE) {
        return null;
    }

    const limitClients = viewPoint !== DISPLAY_BREAK_POINT.FULL ? 1 : undefined;
    const computedLabelClassName = viewPoint === DISPLAY_BREAK_POINT.NO_LABEL ? 'hidden' : labelClassName;

    return (
        <span className="seller-notable-clients-wrapper" onClick={onLabelClick}>
            <NotableClientsCompactBar
                notableClients={sellerNotableClients}
                limitClients={limitClients}
                origin={currentPage}
                containerRef={sellerInfoContainerCurrent}
                className={className}
                seller={seller}
                gigId={gigId}
                labelClassName={computedLabelClassName}
                logoClassName={logoClassName}
            />
        </span>
    );
};

NotableClientsWrapper.propTypes = {
    sellerInfoContainerCurrent: object,
    gigId: number,
    seller: shape({
        id: number,
        name: string,
    }),
    className: string,
    labelClassName: string,
    logoClassName: string,
    onLabelClick: func,
};

export default NotableClientsWrapper;
