const { ALLOCATIONS } = require('../../../constants/experiments');

const isInExperiment = (experimentId, abTests = {}) =>
    isInExperimentGroup(experimentId, [ALLOCATIONS.TEST_B, ALLOCATIONS.TEST_C, ALLOCATIONS.TEST_D], abTests);

const isInExperimentGroup = (experimentId, experimentGroups = [], abTests = {}) => {
    const experimentValue = abTests[experimentId];
    const inExperimentGroup = experimentGroups.includes(String(experimentValue));

    return !!experimentValue && inExperimentGroup;
};

const getVariationNumber = (experimentId, abTests = {}) => {
    const variation = abTests[experimentId];

    return Number(variation);
};

const isAllocated = (experimentId, abTests = {}) => {
    const variation = Number(abTests[experimentId]);

    return variation >= Number(ALLOCATIONS.CONTROL);
};

module.exports = {
    isInExperiment,
    isInExperimentGroup,
    getVariationNumber,
    isAllocated,
};
