import {
    getBuyerAssumedLanguage,
    getSellerValidatedLanguages,
    DEFAULT_FIVERR_LANGUAGE,
} from '@fiverr-private/localization';

/**
 * Check if the seller is proficient enough in the buyer's assumed language
 *
 * @param {String} buyerLanguageCode assumed language code of the buyer (e.g 'en', 'de')
 * @param {{ name: string, level: number }[]} sellerLanguages array of language code and the level of proficiency in that language
 * @returns {Boolean} true if the buyer's assumed language is in the seller's proficient languages
 */
export const isBuyerLanguageMatchWithSeller = (buyerLanguageCode, sellerLanguages) =>
    sellerLanguages.some(({ name }) => name === buyerLanguageCode);

export const shouldDisplayISpeak = ({
    locale,
    browserLanguage,
    countryCode,
    proficientLanguages,
    sellerCountryCode,
    displayISpeak,
    displayCountryFlag,
}) => {
    const buyerAssumedLanguageCode = getBuyerAssumedLanguage({ locale, browserLanguage, countryCode });
    const isBuyerAssumedLanguageEnglish = buyerAssumedLanguageCode === DEFAULT_FIVERR_LANGUAGE;

    return (
        !displayCountryFlag &&
        displayISpeak &&
        !isBuyerAssumedLanguageEnglish &&
        isBuyerLanguageMatchWithSeller(
            buyerAssumedLanguageCode,
            getSellerValidatedLanguages({ proficientLanguages, countryCode: sellerCountryCode })
        )
    );
};
