export const COMPONENT_NAMES = {
    PROFESSION_FILTERS: 'profession_filters',
};

export const ELEMENT_TYPE = {
    VISUAL: 'visual',
};

export const BI_EVENTS_TYPES = {
    CLICK: 'click_on_exposed_filters',
    IMPRESSION: 'exposed_filters_is_shown',
};

export const GROUP_TYPES = {
    USER_ACTIONS: 'user_action',
};
