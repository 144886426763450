import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const CardHeader = ({ children }) => <div className={classNames(styles.cardHeader, 'flex', 'm-b-16')}>{children}</div>;

CardHeader.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CardHeader;
