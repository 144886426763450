import React from 'react';
import PropTypes from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { isInExperimentGroup } from '../../../../GigCardListings/ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS } from '../../../../constants/experiments';
import { GIG_FEATURE_BADGE_TYPES } from '../../../../GigCardListings/utils/gigBadges/constants';
import { ProOverlayBadge } from '../../../ModernGigCards/Components/Badge/OverlayBadges/ProOverlayBadge';
import { FiverrChoiceBadge } from '../../../ModernGigCards/Components/Badge/OverlayBadges/FiverrChoiceBadge';

const MAP_BADGE_TYPE_TO_COMPONENT = {
    [GIG_FEATURE_BADGE_TYPES.PRO]: ProOverlayBadge,
    [GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE]: FiverrChoiceBadge,
};

export const OverlayBadge = ({ badge, isAgency }) => {
    const { abTests } = getContext();

    if (!badge) {
        return null;
    }

    const showOverlayForSellerLevel = isInExperimentGroup(
        EXPERIMENTS.HAWKS_OVERLAY_BADGES,
        [ALLOCATIONS.TEST_B],
        abTests
    );
    if (!showOverlayForSellerLevel) {
        return null;
    }

    const BadgeComponent = MAP_BADGE_TYPE_TO_COMPONENT[badge.type];
    if (!BadgeComponent) {
        return null;
    }

    const additionalProBadgeProps = badge.type === GIG_FEATURE_BADGE_TYPES.PRO ? { isAgency } : {};

    return (
        <span className="overlay">
            <BadgeComponent appendTooltipToBody {...additionalProBadgeProps} />
        </span>
    );
};

OverlayBadge.propTypes = {
    badge: PropTypes.shape({
        type: PropTypes.string,
    }),
    isAgency: PropTypes.bool,
};
