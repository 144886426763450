export const GIG_STATUSES = {
    ONHOLD: 'ONHOLD',
    SUSPENDED: 'SUSPENDED',
    APPROVED: 'APPROVED',
    DELETED: 'DELETED',
    DENIED: 'DENIED',
    BLOCKED: 'BLOCKED',
    SUSPIC: 'SUSPIC',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING',
    ATTENTION: 'ATTENTION',
};
