import { pick, isEmpty, isObject, camelCase } from 'lodash';
import { getDisplayedNotableClients } from '@fiverr-private/seller_presence/src/utils/helpers/notableClients';
import { EXPERTISE_TYPE } from '../../../constants';
import { HIGHLIGHTS_DATA_KEYS, HIGHLIGHTS_ID_LIST, SELLER_LEVEL } from '../../../shared/constants';
import { PRIORITY } from '../../SellerBadge';
import { BADGES } from '../../SellerBadge/constants';
import { parseNotableClients } from '../utils';
import { MAX_DISPLAYED_NOTABLE_CLIENTS } from './constants';

export const getExpertiseType = (type) => {
    switch (type) {
        case EXPERTISE_TYPE.LEAF_CATEGORY_ID: {
            return 1;
        }

        case EXPERTISE_TYPE.SKILL: {
            return 2;
        }

        default: {
            return 0;
        }
    }
};

export const getHighlights = (highlight, highlightsData) => {
    if (!highlight) {
        return [];
    }

    const activeHighlight = Object.keys(highlight).find((key) => highlight[key]);

    if (!activeHighlight) {
        return [];
    }

    const { type, data = {} } = getHighlightTypeAndData(activeHighlight, highlightsData);

    return [
        {
            type,
            parameters: Object.entries(data).map(([key, value]) => ({
                parameter_name: key,
                parameter_value: isObject(value) ? JSON.stringify(value) : value,
            })),
        },
    ];
};

const getHighlightTypeAndData = (type, highlightsData) => {
    switch (type) {
        case HIGHLIGHTS_ID_LIST.WORKED_WITH_YOUR_TEAM: {
            return {
                type: 1,
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.TEAM_MEMBERS),
            };
        }

        case HIGHLIGHTS_ID_LIST.WORKED_WITH_CLIENTS_IN_INDUSTRY: {
            return {
                type: 2,
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.COMMON_INDUSTRY),
            };
        }

        case HIGHLIGHTS_ID_LIST.SAVED_BY_TEAM_MEMBER: {
            return {
                type: 3,
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.SAVED_BY),
            };
        }

        case HIGHLIGHTS_ID_LIST.BUYER_KEEP_COMING_BACK: {
            return { type: 4 };
        }

        case HIGHLIGHTS_ID_LIST.HIGHLY_RESPONSIVE: {
            return { type: 5 };
        }

        case HIGHLIGHTS_ID_LIST.SELLER_SPEAKS: {
            return {
                type: 6,
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.SELLER_LANGUAGE_CODE),
            };
        }

        case HIGHLIGHTS_ID_LIST.ON_VACATION: {
            return {
                type: 7,
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.VACATION_END_DATE),
            };
        }

        case HIGHLIGHTS_ID_LIST.INACTIVE: {
            return { type: 8 };
        }

        default: {
            return { type: 0 };
        }
    }
};

export const getLevel = (level) => {
    switch (level) {
        case SELLER_LEVEL.LEVEL_ONE: {
            return 1;
        }

        case SELLER_LEVEL.LEVEL_TWO: {
            return 2;
        }

        case SELLER_LEVEL.TOP_RATED_SELLER: {
            return 3;
        }

        case SELLER_LEVEL.NO_LEVEL:
        default: {
            return 0;
        }
    }
};

export const getNotableClients = (notableClients) => {
    if (isEmpty(notableClients)) {
        return [];
    }

    let displayedClients = getDisplayedNotableClients(parseNotableClients(notableClients));
    displayedClients = displayedClients.slice(0, MAX_DISPLAYED_NOTABLE_CLIENTS);
    const clientNames = displayedClients.map((client) => ({ name: client?.enriched_details?.name }));

    return clientNames;
};

export const getBadges = (badges = {}) => {
    const result = [];

    for (const badgeType of PRIORITY) {
        if (badges[badgeType]) {
            switch (badgeType) {
                case BADGES.CHOICE: {
                    result.push({ name: 3 });
                    return result;
                }

                case BADGES.PRO: {
                    result.push({ name: 5 });
                    return result;
                }

                default:
            }
        }
    }

    return result;
};

export const convertKeysToCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToCamelCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((result, key) => {
            const camelCaseKey = camelCase(key);
            result[camelCaseKey] = convertKeysToCamelCase(obj[key]);
            return result;
        }, {});
    }
    return obj;
};
