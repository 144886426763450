import React, { useEffect, useState, FC, PropsWithChildren } from 'react';
import { isNil } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import {
    EducationTooltip,
    EducationTooltipAnchor,
    EducationTooltipContent,
    EducationTooltipHeader,
    EducationTooltipBadge,
} from '@fiverr-private/education_tooltip';
import { Fade } from '@fiverr-private/transition';
import { Typography } from '@fiverr-private/typography';
import {
    reportClickHourlyRateEducationTooltipDismissed,
    reportHourlyRateEducationTooltipShown,
} from '../banners/FipProEntryBanner/bqReports';

export interface HourlyRateEducationTooltipProps {
    show: boolean;
    delay?: number;
}

const HourlyRateEducationTooltip: FC<PropsWithChildren<HourlyRateEducationTooltipProps>> = ({
    delay,
    show,
    children,
}) => {
    const [isEducationTooltipShown, setIsEducationTooltipShown] = useState(isNil(delay));

    useEffect(() => {
        if (isNil(delay) || !show) {
            return;
        }
        const timer = setTimeout(() => {
            setIsEducationTooltipShown(true);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [delay, show]);

    useEffect(() => {
        if (isEducationTooltipShown) {
            reportHourlyRateEducationTooltipShown();
        }
    }, [isEducationTooltipShown]);

    const handleOpenChanged = (isOpen: boolean) => {
        if (!isOpen) {
            reportClickHourlyRateEducationTooltipDismissed();
        }
    };

    if (!isEducationTooltipShown || !show) {
        return <>{children}</>;
    }

    return (
        <Fade easing="easeInOut">
            <EducationTooltip
                disableFlip
                variant="white"
                position="bottom"
                tooltipStorageKey="MP_HOURLY_RATE_FILTER_EDUCATION_TOOLTIP"
                onOpenChanged={handleOpenChanged}
            >
                <EducationTooltipAnchor>{children}</EducationTooltipAnchor>
                <EducationTooltipContent closeOnClickOutside={false}>
                    <EducationTooltipHeader>
                        <I18n k="search_perseus.filter.hourly_rate_education_tooltip.title" />
                        <EducationTooltipBadge />
                    </EducationTooltipHeader>
                    <Typography fontWeight="normal">
                        <I18n k="search_perseus.filter.hourly_rate_education_tooltip.description" />
                    </Typography>
                </EducationTooltipContent>
            </EducationTooltip>
        </Fade>
    );
};

export default HourlyRateEducationTooltip;
