import React from 'react';
import PropTypes from 'prop-types';
import { GigListingsContextProvider } from '../../context/GigListingsContext';
import transformListings from '../../ListingsTransformer';

const CarouselTransformer = (transformedProps, props) => ({
    ...transformedProps,
    sliderBaseNum: props.sliderBaseNum,
    sliderBreakpoints: props.sliderBreakpoints,
});

const transformProps = (gigCardType, asCarousel, props) => {
    let transformedProps = transformListings(gigCardType, props);

    if (asCarousel) {
        transformedProps = CarouselTransformer(transformedProps, props);
    }

    return transformedProps;
};

export const withListingsTransformer =
    (gigCardType, asCarousel = false) =>
    (WrappedComponent) =>
        function WithListingsTransformer(props) {
            const listingsProps = transformProps(gigCardType, asCarousel, props);
            const { context, experimentalFeatures, notableClients, onImpression } = listingsProps;
            const { currency, currentPage } = context;

            return (
                <GigListingsContextProvider
                    value={{
                        currencyObj: currency,
                        currentPage,
                        notableClients,
                        experimentalFeatures,
                        onImpression,
                    }}
                >
                    <WrappedComponent {...listingsProps} />
                </GigListingsContextProvider>
            );
        };

withListingsTransformer.propTypes = {
    isLoading: PropTypes.bool,
};
