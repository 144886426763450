import { isNil } from 'lodash';
import { ListingAttributes } from '@fiverr-private/listing_types';
import { activeFiltersToQueryString } from '../activeFiltersToQueryString/activeFiltersToQueryString';
import { abTestsToQueryString } from '../abTestsToQueryString/abTestsToQueryString';
import { normalizeCurrencyInActiveFilters } from '../../util/normalizeCurrencyInActiveFilters/normalizeCurrencyInActiveFilters';

export const buildGoSearcherQueryParams = (listingAttributes: ListingAttributes): string => {
    const {
        id,
        query,
        categoryIds,
        sortBy,
        page,
        pageSize,
        activeFilters,
        isRncUser,
        abTests,
        platform,
        offset,
        currencyExchangeRate,
        assumedLanguage,
        userAgent,
        requestIp,
        shouldSuggest,
        utmCampaign,
        utmMedium,
        utmSource,
        leafClassification,
        showProfessionCampaign,
    } = listingAttributes;

    const normalizedActiveFilters = normalizeCurrencyInActiveFilters(activeFilters, currencyExchangeRate);

    const queryParamsMapper = {
        page_ctx_id: id,
        query_string: query,
        category_id: categoryIds?.categoryId,
        sub_category_id: categoryIds?.subCategoryId,
        nested_sub_category_id: categoryIds?.nestedSubCategoryId,
        filter: sortBy,
        page,
        page_size: pageSize,
        ref: activeFiltersToQueryString(normalizedActiveFilters) || null,
        is_rnc_user: isRncUser,
        user_allocated_tests: abTestsToQueryString(abTests),
        offset,
        currency_exchange_rate: currencyExchangeRate,
        assumed_language: assumedLanguage,
        request_ip: requestIp,
        platform,
        user_agent: userAgent,
        should_suggest: shouldSuggest,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_source: utmSource,
        leaf_classification_id: leafClassification?.toString(),
        show_profession_campaign: showProfessionCampaign,
    };

    const queryParams = new URLSearchParams();

    Object.entries(queryParamsMapper).forEach(([key, value]) => {
        if (!isNil(value)) {
            queryParams.set(key, value.toString());
        }
    });

    return queryParams.toString();
};
