import { isBuyItAgain, isRisingTalent } from '../gigTypes';
import { DEFAULT_CARD, GIG_CARD, LIST_VIEW_CARD, SMALL_GIG_CARD } from '../constants';
import { FEATURED, TALENT, PROMOTED_GIGS, TOOLTIP_EXCLUDED_BADGES, GIG_FEATURE_BADGE_TYPES } from './constants';

const badgeConditions = {
    [GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE]: ({ fiverrChoice }) => !!fiverrChoice,
    [GIG_FEATURE_BADGE_TYPES.PRO]: ({ pro, sbss }) => pro && !sbss,
    [FEATURED]: ({ featured }) => featured,
    [TALENT]: ({ risingTalent }) => risingTalent,
    [GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN]: ({ buyItAgain }) => buyItAgain,
};

const badgeTypes = Object.keys(badgeConditions);

const ALLOWED_BADGES = {
    [DEFAULT_CARD]: [...badgeTypes],
    [GIG_CARD]: [...badgeTypes],
    [LIST_VIEW_CARD]: [
        GIG_FEATURE_BADGE_TYPES.PRO,
        TALENT,
        GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE,
        GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN,
    ],
    [SMALL_GIG_CARD]: [GIG_FEATURE_BADGE_TYPES.PRO],
};

export const getGigBadgeType = ({ cardType, type, isPro, isFeatured, isFiverrChoice, features = {} }) => {
    const risingTalent = isRisingTalent(type),
        buyItAgain = isBuyItAgain(type),
        sbss = buyItAgain;

    const dominateType = badgeTypes.find((badgeType) =>
        badgeConditions[badgeType]({
            featured: isFeatured,
            pro: isPro,
            fiverrChoice: isFiverrChoice,
            risingTalent,
            buyItAgain,
            sbss,
        })
    );

    const excludeTooltip = !features.showBadgeTooltip || TOOLTIP_EXCLUDED_BADGES.includes(dominateType);
    const allowedBadge = (ALLOWED_BADGES[cardType] || ALLOWED_BADGES[DEFAULT_CARD]).includes(dominateType);

    return { type: allowedBadge ? dominateType : undefined, excludeTooltip };
};

export const getGigImpressionBadgesReport = ({ badge, type }) => ({
    is_pro: badge.type === GIG_FEATURE_BADGE_TYPES.PRO,
    is_featured: badge.type === FEATURED,
    is_fiverr_choice: badge.type === GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE,
    is_rising_talent: badge.type === TALENT,
    is_buy_it_again: badge.type === GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN,
    is_promoted_gig: type === PROMOTED_GIGS,
});
