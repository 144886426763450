import { arrayOf, shape, oneOf, func, string, number, node, bool, object } from 'prop-types';
import expertCard, { displayOptionsType } from '../../components/ExpertCard/types';
import { LISTINGS_LAYOUT } from '../../constants';
import { cardVariantType, currencyType } from '../../shared/types';

export default {
    /**
     * An array of all the items where each item is an expert card
     */
    items: arrayOf(shape(expertCard)),
    /**
     * An array of banners to be placed in grid among expert cards
     */
    banners: arrayOf(node),
    /**
     * Currency context
     */
    currency: currencyType,
    /**
     * The type of the layout
     */
    layout: oneOf(Object.values(LISTINGS_LAYOUT)),
    /**
     * Callback to run when impression occurs
     */
    onImpression: func,
    /**
     * callback to invoke if user successfully removed from experts list. optional.
     * */
    onRemoveFromExpertsSuccess: func,
    /**
     * The source param in the url
     */
    source: string,
    /**
     * Listings SubcategoryId for Project Partner Banner
     */
    subCategoryId: number,
    /**
     * Variant of the card - currently we have one variant that indicates if visuals gallery should be shown
     */
    cardVariant: cardVariantType,
    /**
     * Display options for the card
     */
    displayOptions: displayOptionsType,
    /**
     * Whether to force small card
     */
    forceSmallCard: bool,
    /**
     * Render function to render custom footer content
     */
    renderFooterContent: func,
    /**
     * Banner to render inside the cards in specific position
     */
    banner: shape({ position: number, Component: oneOf([func, node]) }),
    /**
     * Props override for the save to list component
     */
    saveToListProps: object,
    /**
     * Notes for the expert card
     */
    notes: object,
    /**
     * Whether the component is rendered in Pro Catalog Grid Experiment
     */
    inProCatalogGridExp: bool,
    /**
     * Whether the component should show hourly rate mode in cards
     */
    showHourlyPrice: bool,
    hourlyRateRolloutActive: bool,
    /**
     * Whether to show loading skeleton
     */
    isLoading: bool,
    /**
     * Amount of skeleton to show (default to 3)
     */
    skeletonAmount: number,
    pageName: string,
};
