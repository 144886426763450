const { logger } = require('@fiverr-private/obs');
const { translate: i18nTranslate } = require('@fiverr-private/i18n-react');
const { DEFAULT_LOCALE } = require('../constants');
const { generateFormData } = require('./formData');

const translate = (k, locale = DEFAULT_LOCALE) => {
    try {
        return i18nTranslate(k, { locale });
    } catch (err) {
        logger.error(`Failed to get default value for key ${k}`);
        return false;
    }
};

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`;

const isBrowser = () => typeof window !== 'undefined' && typeof window.document !== 'undefined';

// README: https://docs.fiverr-gw.com/platform/perseus/migration/migrating_from_v2#no-more-window-over-node
const getWindow = () => (typeof window !== 'undefined' ? window : undefined);

module.exports = {
    translate,
    generateId,
    generateFormData,
    isBrowser,
    getWindow,
};
