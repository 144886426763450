export const PRICE_LABEL_TYPES = {
    DEFAULT: 'default',
    BOUGHT_FOR: 'bought_for',
    USUALLY_BOUGHT_FOR: 'usually_bought_for',
};

export const PRICE_DEFAULT_LABELS = {
    STARTING_AT: 'starting_at',
    FROM: 'from',
};

export const NUMBER_PARTS = {
    INTEGER: 'integer',
    FRACTION: 'fraction',
    DECIMAL: 'decimal',
};
