import { array, arrayOf, bool, func, number, object, oneOf, oneOfType, shape, string } from 'prop-types';
import { URL_TARGET } from '../Components/constants';
import { excludedBadgesType } from '../../shared/types';

const uIdType = oneOfType([string, number]);

const idType = oneOfType([string.isRequired, number.isRequired]);

const TrackerType = shape({
    trackEvent: func.isRequired,
});

const AssetType = shape({
    type: string.isRequired,
    cloudBaseUrl: string,
    image: object,
    title: string,
    streamUrl: string,
});

const SellerType = shape({
    type: string,
    id: idType,
    name: string,
    url: string,
    imgSrc: string,
    employeesCount: number,
    established: number,
    highlights: arrayOf(string),
    bannerImageUrl: string,
});

const RatingsType = shape({
    count: oneOfType([string, number]),
    rating: oneOfType([string, number]),
});

const BadgeType = shape({
    type: string,
    excludeTooltip: bool,
});

const GigIdType = idType;

const GigUidType = uIdType;

const CollectPropsType = shape({
    collections: array,
    onUpdateCollections: func,
});

const BaseGigCardType = {
    url: string,
    title: string,
    isPro: bool,
    isUnavailable: bool,
    ratings: RatingsType,
    badge: BadgeType,
    collectProps: CollectPropsType,
    tracker: TrackerType,
    seller: SellerType,
    isLoading: bool,
    lazyLoad: bool,
    showBoughtFor: bool,
    hidePrefix: bool,
    hideSellerInfo: bool,
};

const GigCardType = {
    ...BaseGigCardType,
    id: GigIdType,
    price: string,
    isTouch: bool,
    isPromotedGig: bool,
    assets: arrayOf(AssetType),
    asset: AssetType,
    type: string,
    cardType: string,
    excludedBadges: excludedBadgesType,
};

const GigCardSmallType = {
    ...BaseGigCardType,
    asset: AssetType,
    clearClick: func,
    showClear: bool,
};

const ListViewCardType = {
    ...BaseGigCardType,
    price: string,
    asset: AssetType,
    urlTarget: oneOf([...Object.values(URL_TARGET)]),
    showFooter: bool,
    hidePrefix: bool,
};

const SmallListViewCardType = {
    ...BaseGigCardType,
    price: string,
    asset: AssetType,
    urlTarget: oneOf([...Object.values(URL_TARGET)]),
    showFooter: bool,
    hidePrefix: bool,
};

export {
    AssetType,
    GigCardType,
    GigIdType,
    GigUidType,
    GigCardSmallType,
    SellerType,
    ListViewCardType,
    BadgeType,
    SmallListViewCardType,
    CollectPropsType,
};
