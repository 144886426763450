import React from 'react';
import { number, string, node, object } from 'prop-types';
import { Container } from '@fiverr-private/layout_components';
import { SaveToListPopover } from '@fiverr-private/collect_actions';
import { getContext } from '@fiverr-private/fiverr_context';
import { collectElementsMapper, createClickedElement } from '../../../utils';
import { useCardContext } from '../../../../GigCardListings/context/CardContext';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../Components/constants';
import { EVENT_TYPES } from '../../../utils/trackGig';

import styles from './styles.module.scss';

const RESOURCE_TYPE = 'GIG';

const CollectWrapper = ({
    id,
    className,
    collectProps,
    collectedClassName = '',
    collectedIcon,
    notCollectedIcon,
    triggerData = {},
}) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();

    const onAddItemToCollection = () => {
        const collectionEventAttributes = collectElementsMapper.menu;
        const { elementName, elementType, elementText } = collectionEventAttributes;
        const clickedElement = createClickedElement(elementName, elementType, elementText);

        trackEvent(EVENT_TYPES.GIG_CLICK, clickedElement);
        trackEvent(EVENT_TYPES.GIG_CARD_CLICK, clickedElement);
    };

    const onClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { userId } = getContext();
        const loggedIn = !!userId;
        const elementNamePrefix = loggedIn ? 'logged_in' : 'join_modal';
        const elementName = `${elementNamePrefix}_${ELEMENTS_NAME.ADD_TO_FAVORITES}`;
        const clickedElement = createClickedElement(elementName, ELEMENTS_TYPE.BUTTON);

        trackEvent(EVENT_TYPES.GIG_CLICK, clickedElement);
        trackEvent(EVENT_TYPES.GIG_CARD_CLICK, clickedElement);
    };

    const onIconClick = () => {
        trackEvent(
            EVENT_TYPES.CLICKED_ON_GIG_CARD,
            createClickedElement(ELEMENTS_NAME.COLLECT_ICON, ELEMENTS_TYPE.BUTTON)
        );
    };

    const collectedIconWithTracking = (
        <Container role="button" className={`${styles.collectTrigger} ${collectedClassName}`} onClick={onIconClick}>
            {collectedIcon}
        </Container>
    );
    const notCollectedIconWithTracking = (
        <Container role="button" className={`${styles.collectTrigger}`} onClick={onIconClick}>
            {notCollectedIcon}
        </Container>
    );

    return (
        <div onClick={onClick} className={className}>
            <SaveToListPopover
                resourceId={id.toString()}
                resourceType={RESOURCE_TYPE}
                position={collectProps.menuPosition || 'bottom-end'}
                initialCollections={collectProps.collections}
                onAddItemToCollection={onAddItemToCollection}
                triggerData={triggerData}
                renderTrigger={({ inCollections }) => {
                    const isCollected = inCollections.length > 0;

                    return isCollected ? collectedIconWithTracking : notCollectedIconWithTracking;
                }}
            />
        </div>
    );
};

CollectWrapper.propTypes = {
    id: number,
    className: string,
    collectedClassName: string,
    notCollectedIcon: node,
    collectProps: object,
    collectedIcon: node,
    triggerData: object,
};

export default CollectWrapper;
