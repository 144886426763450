import { getContext } from '@fiverr-private/fiverr_context';
import { BI_PAGE_NAMES } from '../constants/biConsts';
import { biUserAction } from '../events/biUserAction';
import { BI_EVENTS_TYPES, GROUP_TYPES } from './constants';

export const buildEventEnrichment = ({
    type,
    group,
    elementName,
    elementType,
    elementMetadata,
    elementPosition,
    componentName,
}) => {
    const { pageCtxId, queryParameters: { ref_ctx_id: refCtxId } = {} } = getContext();

    return {
        type,
        ...(group && { group }),
        page: {
            name: BI_PAGE_NAMES.LISTINGS,
            ctx_id: pageCtxId,
            element: {
                name: elementName,
                type: elementType,
                ...(elementMetadata && { metadata: elementMetadata }),
                ...(elementPosition && { position: elementPosition }),
            },
            ...(componentName && {
                component: {
                    name: componentName,
                },
            }),
            ...(refCtxId && {
                referrer: {
                    ctx_id: refCtxId,
                },
            }),
        },
    };
};

export const sendBiClickEvent = (elementName, elementMetadata, elementType, elementPosition, componentName) => {
    const eventEnrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.CLICK,
        group: GROUP_TYPES.USER_ACTIONS,
        elementName,
        elementMetadata,
        elementType,
        elementPosition,
        componentName,
    });
    biUserAction({ ...eventEnrichment });
};
