import React from 'react';
import PropTypes from 'prop-types';
import { VideoIcon } from '@fiverr-private/icons';
import { grey_1100 } from '@fiverr-private/sass/helpers';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';

const ConsultationIndication = ({ shouldShow }) => {
    if (!shouldShow) {
        return null;
    }

    return (
        <Stack gap="2" marginTop="2">
            <Container marginTop="0.5">
                <VideoIcon color={grey_1100} />
            </Container>
            <Text size="b_sm" fontWeight="semibold" color="grey_1100">
                <I18n k="gig_cards.offers_video_consultation.title" />
            </Text>
        </Stack>
    );
};

ConsultationIndication.propTypes = {
    shouldShow: PropTypes.bool,
};

export default ConsultationIndication;
