import React from 'react';
import { Section, Stack } from '@fiverr-private/layout_components';
import { Heading, Text, Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { ClientInitialProps } from '@fiverr-private/listing_types';
import { GenerateBrief } from './components/GenerateBrief';
import { OrDivider } from './components/OrDivider';

interface EmptySearchResultsProps {
    currency: ClientInitialProps['currency'];
}

export const EmptySearchResults = ({ currency }: EmptySearchResultsProps) => (
    <Section>
        <Stack paddingY="8" alignItems="center" direction="column" gap="10">
            <Stack alignItems="center" direction="column" gap="10">
                <Stack alignItems="center" direction="column" gap="6">
                    <Stack alignItems="center" direction="column" gap="2">
                        <Heading width={{ default: '100%', sm: '326px' }} as="h5" textAlign="center">
                            <I18n k="listing_results.empty_results.empty_search_results.title" />
                        </Heading>
                        <Text width={{ default: '100%', sm: '380px' }} color="bodySecondary" textAlign="center">
                            <I18n k="listing_results.empty_results.empty_search_results.sub_title" />
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
            <OrDivider />
            <Typography fontSize="b_md" color="grey_1200">
                <I18n k="listing_results.empty_results.empty_search_results.dynamic_matching.header" />
            </Typography>
            <GenerateBrief currency={currency} />
        </Stack>
    </Section>
);
