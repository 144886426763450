import React from 'react';
import { Skeleton, SkeletonRound, SkeletonSquare as SkeletonSquareComponent } from '@fiverr-private/feedback';
import { Container, Stack, Wrap, WrapItem } from '@fiverr-private/layout_components';

import './index.scss';

const SkeletonSquare = (props) => <SkeletonSquareComponent height="14px" {...props} />;

const ImageSkeleton = () => (
    <div className="image-skeleton">
        <SkeletonSquare height="100%" />
    </div>
);

const LoadingBasicGigCard = () => (
    <Skeleton width="auto">
        <Stack direction="column">
            <ImageSkeleton />
            <Wrap direction="column">
                <WrapItem>
                    <Stack gap="1" alignItems="center">
                        <Stack flex="1" gap="2" alignItems="center">
                            <Container>
                                <SkeletonRound size="24px" />
                            </Container>
                            <SkeletonSquare />
                        </Stack>
                        <Container flex="1" justifyContent="flexEnd" display="flex">
                            <SkeletonSquare maxWidth="60px" />
                        </Container>
                    </Stack>
                </WrapItem>

                <WrapItem>
                    <SkeletonSquare />
                </WrapItem>

                <WrapItem>
                    <SkeletonSquare width="50%" />
                </WrapItem>

                <Container marginTop="4">
                    <SkeletonSquare width="50px" />
                </Container>
            </Wrap>
        </Stack>
    </Skeleton>
);

export default LoadingBasicGigCard;
