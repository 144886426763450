import React, { forwardRef } from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Button, IconButton } from '@fiverr-private/button';
import { HeartIcon, HeartSolidIcon } from '@fiverr-private/visuals';

interface SaveToListButtonBaseProps {
    checked: boolean;
    disabled?: boolean;
    onClick?: () => void;
    isLoading?: boolean;
}

interface SaveToListButtonIconProps extends SaveToListButtonBaseProps {
    variant?: 'icon';
    size?: React.ComponentProps<typeof IconButton>['size'];
}

interface SaveToListButtonLabeledProps extends SaveToListButtonBaseProps {
    variant?: 'labeled';
    size?: React.ComponentProps<typeof Button>['size'];
}

type SaveToListButtonProps = SaveToListButtonIconProps | SaveToListButtonLabeledProps;

export const SaveToListButtonInner = (props: SaveToListButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { checked, disabled, isLoading, onClick, size, variant } = props;
    const icon = checked ? <HeartSolidIcon /> : <HeartIcon />;
    const label = checked
        ? translate('collect_actions.save_to_list_button.saved')
        : translate('collect_actions.save_to_list_button.save');

    if (variant === 'labeled') {
        return (
            <Button
                ref={ref}
                variant="outline"
                intent="secondary"
                disabled={disabled}
                size={size}
                onClick={onClick}
                loading={isLoading}
                fullWidth
            >
                {icon}
                {label}
            </Button>
        );
    }

    return (
        <IconButton
            ref={ref}
            disabled={disabled}
            size={size as SaveToListButtonIconProps['size']}
            aria-label={label}
            variant="outline"
            intent="secondary"
            onClick={onClick}
            loading={isLoading}
        >
            {icon}
        </IconButton>
    );
};

export const SaveToListButton = forwardRef<HTMLButtonElement, SaveToListButtonProps>(SaveToListButtonInner);

interface MinimalCollection {
    id: string;
    name: string;
}

export interface RenderTriggerProps {
    inCollections: MinimalCollection[];
    isLoading?: boolean;
}

export const renderSaveToListTrigger = ({ inCollections, isLoading }: RenderTriggerProps) => (
    <SaveToListButton checked={inCollections.length > 0} isLoading={isLoading} />
);
