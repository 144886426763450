import { bianka } from '@fiverr-private/obs';
import { Impression } from '@fiverr-private/bianka_js';
import { ExpertCard } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/expert_card';
import { CARD_VARIANT, ENGINE_TYPE } from '../../../shared/constants';
import { convertFromUSD } from '../../../utils/currencyConverter';
import {
    convertKeysToCamelCase,
    getBadges,
    getExpertiseType,
    getHighlights,
    getLevel,
    getNotableClients,
} from './utils';

const EXPERT_CARD_IMPRESSION_EVENT_TYPE = 'expert_card_is_shown';
const EXPERT_LISTINGS_IMPRESSION_EVENT_TYPE = 'listings_impression';
const EXPERT_LISTINGS_PAGE_NAME = 'expert_listings';

export const getName = (cardVariant) => {
    const cardName = cardVariant === CARD_VARIANT.VISUAL ? 'experiential' : 'non_experiential';
    return `expert_card_${cardName}`;
};

// todo should use bianka js, but for now cant update package from v2 to v4
const PRICE_TYPES = {
    PER_PROJECT: 1,
    PER_HOUR: 2,
};

export const getImpressionEnrichment = ({
    rating,
    completedOrdersCount,
    isSellerOnline,
    expertises = [],
    highlights = {},
    highlightsData = {},
    seller: { id: sellerId, username, jobTitle, countryCode, badges = [], isPro, achievementLevel } = {},
    notableClients = [],
    priceInUsd,
    currency = {},
    gigId,
    gigEngine,
    cardVariant,
    componentName = getName(cardVariant),
    hourlyCard = false,
}) => ({
    name: componentName,
    enrichment: {
        expertCard: {
            seller: {
                id: sellerId,
            },
            gig: {
                id: gigId,
            },
            expertCardAttributes: {
                shownSellerRating: rating,
                completedOrders: completedOrdersCount,
                isOnline: isSellerOnline,
                badge: getBadges(badges),
                expertise: expertises.map((expertise, index) => ({
                    id: expertise.id,
                    type: getExpertiseType(expertise.type),
                    position: index + 1,
                })),
                highlights: getHighlights(highlights, highlightsData),
                seller: {
                    username,
                    title: jobTitle,
                    isPro,
                    level: getLevel(achievementLevel),
                    location: countryCode,
                },
                notableClient: getNotableClients(notableClients),
                priceUsd: priceInUsd,
                priceType: hourlyCard ? PRICE_TYPES.PER_HOUR : PRICE_TYPES.PER_PROJECT,
                shownPrice: priceInUsd ? convertFromUSD(priceInUsd, currency, true) : 0,
                engine: gigEngine ?? ENGINE_TYPE.DEFAULT,
            },
        },
    },
});

export const onExpertCardImpression = async (pageName, componentName, eventData) => {
    const { enrichment, ...component } = eventData;

    const eventToReport = {
        type: Impression,
        event: {
            type:
                pageName === EXPERT_LISTINGS_PAGE_NAME
                    ? EXPERT_LISTINGS_IMPRESSION_EVENT_TYPE
                    : EXPERT_CARD_IMPRESSION_EVENT_TYPE,
            component: {
                name: componentName,
                ...convertKeysToCamelCase(component),
            },
            ...(pageName && {
                page: {
                    name: pageName,
                },
            }),
        },
        additionalData: {
            type: ExpertCard,
            message: enrichment?.expertCard,
        },
    };

    bianka.reportActivity(eventToReport);
};
