import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import classNames from 'classnames';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { getContext } from '@fiverr-private/fiverr_context';
import { ExpertCardContextProvider } from '../../contexts';
import ExpertisesList from '../ExpertisesList';
import { CARD_VARIANT, CTAS } from '../../shared/constants';
import {
    fetchOnlineStatus,
    getBiEnrichment,
    getDisplayPrice,
    getFreelancerUrl,
    handleCardHover,
    handleExpertCardClick,
    hasOfferConsultationGig,
} from './utils';
import { getImpressionEnrichment, getName, onExpertCardImpression } from './impression';
import SellerDetails from './SellerDetails';
import cardPropTypes, { clickableAreaPropsTypes } from './types';
import Footer from './Footer';
import VisualArea from './VisualArea';
import { EmployeesBigList } from './Employees';

import './index.scss';

export const ClickableArea = ({ children, setIsHovered, clickHandlerArgs }) => {
    const ref = useRef(null);
    const { isMobile } = getContext();
    return (
        <div
            className="clickable-section"
            ref={ref}
            onClick={isMobile ? null : handleExpertCardClick({ ...clickHandlerArgs, ref, listenOnAllElements: false })}
            onMouseOver={handleCardHover(ref, true, setIsHovered)}
            onMouseOut={handleCardHover(ref, false, setIsHovered)}
        >
            {children}
        </div>
    );
};

/**
 * Expert card component that leads to an expert page.
 * Includes the seller and gig details.
 */
const ExpertCard = ({
    seller = {},
    gigs,
    expertises,
    highlights,
    highlightsData,
    rating,
    completedOrdersCount,
    notableClients,
    position,
    cardVariant,
    displayOptions = {},
    cardContainerClassName,
    forceSmallCard = false,
    impressionId: cardImpressionId = uuidV4(),
    currency,
    componentName = getName(cardVariant),
    onImpression,
    renderFooterContent,
    source = 'expert_card',
    contextReferrer,
    disableSwipeMode = false,
    lazyLoadAssets = false,
    saveToListProps = {},
    mutateUrl = (url) => url,
    headerCustomButtons,
    footerCustomButtons,
    inProCatalogGridExp = false,
    showHourlyPrice = false,
    hourlyRateRolloutActive = false,
    onClick,
    onSellerDetailsClick,
    pageName,
}) => {
    const [isSellerOnline, setIsSellerOnline] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const biEnrichment = getBiEnrichment({ seller });
    const priceInUsd = getDisplayPrice(gigs);
    const { showJobTitle = true, mainCta = CTAS.SEE_PROFILE } = displayOptions;
    const { agency } = seller;
    const isAgencyCard = !!agency;

    const impressionEnrichment = getImpressionEnrichment({
        rating: rating?.averageValuation,
        completedOrdersCount,
        isSellerOnline,
        expertises,
        highlights,
        highlightsData,
        seller,
        notableClients,
        cardVariant,
        priceInUsd,
        currency,
        gigId: gigs?.[0]?.id,
        gigEngine: gigs?.[0]?.engine,
        componentName,
        hourlyCard: showHourlyPrice,
    });

    const [expertCardRef, containerId] = useImpressionContainer({ selfId: cardImpressionId });

    const [, impressionId] = useImpressionItem({
        selfId: containerId,
        selfRef: expertCardRef,
        enrichment: impressionEnrichment,
        sendImpressionToBQ: false,
        intersectionThreshold: 0.1,
        onImpression: onImpression ?? ((...args) => onExpertCardImpression(pageName, componentName, ...args)),
    });

    useEffect(() => {
        const setOnlineStatus = () => {
            // eslint-disable-next-line promise/catch-or-return
            fetchOnlineStatus(seller.username)
                .then((isOnline) => setIsSellerOnline(isOnline))
                .catch(() => true);
        };

        setOnlineStatus();
    }, [seller]);

    let freelancerUrl = getFreelancerUrl({
        expertises,
        gigs,
        seller,
        impressionId,
        source,
        agencySlug: agency?.slug,
        isExperiential: cardVariant === CARD_VARIANT.VISUAL,
        contextReferrer,
    });

    freelancerUrl = mutateUrl(freelancerUrl);

    const clickHandlerArgs = { freelancerUrl, impressionId, source, seller, componentName, onClick };
    const { isMobile } = getContext();
    const cardClassNames = classNames('listings-expert-card-container co-text-darker', cardContainerClassName, {
        'small-card': forceSmallCard,
        'shadow-on-hover': !isMobile,
        'p-24': !isMobile,
        'p-20': isMobile,
    });
    const offerConsultation = hasOfferConsultationGig(gigs);

    const handleCardClickedForMobile = () => {
        if (!isMobile) {
            return null;
        }
        return handleExpertCardClick({ ...clickHandlerArgs, ref: expertCardRef, listenOnAllElements: true });
    };

    return (
        <ExpertCardContextProvider
            cardPosition={position}
            seller={seller}
            freelancerUrl={freelancerUrl}
            cardImpressionId={impressionId}
            componentName={componentName}
            rating={rating}
            isAgencyCard={isAgencyCard}
            saveToListProps={saveToListProps}
            offerConsultation={offerConsultation}
            gigs={gigs}
            displayOptions={displayOptions}
            headerCustomButtons={headerCustomButtons}
            pageName={pageName}
        >
            <div className={cardClassNames} ref={expertCardRef} onClick={handleCardClickedForMobile()}>
                <ClickableArea setIsHovered={setIsHovered} clickHandlerArgs={clickHandlerArgs}>
                    <SellerDetails
                        source={source}
                        isSellerOnline={isSellerOnline}
                        isCardHovered={isHovered}
                        showJobTitle={showJobTitle}
                        onSellerDetailsClick={onSellerDetailsClick}
                    />
                </ClickableArea>
                {isAgencyCard ? <EmployeesBigList employees={agency?.members} /> : null}
                <div className="expert-card-body">
                    <ExpertisesList
                        expertises={seller.allExpertises}
                        hasHourlyRate={!!seller?.hourlyRateInCents && !!hourlyRateRolloutActive}
                        forceSmallCard={forceSmallCard}
                        biEnrichment={biEnrichment}
                    />
                </div>
                <VisualArea
                    cardVariant={cardVariant}
                    clickHandlerArgs={clickHandlerArgs}
                    biEnrichment={biEnrichment}
                    setIsHovered={setIsHovered}
                    isHovered={isHovered}
                    gigs={gigs}
                    currency={currency}
                    disableSwipeMode={disableSwipeMode}
                    lazyLoadAssets={lazyLoadAssets}
                />
                <ClickableArea setIsHovered={setIsHovered} clickHandlerArgs={clickHandlerArgs}>
                    <Footer
                        priceInUSD={priceInUsd}
                        highlights={highlights}
                        highlightsData={highlightsData}
                        cardVariant={cardVariant}
                        {...displayOptions}
                        currency={currency}
                        mainCta={mainCta}
                        seller={seller}
                        renderContent={renderFooterContent}
                        source={source}
                        footerCustomButtons={footerCustomButtons}
                        inProCatalogGridExp={inProCatalogGridExp}
                        hourlyRateInCents={seller?.hourlyRateInCents}
                        showHourlyPrice={showHourlyPrice}
                    />
                </ClickableArea>
            </div>
        </ExpertCardContextProvider>
    );
};

ExpertCard.propTypes = cardPropTypes;
ClickableArea.propTypes = clickableAreaPropsTypes;

export default ExpertCard;
