import React from 'react';
import { bool, number, string } from 'prop-types';
import classNames from 'classnames';
import { Rating } from '@fiverr-private/orca';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { createClickedElement } from '../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../Components/constants';
import { isInExperimentGroup } from '../../../../GigCardListings/ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS } from '../../../../constants/experiments';
import { getCount } from './utils';

import styles from './index.module.scss';

const GigRating = ({ hasRating, score, count, className, scoreClassName, countClassName }) => {
    const { abTests = {} } = getContext();
    const isInExperimentForRatingPrecision = isInExperimentGroup(
        EXPERIMENTS.CARIBOU_RATING_SCORE_UPDATES,
        [ALLOCATIONS.TEST_D],
        abTests
    );
    const scorePrecisionProps = isInExperimentForRatingPrecision
        ? {
              scoreMinimumFractionDigits: 1,
              scoreMaximumFractionDigits: 2,
          }
        : {
              scoreMinimumFractionDigits: 1,
              scoreMaximumFractionDigits: 1,
          };
    return (
        <div className={classNames('m-t-8', 'flex', styles.ratingContainer, className)}>
            {hasRating && (
                <Rating
                    score={score}
                    ratingsCount={getCount({ count, translate })}
                    color={Rating.COLORS.BLACK}
                    theme={Rating.THEMES.ONE_STAR}
                    showScore={hasRating}
                    className={styles.rating}
                    scoreClassName={classNames(styles.score, scoreClassName)}
                    starClassName={styles.star}
                    ratingsCountClassName={classNames(styles.count, countClassName)}
                    {...scorePrecisionProps}
                />
            )}
        </div>
    );
};

GigRating.propTypes = {
    count: number,
    className: string,
    scoreClassName: string,
    countClassName: string,
    score: number,
    hasRating: bool,
};

export default GigRating;

export const clickedElement = (score) => createClickedElement(ELEMENTS_NAME.RATING, ELEMENTS_TYPE.LINK, score);
