import React from 'react';
import { Skeleton, SkeletonSquare } from '@fiverr-private/feedback';
import { Container, Stack } from '@fiverr-private/layout_components';

const LoadingGigVideoStripCard = () => (
    <Skeleton width="auto">
        <Stack
            width="100%"
            boxShadow="z3"
            gap={{ default: '4', sm: '6' }}
            direction="column"
            padding={{ default: '5', sm: '4' }}
            borderRadius="lg"
        >
            <Stack gap={{ default: '3', sm: '5' }} direction={{ default: 'column', sm: 'row' }}>
                <Container display={{ default: 'flex', sm: 'none' }}>
                    <SkeletonSquare width={64} height={64} />
                </Container>
                <Container display={{ default: 'none', sm: 'flex' }}>
                    <SkeletonSquare width={96} height={96} />
                </Container>
                <Stack gap="1" direction="column" width="75%">
                    <SkeletonSquare height={32} width="60%" />
                    <SkeletonSquare height={26} width="60%" />
                    <SkeletonSquare height={24} width="30%" />
                </Stack>
            </Stack>
            <Container display="flex" justifyContent="spaceBetween">
                <Stack direction="column" width="50%" gap={{ default: '4', sm: '10' }}>
                    <Stack direction="column" gap="2">
                        <SkeletonSquare height={24} width="50%" />
                        <SkeletonSquare height={24} width="80%" />
                        <SkeletonSquare height={24} width="80%" />
                        <SkeletonSquare height={24} width="80%" />
                    </Stack>
                    <Container alignItems="flexEnd" display={{ default: 'flex', sm: 'none' }}>
                        <SkeletonSquare width="100%" height={218} />
                    </Container>
                    <Stack direction="column" gap="1">
                        <SkeletonSquare height={24} width="80%" />
                        <SkeletonSquare height={24} width="20%" />
                    </Stack>
                </Stack>
                <Container alignItems="flexEnd" display={{ default: 'none', sm: 'flex' }}>
                    <SkeletonSquare width="270px" height={218} />
                </Container>
            </Container>
        </Stack>
    </Skeleton>
);

export default LoadingGigVideoStripCard;
