import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { getGigCardEnrichment } from '../../../GigCardListings/utils/impressionEnrichment';
import { GigUidType } from '../../types';
import { useGigListingsContext } from '../../../GigCardListings/context/GigListingsContext';
import { CardContextProvider } from '../../../GigCardListings/context/CardContext';
import DefaultWrapper from './DefaultWrapper';
import './style.scss';

const GigWrapper = ({
    uId,
    type,
    gig = {},
    children,
    classes,
    layoutClass,
    isLoading,
    isExperiential,
    LoadingComponent,
    isPro,
    isUnavailable,
    ExternalWrapper = DefaultWrapper,
    enrichedTracker,
    showNewSellerBadges,
}) => {
    const gigWrapperClass = classNames('gig-wrapper-impressions gig-wrapper', classes, {
        skeleton: isLoading,
        'pro-experience': isPro,
        unavailable: isUnavailable,
    });
    const ref = useRef(null);
    const { onImpression } = useGigListingsContext();
    const { impressionId: gigImpressionId, id: gigId } = gig;

    const enrichment = useMemo(() => getGigCardEnrichment(gig, type), [gig, type]);

    const impressionItemRef = !isLoading ? ref : { current: null };

    const [impressionRef, impressionId] = useImpressionItem({
        selfId: gigImpressionId,
        selfRef: impressionItemRef,
        intersectionThreshold: 0.01,
        sendImpressionToBQ: false,
        onImpression,
        enrichment,
    });

    return (
        <CardContextProvider value={{ impressionId, enrichedTracker, gigId, isExperiential, showNewSellerBadges }}>
            <div className={layoutClass} ref={!isLoading ? impressionRef : null}>
                <ExternalWrapper gig={gig}>
                    <div className={gigWrapperClass} data-gig-id={uId}>
                        {isLoading ? <LoadingComponent /> : children}
                    </div>
                </ExternalWrapper>
            </div>
        </CardContextProvider>
    );
};

GigWrapper.propTypes = {
    uId: GigUidType,
    type: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    isPro: PropTypes.bool,
    isUnavailable: PropTypes.bool,
    LoadingComponent: PropTypes.node,
    gig: PropTypes.object,
    layoutClass: PropTypes.string,
    ExternalWrapper: PropTypes.node,
    enrichedTracker: PropTypes.object,
    isExperiential: PropTypes.bool,
    showNewSellerBadges: PropTypes.bool,
};

GigWrapper.defaultProps = {
    isLoading: false,
    isPro: false,
    isUnavailable: false,
    LoadingComponent: null,
    isExperiential: false,
    classes: [''],
};

export default GigWrapper;
