import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';

const TranslatedResult = ({ query, modifiedQueryString, phraseStart }) => (
    <React.Fragment>
        <I18n
            k="search_perseus.search_results_counter.showing_translated_results"
            params={{ phraseStart, query, modifiedQueryString }}
        />
        &nbsp;
    </React.Fragment>
);

export default TranslatedResult;
