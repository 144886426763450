import React from 'react';
import PropTypes from 'prop-types';

const withTransformProps = (transform) => (WrappedComponent) => {
    const WithTransformProps = (props) => {
        const { isLoading } = props;

        const transformedProps = !isLoading ? transform(props) : props;
        return <WrappedComponent {...transformedProps} />;
    };

    WithTransformProps.propTypes = {
        isLoading: PropTypes.bool,
    };

    WithTransformProps.defaultProps = {
        isLoading: false,
    };

    return WithTransformProps;
};

export default withTransformProps;
