import { once } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { ALLOCATIONS, EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { useIntersectionObserver } from '@fiverr-private/hooks';
import { allocateToClientExperiment } from '../../../../../clientExperiments/clientUtils';
import { useAppContext } from '../../../../context/listingsContext';

const experimentId = EXPERIMENTS.DOLPHINS_MP_LISTINGS_PJM_BANNER_DESKTOP;

const isInTest = (groupResult: number) => {
    const { abTests = {} } = getContext();
    const group: string | number | undefined = abTests[experimentId] || groupResult;
    const inGroupB = group?.toString() === ALLOCATIONS.TEST_B;
    return { inGroupB };
};

const tryAllocateBanner = once(
    ({
        clientExperimentsGroups,
        activeExperiments,
    }: {
        clientExperimentsGroups: Record<number, string | number>;
        activeExperiments: Record<number, unknown>;
    }): number | undefined => {
        const inExperimentGroup = Number(activeExperiments[experimentId]);
        const inExperiment = inExperimentGroup > 0;

        if (inExperiment) {
            return inExperimentGroup;
        }

        if (isInTest(experimentId).inGroupB) {
            return;
        }

        const experimentGroup = Number(clientExperimentsGroups[experimentId]);
        if (experimentGroup > 0) {
            return allocateToClientExperiment(experimentId, clientExperimentsGroups);
        }
    }
);

export const useProjectManagementBannerAllocation = (observableRef: React.RefObject<Element>) => {
    const { clientExperimentsGroups, activeExperiments } = useAppContext();
    const [group, setGroup] = useState(Number(ALLOCATIONS.CONTROL));
    const entry = useIntersectionObserver(observableRef, { threshold: 0.5 });

    const runAllocation = useCallback(() => {
        const allocationGroup = tryAllocateBanner({ clientExperimentsGroups, activeExperiments });
        if (allocationGroup) {
            setGroup(allocationGroup);
        }
    }, [clientExperimentsGroups, activeExperiments]);

    useEffect(() => {
        if (!entry?.isIntersecting) {
            return;
        }

        runAllocation();
    }, [entry, entry?.isIntersecting, runAllocation]);

    return {
        isInTest: isInTest(group),
    };
};
