import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createTracker, useRenderableGigs } from '../utils';
import { SCOPE_CLASS } from '../utils/constants';
import withImpressions from '../hoc/withImpressions';
import { RolloutsContextProvider } from '../context/RolloutsContext/RolloutsContext';

import './index.scss';

const ListingsContainer = React.forwardRef(
    (
        {
            tracking,
            HeaderComponent,
            gigs = [],
            RenderGig,
            renderGigProps,
            className,
            getContainerRef,
            isLoading,
            size,
            lazyLoadOffset,
            isVintageProTest = false,
            banners,
            enablePopover = false,
            rollouts = {},
        },
        ref
    ) => {
        const [tracker] = useState(createTracker(tracking));
        const gigTracker = tracker.gig;
        const filteredGigs = isVintageProTest ? gigs.map((gig) => ({ ...gig, assets: [gig.assets[0]] })) : gigs;
        const _gigs = useRenderableGigs(filteredGigs, isLoading, size, lazyLoadOffset);

        return (
            <RolloutsContextProvider value={{ rollouts }}>
                <div
                    className={classNames(
                        SCOPE_CLASS,
                        'listing-container',
                        { 'is-pro-vintage': isVintageProTest },
                        className
                    )}
                    ref={ref}
                >
                    {HeaderComponent && <HeaderComponent />}
                    {_gigs.map((gig) => (
                        <RenderGig
                            key={gig.uId || gig.id}
                            tracker={gigTracker}
                            getContainerRef={getContainerRef}
                            enablePopover={enablePopover}
                            {...gig}
                            {...renderGigProps}
                        />
                    ))}
                    {banners}
                </div>
            </RolloutsContextProvider>
        );
    }
);

ListingsContainer.propTypes = {
    HeaderComponent: PropTypes.node,
    gigs: PropTypes.array,
    tracking: PropTypes.object,
    isLoading: PropTypes.bool,
    size: PropTypes.number,
    lazyLoadOffset: PropTypes.number,
    getContainerRef: PropTypes.func.isRequired,
    RenderGig: PropTypes.func.isRequired,
    isVintageProTest: PropTypes.bool,
    className: PropTypes.string,
    banners: PropTypes.array,
    renderGigProps: PropTypes.object,
    enablePopover: PropTypes.bool,
    rollouts: PropTypes.object,
};

ListingsContainer.displayName = 'ListingsContainer';

export { ListingsContainer };

export default withImpressions(ListingsContainer);
