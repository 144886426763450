import { MAX_RATING_SCORE } from './Components/GigRating/constants';

export const getGigRatingProps = (props) => {
    const { ratings: { rating, count } = {} } = props;

    const hasRating = count > 0;
    const score = hasRating ? rating : MAX_RATING_SCORE;

    return { score, hasRating, count };
};
