import React, { createContext, useContext } from 'react';
import { arrayOf, shape, oneOf, func, string, number, node, bool, object } from 'prop-types';
import { sellerType } from '../../shared/types';
import { ratingType } from '../../components/ExpertCard/types';
import { gigType } from '../../components/GigServices/GigServiceCard/propTypes';
import { CTAS } from '../../shared/constants';

const ExpertCardContext = createContext({
    cardPosition: 0,
    seller: {},
    freelancerUrl: null,
    cardImpressionId: null,
    rating: {},
    componentName: null,
    isAgencyCard: undefined,
    saveToListProps: {},
    offerConsultation: false,
    gigs: [],
    displayOptions: {},
    headerCustomButtons: [],
    pageName: '',
});

export const useExpertCardContext = () => useContext(ExpertCardContext);

export const ExpertCardContextProvider = ({
    cardPosition,
    seller = {},
    saveToListProps,
    freelancerUrl,
    cardImpressionId,
    rating,
    componentName,
    children,
    isAgencyCard,
    offerConsultation,
    gigs,
    displayOptions,
    headerCustomButtons,
    pageName,
}) => (
    <ExpertCardContext.Provider
        value={{
            cardPosition,
            seller,
            freelancerUrl,
            cardImpressionId,
            saveToListProps,
            rating: rating || {},
            componentName,
            isAgencyCard,
            offerConsultation,
            gigs,
            displayOptions,
            headerCustomButtons,
            pageName,
        }}
    >
        {children}
    </ExpertCardContext.Provider>
);

ExpertCardContextProvider.propTypes = {
    children: node.isRequired,
    cardPosition: number,
    seller: sellerType.isRequired,
    freelancerUrl: string,
    cardImpressionId: string,
    rating: ratingType,
    componentName: string,
    isAgencyCard: bool,
    saveToListProps: object,
    offerConsultation: bool,
    gigs: arrayOf(gigType),
    displayOptions: shape({
        showPrice: bool,
        showSaveExpert: bool,
        showContact: bool,
        showSeeProfile: bool,
        showHeaderSaveExpert: bool,
        showHeaderContact: bool,
        showHeaderSeeProfile: bool,
        showJobTitle: bool,
        mainCta: oneOf(Object.values(CTAS)),
    }),
    headerCustomButtons: arrayOf(shape({ label: string, onClick: func, isCta: bool, elementName: string })),
    pageName: string,
};
