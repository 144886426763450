import React, { useMemo } from 'react';
import classNames from 'classnames';
import { bool, number, shape, string } from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { AgencyPeopleIcon, PersonCardIcon } from '@fiverr-private/icons';
import { Rating } from '@fiverr-private/orca';
import { Heading, Text } from '@fiverr-private/typography';
import { Stack, Container, Wrap, Layout } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { Avatar } from '@fiverr-private/fit';
import { BadgeType, SellerType, GigIdType, CollectPropsType } from '../../../../types';
import Badge from '../../../Components/Badge';
import { getCount } from '../../../Components/GigRating/utils';
import { overrideImageTransformation } from '../../../../utils/assetsHelper';
import { PROFILE_IMAGE_RESOLUTION, AGENCY_PROFILE_IMAGE_RESOLUTION } from '../../../../utils/assetsHelper/constants';
import commonStyles from '../../../../styles/utils.module.scss';
import { createClickedElement, trackGigForClickType } from '../../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../../Components/constants';
import ContextualLink from '../../../../Components/ContextualLink';
import { useCardContext } from '../../../../../GigCardListings/context/CardContext';
import { isInExperimentGroup } from '../../../../../GigCardListings/ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS } from '../../../../../constants/experiments';
import { getCollectWrapperProps } from '../../utils/getCollectWrapperProps';
import CollectWrapper from '../../../Components/CollectWrapper';
import styles from './AgencyInfo.module.scss';

const AgencyAvatarClickedElement = createClickedElement(ELEMENTS_NAME.AVATAR, ELEMENTS_TYPE.LINK);
const AgencyTitleClickedElement = (title) =>
    createClickedElement(ELEMENTS_NAME.SELLER_TITLE, ELEMENTS_TYPE.LINK, title);
const AgencyRatingClickedElement = (score) => createClickedElement(ELEMENTS_NAME.RATING, ELEMENTS_TYPE.LINK, score);
const AgencyInfoWrapperClickedElement = createClickedElement(ELEMENTS_NAME.AGENCY_INFO_WRAPPER, ELEMENTS_TYPE.LINK);

const AgencyInfo = ({ badge, ratingProps, seller, url, urlTarget, id, collectProps }) => {
    const { isTouch, abTests = {} } = getContext();
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();

    const { displayName, imgSrc, level, name, employeesCount } = seller;
    const { hasRating, score, count } = ratingProps;

    const username = displayName || name;

    const avatarSize = isTouch ? 64 : 96;
    const avatarSrc = useMemo(
        () => overrideImageTransformation(imgSrc, PROFILE_IMAGE_RESOLUTION, AGENCY_PROFILE_IMAGE_RESOLUTION),
        [imgSrc]
    );
    const isInExperimentForRatingPrecision = isInExperimentGroup(
        EXPERIMENTS.CARIBOU_RATING_SCORE_UPDATES,
        [ALLOCATIONS.TEST_D],
        abTests
    );
    const scorePrecisionProps = isInExperimentForRatingPrecision
        ? {
              scoreMinimumFractionDigits: 1,
              scoreMaximumFractionDigits: 2,
          }
        : {
              scoreMinimumFractionDigits: 1,
              scoreMaximumFractionDigits: 1,
          };

    const collectWrapperProps = getCollectWrapperProps({ id, collectProps });

    return (
        <Container position="relative" width="100%" height="100%" className={styles.containerWrapper}>
            <ContextualLink
                href={url}
                target={urlTarget}
                className={classNames('agency-contextual-link', commonStyles.zIndex1)}
                onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, AgencyInfoWrapperClickedElement)}
            />
            <Stack gap={{ default: '3', sm: '6' }} direction={{ default: 'column', sm: 'row' }} overflow="hidden">
                <Stack justifyContent="spaceBetween">
                    <ContextualLink
                        href={url}
                        target={urlTarget}
                        className={commonStyles.zIndex1}
                        onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, AgencyAvatarClickedElement)}
                    >
                        <Avatar
                            className={styles.agencyAvatar}
                            size={avatarSize}
                            src={avatarSrc}
                            shape={Avatar.SHAPES.SQUARE}
                            username={username}
                        />
                    </ContextualLink>
                    <Layout hidden={{ default: false, sm: true }} className={commonStyles.zIndex1}>
                        <CollectWrapper {...collectWrapperProps} />
                    </Layout>
                </Stack>

                <Stack direction="column" gap="0" overflow="hidden" width="100%">
                    <Wrap direction="row" alignItems="center" gap={{ default: '1', sm: '3' }} marginBottom="1.5">
                        <ContextualLink
                            title={username}
                            href={url}
                            target={urlTarget}
                            className={classNames(commonStyles.zIndex1, commonStyles.ellipsis)}
                            onMouseDown={(e) =>
                                trackGigForClickType(e.button, trackEvent, AgencyTitleClickedElement(username))
                            }
                        >
                            <Heading
                                className={styles.title}
                                as="h4"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {username}
                            </Heading>
                        </ContextualLink>
                        <Container>
                            <Badge {...badge} level={level} />
                        </Container>
                    </Wrap>
                    <Stack direction="row" alignItems="center" justifyContent="flexStart" gap="4" marginBottom="2">
                        <Stack direction="row" alignItems="center" gap="2" width="fitContent">
                            <AgencyPeopleIcon size="20" />
                            <Text size="b_md" fontWeight="semibold">
                                <I18n k="gig_listings.agency_card.agency" />
                            </Text>
                        </Stack>
                        {!!employeesCount && (
                            <Stack direction="row" alignItems="center" gap="2" width="fitContent">
                                <PersonCardIcon size="20" />
                                <Text
                                    size="b_md"
                                    fontWeight="semibold"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                >
                                    <I18n k="gig_listings.agency_card.employees" params={{ number: employeesCount }} />
                                </Text>
                            </Stack>
                        )}
                    </Stack>
                    {hasRating && (
                        <ContextualLink
                            href={url}
                            target={urlTarget}
                            className={classNames(commonStyles.zIndex1, commonStyles.widthFitContent)}
                            onMouseDown={(e) =>
                                trackGigForClickType(e.button, trackEvent, AgencyRatingClickedElement(score))
                            }
                        >
                            <Rating
                                score={score}
                                ratingsCount={getCount({ count, translate })}
                                color={Rating.COLORS.BLACK}
                                theme={Rating.THEMES.ONE_STAR}
                                showScore={hasRating}
                                starClassName={styles.agencyRatingStar}
                                scoreClassName={styles.agencyRatingScore}
                                ratingsCountClassName={styles.agencyRatingCount}
                                {...scorePrecisionProps}
                            />
                        </ContextualLink>
                    )}
                </Stack>

                <Layout hidden={{ default: true, sm: false }} className={commonStyles.zIndex1}>
                    <CollectWrapper {...collectWrapperProps} />
                </Layout>
            </Stack>
        </Container>
    );
};

AgencyInfo.propTypes = {
    badge: BadgeType,
    seller: SellerType,
    ratingProps: shape({
        count: number,
        score: number,
        hasRating: bool,
    }),
    url: string,
    urlTarget: string,
    id: GigIdType,
    collectProps: CollectPropsType,
};

export default AgencyInfo;
