import React from 'react';
import { bool, string } from 'prop-types';
import { blue_1200, white } from '@fiverr-private/sass/helpers';
import { translate } from '@fiverr-private/i18n-react';
import { PentaBadge as Badge } from '../PentaBadge/PentaBadge';

import { baseBadge, content } from './OverlayWrapper.module.scss';
import { typographyGreen } from './FiverrChoiceBadge.module.scss';

export const FiverrChoiceBadge = ({
    showTooltip = true,
    tooltipPosition = 'top',
    dataTestId,
    appendTooltipToBody,
    disableTooltipFlip,
}) => (
    <Badge
        className={baseBadge}
        showTooltip={showTooltip}
        color={white}
        backgroundColor={blue_1200}
        tooltipContent={translate('badges.fiverr_choice_badge.tooltip')}
        tooltipPosition={tooltipPosition}
        dataTestId={dataTestId}
        appendTooltipToBody={appendTooltipToBody}
        disableTooltipFlip={disableTooltipFlip}
    >
        <div className={content}>
            <p>Fiverr’s</p>
            <p className={typographyGreen}>Choice</p>
        </div>
    </Badge>
);

FiverrChoiceBadge.displayName = 'FiverrChoiceBadge';

FiverrChoiceBadge.propTypes = {
    showTooltip: bool,
    tooltipPosition: string,
    dataTestId: string,
    appendTooltipToBody: bool,
    disableTooltipFlip: bool,
};
