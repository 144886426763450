import React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import { array, object } from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { URI } from '@fiverr-private/futile';
import { CloseIcon } from '@fiverr-private/icons';
import { Icon } from '@fiverr-private/fit';
import {
    setCookie,
    deregisterStickyFilter,
    onRemoveStickyFilter,
    CLEARED_FILTERS_COOKIE_NAME,
    FILTER,
    SELECTORS,
    FILTERS_LOCATION,
    EVENTS_MAPPING,
    sendBiClickEvent,
    COMPONENT_NAMES,
    ELEMENT_TYPE,
} from '@fiverr-private/listing_lib';
import { filterNavigation } from '../../../service/clientNavigation';
import './index.scss';

// Mustangs
const SelectedFilters = ({
    className = '',
    activeFilters,
    selectedFilters,
    selectedScName,
    showClearAll = false,
    inMustangsDefaultFiltersRollout = false,
    onClearFilterClick,
}) => {
    const fromSearchFlow = !!selectedScName;

    // TODO: Clean after default filters test is done
    const setFilterCleanedCookie = () => {
        if (inMustangsDefaultFiltersRollout) {
            setCookie({
                name: CLEARED_FILTERS_COOKIE_NAME,
                value: true,
                expirationDays: 1,
            });
        }
    };

    const onClick = (filter) => {
        sendBiClickEvent(
            EVENTS_MAPPING.FILTER_REMOVE.biquery,
            filter.alias,
            ELEMENT_TYPE.VISUAL,
            null,
            COMPONENT_NAMES.PROFESSION_FILTERS
        );
        const { id, value } = filter;
        onRemoveStickyFilter({ id, value });
        const selector = SELECTORS.getType(id);

        const removeHandler = {
            pair: ({ activeFilters, value }) =>
                activeFilters.map((filterValue) =>
                    filterValue === value ? FILTER.LANGUAGES_PAIR.DEFAULT : filterValue
                ),
            multi: ({ activeFilters, value }) => activeFilters.filter((filterValue) => filterValue !== value),
            single: () => undefined,
        };

        // TODO: Clean after default filters test is done
        setFilterCleanedCookie();

        const newValue = removeHandler[selector]({ activeFilters: activeFilters[id], value });
        filterNavigation({ [id]: newValue }, activeFilters, FILTERS_LOCATION.SELECTED);
    };

    const clearAllFiltersHandler = () => {
        onClearFilterClick();
        if (!window?.location) {
            return;
        }

        deregisterStickyFilter();
        const uri = new URI(window.location.href);

        uri.removeParams('sub_category', 'nested_sub_category', 'ref');

        // TODO: Clean after default filters test is done
        setFilterCleanedCookie();

        window.location.href = uri.href;
    };

    return (
        <div className={classnames(className, 'selected-filters')}>
            {fromSearchFlow &&
                selectedScName !== translate('search_perseus.filter_builder.sub_category.all_categories') && (
                    <React.Fragment>
                        <a className="tbody-6" onClick={clearAllFiltersHandler}>
                            {selectedScName}
                            <Icon className="remove-icon" size={10}>
                                <CloseIcon />
                            </Icon>
                        </a>
                    </React.Fragment>
                )}
            {selectedFilters.map((filter) => (
                <a key={`${filter.id}_${filter.value}`} className="tbody-6" onClick={() => onClick(filter)}>
                    {filter.alias}
                    <Icon className="remove-icon" size={10}>
                        <CloseIcon />
                    </Icon>
                </a>
            ))}
            {showClearAll && (
                <div className={'clear-all-selected-filters'} onClick={clearAllFiltersHandler}>
                    <I18n k="search_perseus.filter.clear_filters" />
                </div>
            )}
        </div>
    );
};

SelectedFilters.propTypes = {
    activeFilters: object.isRequired,
    selectedFilters: array,
};

SelectedFilters.defaultProps = {
    selectedFilters: [],
    onClearFilterClick: noop,
};

export default SelectedFilters;
