import { merge, startCase } from 'lodash';
import { removeEmptyEntries } from '../removeEmptyEntries';
import { sendMixPanelEvent } from '../../../GigCards/utils/sendMixpanelEvent';
import { sendBigQueryEvent } from '../../../GigCards/utils/sendBigQueryEvent';
import { EVENT_TYPES } from './constants';

const prepareBigqueryPayload = (tracking, payload = {}) =>
    merge(
        {},
        {
            group: tracking.currentPage,
            page: {
                ctx_id: tracking.pageCtxId,
                element: {
                    name: tracking.sourceComponent,
                },
            },
        },
        payload
    );

const prepareImpressionPayload = (tracking, { type, group, ...payload }) => ({
    group,
    type: `${tracking.viewportType}.${type}`,
    ...payload,
});

const prepareMixpanelPayload = (tracking, payload = {}) =>
    merge(
        {},
        {
            eventPayload: {
                'Page Name': startCase(tracking.currentPage),
                'Source Component': tracking.sourceComponent,
            },
        },
        payload
    );

export const createTracker = (tracking = {}) => ({
    gig: {
        trackEvent: (payload) => {
            const eventPayload = prepareBigqueryPayload(tracking, payload);
            sendBigQueryEvent(removeEmptyEntries(eventPayload));
            return eventPayload;
        },
        trackImpression: (payload) => {
            const eventPayload = prepareImpressionPayload(tracking, payload);
            sendBigQueryEvent(eventPayload);
            return eventPayload;
        },
        trackMixpanelEvent: (event) => {
            const eventPayload = prepareMixpanelPayload(tracking, event);
            sendMixPanelEvent(eventPayload);
            return eventPayload;
        },
    },
    carousel: {
        trackSlideEvent: () => {
            const eventPayload = prepareBigqueryPayload(tracking, { type: EVENT_TYPES.CAROUSEL_SCROLL });
            sendBigQueryEvent(eventPayload);
            return eventPayload;
        },
        trackLoading: (payload) => {
            const eventPayload = prepareBigqueryPayload(tracking, payload);
            sendBigQueryEvent(eventPayload);
        },
    },
});
