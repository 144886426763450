import { isEmpty, set } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { getLocalizationContext } from '@fiverr-private/localization';
import { getContext } from '@fiverr-private/fiverr_context';
import { removeEmptyEntries } from '../../utils';
import { getGigImpressionBadgesReport } from '../../utils/gigBadges';

const defaultImpressionType = 'default';
const impressionsGroup = 'gig_impression';

const enrichImpressionData = ({ gig, gigImpression = {} }) => {
    if (!gig) {
        logger.warn('Gig data is missing in impression data enrichment.', { gigImpression });

        return {};
    }

    try {
        const { currency } = getContext();
        const { formattingLocale } = getLocalizationContext();
        const { pageCtxId } = gigImpression;

        const url = getLocationUrl();

        const impressionData = getImpressionData({ gig, gigImpression });
        const pageImpressionData = getPageImpressionData(gigImpression);

        const itemContext = removeEmptyEntries({
            source_component: gigImpression.sourceComponent,
            source_page: gigImpression.sourcePage,
            source_page_controller_action: gigImpression.controllerAction,
            page_ctx_id: pageCtxId,
            position: gig.position,
            source_url: url,
        });

        return removeEmptyEntries({
            group: impressionsGroup,
            aux: { page_ctx_id: pageCtxId },
            type: `${gigImpression.viewportType}$viewport`,
            item_context: itemContext,
            gig_impression: impressionData,
            localization: {
                display_currency: currency,
                formatting_locale: formattingLocale,
            },
            page: pageImpressionData,
        });
    } catch (error) {
        logger.error(error, {
            message: 'Gig Impressions Data Error.',
            gig,
        });

        return {};
    }
};

const getImpressionData = ({ gig, gigImpression }) => {
    const { seller, ratings } = gig;
    const gigImpressionExtendingData = {
        ...gigImpression.gigData,
        ...gig.impressionData,
    };
    const gigAuction = calcAuction(gigImpression.auctionData, gig.id);

    return removeEmptyEntries({
        id: gig.id,
        cacheslug: gig.cacheSlug,
        category: gig.categoryId,
        sub_category: gig.subCategoryId,
        price: gig.originalPrice,
        position: gig.position,
        auction: gig.auction,
        num_of_packages: gig.num_of_packages,
        seller_id: seller.id,
        seller_name: seller.name,
        seller_country: seller.country,
        seller_online: seller.isOnline,
        seller_level: seller.level,
        source: gigImpression.sourceComponent,
        sourcePage: gigImpression.controllerAction,
        type: gig.type || defaultImpressionType,
        ratings: getGigImpressionRatings(ratings),
        badges: getGigImpressionBadgesReport(gig),
        ...gigImpressionExtendingData,
        ...gigAuction,
        listings: {
            ...gigImpression.listings,
            url: getLocationUrl(),
        },
    });
};

const getPageImpressionData = (pageImpressionData) => {
    const pageData = {};

    try {
        const { pageComponentName, pageElementName } = pageImpressionData;

        if (isEmpty(pageComponentName) && isEmpty(pageElementName)) {
            return;
        }

        pageComponentName && set(pageData, 'component.name', pageComponentName);
        pageElementName && set(pageData, 'element.name', pageElementName);

        return pageData;
    } catch (error) {
        logger.error(error, {
            message: 'Failed to enrich page component:',
            pageImpressionData: JSON.stringify(pageImpressionData),
        });
    }
};

const getGigImpressionRatings = (ratings) => {
    const rating = ratings.original.rating;
    const impressionRating = rating ? Number(rating.toFixed(2)) : rating;

    return {
        average_rating: impressionRating,
        rated_orders: ratings.original.count,
        rated_orders_shown: ratings.count,
    };
};

const getLocationUrl = () => {
    if (typeof window !== 'undefined') {
        return window.location && window.location.href;
    }

    return '';
};

const calcAuction = (auctionData, gigId) => {
    if (!auctionData || !gigId) {
        return {};
    }

    const { auctionGigsIds = [], auctionId } = auctionData;
    const auctionGig = auctionGigsIds.find((auctionGigId) => auctionGigId === gigId);

    if (auctionGig && auctionId !== undefined) {
        return {
            auction: { id: auctionId },
        };
    }

    return {};
};

export default enrichImpressionData;
