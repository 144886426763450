import { useEffect, useState } from 'react';
import { createGigsSkeleton } from '../createGigsSkeleton';
import { COLLECT_MENU_POSITIONS } from '../../../GigCards/ModernGigCards/Components/CollectWrapper/constants';

import utilStyles from '../../../GigCards/styles/utils.module.scss';

export const useRenderableGigs = (gigs, loading, skeletonSize, lazyOffset) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const _offset = lazyOffset || gigs.length;

    const transformedGigs = gigs.map((gig, index) => ({ ...gig, lazyLoad: index >= _offset }));

    if (loading) {
        return createGigsSkeleton(skeletonSize);
    }

    if (!isClient) {
        return transformedGigs.slice(0, _offset);
    }

    return transformedGigs;
};

export const setCarouselGigsData = (slides, props, onClearClick, gigTracker) => {
    const {
        layoutClass,
        isTouch,
        isLoading,
        getContainerRef,
        showTranslatedUGC,
        collectProps,
        hidePrefix,
        excludedBadges,
    } = props;

    return slides.map((gig, index) => ({
        ...gig,
        layoutClass,
        isTouch,
        isLoading,
        tracker: gigTracker,
        getContainerRef,
        clearClick: onClearClick,
        showTranslatedUGC,
        collectProps: {
            ...collectProps,
            popoverContainerClassName: utilStyles.zIndex9999,
            menuPosition: index === 0 ? COLLECT_MENU_POSITIONS.BOTTOM_LEFT : COLLECT_MENU_POSITIONS.BOTTOM_RIGHT,
        },
        hidePrefix,
        excludedBadges,
    }));
};
