import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useOpenBriefCreationDrawer } from '@fiverr-private/dm_brief_creation_triggers';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { EmptyStateProDetailedIcon } from '@fiverr-private/icons';
import { Button } from '@fiverr-private/button';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { SendMessageModal } from '@fiverr-private/help_menu';
import { BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { useBusinessSignInSteps } from '@fiverr-private/user_session';
import { tracker } from '@fiverr-private/listing_lib';
import { DEFAULT_CURRENCY } from '../../../../../../../server/middlewares/addCurrency/constants';
import { isBrowser } from '../../../../../../../shared/utils';
import { trackingPageName } from '../../../../../../../shared/services/BIEvents/utils';
import { AppContext } from '../../../../context/listingsContext';
import { ProjectManageBanner } from '../../../banner/ProjectManageBanner';
import { BannerMode } from '../../../banner/util';
import { getBqEventSource } from '../../../biUtils';
import { useBusinessListingsContext } from '../../Context';
import { getNoResultsContent, hasBsm, shouldShowIcon, T_PREFIX } from './utils';
import { useSupportChat } from './useSupportChat';
import { getImpressionEventPayload, reportChatClickedToBigQuery } from './bqReports';
import { Divider } from './divider';

import styles from './index.module.scss';

const NoResultsWrapper = () => {
    const { openBriefCreationDrawer, isLoading } = useOpenBriefCreationDrawer();

    const { resultsCount, isExpertListings, activeFilters, managedAccount } = useBusinessListingsContext();

    const hasActiveFilters = !isEmpty(activeFilters);

    const [mainContainerRef] = useImpressionItem({
        enrichment: getImpressionEventPayload(isExpertListings, managedAccount, resultsCount, hasActiveFilters),
    });

    const hasResults = resultsCount > 0;
    const withBsm = hasBsm(managedAccount);
    const source = getBqEventSource(isExpertListings);
    const { flow, inProjectPanthersBannerFipNoResultPageRollout, currency } = useContext(AppContext);
    const pageName = trackingPageName(isExpertListings, flow);
    const { title, suggestion, help } = getNoResultsContent(hasActiveFilters, resultsCount, withBsm, isExpertListings);
    const briefCtaTranslateKey = `${T_PREFIX}.no_results.buttons.fill_out_brief_cta`;
    const chatTranslateKey = `${T_PREFIX}.no_results.buttons.support_chat_cta`;
    const sendMessageTranslateKey = `${T_PREFIX}.no_results.buttons.send_message_cta`;

    const { toggleChat } = useSupportChat();

    const openChat = () => {
        toggleChat();
        tracker.trackNoResultChatCTA(pageName, hasResults, withBsm);
        reportChatClickedToBigQuery(
            isExpertListings,
            translate(chatTranslateKey),
            resultsCount,
            hasActiveFilters,
            managedAccount
        );
    };

    useEffect(() => {
        if (isBrowser()) {
            tracker.trackNoResultImpression(hasActiveFilters, isExpertListings);
        }
    }, [hasActiveFilters, isExpertListings]);

    const { signInSteps: onChatClick } = useBusinessSignInSteps({
        id: `${pageName}-${source}:chat`,
        onSuccess: openChat,
        signInModalParams: {
            source,
            businessTriggerCtaType: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_CHAT,
            biEnrichmentParams: { source },
        },
        activationModalParams: { biPageName: pageName },
        migrateModalParams: {
            openFibMigrationPayload: {
                source,
                biEnrichment: {
                    pageElementName: source,
                    trigger: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_CHAT,
                },
                flowContext: {
                    type: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_CHAT,
                },
                redirectCtaData: {
                    type: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_CHAT,
                    source: `${pageName}-${source}:chat`,
                },
            },
        },
    });

    const { signInSteps: onBriefCreationClick } = useBusinessSignInSteps({
        id: `${pageName}-${source}:brief`,
        onSuccess: () =>
            openBriefCreationDrawer({
                currency: currency || DEFAULT_CURRENCY,
                source: `${source}${!hasResults ? '_no_results' : ''}`,
            }),
        signInModalParams: {
            source,
            businessTriggerCtaType: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_BRIEF,
            biEnrichmentParams: { source },
        },
        activationModalParams: { biPageName: pageName },
        migrateModalParams: {
            openFibMigrationPayload: {
                source,
                biEnrichment: {
                    pageElementName: source,
                    trigger: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_BRIEF,
                },
                flowContext: {
                    type: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_BRIEF,
                },
                redirectCtaData: {
                    type: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_BRIEF,
                    source: `${pageName}-${source}:brief`,
                },
            },
        },
    });

    const sendMessageButton = (
        <Button size="sm" intent="primary" variant="filled">
            <I18n k={sendMessageTranslateKey} />
        </Button>
    );

    return (
        <div className={classnames(styles.container, styles.newProjectBanner)}>
            <div ref={mainContainerRef} className="flex flex-items-center flex-col">
                {shouldShowIcon(resultsCount) && <EmptyStateProDetailedIcon size={124} />}
                <h6 className="m-t-16 text-display-6 co-text-darkest">{title}</h6>
                <p className="m-t-8 tbody-6">
                    {suggestion}
                    <br />
                    {help}
                </p>
                <div className={classnames(styles.buttonsContainer, 'm-t-24')}>
                    {!withBsm && (
                        <Button size="sm" intent="primary" variant="outline" onClick={onChatClick}>
                            <I18n k={chatTranslateKey} />
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        size="sm"
                        intent="primary"
                        variant={withBsm ? 'outline' : 'filled'}
                        onClick={onBriefCreationClick}
                    >
                        <I18n k={briefCtaTranslateKey} />
                    </Button>
                    {withBsm && (
                        <SendMessageModal
                            isMatching={true}
                            bsm={managedAccount?.bsm}
                            trigger={sendMessageButton}
                            source={source}
                        />
                    )}
                </div>
                {inProjectPanthersBannerFipNoResultPageRollout && (
                    <div className={styles.bannerContainer}>
                        <Divider />
                        <ProjectManageBanner
                            resultsCount={resultsCount}
                            hasActiveFilters={hasActiveFilters}
                            bannerMode={BannerMode.NO_RESULTS_PAGE}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NoResultsWrapper;
