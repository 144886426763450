import { padEnd, parseInt, round } from 'lodash';
import { interpolator } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { currencyFormat } from '@fiverr-private/localization';
import { EXPERIMENTS } from '../../../constants/experiments';
import { isBuyItAgain, isAverageSellingPrice } from '../../utils/gigTypes';
import { isInExperiment } from '../abTestsTransformer';

import { PRICE_LABEL_TYPES, NUMBER_PARTS } from './constants';

export const fallbackFormatFraction = (numberArr = []) => {
    const integer = numberArr.find(({ type }) => type === NUMBER_PARTS.INTEGER);
    const fraction = numberArr.find(({ type }) => type === NUMBER_PARTS.FRACTION);
    return fraction && parseInt(fraction.value) > 0
        ? `${integer.value}<sup>${padEnd(fraction.value, 2, '0')}</sup>`
        : integer.value;
};

// TEMP - mocking the number array until the fromatting is rolled out
export const fallbackArray = (price) => {
    const [integer, fraction] = price.toString().split('.');
    const priceArr = [
        {
            type: NUMBER_PARTS.INTEGER,
            value: integer,
        },
    ];

    if (fraction) {
        priceArr.push({
            type: NUMBER_PARTS.FRACTION,
            value: fraction,
        });
    }
    return priceArr;
};

/**
 * Build the price string out of the price array we got back from currencyFormat with asParts:true
 * @param {Array.<{type: String, value: String}>} numberArr - the response from currencyFormat asParts:true
 * @returns {string} - string that represents the price that need to be displayed (with the currency)
 */
export const buildPriceStr = (numberArr = []) => {
    const formattedParts = numberArr.map(({ type, value }) => {
        switch (type) {
            case NUMBER_PARTS.FRACTION:
                return `<sup>${padEnd(value, 2, '0')}</sup>`;
            case NUMBER_PARTS.DECIMAL:
                return '';
            default:
                return value;
        }
    });
    return formattedParts.join('');
};

export const priceFormatter = ({ currencyObj = {}, price }) => {
    const { template, name } = currencyObj;
    const interpolate = interpolator(/{{([^{}]*)}}/g);

    return currencyFormat(price, name, {
        fallbackValue: interpolate(template, { amount: price }),
    });
};

export const discountFormatter = ({ currencyObj = {}, price }) => {
    const { template, name } = currencyObj;
    const interpolate = interpolator(/{{([^{}]*)}}/g);

    return currencyFormat(price, name, {
        fallbackValue: interpolate(template, { amount: round(price, 2) }),
    });
};

export const priceMarkupFormatter = ({ currencyObj = {}, price }) => {
    const { template } = currencyObj;
    const interpolate = interpolator(/{{([^{}]*)}}/g);
    const fallbackValue = fallbackArray(price);

    const numberArr = currencyFormat(price, currencyObj.name, {
        fallbackValue: fallbackArray(price),
        asParts: true,
    });

    if (JSON.stringify(fallbackValue) === JSON.stringify(numberArr)) {
        return interpolate(template, { amount: fallbackFormatFraction(numberArr) });
    }

    return buildPriceStr(numberArr);
};

export const convertCentsToDollars = (value) => value / 100;

export const transformPrice = ({ currencyConverter, gig, useForcePriceRounding = false }) => {
    const {
        price_i: price,
        gigQueryParams: { recommended_price: recommendedPrice, price_type: priceType } = {},
        type,
    } = gig;
    const recommendedGigPrice = recommendedPrice && convertCentsToDollars(recommendedPrice);
    const shouldDisplayRecommendedGigPrice =
        (isBuyItAgain(type) || isAverageSellingPrice(priceType)) && recommendedGigPrice;
    const displayPrice = shouldDisplayRecommendedGigPrice ? recommendedGigPrice : price;

    const convertedPrice = currencyConverter.convert(displayPrice, {
        commaSeparate: true,
        convertRate: true,
        asNumber: true,
    });

    if (useForcePriceRounding) {
        return Math.ceil(convertedPrice);
    }

    return convertedPrice;
};

export const transformPriceLabel = ({ gig }) => {
    const { type, package_i: packageId, extras, gigQueryParams: { price_type: priceType } = {} } = gig;
    const isBuyAgain = isBuyItAgain(type);
    const isAverageSellingPriceType = isAverageSellingPrice(priceType);

    const { abTests = {} } = getContext();
    const inPriceBucketsTest = isInExperiment(EXPERIMENTS.CAT_LISTINGS_PRICE_BUCKETS, abTests);

    if (isBuyAgain) {
        return PRICE_LABEL_TYPES.BOUGHT_FOR;
    }

    if (inPriceBucketsTest) {
        if (packageId === 1 && !extras) {
            return PRICE_LABEL_TYPES.DEFAULT;
        }
        return null;
    }

    if (isAverageSellingPriceType) {
        return PRICE_LABEL_TYPES.USUALLY_BOUGHT_FOR;
    }

    return PRICE_LABEL_TYPES.DEFAULT;
};
