import React from 'react';
import { func, string, object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import CloseButton from '../closeButton';
import './index.scss';

const Header = ({ close, titleKey, params }) => (
    <header>
        <h4>
            <I18n k={titleKey} params={params} />
        </h4>
        <CloseButton close={close} />
    </header>
);

Header.propTypes = {
    close: func,
    titleKey: string,
    params: object,
};

export default Header;
