import { GoSearcherRoute, RequestMethod, SearchPerseusConfig, ListingAttributes } from '@fiverr-private/listing_types';
import { BasicFlow } from '../BasicFlow';
import { validateQuery } from '../../util';
import { buildGoSearcherQueryParams } from '../../url';
import { FetchGigsDataFromPhoenixResult, FetchRelatedSearchTerms } from '../../dataEnrichmentPromises';
import { ROLE_SEARCH_FLOW_PAGE_SIZE } from '../../constants';

export class RoleSearchFlow extends BasicFlow {
    override validateRequest: (listingAttributes: ListingAttributes) => void;
    override method: RequestMethod;
    override serviceQueryParams: (listingAttributes: ListingAttributes) => string;
    override pageSize: number;
    fetchGigsData: () => Promise<FetchGigsDataFromPhoenixResult>;
    fetchRelatedSearchTerms: () => Promise<FetchRelatedSearchTerms>;
    override dataEnrichment: () => Promise<unknown>[];
    override formatResult: () => void;

    timeout?: number;
    metricRouteKey: string;
    servicePath: string;
    route: GoSearcherRoute = 'roles/search';
    routeMetric = 'roles_search';

    constructor(serviceConfigs: SearchPerseusConfig) {
        super('roleSearchFlow');

        const { search_service: searchService } = serviceConfigs;

        const { timeout, name, url } = searchService;

        if (timeout?.[this.routeMetric]) {
            this.timeout = timeout[this.routeMetric];
        }

        this.metricRouteKey = `${name}.${this.routeMetric}`;
        this.servicePath = `${url}/v1/${this.route}`;
        this.fetchGigsData = () => Promise.resolve({ gigs: [] });
        this.fetchRelatedSearchTerms = () => Promise.resolve([]);
        this.dataEnrichment = () => [];
        this.formatResult = () => undefined;
        this.method = 'get';
        this.serviceQueryParams = buildGoSearcherQueryParams;
        this.pageSize = ROLE_SEARCH_FLOW_PAGE_SIZE;
        this.validateRequest = validateQuery;
    }
}
