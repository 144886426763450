import { logger } from '@fiverr-private/obs';
import { maxios } from '@fiverr-private/listing_lib';

const routesQueue = {};
const cache = {
    get(route) {
        return routesQueue[route] || [];
    },
    set(route, val) {
        routesQueue[route] = [...this.get(route), val];
    },
    removeEntry(route) {
        const current = [...this.get(route)];
        current.shift();

        routesQueue[route] = current;
    },
};

class RequestService {
    initialize(context) {
        this.context = context;
    }

    post(route, routeKey, payload) {
        cache.set(route, payload);

        return postRequest(route, routeKey, payload, this.context).then((result) => {
            const currentQueue = cache.get(route);
            result.accepted = currentQueue.length === 1;

            cache.removeEntry(route);
            return result;
        });
    }
}

const postRequest = async (route, routeKey, payload, context) => {
    const url = `/v4/search/${route}`;

    try {
        const { data } = await maxios.post(url, { ...context, ...payload }, { routeKey });

        return data;
    } catch (err) {
        logger.warn(err, { message: `Failed on Search perseus Request service | url: ${url}` });

        throw err;
    }
};

export default new RequestService();
