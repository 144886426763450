export const TALENT_BADGE = 'talent';
export const STUDIO_BADGE = 'studio';
export const FEATURED_BADGE = 'featured';
export const BUY_IT_AGAIN_BADGE = 'buy_it_again';
export const LIMITED_BADGE = 'limited';
export const SUBSCRIPTION_BADGE = 'subscription';
export const PROMOTED_GIGS = 'promoted_gigs';
export const UNAVAILABLE = 'unavailable';
export const OPEN_GIG_SAME_TAB_TARGET = '_self';

export const TOOLTIP_MAPPER = {
    DELIVERY_ASSET: 'deliveryAsset',
};

export const EXCLUDED_SELLER_LEVELS = [];

export const CAPTIONED_BADGES_LIST = [
    BUY_IT_AGAIN_BADGE,
    TALENT_BADGE,
    FEATURED_BADGE,
    STUDIO_BADGE,
    LIMITED_BADGE,
    PROMOTED_GIGS,
    SUBSCRIPTION_BADGE,
    UNAVAILABLE,
];

export const PRO_BADGE_LOCALE_CLASS_MAPPER = {
    'de-DE': 'german',
    es: 'spanish',
    'fr-FR': 'french',
    'it-IT': 'italian',
    'nl-NL': 'dutch',
    'pt-BR': 'portuguese',
};

export const DEFAULT_LOCALE = 'en-US';

export const ELEMENTS_NAME = {
    GIG_IMAGE: 'gallery_image',
    GIG_TITLE: 'gig_title',
    VIDEO_STRIP_GIG_TITLE: 'video_strip_gig_title',
    SELLER_TITLE: 'seller_title',
    GALLERY_NEXT: 'gallery_next',
    GIG_PRICE: 'gig_price',
    RATING: 'rating',
    DECISION_INDICATOR: 'decision_indicator',
    GIG_DISCOUNT_LINE: 'gig_discount_line',
    ADD_TO_FAVORITES: 'add_to_favorites',
    SAVE_IN: 'save_in',
    COLLECT_ICON: 'save',
    GIG_BADGE: 'gig_badge',
    VIDEO_ICON: 'video_icon',
    MUTE_ICON: 'mute_icon',
    WRAPPER: 'gig_wrapper',
    SERVICE_NAME: 'service_name',
    Ad: 'Ad',
    AVATAR: 'avatar',
    HIGHLIGHTS: 'highlights',
    AGENCY_INFO_WRAPPER: 'agency_info_wrapper',
};

export const ELEMENTS_TYPE = {
    LINK: 'link',
    BUTTON: 'button',
    TOGGLE: 'toggle',
};

export const URL_TARGET = {
    self: '_self',
    blank: '_blank',
};
