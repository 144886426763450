import React, { useContext, useEffect, useState } from 'react';
import { get, merge, noop, take } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { BannerWrapper } from '@fiverr-private/gig_listings';
import { tracker, maxios } from '@fiverr-private/listing_lib';
import { ROUTE_KEYS } from '../../../../../../shared/utils/request/constants';
import { AppContext } from '../../../../store';
import ACTION_TYPES from '../../../context/listingsContextReducerActionTypes';
import ErrorBoundary from '../../errors/error_boundary/ErrorBoundary';
import HighlightedRecommendations from '../HighlightedRecommendations';
import {
    AMOUNT_OF_SHOWN_GIGS,
    BANNER_CLASS_NAME,
    BANNER_ROW,
    BASE_KEY,
    GIF_PATH,
    SOURCE_COMPONENT,
    URL,
} from './constants';
import { getFirstOrganicDlcGigId, getHighestPricedGigId, parseRecommendations } from './utils';

import './styles.scss';

/**
 * Express delivery component, built to be server-side rendered with a loading placeholder
 * and then fetch the recommendations in CSR
 */
const ExpressDeliveryRecommendations = ({ onAbortRender = noop }) => {
    const [isLoading, setIsLoading] = useState(true);

    const { queryParameters: { ref = '' } = {} } = getContext();

    const appContext = useContext(AppContext);
    const { dispatch, displayQuery } = appContext;

    const abortRender = () => {
        dispatch({ type: ACTION_TYPES.DISPLAY_EXPRESS_RECOMMENDATION, payload: false });
        onAbortRender();
    };

    const fetchRecommendations = async (gigId) => {
        try {
            const configRequest = { routeKey: ROUTE_KEYS.FETCH_EXPRESS_RECOMMENDATION };

            const { data } = await maxios.post(
                URL,
                {
                    gig: {
                        id: gigId,
                    },
                    subcategory: {
                        id: dominateSubCategoryId,
                    },
                    context_filters: ref,
                    search_term: displayQuery,
                },
                configRequest
            );

            const gigs = parseRecommendations(data);

            if (gigs.length < AMOUNT_OF_SHOWN_GIGS) {
                abortRender();
                tracker.trackNoRecommendationsSupply(SOURCE_COMPONENT, 'not_enough_recommendations');
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_RECOMMENDATIONS_LISTINGS,
                    payload: [
                        merge({}, listings[0], {
                            recommendations: {
                                express: take(gigs, AMOUNT_OF_SHOWN_GIGS),
                            },
                        }),
                    ],
                });
            }
        } catch (err) {
            logger.error(err, {
                message: 'Failed while fetching express delivery recommendations in CSR',
                gigId,
                dominateSubCategoryId,
                ref,
            });
            tracker.trackNoRecommendationsSupply(SOURCE_COMPONENT, 'ajax_failed');
            abortRender();
        }

        setIsLoading(false);
    };

    const { listings = [], dominateSubCategoryId } = useContext(AppContext);

    const recommendationsGigs = get(listings, '[0].recommendations.express', []);

    useEffect(() => {
        const gigs = get(listings, '[0].gigs', []);
        const isHeadQuery = get(listings, '[0].isHeadQuery', true);

        const inputGigId = getQueryGigId({ isHeadQuery, gigs, dominateSubCategoryId });

        if (inputGigId) {
            // noinspection JSIgnoredPromiseFromCall
            fetchRecommendations(inputGigId);
        } else {
            trackNoRecommendations({ isHeadQuery, dominateSubCategoryId });
            abortRender();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const trackNoRecommendations = ({ isHeadQuery, dominateSubCategoryId }) => {
        isHeadQuery && !dominateSubCategoryId
            ? tracker.trackNoRecommendationsSupply(SOURCE_COMPONENT, 'no_dsc')
            : tracker.trackNoRecommendationsSupply(SOURCE_COMPONENT, 'not_enough_input_gigs');
    };

    const getQueryGigId = ({ isHeadQuery, gigs, dominateSubCategoryId }) =>
        isHeadQuery ? getFirstOrganicDlcGigId(dominateSubCategoryId, gigs) : getHighestPricedGigId(gigs);

    return (
        <ErrorBoundary>
            <HighlightedRecommendations
                className="express-delivery-recommendations"
                title={<I18n k={`${BASE_KEY}.title`} />}
                description={<I18n k={`${BASE_KEY}.description`} />}
                listings={listings[0]}
                gigs={recommendationsGigs}
                componentName={SOURCE_COMPONENT}
                iconPath={GIF_PATH}
                isLoading={isLoading}
            />
        </ErrorBoundary>
    );
};

export default ExpressDeliveryRecommendations;

export const ExpressDeliveryBanner = () =>
    BannerWrapper(<ExpressDeliveryRecommendations />)({
        bannerRow: BANNER_ROW,
        className: BANNER_CLASS_NAME,
    });
