import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button, Icon, Radio } from '@fiverr-private/fit';
import { l_times } from '@fiverr-private/fit/icons';
import './index.scss';

const SortContainer = ({ sorts, close, values, onChange, applyFilters }) => (
    <div className={classNames('filter-wrapper', 'modal', 'new-design', 'sort-container')}>
        <header>
            <span>
                <I18n k={'search_perseus.filter.sort_gigs_by'} />
            </span>
            <Icon className="icon" size={12} color={'#7A7D85'} onClick={close}>
                {l_times}
            </Icon>
        </header>
        <div className="filter-group radio-container">
            {sorts.map(({ id, alias }) => (
                <Radio
                    className="single-filter box-shadow-z1"
                    name={id}
                    key={id}
                    value={id}
                    onChange={onChange}
                    checked={id === values}
                >
                    {alias}
                </Radio>
            ))}
        </div>
        <div className="filters-result-btn">
            <Button className="button" onClick={() => applyFilters(close)}>
                <I18n k={'search_perseus.filter.show_filter_results'} params={{ total: '' }} />
            </Button>
        </div>
    </div>
);

SortContainer.defaultProps = {
    close: () => ({}),
};

SortContainer.propTypes = {
    close: PropTypes.func,
};

export default SortContainer;
