import React from 'react';
import { bool, string } from 'prop-types';
import { VettedProBadge } from '@fiverr-private/badges';

export const ProOverlayBadge = ({
    showTooltip = true,
    tooltipPosition = 'top',
    dataTestId,
    appendTooltipToBody,
    disableTooltipFlip,
}) => (
    <VettedProBadge
        showTooltip={showTooltip}
        tooltipPosition={tooltipPosition}
        dataTestId={dataTestId}
        appendTooltipToBody={appendTooltipToBody}
        disableTooltipFlip={disableTooltipFlip}
    />
);

ProOverlayBadge.displayName = 'ProOverlayBadge';

ProOverlayBadge.propTypes = {
    showTooltip: bool,
    tooltipPosition: string,
    dataTestId: string,
    appendTooltipToBody: bool,
    disableTooltipFlip: bool,
};
