import React from 'react';
import { bool, string } from 'prop-types';
import { Container, Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { Text } from '@fiverr-private/typography';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { SellerAvatar } from '../../../Components/SellerAvatar';
import { SellerName } from '../../../Components/SellerName';
import Badge from '../../../Components/Badge';
import * as styles from './styles.ve.css';

const useResponsiveAvatarSize = () => {
    const { width } = useWindowSize();
    let size = 36;

    if (width < theme.numericBreakpoints.sm) {
        size = 24;
    } else if (width >= theme.numericBreakpoints.lg) {
        size = 48;
    }

    return size;
};

const SellerInfo = ({ name: username, displayName, url, urlTarget, imgSrc, level, isOnline, type }) => {
    const responsiveAvatarSize = useResponsiveAvatarSize();
    return (
        <Container>
            <Container display={{ default: 'none', lg: 'flex' }} direction="row" gap="3" alignItems="center">
                <SellerAvatar
                    imgSrc={imgSrc}
                    displayName={displayName}
                    username={username}
                    isOnline={isOnline}
                    type={type}
                    size={responsiveAvatarSize}
                />
                <Stack direction="column" gap="0">
                    <Text size="b_md">
                        <SellerName
                            displayName={displayName}
                            username={username}
                            url={url}
                            urlTarget={urlTarget}
                            isPromotedGig
                            type={type}
                        />
                    </Text>
                    <Badge level={level} className={styles.badge} />
                </Stack>
            </Container>
            <Container display={{ default: 'flex', lg: 'none' }} direction="row" gap="3" alignItems="center">
                <SellerAvatar
                    imgSrc={imgSrc}
                    displayName={displayName}
                    username={username}
                    isOnline={isOnline}
                    type={type}
                    size={responsiveAvatarSize}
                />
                <Stack direction="row" gap="0" justifyContent="spaceBetween" width="100%">
                    <Text size="b_md">
                        <SellerName
                            displayName={displayName}
                            username={username}
                            url={url}
                            urlTarget={urlTarget}
                            isPromotedGig
                            type={type}
                        />
                    </Text>
                    <Badge level={level} className={styles.badge} />
                </Stack>
            </Container>
        </Container>
    );
};

SellerInfo.propTypes = {
    name: string,
    displayName: string,
    url: string,
    urlTarget: string,
    imgSrc: string,
    level: string,
    type: string,
    isOnline: bool,
};

export default SellerInfo;
