import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Radio } from '@fiverr-private/forms';
import Types from '../../types';
import { useSidebarFiltersContext, Triggers } from '../../Context';
import ShowMoreWrapper from '../ShowMoreWrapper';
import { removeSubcategoryFilter, navigateLeafCategory } from '../utils';
import FilterWithCount from '../FilterWithCount';
import styles from './index.module.scss';

const CHILDREN_TO_DISPLAY_ON_LOAD = 3;

const ScFilter = ({ filter, disableClicks }) => {
    const { activeTrigger } = useSidebarFiltersContext();
    const { selected: allCategoriesSelected, alias: allCategoriesAlias, id = '' } = filter;

    const radioListName = `normal-sc-filter-${id}-${uuid()}`;

    const shouldTriggerNavigationOnClick = activeTrigger === Triggers.FILTER_COMPONENT_CLICK;

    const categoryChanged = (params) => {
        if (shouldTriggerNavigationOnClick) {
            navigateLeafCategory(params, false, false);
            disableClicks();
        }
    };

    const categoryRemoved = () => {
        if (shouldTriggerNavigationOnClick) {
            removeSubcategoryFilter();
            disableClicks();
        }
    };

    const radioClasses = classNames(styles.categoryRadio, 'tbody-6');

    return (
        <div className={styles.container}>
            <div className="p-b-24">
                <ShowMoreWrapper filter={filter} showLessMaxOptions={CHILDREN_TO_DISPLAY_ON_LOAD}>
                    {({ optionsToDisplay }) => (
                        <>
                            <div className="p-b-8">
                                <Radio
                                    name={radioListName}
                                    className={radioClasses}
                                    onChange={categoryRemoved}
                                    defaultChecked={allCategoriesSelected}
                                >
                                    {allCategoriesAlias}
                                </Radio>
                            </div>
                            {optionsToDisplay.map(({ alias, selected, params, count }) => (
                                <div key={`sc-${id}-filter`} className="p-b-8">
                                    <Radio
                                        name={radioListName}
                                        className={radioClasses}
                                        onChange={() => categoryChanged(params)}
                                        defaultChecked={selected}
                                    >
                                        <FilterWithCount aliasClassName="tbody-6" alias={alias} count={count} />
                                    </Radio>
                                </div>
                            ))}
                        </>
                    )}
                </ShowMoreWrapper>
            </div>
        </div>
    );
};

ScFilter.propTypes = {
    filter: Types.subCategory.isRequired,
    disableClicks: PropTypes.func,
};

export default ScFilter;
