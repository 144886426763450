import { EVENT_ACTION_TYPES, EVENT_TYPES } from '../../../utils/trackGig';
import { ELEMENTS_TYPE } from '../../constants';
import { ELEMENT_METADATA } from './constants';

const getType = (actionType) => {
    switch (actionType) {
        case EVENT_ACTION_TYPES.CLICK:
        case EVENT_TYPES.GIG_CARD_CLICK: {
            return ELEMENTS_TYPE.BUTTON;
        }

        default: {
            return actionType;
        }
    }
};

export const trackVideoPlay = ({ track, actionType } = {}) => {
    const name = actionType === EVENT_ACTION_TYPES.CLICK ? ELEMENT_METADATA.PLAY_VIDEO_ICON : actionType;
    track(EVENT_TYPES.GIG_CARD_PLAY_VIDEO, { name, type: getType(actionType) }, actionType);
};

export const trackVideoPause = ({ track, actionType, playerRef = {} } = {}) => {
    const { current: playerElement } = playerRef;
    const videoTime = playerElement?.currentTime;

    if (typeof IntersectionObserver === 'undefined' || !playerElement) {
        trackVideoPauseInternal({ track, actionType, videoTime });
        return;
    }

    const observer = new IntersectionObserver(
        ([entry]) => {
            observer.unobserve(playerElement);

            const ratio = parseFloat(entry.intersectionRatio.toString()).toFixed(2) * 100;
            trackVideoPauseInternal({ track, actionType, videoTime, ratio });
        },
        { threshold: 0 }
    );

    observer.observe(playerElement);
};

const trackVideoPauseInternal = ({ track, actionType, videoTime, ratio } = {}) => {
    const name = actionType === EVENT_ACTION_TYPES.CLICK ? ELEMENT_METADATA.PAUSE_VIDEO_ICON : actionType;
    track(EVENT_TYPES.GIG_CARD_PAUSE_VIDEO, { name, type: getType(actionType), metadata: ratio }, actionType, {
        ...(videoTime && { video: { watch_duration: videoTime, exposure_percentage: ratio } }),
    });
};
