/**
 * Retrieves the gig title props.
 * @param {Object} props - The props for the gig title.
 * @returns {Object} - An object containing the gig title props.
 * @property {boolean} hidePrefix - Indicates whether to hide the prefix.
 * @property {string} title - The title of the gig.
 * @property {boolean} showTranslatedUGC - Indicates whether to show translated UGC.
 * @property {string} translatedTitle - The translated title of the gig.
 * @property {string} localizedTitle - The localized title of the gig.
 * @property {string} url - The URL of the gig.
 * @property {string} urlTarget - The target of the gig URL.
 * @property {string} className - The class name for the gig title.
 * @property {string} sellerType - The type of the seller.
 */
export const getGigTitleProps = (props) => {
    const { hidePrefix, title, showTranslatedUGC, translatedTitle, localizedTitle, url, urlTarget, seller, className } =
        props;

    return {
        hidePrefix,
        title,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        url,
        urlTarget,
        className,
        sellerType: seller?.type,
    };
};
