import { SellerLevelOneBadge, SellerLevelTwoBadge, SellerTopRatedBadge } from '@fiverr-private/go_shared_ui';

export const FEATURED = 'featured';
export const TALENT = 'talent';
export const SUBSCRIPTION = 'subscription';
export const PROMOTED_GIGS = 'promoted_gigs';

export const GIG_FEATURE_BADGE_TYPES = {
    PRO: 'pro',
    BUY_IT_AGAIN: 'buy_it_again',
    FIVERR_CHOICE: 'fiverr_choice',
};

export const TOOLTIP_EXCLUDED_BADGES = [GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN];

export const SELLER_LEVELS = {
    TOP_RATED_SELLER: 'top_rated_seller',
    LEVEL_ONE_SELLER: 'level_one_seller',
    LEVEL_TWO_SELLER: 'level_two_seller',
};

export const MAP_SELLER_LEVEL_TO_BADGE = {
    [SELLER_LEVELS.TOP_RATED_SELLER]: SellerTopRatedBadge,
    [SELLER_LEVELS.LEVEL_ONE_SELLER]: SellerLevelOneBadge,
    [SELLER_LEVELS.LEVEL_TWO_SELLER]: SellerLevelTwoBadge,
};
