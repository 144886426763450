import React, { Component } from 'react';
import classNames from 'classnames';
import { replace } from 'lodash';
import PropTypes from 'prop-types';
import { logger } from '@fiverr-private/obs';
import { AssetType } from '../../../types';
import {
    DELIVERIES_NEW_RESOLUTION,
    DELIVERIES_NEW_RESOLUTION_X2,
    IMAGES_RESOLUTION,
    IMAGES_RESOLUTION_X2,
} from '../../../utils/assetsHelper/constants';
import { FALLBACK_URL, IMG_LOADING_TYPES, NOT_FOUND_STATUS } from './constants';

class SlideImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.handleImageLoadError = this.handleImageLoadError.bind(this);
    }

    componentDidMount() {
        const { lazy } = this.props;
        lazy && require('lazysizes');
    }

    handleImageLoadError() {
        const { error } = this.state;
        const imageSrc = this.img.src;

        if (!error) {
            fetch(imageSrc)
                .then((result) => {
                    const notFound = result.status === NOT_FOUND_STATUS;
                    notFound &&
                        logger.warn(`Failed to load Gig image from : ${imageSrc}`, { source: 'gig-cards-package' });
                })
                .catch(error); // TODO - handle this error

            this.img.srcset = '';
            this.img.src = FALLBACK_URL;
            this.setState({ error: true });
        }
    }

    render() {
        const { asset = {}, lazy, showNewImageRatio = false } = this.props;

        const { image = {}, deliveredAsset } = asset;
        let assetSrc = image.src || FALLBACK_URL;

        if (showNewImageRatio && deliveredAsset) {
            assetSrc = replace(assetSrc, IMAGES_RESOLUTION, DELIVERIES_NEW_RESOLUTION);
            image.srcSet = replace(
                replace(image.srcSet, IMAGES_RESOLUTION, DELIVERIES_NEW_RESOLUTION),
                IMAGES_RESOLUTION_X2,
                DELIVERIES_NEW_RESOLUTION_X2
            );
        }

        // When marked, assets will be lazy loaded to reduce TTIa
        const imageProps = lazy
            ? { 'data-src': assetSrc, 'data-srcset': image.srcSet, loading: IMG_LOADING_TYPES.LAZY }
            : {
                  src: assetSrc,
                  srcSet: image.srcSet,
                  loading: IMG_LOADING_TYPES.EAGER,
              };

        const classnames = classNames({
            'box-image-ratio': showNewImageRatio,
            'delivery-asset': deliveredAsset,
            lazyload: lazy,
        });

        return (
            <img
                {...imageProps}
                ref={(img) => (this.img = img)}
                className={classnames}
                onError={this.handleImageLoadError}
                alt={asset.title}
            />
        );
    }
}

SlideImage.propTypes = {
    asset: AssetType.isRequired,
    lazy: PropTypes.bool.isRequired,
    showNewImageRatio: PropTypes.bool,
};

SlideImage.defaultProps = {
    lazy: false,
};

export default SlideImage;
