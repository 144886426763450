import React, { useContext } from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { AppContext } from '../../context/listingsContext';
import TopListingBanner from './topListingBanner';
import PromoteYourGigBtn from './PromoteYourGigBtn';
import desktopBannerLogo from './assets/desktop_banner_logo.png';
import mobileBannerLogo from './assets/mobile_banner_logo.png';
import './style.scss';

const PromoteBanner = () => {
    const appContext = useContext(AppContext);
    const {
        flow,
        currentUser: { username },
        listings: [listings],
    } = appContext;
    const { promotedGigsBanner: { encouragementStatus } = {} } = listings;
    return (
        <TopListingBanner>
            <span className="text-container">
                <h2 className={classNames('m-b-8', 'title')}>
                    <I18n k={'search_perseus.promoted_gigs_banner.title'} />
                </h2>
                <p className={classNames('m-b-24', 'label')}>
                    <I18n k={'search_perseus.promoted_gigs_banner.label'} />
                </p>
                <PromoteYourGigBtn username={username} flow={flow} promotionEncouragementStatus={encouragementStatus} />
            </span>
            <div className="img-container">
                <img className="desktop-banner-logo" src={desktopBannerLogo} />
                <img className="mobile-banner-logo" src={mobileBannerLogo} />
            </div>
        </TopListingBanner>
    );
};

export default PromoteBanner;
