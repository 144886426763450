import React from 'react';
import { ComplianceProvider } from '@fiverr-private/compliance';
import { getContext } from '@fiverr-private/fiverr_context';
import { useAppContext } from './context/listingsContext';

export const ComplianceDataProvider = ({ children }) => {
    const { organizationId, userId } = getContext();

    const { complianceData, inStorksComplianceGeneralRollout } = useAppContext();

    if (!inStorksComplianceGeneralRollout || !organizationId || !userId) {
        return children;
    }

    return (
        <ComplianceProvider
            pageName={'search_perseus'}
            complianceData={complianceData?.state}
            organizationId={organizationId}
            requester_id={userId}
        >
            {children}
        </ComplianceProvider>
    );
};
