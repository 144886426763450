import { snakeCase } from 'lodash';
import { retryDecorator as baseRetryDecorator, RetryConfig } from 'ts-retry-promise';
import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../lib/metric';

type RetryableFunction<T> = (...args: any[]) => Promise<T>;
interface ExtendedRetryConfig<T> extends Partial<RetryConfig<T>> {
    metricName: string;
    source?: string;
}

// Total timeout for all retries. This seems high but webpack's chunk load
// timeout is 120s and we don't want to time out before webpack does.
const TOTAL_TIMEOUT = 125 * 1000;

const DEFAULT_RETRY_CONFIG = {
    timeout: TOTAL_TIMEOUT,
    retries: 2,
    delay: 400,
    backoff: 'LINEAR',
} satisfies Partial<RetryConfig>;

// Get a default source by removing the path params
// from the URL, leaving only the base route path.
export const getDefaultSource = () => {
    // This shouldn't happen, but just in case
    if (typeof window === 'undefined') {
        return 'server';
    }

    const { pathParameters } = getContext();
    const paramsRegex = new RegExp(Object.values(pathParameters).join('|'), 'g');

    return snakeCase(window.location.pathname.replace(paramsRegex, ''));
};

export const retryDecorator = <T, F extends RetryableFunction<T>>(func: F, config: ExtendedRetryConfig<T>) =>
    baseRetryDecorator(func, {
        ...DEFAULT_RETRY_CONFIG,
        logger: () => {
            const { metricName, source = getDefaultSource() } = config;

            metric.count(`retries.${metricName}.${source}`);
        },
        ...config,
    });
