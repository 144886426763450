import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@fiverr-private/hooks';

// If shouldObserve is false, then we don't want that `useIntersectionObserver` will track the element,
// so we send `nullRef` to `useIntersectionObserver` and he will check if `observableRef` is null then he won't track it
// See here how it work: https://github.com/fiverr/penta_sphinx/blob/master/packages/hooks/src/hooks/useIntersectionObserver/index.ts#L17
const useSafeIntersectionObserver = ({ shouldObserve, itemRef, intersectionObserverOptions }) => {
    const nullRef = useRef(null);
    const observableRef = shouldObserve ? itemRef : nullRef;

    return useIntersectionObserver(observableRef, intersectionObserverOptions);
};

export const useObserveOnEnterAndLeave = ({ shouldObserve, onEnter, onLeave, threshold = 0.5 }) => {
    const itemRef = useRef(null);
    const entry = useSafeIntersectionObserver({ shouldObserve, itemRef, intersectionObserverOptions: { threshold } });
    const hasEntry = !!entry;
    const isIntersecting = entry?.isIntersecting;

    useEffect(() => {
        if (hasEntry) {
            if (isIntersecting) {
                onEnter();
            } else {
                onLeave();
            }
        }
    }, [hasEntry, isIntersecting, onEnter, onLeave]);

    return itemRef;
};
