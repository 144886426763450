import { Carousel } from '@fiverr-private/orca';

export const SLIDER_BASE_NUM = 5;
export const SMALL_SLIDER_BASE_NUM = 8;

export const CAROUSEL_BREAKPOINTS = {
    [Carousel.BREAKPOINTS.BP_600]: 1,
    [Carousel.BREAKPOINTS.BP_750]: 2,
    [Carousel.BREAKPOINTS.BP_900]: 2,
    [Carousel.BREAKPOINTS.BP_1000]: 3,
    [Carousel.BREAKPOINTS.BP_1160]: 3,
    [Carousel.BREAKPOINTS.BP_1300]: 4,
};
