import axios from 'axios';
import { logger } from '@fiverr-private/obs';
import { CustomAxiosConfig } from './maxios';

export const sendRequestReportToBQ =
    (listingsPegasusUrl: string) => async (config: CustomAxiosConfig, statusCode: string | number) => {
        const { url, headers, startedAt } = config;

        const data = [
            {
                uri: url,
                status_code: statusCode,
                processing_time: Date.now() - startedAt,
                created_at: 'AUTO', // AUTO sets the current timestamp in BQ
                headers: JSON.stringify(headers),
            },
        ];

        axios
            .post(`${listingsPegasusUrl}/report_request`, { data })
            .catch((error) => logger.warn('Failed to send request_report payload to endpoint', error));
    };
