import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import axios from 'axios';
import { logger } from '@fiverr-private/obs';
import { BUSINESS_TRIGGER_CTA, useSuccessSignCtaEvent } from '@fiverr-private/business_success_sign_modal';
import {
    FLOW_CONTEXT_TYPES,
    ModalType,
    openFipMigrationModal,
    shouldDisplayMigrationPopup,
} from '@fiverr-private/pro_migration';
import pathfinder from '@fiverr-private/pathfinder';
import { LazyComponent } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Button, IconButton } from '@fiverr-private/button';
import { PaperPlaneIcon } from '@fiverr-private/icons';
import { openFloatingChat } from '@fiverr-private/go_floating_chat_triggers';
import { PATHFINDER } from '../../../../constants';
import { clickOnExpertCardEvent, clickOnSendMessageEvent } from '../../../../utils/biEvents/events';
import { ELEMENT_NAMES } from '../../../../utils/biEvents/constants';
import { useExpertCardContext } from '../../../../contexts';
import { fetchOnlineStatus, getSellerDisplayName } from '../../utils';
import { SEND_MESSAGE_SOURCE } from './constants';
import UserActivationModalWrapper from './UserActivationModalWrapper';

import './index.scss';

const ASSISTANT_DATA_PATHFINDER_ID = 'personal_assistant_get_chat_data';

const getAssistantData = async (sellerId) => {
    const assistantDataUrl = pathfinder(ASSISTANT_DATA_PATHFINDER_ID, { user_id: `${sellerId}` });

    try {
        const response = await axios.get(assistantDataUrl);
        const { data } = response;

        return data;
    } catch (error) {
        logger.error(error, {
            description: 'Failed to get assistant data',
            payload: { sellerId, url: assistantDataUrl },
        });

        return {};
    }
};

export const ContactButton = ({ onClick = noop, className, source, size = 'md', isCta }) => {
    const {
        isMobile,
        userId,
        experience: { isBusiness },
    } = getContext();
    const { cardImpressionId, seller, gigs } = useExpertCardContext();
    const [showModal, setShowModal] = useState(false);
    const [initialData, setInitialData] = useState(null);
    const [renderAndOpenUserActivationModal, setRenderAndOpenUserActivationModal] = useState(false);
    const sellerName = getSellerDisplayName(seller);
    const sellerSourceCta = `listings-card-${seller.username}:${source}`;

    const openContactSellerModal = async () => {
        const { username: sellerUsername } = seller;

        try {
            const { fetchInitialData } = await import(
                /* webpackChunkName: 'fetchInitialData' */ '@fiverr-private/send_message'
            );
            const initialData = await fetchInitialData(sellerUsername);

            if (shouldRedirectToInbox(initialData)) {
                window.location.href = pathfinder(PATHFINDER.CONVERSATION_PAGE, {
                    id: sellerUsername,
                });

                return;
            }

            setInitialData(initialData);
            setShowModal(true);
        } catch (error) {
            logger.error(error, {
                description: 'Failed to get send message initial data',
                context: {
                    sellerUsername,
                },
            });
        }
    };

    const openAssistantModal = async (seller) => {
        const { username: sellerUserName, displayName: sellerDisplayName, avatarUrl: sellerAvatarUrl } = seller;
        const isSellerOnline = await fetchOnlineStatus(sellerUserName);

        openFloatingChat({
            data: {
                recipientUsername: sellerUserName,
                seller: {
                    username: sellerUserName,
                    name: sellerDisplayName,
                    imageSrc: sellerAvatarUrl,
                    isOnline: isSellerOnline,
                },
            },
        });
    };

    const onContactClick = async () => {
        clickOnExpertCardEvent({
            pageName: source,
            elementName: ELEMENT_NAMES.CONTACT_SELLER,
            impressionId: cardImpressionId,
            seller,
        });

        onClick();

        if (!userId) {
            window.openSignUpModal?.(
                {
                    triggerCta: {
                        type: BUSINESS_TRIGGER_CTA.CONTACT,
                        source: sellerSourceCta,
                        data: { sellerName },
                    },
                },
                { source }
            );
            return;
        }

        const { userIsPending } = await import(
            /* webpackChunkName: 'userIsPending' */ '@fiverr-private/user_activation/src/UserActivationModal/api'
        );
        if (await userIsPending()) {
            setRenderAndOpenUserActivationModal(true);
            return;
        }

        if (shouldDisplayMigrationPopup()) {
            openFipMigrationModal({
                variant: ModalType.FullMigration,
                payload: {
                    source: `${source}.${FLOW_CONTEXT_TYPES.contact}`,
                    biEnrichment: {
                        pageElementName: source,
                        trigger: FLOW_CONTEXT_TYPES.contact,
                    },
                    flowContext: {
                        type: FLOW_CONTEXT_TYPES.contact,
                        sellerName,
                    },
                    redirectCtaData: {
                        source: sellerSourceCta,
                        type: BUSINESS_TRIGGER_CTA.CONTACT,
                        data: { sellerName },
                    },
                    onSuccess: openContactSellerModal,
                },
                callbackWhenNotMounted: openContactSellerModal,
            });

            return;
        }

        const { id: sellerId } = seller;
        const assistantData = await getAssistantData(sellerId);
        const { isAssistantActive, currentUserRole } = assistantData;
        const shouldShowFloatingChat = isAssistantActive && !!currentUserRole && currentUserRole === 'buyer';

        if (shouldShowFloatingChat) {
            return openAssistantModal(seller);
        } else {
            return openContactSellerModal();
        }
    };

    useSuccessSignCtaEvent({
        type: BUSINESS_TRIGGER_CTA.CONTACT,
        source: sellerSourceCta,
        callback: onContactClick,
    });

    const shouldRedirectToInbox = (initialData) => isMobile || isEmpty(initialData) || initialData?.priorInteraction;

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleAfterSubmit = () =>
        clickOnSendMessageEvent({
            pageName: source,
            impressionId: cardImpressionId,
            seller,
        });

    const lazyImportModal = () => import(/* webpackChunkName: 'ContactSellerDrawer' */ './ContactSellerDrawer');

    const getModalProps = () => {
        const recipientInfo = initialData?.recipient || {};
        const show = showModal && !isEmpty(initialData);
        const { id: sellerId, displayName } = seller;
        const { categoryId, subCategoryId } = gigs?.[0] || {};

        return {
            pageName: source,
            isOpen: show,
            recipientInfo: {
                ...recipientInfo,
                sellerId,
                displayName,
            },
            attachmentsUploadConfig: initialData?.attachmentsUploadConfig,
            showInfo: true,
            source: SEND_MESSAGE_SOURCE,
            onClose: handleCloseModal,
            onSubmitSuccess: handleAfterSubmit,
            withSatisfactionGuarantee: seller?.isPro && isBusiness,
            ...(categoryId && subCategoryId ? { brief: { categoryId, subCategoryId } } : {}),
        };
    };

    const ButtonComponent = isCta ? Button : IconButton;

    if (gigs && gigs.length === 0) {
        return null;
    }

    return (
        <>
            <ButtonComponent
                className={classNames(`contact-icon-${size}`, className, {
                    'contact-icon': !isCta,
                    'is-cta': isCta,
                })}
                onClick={onContactClick}
                size={size}
                variant="outline"
                intent={isCta ? 'primary' : 'secondary'}
            >
                {isCta ? <I18n k="listings.card_actions.contact" /> : <PaperPlaneIcon />}
            </ButtonComponent>
            <LazyComponent lazyImport={lazyImportModal} shouldImport={showModal} componentProps={getModalProps()} />
            <UserActivationModalWrapper
                renderAndOpen={renderAndOpenUserActivationModal}
                onClose={() => setRenderAndOpenUserActivationModal(false)}
                sellerName={sellerName}
                source={source}
            />
        </>
    );
};

ContactButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    source: PropTypes.string,
    size: PropTypes.string,
    isCta: PropTypes.bool,
};

export default ContactButton;
