import React from 'react';
import { arrayOf, object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import HighlightedRecommendations from '../HighlightedRecommendations';
import { TROPHY_GIF_PATH, SOURCE_COMPONENT } from './constants';
import './styles.scss';

const BASE_KEY = 'search_perseus.recommendations.repeat_buyers';

const RepeatedBuyersRecommendations = ({ listings, gigs = [], isLoading = false }) => (
    <HighlightedRecommendations
        className="repeated-buyers-recommendations"
        title={<I18n k={`${BASE_KEY}.title`} />}
        description={<I18n k={`${BASE_KEY}.description`} />}
        listings={listings}
        gigs={gigs}
        componentName={SOURCE_COMPONENT}
        iconPath={TROPHY_GIF_PATH}
        isLoading={isLoading}
    />
);

RepeatedBuyersRecommendations.propTypes = {
    listings: object,
    gigs: arrayOf(object),
};

export default RepeatedBuyersRecommendations;
