import React from 'react';
import classNames from 'classnames';
import ListingsContainer from '../../GigCardListings/ListingsContainer';
import { withListingsTransformer } from '../../GigCardListings/hoc/withListingsTransformer';
import { GigCardListingPropTypes } from '../../GigCardListings/types/types';
import {
    GIG_CARD,
    LISTINGS_CONTAINER_GRID_VIEW,
    LISTINGS_CONTAINER_GRID_VIEW_SM,
} from '../../GigCardListings/utils/constants';
import { RenderGigForGigCardListings } from './RenderGigForGigCardListings';

const GigCardListings = ({
    gigs,
    gigImpression,
    onImpression,
    isTouch,
    HeaderComponent,
    container,
    tracking,
    listingsContainerClassName,
    size = 24,
    isLoading,
    lazyLoadOffset = 0,
    isVintageProTest = false,
    isExperiential = false,
    isNonExperiential = false,
    showTranslatedUGC,
    banners,
    collectProps,
    Wrapper,
    hidePrefix = false,
    hideSellerInfo = false,
    showGalleryDecisionIndicators,
    rollouts,
    excludedBadges = [],
    subCategoryData = {},
    experimentsToAllocate = {},
    gridContainerSize = 'md',
    triggerData = {},
}) => (
    <ListingsContainer
        gigs={gigs}
        gigImpression={gigImpression}
        HeaderComponent={HeaderComponent}
        container={container}
        isLoading={isLoading}
        tracking={tracking}
        lazyLoadOffset={lazyLoadOffset}
        size={size}
        isVintageProTest={isVintageProTest}
        banners={banners}
        className={classNames(LISTINGS_CONTAINER_GRID_VIEW, listingsContainerClassName, {
            [LISTINGS_CONTAINER_GRID_VIEW_SM]: gridContainerSize === 'sm',
        })}
        RenderGig={RenderGigForGigCardListings}
        enablePopover={true}
        rollouts={rollouts}
        renderGigProps={{
            ExternalWrapper: Wrapper,
            isLoading,
            onImpression,
            isTouch,
            isExperiential,
            isNonExperiential,
            showTranslatedUGC,
            collectProps,
            hidePrefix,
            hideSellerInfo,
            showGalleryDecisionIndicators,
            excludedBadges,
            subCategoryData,
            experimentsToAllocate,
            triggerData,
        }}
    />
);

GigCardListings.propTypes = GigCardListingPropTypes;

export default withListingsTransformer(GIG_CARD)(GigCardListings);
