import { localStorage, env } from '@fiverr-private/futile';

const KEYS = {
    DELIVERY_ASSET: 'delivery_asset_tooltip_initial_message',
};

const ssrStorageMock = {
    [KEYS.DELIVERY_ASSET]: true,
};

const defaultStorage = {
    [KEYS.DELIVERY_ASSET]: false,
};

const get = (key) => {
    if (!env.browser) {
        return ssrStorageMock[key];
    }

    return localStorage.get(key) || defaultStorage[key];
};

const set = (key, value) => {
    if (env.browser) {
        return localStorage.set(key, value);
    }
};

export default { get, set, KEYS };
