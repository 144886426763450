import React from 'react';
import { SellerLevelOneBadge, SellerLevelTwoBadge, TopRatedBadge } from '@fiverr-private/badges';
import { Center } from '@fiverr-private/layout_components';
import { SellerLevel } from '../../shared/types';
import propTypes from './types';

export const MAP_SELLER_LEVEL_TO_BADGE = {
    [SellerLevel.LevelTrs]: TopRatedBadge,
    [SellerLevel.LevelOne]: SellerLevelOneBadge,
    [SellerLevel.LevelTwo]: SellerLevelTwoBadge,
};

export const SellerLevelBadge = ({ sellerLevel, badges }) => {
    if (!MAP_SELLER_LEVEL_TO_BADGE[sellerLevel] && !badges?.pro && !badges?.choice) {
        return null;
    }

    const LevelBadge = MAP_SELLER_LEVEL_TO_BADGE[sellerLevel];

    if (!LevelBadge) {
        return null;
    }

    return (
        <Center aria-label="Seller Level Badge" whiteSpace="nowrap" direction="row">
            <LevelBadge appendTooltipToBody />
        </Center>
    );
};

SellerLevelBadge.propTypes = propTypes;
