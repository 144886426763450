import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { IMAGE_TYPE, VIDEO_TYPE } from '../../../GigCards/utils/assetsHelper/constants';
import { GIG_FEATURE_BADGE_TYPES, FEATURED, SELLER_LEVELS, TALENT } from '../gigBadges/constants';
import { GIG_ENGINE_TYPES_MAPPER } from '../gigTypes/constants';
import {
    AGENCY_CARD,
    BASIC_GIG_CARD,
    GIG_CARD,
    SELLER_FOCUSED_GIG_CARD,
    AGENCY_CARD_V2,
    VIDEO_STRIP_CARD,
} from '../constants';
import { isInExperimentGroup } from '../../ListingsTransformer/abTestsTransformer';
import { ALLOCATIONS, EXPERIMENTS } from '../../../constants/experiments';

const cardNameMap = {
    [SELLER_FOCUSED_GIG_CARD]: SELLER_FOCUSED_GIG_CARD,
    [BASIC_GIG_CARD]: BASIC_GIG_CARD,
    [AGENCY_CARD]: AGENCY_CARD,
    [AGENCY_CARD_V2]: AGENCY_CARD_V2,
    [VIDEO_STRIP_CARD]: VIDEO_STRIP_CARD,
    default: GIG_CARD,
};

const SELLER_LEVEL_NO_LEVEL = 'SELLER_LEVEL_NO_LEVEL';

const getCardAttributesByType = (gig, cardType) => {
    const sharedAttributes = {
        shown_price: gig.price,
        shown_package: gig.packageIndex,
        shown_rated_orders: gig.ratings?.count,
        shown_rating: gig.ratings?.rating,
        price_usd: gig.originalPrice,
        is_online: gig.seller?.isOnline,
        badges: getBadges(gig),
        engine: getGigEngine(gig.type),
        auction: gig.auction,
        seller_displayed_languages: getSellerDisplayedLanguages(gig),
        translation_layer: getGigTranslationLayer(gig),
        price_bucket: '' /**/,
        price_scope: gig.numericPricingFactor?.scope,
    };

    if (cardType === SELLER_FOCUSED_GIG_CARD) {
        const { gigAttributes: { attributeType, attributeKey } = {} } = gig;

        return {
            seller_focused_card_attributes: {
                ...sharedAttributes,
                decision_indicators_section: {
                    parameter_name: attributeType,
                    parameter_value: {
                        id: attributeKey,
                        text: [], // TODO: in test out need to send only the visible attributes | attributeValues.map(({ id }) => id) |
                    },
                },
            },
        };
    } else {
        return {
            gig_card_attributes: {
                ...sharedAttributes,
                attachment: getAttachment(gig.assets),
                images_count: getAttachmentsCount(gig.assets, IMAGE_TYPE),
                videos_count: getAttachmentsCount(gig.assets, VIDEO_TYPE),
            },
        };
    }
};

const getGigCardEnrichment = (gig = {}, cardType) => {
    const cardKey = cardType === SELLER_FOCUSED_GIG_CARD ? SELLER_FOCUSED_GIG_CARD : GIG_CARD;

    return {
        [cardKey]: {
            gig: {
                id: gig.id,
            },
            seller: {
                id: gig.seller?.id,
                country: gig.seller?.country,
                seller_level: getLevel(gig.seller?.level),
                is_pro: gig.isPro,
            },
            ...getCardAttributesByType(gig, cardType),
        },
        name: cardNameMap[cardType] || cardNameMap.default,
    };
};

const getBadges = ({ badge = {}, showCountryFlag, isPromotedGig, offerConsultation, seller, models } = {}) => {
    const { abTests } = getContext();
    const result = [];

    badge.type === GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE && result.push('BADGE_FIVERR_CHOICE');
    badge.type === GIG_FEATURE_BADGE_TYPES.PRO && result.push('BADGE_PRO');
    isPromotedGig && result.push('BADGE_PROMOTED_GIG');
    badge.type === TALENT && result.push('BADGE_RISING_TALENT');
    badge.type === GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN && result.push('BADGE_BUY_IT_AGAIN');
    badge.type === FEATURED && result.push('BADGE_FEATURED');
    showCountryFlag && result.push('BADGE_SELLER_COUNTRY_FLAG');
    offerConsultation && result.push('BADGE_OFFER_CONSULTATION');
    !isEmpty(models) && result.push('BADGE_AI_DELIVERY');

    const shouldShowTypeBadgeAsOverlay = isInExperimentGroup(
        EXPERIMENTS.HAWKS_OVERLAY_BADGES,
        [ALLOCATIONS.TEST_B],
        abTests
    );
    const typeBadgesThatShowInsteadOfSellerLevel = shouldShowTypeBadgeAsOverlay
        ? [TALENT, GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN]
        : [
              GIG_FEATURE_BADGE_TYPES.PRO,
              GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE,
              TALENT,
              GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN,
          ];

    const shouldSellerLevelBeDisplayed = !typeBadgesThatShowInsteadOfSellerLevel.includes(badge.type);

    if (shouldSellerLevelBeDisplayed) {
        const sellerLevel = getLevel(seller?.level);

        if (sellerLevel !== SELLER_LEVEL_NO_LEVEL) {
            result.push(`BADGE_${sellerLevel}`);
        }
    }

    return result;
};

const getAttachment = (assets = []) => {
    const result = {};

    const attachment = assets[0];

    if (attachment) {
        result.id = attachment.id;
        result.is_video = attachment.type === 'video';
        result.is_default = true;
    }

    return result;
};

const getGigEngine = (type) => GIG_ENGINE_TYPES_MAPPER[type] || GIG_ENGINE_TYPES_MAPPER.DEFAULT;

const getSellerDisplayedLanguages = ({ showISpeak, seller: { sellerLanguages = [] } = {} }) => {
    if (!showISpeak) {
        return [];
    }

    return sellerLanguages.map(({ name }, index) => ({ language_code: name, position: index + 1 }));
};

const getLevel = (level) => {
    switch (level) {
        case SELLER_LEVELS.LEVEL_ONE_SELLER: {
            return 'SELLER_LEVEL_ONE';
        }

        case SELLER_LEVELS.LEVEL_TWO_SELLER: {
            return 'SELLER_LEVEL_TWO';
        }

        case SELLER_LEVELS.TOP_RATED_SELLER: {
            return 'SELLER_LEVEL_TOP_RATED';
        }

        default: {
            return SELLER_LEVEL_NO_LEVEL;
        }
    }
};

const getAttachmentsCount = (assets = [], type, includeDeliveries = false) => {
    const attachmentsOfType = assets.filter(({ type: assetType }) => assetType === type);

    if (includeDeliveries) {
        return attachmentsOfType.length;
    }

    return attachmentsOfType.filter(({ deliveredAsset }) => !deliveredAsset).length;
};

const getGigTranslationLayer = (gig) => {
    const { locale } = getContext();

    if (locale === DEFAULT_FIVERR_LOCALE || !gig.localizedTitle || !gig.showTranslatedUGC) {
        return null;
    }

    const translatedAssets = gig.localizedAssets.filter(({ deliveredAsset }) => !deliveredAsset);

    return {
        title: gig.localizedTitle,
        images_count: translatedAssets.filter(({ type }) => type === IMAGE_TYPE).length,
        videos_count: translatedAssets.filter(({ type }) => type === VIDEO_TYPE).length,
    };
};

export { getGigCardEnrichment };
