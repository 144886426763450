import React from 'react';
import PropTypes from 'prop-types';
import { VettedProBadge } from '@fiverr-private/badges';
import { trackGigForClickType, createClickedElement } from '../../utils';
import { OPEN_GIG_SAME_TAB_TARGET, ELEMENTS_NAME, ELEMENTS_TYPE } from '../constants';
import ContextualLink from '../ContextualLink';
import { getTitle } from './utils';
import './style.scss';

const Title = (
    { hidePrefix, url, title, showTranslatedUGC, localizedTitle, translatedTitle, urlTarget, isPro },
    { trackEvent }
) => {
    const gigTitleKey = 'gig_listings.gig_cards.i_will';

    const textTitle = getTitle({
        hidePrefix,
        showTranslatedUGC,
        localizedTitle,
        translatedTitle,
        title,
        gigTitleKey,
    });

    return (
        <h3>
            <ContextualLink
                href={url}
                target={urlTarget}
                title={textTitle}
                onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(textTitle))}
            >
                {isPro && (
                    <span className="pro-icon">
                        <VettedProBadge variant="compact" />
                    </span>
                )}
                {textTitle}
            </ContextualLink>
        </h3>
    );
};

Title.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    localizedTitle: PropTypes.string,
    translatedTitle: PropTypes.string,
    showTranslatedUGC: PropTypes.bool,
    urlTarget: PropTypes.string,
    isPro: PropTypes.bool,
    hidePrefix: PropTypes.bool,
};

Title.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
};

Title.defaultProps = {
    urlTarget: OPEN_GIG_SAME_TAB_TARGET,
    isPro: false,
};

export const clickedElement = (textTitle) =>
    createClickedElement(ELEMENTS_NAME.GIG_TITLE, ELEMENTS_TYPE.LINK, textTitle);

export default Title;
