export const ROUTE_KEYS = {
    DEFAULT: 'unknown',
    SEARCH: 'search',
    SHORT_SEARCH: 'short_search',
    CATEGORIES: 'categories',
    FIVERR_BUSINESS_SEARCH: 'fiverr_business_search',
    FIVERR_BUSINESS_CATEGORIES: 'fiverr_business_categories',
    EXPERTS_BUSINESS_SEARCH: 'experts_business_search',
    EXPERTS_BUSINESS_CATEGORIES: 'experts_business_categories',
    SUB_CATEGORY_ID: 'sub_category_id',
    SUB_CATEGORIES_WHITELIST: 'sub_categories_whitelist',
    EXPERT_LISTINGS_PAGINATION: 'expert_listings_pagination',
    FETCH_MULTIPLE_NOTABLE_CLIENTS: 'fetch_multiple_notable_clients',
    FETCH_SELLERS_DATA: 'fetch_sellers_data',
    FETCH_FLP_BY_CATEGORY_ID: 'fetch_flp_by_category_id',
    FETCH_RELATED_GUIDES_BY_ID: 'fetch_related_guides_by_id',
    FETCH_SUB_CATEGORY_SKILLS: 'fetch_sub_category_skills',
    SUB_CATEGORY_BY_SLUG: 'sub_category_by_slug',
    USER_SESSION_BY_USER_ID: 'user_session_by_user_id',
    FETCH_CMS_DATA_BY_ID: 'fetch_cms_data_by_id',
    SELLERS_ID_APPLY_PROMOTION: 'sellers_id_apply_promotion',
    FETCH_EXPRESS_RECOMMENDATION: 'fetch_express_recommendations',
    FETCH_TRANSLATED_UGC: 'fetch_translated_ugc',
    FETCH_RECENTLY_VIEWED: 'fetch_recently_viewed',
    CLEAR_RECENTLY_VIEWED_GIGS: 'clear_recently_viewed_gigs',
    FETCH_SEO_DATA_BY_VERTICAL_ID: 'fetch_seo_data_by_vertical_id',
    FETCH_VERTICALS_CMS_DATA_BY_ID: 'fetch_verticals_cms_data_by_id',
    CHECKOUT_GET_CUSTOMIZE_FEES: 'checkout_get_customize_fees',
    FETCH_USER_REVIEWS_SERVER: 'fetch_user_reviews_server',
    FETCH_USER_REVIEWS_CLIENT: 'fetch_user_reviews_client',
    FETCH_GIG_REVIEW: 'fetch_gig_review',
    POST_NEW_BRIEF_SERVER: 'post_new_brief_server',
    POST_NEW_BRIEF_CLIENT: 'post_new_brief_client',
    GET_MATCHING_GIG_SERVER: 'get_matching_gig_server',
    GET_MATCHING_GIG_CLIENT: 'get_matching_gig_client',
    BUILD_TIMELINE_BUCKETS_SERVER: 'build_timeline_buckets_server',
    BUILD_PRICE_BUCKETS_SERVER: 'build_price_buckets_server',
    BUILD_TIMELINE_BUCKETS_CLIENT: 'build_timeline_buckets_client',
    BUILD_PRICE_BUCKETS_CLIENT: 'build_price_buckets_client',
    FETCH_GIGS_BY_GO_SEARCHER: 'fetch_gigs_by_go_searcher',
    FETCH_EXPERTS_LISTINGS: 'fetch_experts_listings',
    FETCH_EXPRESS_DELIVERY_ELIGIBILITY: 'fetch_express_delivery_eligibility',
    FETCH_EXPRESS_DELIVERY_RECOMMENDATIONS: 'fetch_express_delivery_recommendations',
    FETCH_RELATED_SEARCH_TERMS_GO_SEARCHER: 'fetch_related_search_terms_go_searcher',
    PERSONALIZED_CONTENT_BY_FUNNEL: 'personalized_content_by_funnel',
    FILTERS: 'filters',
    SIDE_FILTERS: 'side_filters',
    MANAGED_ACCOUNT: 'managed_account',
    FETCH_DATA_MODEL_BY_SLUG: 'fetch_data_model_by_slug',
    FETCH_PRO_SEARCH_QUERIES: 'fetch_pro_search_queries',
    FETCH_HOURLY_RATE_SUB_CATEGORIES: 'fetch_hourly_rate_sub_categories',
    CATALOGS_ELIGIBILITY: 'catalogs_eligibility',
    ROLES_SEARCH: 'roles_search',
    FETCH_ROLES_BY_LISTINGS_PEGASUS: 'fetch_roles_by_listings',
    PROFESSION_FILTER_IMAGES: 'profession_filter_images',
    PROFESSION_TRANSLATIONS: 'profession_translations',
    GET_NEO_CHAT: 'get_neo_chat',
    FETCH_SEARCH_RESULT_API: 'fetch_search_result_api',
};
