import constants from './constants';

const types = constants.TYPES;
const deliveryAssetSort = (a, b) => constants.DELIVERY_ORDER[a.type] - constants.DELIVERY_ORDER[b.type];

export const transformAssets = ({ assets = [], attachments = [], filtered_delivery_attachments = [] }) => {
    const gigAssets = formatAssets(assets, attachments, _filterGigAssets, _formatGigAsset);
    const deliveryAssets = formatDeliveryAttachments(filtered_delivery_attachments, _formatAttachment);
    return gigAssets.concat(deliveryAssets);
};

export const transformLocalizedAssets = ({ localizedAssets = [], filtered_delivery_attachments = [] }) => {
    const gigAssets = formatAssets(localizedAssets, [], () => true, _formatLocalizedGigAsset);
    if (gigAssets.length) {
        const deliveryAssets = formatDeliveryAttachments(filtered_delivery_attachments, _formatAttachment);
        return gigAssets.concat(deliveryAssets);
    }
    return [];
};

const formatAssets = (assets, attachments, filterFunc, formatter) => {
    const formatted = assets.filter(filterFunc).reduce((formattedAssets, asset) => {
        const formattedAsset = formatter(asset, attachments);
        !!formattedAsset && formattedAssets.push(formattedAsset);

        return formattedAssets;
    }, []);

    return addAudioToImages(formatted);
};

const formatDeliveryAttachments = (attachments, formatter) =>
    attachments
        .map(formatter)
        .filter((attachment) => attachment)
        .sort(deliveryAssetSort);

const _filterGigAssets = ({ type, status }) => {
    if (type === constants.TYPE_KEYS.DeliveryAsset) {
        return false;
    }
    const assetType = types[type];

    return assetType ? statusValidators[assetType](status) : false;
};

const _formatGigAsset = (asset, attachments) => {
    const { type, attachment_id, id } = asset;
    const attachment = attachment_id && attachments.find(({ id }) => id.toString() === attachment_id.toString());
    const assetType = types[type];

    if (assetType === types.ImageAsset) {
        return gigImageFormatter(asset);
    }

    if (!assetType || !attachment) {
        return;
    }

    const filerrData = filerrFormatters[assetType](attachment);
    if (!filerrData) {
        return;
    }

    return {
        ...filerrData,
        type: assetType,
        id,
    };
};

const _formatLocalizedGigAsset = (asset) => {
    const { id, type, previewUrl, mediaUrl } = asset;

    if (previewUrl) {
        return {
            id,
            type: type.toLowerCase(),
            cloudBaseUrl: previewUrl.replace(constants.DEFAULT_IMAGE_TYPE, constants.PLACEHOLDER),
            streamUrl: mediaUrl,
        };
    }
};

const _formatAttachment = (attachment = {}) => {
    const formatter = filerrFormatters[attachment.type];
    return formatter && formatter(attachment, true);
};

const statusValidators = {
    [constants.TYPES.ImageAsset]: () => true,
    [constants.TYPES.VideoAsset]: (status) => status === constants.VIDEO_APPROVED_STATUS,
    [constants.TYPES.AudioAsset]: (status) => status === constants.AUDIO_APPROVED_STATUS,
};

const gigImageFormatter = ({ cloud_img_main_gig, id }) => {
    if (cloud_img_main_gig) {
        return {
            id,
            type: types.ImageAsset,
            cloudBaseUrl: cloud_img_main_gig.replace(constants.DEFAULT_IMAGE_TYPE, constants.PLACEHOLDER),
        };
    }
};

const filerrFormatters = {
    [types.ImageAsset]: ({ image_url, type }, deliveredAsset = false) =>
        image_url && {
            type,
            deliveredAsset,
            cloudBaseUrl: _conditionalEncodeURI(image_url),
        },
    [types.AudioAsset]: ({ stream_url, type }, deliveredAsset = false) =>
        stream_url && {
            type,
            deliveredAsset,
            streamUrl: stream_url,
            cloudBaseUrl: constants.URLS.audioDefaultThumbnail,
        },
    [types.VideoAsset]: ({ stream_url, image_url, type }, deliveredAsset = false) =>
        stream_url &&
        image_url && {
            type,
            deliveredAsset,
            streamUrl: stream_url,
            cloudBaseUrl: _conditionalEncodeURI(image_url),
        },
};

const addAudioToImages = (assets) => {
    const audiosAndImages = [];
    const assetsByType = _groupByType(assets);

    const videos = assetsByType[types.VideoAsset],
        audios = assetsByType[types.AudioAsset],
        images = assetsByType[types.ImageAsset];

    for (let i = 0; i < Math.max(audios.length, images.length); i++) {
        const audio = audios[i],
            image = images[i];

        if (audio) {
            audiosAndImages.push(image ? { ...audio, cloudBaseUrl: image.cloudBaseUrl } : audio);
        } else {
            audiosAndImages.push(image);
        }
    }

    return videos.concat(audiosAndImages);
};

const _conditionalEncodeURI = (url) => (decodeURI(url) === url ? encodeURI(url) : url);

const _groupByType = (assets) =>
    assets.reduce(
        (acc, asset) => {
            acc[asset.type].push(asset);
            return acc;
        },
        {
            [types.ImageAsset]: [],
            [types.VideoAsset]: [],
            [types.AudioAsset]: [],
        }
    );
