import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { localStorage } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Link, Text, Typography } from '@fiverr-private/typography';
import { Button, IconButton } from '@fiverr-private/button';
import { DynamicBriefIllistratedL2Icon, TimesIcon } from '@fiverr-private/visuals';
import pathfinder from '@fiverr-private/pathfinder';
import { useAppContext } from '../../../context/listingsContext';
import { LISTINGS_PAGE } from '../../../../constants';
import { PJM_BANNER_CUSTOM_TITLE_CATEGORIES_KEYS } from './ProjectManagementBanner.utils';
import { reportPjmBannerClickEvent, reportPjmBannerImpressionEvent } from './ProjectManagementBanner.events';

const getLsKey = () => `ProjectManagementBanner:closed:${getContext().userGuid}`;
const bannerWasClosed = () => !!localStorage.get(getLsKey());
const setBannerWasClosed = () => localStorage.set(getLsKey(), 'true');
export const resetLsBannerWasClosed = () => localStorage.remove(getLsKey());

const T_PREFIX = 'search_perseus.business_listings.banners.pjm_banner';

export const ProjectManagementBanner = () => {
    const { categoryIds } = useAppContext();
    const categoryId = get(categoryIds, 'categoryId');

    const [isClosed, setClosed] = useState(bannerWasClosed());

    useEffect(() => {
        if (!isClosed) {
            reportPjmBannerImpressionEvent();
        }
    }, [isClosed]);

    if (isClosed) {
        return null;
    }

    const onCloseClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setClosed(true);
        setBannerWasClosed();

        reportPjmBannerClickEvent('dismiss');
    };

    const customTitleKey = PJM_BANNER_CUSTOM_TITLE_CATEGORIES_KEYS[categoryId] || 'title';
    const projectManagementPageUrl = pathfinder(
        'content_pages_page',
        { page_slug: 'project-management' },
        { query: { source: LISTINGS_PAGE } }
    );

    return (
        <Link target="_blank" href={projectManagementPageUrl} decoration="none">
            <Stack
                paddingLeft="6"
                paddingRight={{ default: '4', md: '5' }}
                paddingY={{ default: '5', md: '4' }}
                gap="3"
                borderColor="grey_400"
                borderWidth="sm"
                borderRadius="xl"
                justifyContent="spaceBetween"
            >
                <Stack
                    direction={{ default: 'column', md: 'row' }}
                    alignItems={{ default: 'flexStart', md: 'center' }}
                    gap={{ default: '4', md: '3' }}
                    justifyContent="spaceBetween"
                    width="100%"
                >
                    <Stack
                        alignItems={{ default: 'flexStart', sm: 'center' }}
                        gap="3"
                        direction={{ default: 'column', sm: 'row' }}
                    >
                        <Container flexShrink="0">
                            <DynamicBriefIllistratedL2Icon size="lg" />
                        </Container>
                        <Stack direction="column" gap="1">
                            <Typography decoration="none" color="grey_1200" fontSize="b_lg" fontWeight="bold">
                                <I18n k={`${T_PREFIX}.${customTitleKey}`} />
                            </Typography>
                            <Text decoration="none">
                                <I18n k={`${T_PREFIX}.subtitle`} />
                            </Text>
                        </Stack>
                    </Stack>
                    <Container flexShrink="0" paddingLeft={{ default: '0', sm: '20', md: '0' }}>
                        <Button size="sm" onClick={() => reportPjmBannerClickEvent('find_out_more')}>
                            <I18n k={`${T_PREFIX}.cta`} />
                        </Button>
                    </Container>
                </Stack>
                <Stack alignItems={{ default: 'flexStart', md: 'center' }}>
                    <IconButton aria-label="Close" size="sm" intent="secondary" variant="ghost" onClick={onCloseClick}>
                        <TimesIcon color="grey_1200" size="md" />
                    </IconButton>
                </Stack>
            </Stack>
        </Link>
    );
};
