import { URI } from '@fiverr-private/futile';
import pathfinder from '@fiverr-private/pathfinder';
import { removeEmptyEntries } from '../../utils';
import { TALENT, GIG_FEATURE_BADGE_TYPES } from '../../utils/gigBadges/constants';

const shortUserRoute = 'short_user',
    shortGigRoute = 'short_v4_user_gig',
    agencyRoute = 'agency_page',
    gigCardSource = 'gig_cards',
    GIG_URL_BOOLEANS = ['extra_fast', 'seller_online'];

export const transformGigUrl = (gig, index, context, badge) => {
    const gigUrl = pathfinder(shortGigRoute, {
        username: gig.seller_name,
        slug: gig.cached_slug,
    });

    const contextParams = getContextParams(context);
    const badgeParams = getBadgeParams(gig, badge);
    const gigParams = getSpecificGigParams(gig, index);

    const merged = removeEmptyEntries({ ...contextParams, ...gigParams, ...badgeParams });

    return createUrl(gigUrl, merged);
};

export const transformSellerUrl = ({ seller_name, cached_slug, sellerQueryParams = {} }, { pageCtxId }) => {
    const sellerUrl = pathfinder(shortUserRoute, {
        id: seller_name,
    });

    return createUrl(sellerUrl, {
        source: gigCardSource,
        referrer_gig_slug: cached_slug,
        ref_ctx_id: pageCtxId,
        ...sellerQueryParams,
    });
};

export const transformAgencyUrl = (gig, context) => {
    const { cached_slug: cachedSlug, agency: { slug } = {} } = gig;
    const contextParams = getContextParams({ ...context, sourceComponent: gigCardSource });

    const agencyUrl = pathfinder(agencyRoute, {
        agency_slug: slug,
    });

    const merged = removeEmptyEntries({
        ...contextParams,
        ...(cachedSlug && { referrer_gig_slug: cachedSlug }),
    });

    return createUrl(agencyUrl, merged);
};

const parseGigAttributesForUrl = (gigAttributes = {}) => {
    const { attributeType, attributeKey, attributeValues = [] } = gigAttributes;
    const maxAttributeValues = 10;

    return {
        di_type: attributeType,
        di_key: attributeKey,
        di_values: attributeValues
            .slice(0, maxAttributeValues)
            .map(({ id }) => id)
            .join(),
    };
};

const getSpecificGigParams = (
    {
        tier_selection,
        extras = [],
        package_i,
        auction,
        gigAttributes,
        gigQueryParams,
        numericPricingFactor: { scope: pricingFactorScope } = {},
        ...gig
    },
    index
) => {
    let specificGigParams = {
        pckg_id: package_i,
        pos: index + 1,
        tier_selection,
        extras: extras.join(),
        ...(gigAttributes && parseGigAttributesForUrl(gigAttributes)),
        ...(auction && { ad_key: auction.id }),
        ...(pricingFactorScope && { calc: pricingFactorScope }),
    };

    if (gigQueryParams) {
        specificGigParams = { ...specificGigParams, ...gigQueryParams };
    }

    GIG_URL_BOOLEANS.forEach((key) => {
        if (gig[key] === true) {
            specificGigParams[key] = 'true';
        }
    });

    return removeEmptyEntries(specificGigParams);
};

const getContextParams = ({ pageCtxId, sourcePage, sourceComponent, queryParameters }) => ({
    context_referrer: sourcePage,
    source: sourceComponent,
    ref_ctx_id: pageCtxId,
    ...queryParameters,
});

const getBadgeParams = ({ gigQueryParams }, badge) => {
    if (badge) {
        if (badge.type === GIG_FEATURE_BADGE_TYPES.FIVERR_CHOICE) {
            return { fiverr_choice: 'true' };
        } else if (badge.type === GIG_FEATURE_BADGE_TYPES.BUY_IT_AGAIN) {
            return { pckg_id: gigQueryParams.recommended_package_id, tier_selection: 'recommended' };
        } else if (badge.type === TALENT) {
            return { rising_talent: 'true' };
        }
    }
    return {};
};

const createUrl = (uri, params) => new URI(uri).addParams(params).href;
