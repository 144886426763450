import React, { useRef, useEffect, useState } from 'react';
import { object, string, node, func, number } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { ArrowsHorizontalIcon, ArrowRightIcon } from '@fiverr-private/icons';
import { grey_900 } from '@fiverr-private/sass/helpers';
import MoreAttributesIndicator from '../MoreAttributesIndicator';
import { LANGUAGES_PAIR } from '../constants';
import ContextualLink from '../../../../../Components/ContextualLink';
import { createClickedElement, trackGigForClickType } from '../../../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../../../Components/constants';
import { useCardContext } from '../../../../../../GigCardListings/context/CardContext';

import styles from '../../../../../styles/utils.module.scss';
import attributeStyles from './index.module.scss';

const PairAttribute = ({ attributeValue, url, urlTarget, onMouseDown }) => (
    <ContextualLink
        onMouseDown={onMouseDown}
        href={url}
        target={urlTarget}
        className={classNames(attributeStyles.gigAttribute, 'm-r-8', 'fs-14', 'co-text-darker')}
    >
        <span
            title={attributeValue.translatedFromLanguage}
            className={classNames(attributeStyles.attributeText, styles.noShrink, 'm-r-8')}
        >
            {attributeValue.translatedFromLanguage}
        </span>
        <Icon className={styles.noShrink} color={grey_900}>
            {attributeValue.hasOppositePair ? ArrowsHorizontalIcon : ArrowRightIcon}
        </Icon>
        <span
            title={attributeValue.translatedToLanguage}
            className={classNames(attributeStyles.attributeText, 'm-l-8')}
        >
            {attributeValue.translatedToLanguage}
        </span>
    </ContextualLink>
);

PairAttribute.propTypes = {
    attributeValue: object,
    url: string,
    urlTarget: string,
    onMouseDown: func,
};

const SingleAttribute = ({ attributeValue, url: urlLink, urlTarget, onMouseDown }) => {
    const { url } = getContext();
    const handleImageError = () => {
        logger.warn('Missing gig attribute icon', { icon: attributeValue.icon, name: attributeValue.alias, url });
    };

    return (
        <ContextualLink
            onMouseDown={onMouseDown}
            href={urlLink}
            target={urlTarget}
            className={classNames(attributeStyles.gigAttribute, 'm-r-8', 'fs-14', 'co-text-darker')}
        >
            {attributeValue.icon && (
                <img
                    onError={handleImageError}
                    className={classNames(styles.noShrink, 'm-r-8')}
                    src={attributeValue.icon}
                    alt={attributeValue.alias}
                />
            )}
            <span title={attributeValue.alias} className={attributeStyles.attributeText}>
                {attributeValue.alias}
            </span>
        </ContextualLink>
    );
};

SingleAttribute.propTypes = {
    attributeValue: object,
    url: string,
    urlTarget: string,
    onMouseDown: func,
};

const Attribute = ({
    attributeValue,
    metadataId,
    containerRef,
    url,
    urlTarget,
    notifyHiddenAttribute,
    containerWidth,
    hiddenAttributes,
}) => {
    const attributeRef = useRef(null);
    const [isHidden, setIsHidden] = useState(false);
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const isPairAttribute = metadataId === LANGUAGES_PAIR;
    const AttributeComponent = isPairAttribute ? PairAttribute : SingleAttribute;
    const clickedElement = createClickedElement(
        ELEMENTS_NAME.DECISION_INDICATOR,
        ELEMENTS_TYPE.LINK,
        attributeValue.id
    );

    useEffect(() => {
        if (attributeRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const attributeRect = attributeRef.current.getBoundingClientRect();

            if (containerRect.bottom < attributeRect.bottom) {
                setIsHidden(true);
                notifyHiddenAttribute(attributeValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerWidth]);

    return (
        <div
            className={classNames(attributeStyles.gigAttributeWrapper, { [styles.hidden]: isHidden })}
            ref={attributeRef}
            title={attributeValue.alias}
        >
            <AttributeComponent
                onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement)}
                url={url}
                urlTarget={urlTarget}
                attributeValue={attributeValue}
            />
            {hiddenAttributes.length > 0 && (
                <MoreAttributesIndicator isPairAttribute={isPairAttribute} hiddenAttributes={hiddenAttributes} />
            )}
        </div>
    );
};

Attribute.propTypes = {
    attributeValue: object,
    metadataId: string,
    url: string,
    containerWidth: number,
    urlTarget: string,
    containerRef: node,
    notifyHiddenAttribute: func,
    hiddenAttributes: object,
};

export default Attribute;
