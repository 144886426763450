const CARD_VARIANT = {
    DEFAULT: 'default',
    VISUAL: 'visual',
    SERVICE: 'service',
};

const HIGHLIGHTS_ID_LIST = {
    WORKED_WITH_YOUR_TEAM: 'workedWithYourTeam',
    WORKED_WITH_CLIENTS_IN_INDUSTRY: 'workedWithClientsInIndustry',
    BUYER_KEEP_COMING_BACK: 'buyersKeepComingBack',
    HIGHLY_RESPONSIVE: 'highlyResponsive',
    SAVED_BY_TEAM_MEMBER: 'savedByTeamMember',
    SELLER_SPEAKS: 'sellerSpeaks',
    INACTIVE: 'inactive',
    ON_VACATION: 'onVacation',
};

const SELLER_LEVEL = {
    NO_LEVEL: 'NO_LEVEL',
    LEVEL_ONE: 'LEVEL_ONE',
    LEVEL_TWO: 'LEVEL_TWO',
    TOP_RATED_SELLER: 'TOP_RATED_SELLER',
};

const ENGINE_TYPE = {
    DEFAULT: 'default',
    PRO: 'pro',
    FIVERR_CHOICE: 'fiverr_choice',
};

const ASSET_TYPES = {
    VIDEO: 'VideoAsset',
    AUDIO: 'AudioAsset',
    IMAGE: 'ImageAsset',
    DELIVERY: 'DeliveryAsset',
};

const DELIVERY_ATTACHMENT_TYPES = {
    [ASSET_TYPES.IMAGE]: 'image',
    [ASSET_TYPES.VIDEO]: 'video',
    [ASSET_TYPES.AUDIO]: 'audio',
    [ASSET_TYPES.DELIVERY]: 'delivery',
};

const ASSET_APPROVED_STATUS_BY_TYPE = {
    VIDEO: 0,
    AUDIO: 1,
};

const HIGHLIGHTS_DATA_KEYS = {
    TEAM_MEMBERS: 'teamMembers',
    COMMON_INDUSTRY: 'commonIndustry',
    SAVED_BY: 'savedBy',
    SELLER_LANGUAGE_CODE: 'sellerLanguageCode',
    VACATION_END_DATE: 'vacation',
};

const CTAS = /** @type {const} */ ({
    SEE_PROFILE: 'seeProfile',
    CONTACT: 'contact',
});

const HIGHLIGHTS_PRIORITIZED_LIST = [
    [HIGHLIGHTS_ID_LIST.INACTIVE],
    [HIGHLIGHTS_ID_LIST.ON_VACATION],
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_YOUR_TEAM],
    [HIGHLIGHTS_ID_LIST.SAVED_BY_TEAM_MEMBER],
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_CLIENTS_IN_INDUSTRY],
    [HIGHLIGHTS_ID_LIST.BUYER_KEEP_COMING_BACK],
    [HIGHLIGHTS_ID_LIST.SELLER_SPEAKS],
    [HIGHLIGHTS_ID_LIST.HIGHLY_RESPONSIVE],
];

const DEFAULT_HIGHLIGHTS_CONFIGURATION = {
    [HIGHLIGHTS_ID_LIST.INACTIVE]: false,
    [HIGHLIGHTS_ID_LIST.ON_VACATION]: false,
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_YOUR_TEAM]: true,
    [HIGHLIGHTS_ID_LIST.SAVED_BY_TEAM_MEMBER]: false,
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_CLIENTS_IN_INDUSTRY]: true,
    [HIGHLIGHTS_ID_LIST.BUYER_KEEP_COMING_BACK]: true,
    [HIGHLIGHTS_ID_LIST.SELLER_SPEAKS]: true,
    [HIGHLIGHTS_ID_LIST.HIGHLY_RESPONSIVE]: true,
};

const LANGUAGE_LEVEL_TRANSALTION_PREFIX = 'languages.proficiencies';

const LANGUAGE_LEVEL = {
    UNSPECIFIED: {
        ID: 'unspecified',
        TRANSLATION_KEY: `${LANGUAGE_LEVEL_TRANSALTION_PREFIX}.level_0`,
    },
    BASIC: {
        ID: 'basic',
        TRANSLATION_KEY: `${LANGUAGE_LEVEL_TRANSALTION_PREFIX}.level_1`,
    },
    CONVERSATIONAL: {
        ID: 'conversational',
        TRANSLATION_KEY: `${LANGUAGE_LEVEL_TRANSALTION_PREFIX}.level_2`,
    },
    FLUENT: {
        ID: 'fluent',
        TRANSLATION_KEY: `${LANGUAGE_LEVEL_TRANSALTION_PREFIX}.level_3`,
    },
    NATIVE_OR_BILINGUAL: {
        ID: 'native_or_bilingual',
        TRANSLATION_KEY: `${LANGUAGE_LEVEL_TRANSALTION_PREFIX}.level_4`,
    },
};

const HIGH_PROFICIENCY_LEVELS = [
    LANGUAGE_LEVEL.CONVERSATIONAL.ID,
    LANGUAGE_LEVEL.FLUENT.ID,
    LANGUAGE_LEVEL.NATIVE_OR_BILINGUAL.ID,
];

const ENGLISH_LOCALE_PREFIX = 'en-';

const EXPERTISE_TYPE = {
    SKILL: 'skill',
    LEAF_CATEGORY_ID: 'leaf_cat_id',
};

const ARROW_MODE = {
    ALWAYS: 'always',
    HOVER: 'hover',
};

const AUDIO_DEFAULT_THUMBNAIL =
    'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto/search_perseus/default-audio-bg.png';

module.exports = {
    CARD_VARIANT,
    HIGHLIGHTS_ID_LIST,
    HIGHLIGHTS_DATA_KEYS,
    DEFAULT_HIGHLIGHTS_CONFIGURATION,
    HIGHLIGHTS_PRIORITIZED_LIST,
    ASSET_TYPES,
    ASSET_APPROVED_STATUS_BY_TYPE,
    DELIVERY_ATTACHMENT_TYPES,
    ENGLISH_LOCALE_PREFIX,
    SELLER_LEVEL,
    ENGINE_TYPE,
    LANGUAGE_LEVEL,
    HIGH_PROFICIENCY_LEVELS,
    EXPERTISE_TYPE,
    ARROW_MODE,
    AUDIO_DEFAULT_THUMBNAIL,
    CTAS,
};
