import React, { useCallback, useContext, useEffect } from 'react';
import { merge } from 'lodash';
import { arrayOf, bool, object, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import GigCardListings from '@fiverr-private/gig_listings/src/entries/GigCardListings';
import { ListingCarousel } from '@fiverr-private/listing_carousels';
import { tracker } from '@fiverr-private/listing_lib';
import ErrorBoundary from '../../errors/error_boundary/ErrorBoundary';
import { ListingsImpressionContext } from '../../../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { reportHighlightRecommendationRendered } from '../../util/metric';
import { useAppContext } from '../../../context/listingsContext';
import { useInResponsiveCarouselsExperiment } from '../../../../hooks/useInResponsiveCarouselsExperiment/useInResponsiveCarouselsExperiment';
import { HighlightedRecommendationsHeaderContent } from './HighlightedRecommendationsHeaderContent';

import './styles.scss';

const LOADING_SIZE = 4;
const RECOMMENDATIONS_CONTEXT = 'recommendations';

const getBIContext = (componentName, shouldSendRecommendations) => ({
    context: {
        sourceComponent: componentName,
        ...(shouldSendRecommendations && {
            queryParameters: {
                context: RECOMMENDATIONS_CONTEXT,
            },
        }),
    },
});

/**
 * Generic component to build highlighted recommendations display
 */
const HighlightedRecommendations = ({
    className,
    title,
    description,
    listings,
    gigs = [],
    isLoading = false,
    componentName,
    iconPath,
    displayISpeak = false,
    shouldSendRecommendations = true,
}) => {
    const { onImpression } = useContext(ListingsImpressionContext);
    const { rollouts } = useAppContext();
    const recommendationListings = merge({}, listings, getBIContext(componentName, shouldSendRecommendations));
    const shouldShowNewCarousel = useInResponsiveCarouselsExperiment();

    const [containerRef, containerId] = useImpressionContainer();

    const sendMixPanelEvent = () => {
        tracker.viewHighlightRecommendation();
    };

    const handleImpression = (impression) => {
        onImpression(impression);
        sendMixPanelEvent();
    };

    useEffect(() => reportHighlightRecommendationRendered(componentName), [componentName]);

    useImpressionItem({
        selfId: containerId,
        selfRef: containerRef,
        sendImpressionToBQ: false,
        enrichment: {
            ...(componentName && { name: componentName }),
            modalities_carousel: {},
        },
        onImpression: handleImpression,
    });

    const HeaderComponent = useCallback(
        () => <HighlightedRecommendationsHeaderContent title={title} description={description} iconPath={iconPath} />,
        [description, iconPath, title]
    );

    if (shouldShowNewCarousel) {
        return (
            <ListingCarousel
                listings={recommendationListings}
                gigs={gigs}
                isLoading={isLoading}
                backgroundColor="yellow_100"
                HeaderContent={HeaderComponent}
                displayISpeak={displayISpeak}
                onImpression={onImpression}
                trackView={() => tracker.viewHighlightRecommendation}
                sourceComponent={componentName}
                rollouts={rollouts}
            />
        );
    }

    return (
        <ErrorBoundary>
            <div className={classNames('highlighted-recommendations', className)} ref={containerRef}>
                <header className="header-wrapper p-b-24">
                    <img src={iconPath} />
                    <div className="header">
                        <h4 className="title">{title}</h4>
                        {description && <p className="description p-t-4">{description}</p>}
                    </div>
                </header>
                <GigCardListings
                    {...recommendationListings}
                    gigs={gigs}
                    isLoading={isLoading}
                    displayISpeak={displayISpeak}
                    size={isLoading ? LOADING_SIZE : gigs.length}
                    onImpression={onImpression}
                    isExperiential={true}
                    rollouts={rollouts}
                    gridContainerSize="md"
                />
            </div>
        </ErrorBoundary>
    );
};

HighlightedRecommendations.propTypes = {
    className: string,
    title: oneOfType([string, object]),
    description: oneOfType([string, object, null]),
    listings: object,
    gigs: arrayOf(object),
    isLoading: bool,
    componentName: string,
    iconPath: string,
};

export default HighlightedRecommendations;
