import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SlideImage from '../SlideImage';
import { MEDIA_ASSETS_CONFIG, VIDEO_TYPE } from '../../../utils/assetsHelper/constants';
import {
    bus,
    calculateVideoProgress,
    createClickedElement,
    gigClick,
    gigMiddleClick,
    gigRightClick,
    PAUSE_INTERNAL_PLAYERS,
    PAUSE_PLAYERS,
} from '../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../constants';
import { AssetType } from '../../../types';
import { EVENT_ACTION_TYPES, EVENT_TYPES } from '../../../utils/trackGig';
import CardContext from '../../../../GigCardListings/context/CardContext';
import InlineVideoProgress from './InlineVideoProgress';
import { ELEMENT_METADATA } from './constants';
import { trackVideoPause, trackVideoPlay } from './utils';
import VolumeControl from './components/VolumeControl';

import './index.scss';

class Player extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            showImagePreview: true,
            addAssetSource: false,
            progress: '100 100',
            remainingPlaytime: null,
        };

        this.player = createRef();

        this.togglePlaying = this.togglePlaying.bind(this);
        this.toggleHoverPlaying = this.toggleHoverPlaying.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.pauseInternal = this.pauseInternal.bind(this);
        this.playerEnded = this.playerEnded.bind(this);
        this.updateVideoProgress = this.updateVideoProgress.bind(this);
        this.trackGigForClickType = this.trackGigForClickType.bind(this);
        this.handleVolumeControlClick = this.handleVolumeControlClick.bind(this);
        this.getPlayButtonProperties = this.getPlayButtonProperties.bind(this);

        this.isVideo = props.asset.type === VIDEO_TYPE;
        this.assetConfig = this.isVideo ? MEDIA_ASSETS_CONFIG.video : MEDIA_ASSETS_CONFIG.audio;
    }

    componentDidMount() {
        bus.on(PAUSE_INTERNAL_PLAYERS, this.pauseInternal);
        this.player.current?.addEventListener('ended', this.playerEnded, false);
        this.player.current?.addEventListener('timeupdate', this.updateVideoProgress, false);
    }

    componentDidUpdate(prevProps) {
        const { playOnHover, isHoveringSlider, active, autoPlay } = this.props;

        if (playOnHover) {
            if (prevProps.isHoveringSlider !== isHoveringSlider || (active && active !== prevProps.active)) {
                this.toggleHoverPlaying(isHoveringSlider);
            }
        }

        if (autoPlay && !prevProps.autoPlay && this.player.current?.paused) {
            this.play(EVENT_ACTION_TYPES.MOUSE_HOVER);
        } else if (!autoPlay && prevProps.autoPlay && !this.player.current?.paused) {
            this.pause(EVENT_ACTION_TYPES.MOUSE_BLUR);
        }
    }

    componentWillUnmount() {
        bus.off(PAUSE_INTERNAL_PLAYERS, this.pauseInternal);
        this.player.current?.removeEventListener('ended', this.playerEnded, false);
        this.player.current?.removeEventListener('timeupdate', this.updateVideoProgress, false);
    }

    static contextType = CardContext;

    getPlayButtonProperties() {
        const { isPlaying } = this.state;
        const metadata = isPlaying ? ELEMENT_METADATA.PAUSE_VIDEO_ICON : ELEMENT_METADATA.PLAY_VIDEO_ICON;
        return createClickedElement(ELEMENTS_NAME.VIDEO_ICON, ELEMENTS_TYPE.BUTTON, null, metadata);
    }

    handleVolumeControlClick() {
        const metadata = this.player.current.muted ? ELEMENT_METADATA.UNMUTE_VIDEO : ELEMENT_METADATA.MUTE_VIDEO;
        gigClick(
            this.props.trackEvent,
            createClickedElement(ELEMENTS_NAME.MUTE_ICON, ELEMENTS_TYPE.BUTTON, null, metadata)
        );
    }

    playerEnded() {
        this.setState({ isPlaying: false });
        bus.off(PAUSE_PLAYERS, this.pause);

        if (this.context?.isExperiential) {
            trackVideoPause({
                track: this.props.trackEvent,
                actionType: EVENT_ACTION_TYPES.VIDEO_ENDED,
                playerRef: this.player,
            });
        }
    }

    play(actionType = EVENT_ACTION_TYPES.CLICK) {
        this.setState({
            showImagePreview: !this.isVideo,
            isPlaying: true,
            addAssetSource: true,
        });

        this.player.current?.play();
        bus.emit(PAUSE_PLAYERS);
        bus.on(PAUSE_PLAYERS, this.pause);

        if (this.context?.isExperiential) {
            trackVideoPlay({ track: this.props.trackEvent, actionType });
        }
    }

    pause(actionType = EVENT_ACTION_TYPES.CLICK) {
        this.setState({ isPlaying: false });
        this.player.current?.pause();
        bus.off(PAUSE_PLAYERS, this.pause);

        if (this.context?.isExperiential) {
            trackVideoPause({ track: this.props.trackEvent, actionType, playerRef: this.player });
        }
    }

    pauseInternal(gigId) {
        const { isPlaying } = this.state;

        if (isPlaying && this.context.gigId === gigId) {
            this.pause(EVENT_TYPES.GIG_CARD_CLICK);
        }
    }

    togglePlaying(e) {
        e.preventDefault();
        e.stopPropagation();

        gigClick(this.props.trackEvent, this.getPlayButtonProperties());

        if (this.player.current?.paused) {
            this.play();
        } else {
            this.pause();
        }
    }

    toggleHoverPlaying(isHoveringSlider) {
        if (isHoveringSlider && this.player.current?.paused) {
            this.play(EVENT_ACTION_TYPES.MOUSE_HOVER);
        } else if (!isHoveringSlider && !this.player.current?.paused) {
            this.pause(EVENT_ACTION_TYPES.MOUSE_BLUR);
        }
    }

    trackGigForClickType(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.button === 1) {
            gigMiddleClick(this.props.trackEvent, this.getPlayButtonProperties());
        } else if (e.button === 2) {
            gigRightClick(this.props.trackEvent, this.getPlayButtonProperties());
        }
    }

    updateVideoProgress() {
        const { currentTime, duration } = this.player.current || {};

        this.setState(calculateVideoProgress(currentTime, duration, !this.isVideo));
    }

    render() {
        const {
            asset,
            lazy,
            trackEvent,
            muted,
            showPlayerControls = false,
            showAudioRemainingTime = true,
            showNewImageRatio = false,
        } = this.props;
        const { showImagePreview, remainingPlaytime, progress, isPlaying, addAssetSource } = this.state;

        const AssetDomTag = this.assetConfig.dom;
        const showRemainingPlaytime = showAudioRemainingTime && this.state.remainingPlaytime && !this.isVideo;

        const mediaPlayerClasses = classNames(
                'player',
                { backdrop: !showImagePreview },
                { 'delivery-asset': asset.deliveredAsset },
                { 'box-image-ratio': showNewImageRatio }
            ),
            remainingTimeClasses = classNames('remaining-time', { visible: isPlaying }),
            videoButtonClass = classNames('video-button', {
                pause: isPlaying,
                play: !isPlaying,
            });

        return (
            <div className={mediaPlayerClasses}>
                {showImagePreview && (
                    <SlideImage
                        showNewImageRatio={showNewImageRatio}
                        asset={asset}
                        lazy={lazy}
                        trackEvent={trackEvent}
                    />
                )}
                {showRemainingPlaytime && <span className={remainingTimeClasses}> {remainingPlaytime} </span>}

                <span className="video-button-wrapper">
                    <span
                        onClick={this.togglePlaying}
                        onMouseDown={this.trackGigForClickType}
                        className={videoButtonClass}
                    />
                    <InlineVideoProgress progress={progress} />
                </span>

                {showPlayerControls && isPlaying && (
                    <>
                        <VolumeControl playerRef={this.player} muted={muted} onClick={this.handleVolumeControlClick} />
                    </>
                )}

                <AssetDomTag muted={muted} ref={this.player}>
                    {addAssetSource && (
                        <source src={asset.streamUrl} type={`${AssetDomTag}/${this.assetConfig.format}`} />
                    )}
                </AssetDomTag>
            </div>
        );
    }
}

Player.propTypes = {
    asset: AssetType.isRequired,
    lazy: PropTypes.bool,
    active: PropTypes.bool,
    trackEvent: PropTypes.func,
    playOnHover: PropTypes.bool,
    isHoveringSlider: PropTypes.bool,
    showPlayerControls: PropTypes.bool,
    showAudioRemainingTime: PropTypes.bool,
    showNewImageRatio: PropTypes.bool,
    muted: PropTypes.bool,
    autoPlay: PropTypes.bool,
};

Player.contextTypes = {
    gigId: PropTypes.number.isRequired,
};

export default Player;
