import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import RelatedGuides from '@fiverr-private/related_guides';
import { BRAND_STYLE_GUIDE_SC_SLUG } from '../../../utils/constants';
import { Faqs } from './components/Faqs';
import { RelatedLinks } from './components/RelatedLinks';
import './index.scss';

const SeoSubCategory = ({
    title,
    faqs,
    answersLink,
    relatedLinksTitle,
    relatedLinks,
    relatedSkillsTitle,
    relatedSkillsLinks,
    relatedGuides,
    seeMoreText,
    seeMoreLink,
    cachedSlug,
}) => {
    const relatedGuidesSourcePageKey = `subcategories_${cachedSlug}`;
    const relatedGuidesTitle =
        cachedSlug === BRAND_STYLE_GUIDE_SC_SLUG
            ? translate('search_perseus.seo_sub_category.related_guides.brand_style_guides_title')
            : translate('search_perseus.seo_sub_category.related_guides.title', {
                  params: { sub_category_title: title },
              });
    return (
        <React.Fragment>
            {!_.isEmpty(relatedLinks) && <RelatedLinks title={relatedLinksTitle} relatedLinks={relatedLinks} />}
            {!_.isEmpty(relatedSkillsLinks) && (
                <RelatedLinks title={relatedSkillsTitle} relatedLinks={relatedSkillsLinks} />
            )}
            {!_.isEmpty(faqs) && <Faqs title={title} faqs={faqs} answersLink={answersLink} />}
            {!_.isEmpty(relatedGuides) && (
                <RelatedGuides
                    guides={relatedGuides}
                    seeMoreText={seeMoreText}
                    seeMoreLink={seeMoreLink}
                    title={relatedGuidesTitle}
                    sourcePageKey={relatedGuidesSourcePageKey}
                />
            )}
        </React.Fragment>
    );
};

SeoSubCategory.propTypes = {
    relatedLinksTitle: PropTypes.string,
    relatedSkillsTitle: PropTypes.string,
    title: PropTypes.string,
    cachedSlug: PropTypes.string,
    seeMoreText: PropTypes.string,
    seeMoreLink: PropTypes.string,
    faqs: PropTypes.array,
    relatedLinks: PropTypes.array,
    relatedSkillsLinks: PropTypes.array,
    relatedGuides: PropTypes.array,
};

SeoSubCategory.defaultProps = {
    title: '',
    relatedLinksTitle: '',
    relatedSkillsTitle: '',
    cachedSlug: '',
    seeMoreText: '',
    seeMoreLink: '',
    faqs: [],
    relatedLinks: [],
    relatedSkillsLinks: [],
    relatedGuides: [],
};

export default SeoSubCategory;
