import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { InitialProps } from '@fiverr-private/listing_types';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { PROMOTED_GIGS } from '../../utils/constants';
import { NUM_OF_ADS_THREE_ROWS } from './constants';

const isEligibleForAllocation = () => {
    const {
        experience: { isBusiness },
        isTouch,
        initialProps,
    } = RequestContext;

    const { listings = {} } = initialProps as InitialProps;
    const promotedGigsCount = listings[0]?.gigs?.filter(({ type }) => type === PROMOTED_GIGS).length;

    return !isBusiness && !isTouch && promotedGigsCount > NUM_OF_ADS_THREE_ROWS;
};

const inRollout = async () => {
    const { userGuid } = RequestContext;

    return await isActive(ROLLOUTS.BULLS_ADS_PLACEMENTS, userGuid);
};

export const adsPlacement = {
    id: EXPERIMENTS.ADS_PLACEMENT,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
