import { get } from 'lodash';
import { RequestContext } from '@fiverr-private/perseus';
import { InitialProps } from '@fiverr-private/listing_types';

export const isEligibleForAllocation = (): boolean => {
    const {
        userId,
        initialProps,
        isMobile,
        experience: { isBusiness },
    } = RequestContext;
    const { userData } = initialProps as InitialProps;

    if (isBusiness) {
        return false;
    }

    const isRncUser = get(userData, 'isRncUser');

    const isGuestUser = !userId;
    const shouldShowProjectManagementBanner = (isRncUser || isGuestUser) && !isMobile;

    RequestContext.addInitialProps({
        shouldShowProjectManagementBanner,
    });

    return shouldShowProjectManagementBanner;
};
