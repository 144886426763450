import { array, bool, object, func, number, oneOf, node, string } from 'prop-types';
import { URL_TARGET } from '../../GigCards/Components/constants';
import { excludedBadgesType } from '../../shared/types';

const GRID_CONTAINER_SIZES = ['sm', 'md'];

const EntriesBasePropTypes = {
    gigs: array,
    gigImpression: object,
    HeaderComponent: node,
    container: node,
    isTouch: bool,
    tracking: object,
    isLoading: bool,
    markUnavailable: bool,
    collectProps: object,
    isNonExperiential: bool,
    isExperiential: bool,
    displayISpeak: bool,
    displayCountryFlag: bool,
    hidePrefix: bool,
    hideSellerInfo: bool,
    showGalleryDecisionIndicators: bool,
    rollouts: object,
    excludedBadges: excludedBadgesType,
    subCategoryData: object,
    gridContainerSize: oneOf(GRID_CONTAINER_SIZES),
};

const ListViewBasePropTypes = {
    size: number,
};

const CarouselBasePropTypes = {
    sliderBaseNum: number,
    sliderBreakpoints: object,
    isInfinite: bool,
    showTopRightArrows: bool,
    arrowsWrapperClassName: string,
    slideClassName: string,
    withScrollGradient: bool,
    Wrapper: node,
};

const GigCardListingPropTypes = {
    listingsContainerClassName: string,
    ...EntriesBasePropTypes,
    ...ListViewBasePropTypes,
};

const ListViewListingPropTypes = {
    ...EntriesBasePropTypes,
    ...ListViewBasePropTypes,
    urlTarget: oneOf([...Object.values(URL_TARGET)]),
    showFooter: bool,
};

const GigCardCarouselPropTypes = {
    ...EntriesBasePropTypes,
    ...CarouselBasePropTypes,
};

const SmallGigCardCarouselPropTypes = {
    ...EntriesBasePropTypes,
    ...CarouselBasePropTypes,
    onClearGig: func,
};

export {
    EntriesBasePropTypes,
    GigCardListingPropTypes,
    ListViewListingPropTypes,
    GigCardCarouselPropTypes,
    SmallGigCardCarouselPropTypes,
};
