const { isPlainObject } = require('lodash');
const { logger } = require('@fiverr-private/obs');
const { FILTER } = require('@fiverr-private/listing_lib');
const { DISPLAY_TYPES, SELECTED_EXCLUDED, EXCLUDED_NON_FACET_FILTERS_BY_FLOW } = require('../../config/filters');
const {
    STATIC_FILTERS_MAPPING_SEPARATORS,
    PAIR_FILTERS_IDS_FLP,
    MULTIPLE_FILTERS_IDS_FLP,
    FLOWS,
    SORT_BY_FILTER_TYPES,
} = require('../constants');
const { shouldShowSideFilters } = require('../../experiments/sideFilters/util/shouldShowSideFilters');
const handler = require('./handlers');
const selectedHandler = require('./selected_handlers');
const { getVisualFiltersById } = require('./visual_filters');
const { findStepIndex, buildVisualFiltersList, getVisualFiltersConditions } = require('./visual_filters/utils');
const { parseActiveFilters, parseQueryParamsFilters } = require('./_utils');
const { getNonFacetActiveFilters, enrichFacetFilters, combineAndSortFilters } = require('./filterUtils');

const buildMainFilters = (facets, context, flow) => {
    const facetArray = Object.entries(facets);
    const excludedNonFacetFilters = EXCLUDED_NON_FACET_FILTERS_BY_FLOW[context.flowName] || [];

    if (facetArray.length === 0) {
        return getNonFacetActiveFilters(context, excludedNonFacetFilters);
    }

    const mapped = enrichFacetFilters(facetArray, context, flow);

    const sortedFilters = combineAndSortFilters(mapped, context, excludedNonFacetFilters);

    return sortedFilters;
};

const buildVisualFilters = (mainFilters, context) => {
    try {
        const {
            dominateSubCategoryId,
            categoryIds: { nestedSubCategoryId } = {},
            activeFilters,
            query,
            modifiedQueryString,
            isBusiness,
            isTouch,
            abTests,
        } = context || {};
        const config = getVisualFiltersById(dominateSubCategoryId, nestedSubCategoryId);
        const displayQuery = modifiedQueryString || query;

        const showSideFilters = shouldShowSideFilters(isBusiness, isTouch, abTests);

        if (showSideFilters) {
            return;
        }

        if (!config) {
            return;
        }

        const activeFiltersKeys = Object.keys(activeFilters);
        const { filters } = config;

        const visualFiltersList = buildVisualFiltersList({
            filters,
            mainFilters,
            activeFiltersKeys,
            displayQuery,
        });
        const stepIndex = findStepIndex(visualFiltersList);

        if (!isBusiness && stepIndex === -1) {
            return;
        }

        const shouldDisplayVisualFilters = visualFiltersList.length && getVisualFiltersConditions(context);

        return { visualFiltersList, stepIndex, shouldDisplayVisualFilters };
    } catch (error) {
        logger.error({
            message: 'Error while parsing visual filters',
            error,
        });
    }
};

const buildLeafCategoryFilter = (facets, context) => handler[context.flow]?.(context, facets);

const buildSelectedFilter = (sideBar, topBarOrderMap, context, selectedExcluded = SELECTED_EXCLUDED) =>
    sideBar
        .filter(({ id }) => !selectedExcluded.includes(id))
        .reduce((acc, filter) => acc.concat(selectedHandler.handler(filter, context)), [])
        .sort((f1, f2) => topBarOrderMap[f1.id] > topBarOrderMap[f2.id]);

const buildFacetsFromActiveFilters = (activeFilters) =>
    Object.keys(activeFilters)
        .filter((id) => ![FILTER.GIG_PRICE_RANGE.ID, FILTER.DELIVERY_TIME.ID].includes(id))
        .reduce((acc, id) => {
            const filterValue = activeFilters[id];
            acc[id] = Array.isArray(filterValue) ? filterValue.map((val) => ({ id: val })) : [{ id: filterValue }];
            return acc;
        }, {});

/**
 * Build an active filters mapper
 * @param {object} baseActiveFilters - single filters map - type to value
 * @param {object} staticFilters
 * @returns {object} active filters map including multiple filters
 */
const buildActiveFilters = (baseActiveFilters, staticFilters) =>
    Object.entries(baseActiveFilters).reduce((acc, [filterId, filterVal]) => {
        if (PAIR_FILTERS_IDS_FLP.includes(filterId) && typeof filterVal === 'string') {
            acc[filterId] = filterVal.split(STATIC_FILTERS_MAPPING_SEPARATORS.HYPHEN);
        } else if (MULTIPLE_FILTERS_IDS_FLP.includes(filterId) && typeof filterVal === 'string') {
            acc = { ...acc, ...staticFilters.multipleFilters[filterVal] };
        } else {
            acc[filterId] = filterVal;
        }

        return acc;
    }, {});

const getMultiPairsSelectValue = (options) => {
    const { fromOptions, toOptions } = options;
    const fromSelectedValue = fromOptions.find(({ selected }) => selected);
    const toSelectedValue = toOptions.find(({ selected }) => selected);

    return [fromSelectedValue && fromSelectedValue.id, toSelectedValue && toSelectedValue.id];
};

const getValueByViewType = (selectedOptions, display_type) => {
    const singleOption = selectedOptions[0];
    switch (display_type) {
        case DISPLAY_TYPES.MULTI_PAIRS_SELECT:
            return getMultiPairsSelectValue(selectedOptions);

        case DISPLAY_TYPES.CHECKBOX_GROUP:
            return selectedOptions.map(({ id }) => id);

        case DISPLAY_TYPES.INPUT_RANGE:
            return [singleOption.min, singleOption.max];

        case DISPLAY_TYPES.TOGGLE:
        case DISPLAY_TYPES.CHECKBOX:
        case DISPLAY_TYPES.PRO:
        case DISPLAY_TYPES.TOGGLE_DETAILED:
            return 'true';

        default:
            return singleOption.id;
    }
};

const getSelectedOptions = (options) => options.filter(({ selected }) => selected);

const getSelectedOptionValue = (options) => {
    const selectedValue = getSelectedOptions(options);
    return selectedValue[0] ? selectedValue[0].id : undefined;
};

/**
 * generate filters selected values based on if the filter has been selected.
 * @param filters - array of filter objects.
 * @returns object of filter id and the selected value based on the display_type
 */
const getFiltersSelectedValues = (filters) =>
    filters.reduce((acc, { id: filterId, options, display_type }) => {
        if (!options) {
            return acc;
        }

        const isOptionsObject = isPlainObject(options);
        const selectedOptions = isOptionsObject ? options : getSelectedOptions(options);

        if (!Object.keys(selectedOptions).length) {
            return acc;
        }

        acc[filterId] = getValueByViewType(selectedOptions, display_type);

        return acc;
    }, {});

/**
 * calculate sortBy filter when doesn't exist.
 * @param {bool} isRncUser - Is the user rnc type
 * @param {number} userId - The user id if exists
 * @param {string} flow - Current listings flow
 * @returns the correct sort by filter based on the logic
 */
const calculateSortByFilter = (isRncUser, userId, flow) => {
    const rncOrGuest = isRncUser || !userId;

    if (flow === FLOWS.CATEGORY && rncOrGuest) {
        return SORT_BY_FILTER_TYPES.RATING;
    }

    return SORT_BY_FILTER_TYPES.AUTO;
};

const removeIfNoResults = (activeFilters, filters) => {
    const deliveryTime = activeFilters[FILTER.DELIVERY_TIME.ID];
    const priceRange = activeFilters[FILTER.GIG_PRICE_RANGE.ID];

    if (!deliveryTime) {
        filters = filters.filter(({ id }) => id !== FILTER.DELIVERY_TIME.ID);
    }

    if (!priceRange) {
        filters = filters.filter(({ id }) => id !== FILTER.GIG_PRICE_RANGE.ID);
    }

    return filters;
};

/**
 * Get all the keys of the active static filters (including multiple filters)
 * @param {object} baseStaticFilterKeys - active keys of single static filters
 * @param {object} staticFilters
 * @returns {Array} active filter keys
 */
const extractActiveStaticFilters = (baseStaticFilterKeys, staticFilters) => {
    const { filters } = staticFilters;
    const multipleFilters = filters.filter((filter) => MULTIPLE_FILTERS_IDS_FLP.includes(filter.id)).shift();
    const singleStaticFilterKeys = baseStaticFilterKeys.filter((item) => !MULTIPLE_FILTERS_IDS_FLP.includes(item));

    if (!multipleFilters.values) {
        return singleStaticFilterKeys;
    }
    const activeStaticFilterKeys = singleStaticFilterKeys.concat(
        ...multipleFilters.values.map((item) => item.filtersTypes)
    );

    return [...new Set(activeStaticFilterKeys)];
};

const organizeServiceOfferingsOptions = (facets, serviceOfferings, newFacet, key) => {
    serviceOfferings.push(buildServiceOfferingsFacetOptions(newFacet, key));
    delete facets[key];

    return serviceOfferings;
};

const buildServiceOfferingsFacetOptions = (facet, key) => {
    const count = facet.find(({ id }) => id === 'true')?.count ?? 0;
    return { id: key, count };
};

const buildServiceOfferingsFacet = (facets) => {
    let serviceOfferings = [];

    const consultationFacet = facets[FILTER.SERVICE_OFFERINGS.OFFER_CONSULTATION];
    const subscriptionFacet = facets[FILTER.SERVICE_OFFERINGS.SUBSCRIPTION];

    if (consultationFacet) {
        serviceOfferings = organizeServiceOfferingsOptions(
            facets,
            serviceOfferings,
            consultationFacet,
            FILTER.SERVICE_OFFERINGS.OFFER_CONSULTATION
        );
    }

    if (subscriptionFacet) {
        serviceOfferings = organizeServiceOfferingsOptions(
            facets,
            serviceOfferings,
            subscriptionFacet,
            FILTER.SERVICE_OFFERINGS.SUBSCRIPTION
        );
    }

    Object.assign(facets, {
        service_offerings: serviceOfferings,
    });

    return facets;
};

const enrichFacets = (facets, noResults, activeFilters) => {
    if (noResults) {
        return buildFacetsFromActiveFilters(activeFilters);
    }

    return buildServiceOfferingsFacet(facets);
};

module.exports = {
    buildActiveFilters,
    parseActiveFilters,
    parseQueryParamsFilters,
    getFiltersSelectedValues,
    getSelectedOptionValue,
    calculateSortByFilter,
    extractActiveStaticFilters,
    buildFacetsFromActiveFilters,
    buildMainFilters,
    removeIfNoResults,
    buildLeafCategoryFilter,
    buildSelectedFilter,
    buildVisualFilters,
    buildServiceOfferingsFacet,
    enrichFacets,
};
