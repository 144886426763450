import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { AssetType, SellerType } from '../../types';
import { OPEN_GIG_SAME_TAB_TARGET, UNAVAILABLE } from '../constants';
import ContextualLink from '../ContextualLink';
import Badge from '../Badge';
import { SellerRepresentative } from '../../../constants/seller';
import { useCardContext } from '../../../GigCardListings/context/CardContext';
import { pausePlayers } from '../../utils/pausePlayers';
import Slider from './Slider';
import SlideImage from './SlideImage';
import { OverlayBadge } from './OverlayBadge/overlayBadge';

import './index.scss';

const Media = ({
    url,
    badge,
    gigType,
    gigId,
    assets,
    asset,
    localizedAssets,
    isTouch,
    isUnavailable = false,
    lazyLoad,
    seller,
    urlTarget,
    gigAttachmentIds,
    showGalleryDecisionIndicators,
    showTooltip,
    toggleTooltip,
    showTranslatedUGC,
    showNewImageRatio,
    playOnHover,
    isAutoPlay = false,
    showPlayerControls,
    showAudioRemainingTime,
    onMouseEnter,
    onMouseLeave,
    showSingleSlide,
}) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const slides = showTranslatedUGC && localizedAssets?.length ? localizedAssets : assets;

    return (
        <ContextualLink
            className={classNames('media relative', { 'box-image-ratio': showNewImageRatio })}
            href={url}
            target={urlTarget}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={() => pausePlayers(gigId)}
            ariaLabel={translate('gig_cards.gig_link_aria_label')}
        >
            {showSingleSlide ? (
                <div className="single-slide">
                    <SlideImage asset={asset} showNewImageRatio={showNewImageRatio} lazy={lazyLoad} />
                </div>
            ) : (
                <Slider
                    seller={seller}
                    showNewImageRatio={showNewImageRatio}
                    gigAttachmentIds={gigAttachmentIds}
                    slides={slides}
                    gigId={gigId}
                    gigType={gigType}
                    isTouch={isTouch}
                    lazyLoad={lazyLoad}
                    showTooltip={showTooltip}
                    toggleTooltip={toggleTooltip}
                    playOnHover={playOnHover}
                    isAutoPlay={isAutoPlay}
                    showPlayerControls={showPlayerControls}
                    showAudioRemainingTime={showAudioRemainingTime}
                    showGalleryDecisionIndicators={showGalleryDecisionIndicators}
                    trackEvent={trackEvent}
                />
            )}
            <OverlayBadge badge={badge} isAgency={seller?.type === SellerRepresentative.AGENCY} />
            {isUnavailable && <Badge type={UNAVAILABLE} excludeTooltip={true} />}
        </ContextualLink>
    );
};

Media.propTypes = {
    url: PropTypes.string.isRequired,
    badge: PropTypes.shape({
        type: PropTypes.string,
    }),
    isTouch: PropTypes.bool,
    isUnavailable: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    urlTarget: PropTypes.string,
    showTooltip: PropTypes.bool,
    toggleTooltip: PropTypes.func,
    showGalleryDecisionIndicators: PropTypes.bool,
    gigId: PropTypes.number,
    gigType: PropTypes.string,
    gigAttachmentIds: PropTypes.arrayOf(PropTypes.string),
    asset: AssetType,
    assets: PropTypes.arrayOf(AssetType).isRequired,
    localizedAssets: PropTypes.arrayOf(AssetType),
    showTranslatedUGC: PropTypes.bool,
    playOnHover: PropTypes.bool,
    isAutoPlay: PropTypes.bool,
    showPlayerControls: PropTypes.bool,
    showAudioRemainingTime: PropTypes.bool,
    onMouseEnter: PropTypes.bool,
    seller: SellerType,
    onMouseLeave: PropTypes.bool,
    showNewImageRatio: PropTypes.bool,
    showSingleSlide: PropTypes.bool,
};

Media.defaultProps = {
    isTouch: false,
    lazyLoad: false,
    urlTarget: OPEN_GIG_SAME_TAB_TARGET,
    gigType: '',
};

export default Media;
