import { DISPLAY_TYPES } from '../../../../config/filters';

export const FILTERS_EXTRAS_PROPS = {
    [DISPLAY_TYPES.AUTO_COMPLETE]: {
        showClear: true,
        placeholder: 'search_perseus.filter.seller_location.placeholder',
    },
};

export const FILTERS_DISPLAY = {
    delivery_time: DISPLAY_TYPES.AUTO_COMPLETE,
};

export const MAX_GIG_PRICE_RANGE = 50000;
export const MIN_GIG_PRICE_RANGE = 0;

export const TAG_TYPES = {
    NEW: 'new',
};
