import { PerseusMiddleware } from '@fiverr-private/perseus';
import { metricsService } from '../metricService/metricsService';

/**
 * Wraps an Express middleware function to report its execution time to Grafana
 *
 * @param middleware - The Express middleware function to wrap
 * @returns {PerseusMiddleware} A new middleware function that reports execution time in ms
 *
 * @example
 * export const addInitialProps = withMiddlewareBreakdownMetric(_addInitialProps);
 */
export const withMiddlewareBreakdownMetric =
    (middleware): PerseusMiddleware =>
    async (req, res, next) => {
        const startTime = process.hrtime();

        try {
            await new Promise<void>((resolve, reject) => {
                middleware(req, res, (error: unknown) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve();
                    }
                });
            });

            next();
        } catch (error) {
            next(error);
        } finally {
            const executionTime = process.hrtime(startTime);
            const executionTimeInMs = executionTime[0] * 1e3 + executionTime[1] * 1e-6;
            const middlewareName = middleware.name || 'unknown';

            metricsService.time(`middleware.breakdown.${middlewareName}.process_time`, executionTimeInMs);
        }
    };
