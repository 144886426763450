import React, { useContext } from 'react';
import classnames from 'classnames';
import pathfinder from '@fiverr-private/pathfinder';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { ArrowRightIcon, UnionIcon } from '@fiverr-private/icons';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { tracker, EXPERT_BANNER_TYPE } from '@fiverr-private/listing_lib';
import { getWindow } from '../../../../../../shared/utils';
import { BannerMode, BI_EVENT_TYPES, mixPanelBasePayload, T_PREFIX } from '../util';
import { AppContext } from '../../../context/listingsContext';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../constants';
import { getBqEventSource } from '../../biUtils';
import { getImpressionEventPayload, reportBigQueryBannerClick } from '../bqReports';
import {
    CUSTOM_CATEGORIES_SOURCE,
    CUSTOM_HREF_CATEGORIES,
    CUSTOM_TITLE_CATEGORIES,
    PROJECT_PARTNER_IMAGE,
} from './constants';
import styles from './styles.module.scss';

export const ProjectManageBanner = ({ resultsCount, hasActiveFilters, position, bannerMode, userData }) => {
    const noResultsPage = bannerMode === BannerMode.NO_RESULTS_PAGE;
    const fullCatalogPage = bannerMode === BannerMode.FULL_CATALOG;
    const { isExpertListings = false, subCategoryData } = useContext(AppContext);
    const customTitle = CUSTOM_TITLE_CATEGORIES[subCategoryData?.category?.id];
    const customSource = CUSTOM_CATEGORIES_SOURCE[subCategoryData?.category?.id];
    const customHref = CUSTOM_HREF_CATEGORIES[subCategoryData?.category?.id];
    const BQEnrichment = getImpressionEventPayload(
        isExpertListings,
        resultsCount,
        hasActiveFilters,
        position,
        EXPERT_BANNER_TYPE.PROJECT_PARTNERS
    );
    const [mainContainerRef] = useImpressionItem({
        enrichment: BQEnrichment,
        onImpression: () => {
            tracker.trackBannerImpression(
                EXPERT_BANNER_TYPE.PROJECT_PARTNERS,
                mixPanelBasePayload(userData?.role, isExpertListings)
            );
        },
    });

    const mixPanelPayload = () => {
        const noResults = 'no results';
        const fewResults = 'less than 4 results';
        const regular = 'listing banner';
        let component;
        if (resultsCount === 0) {
            component = noResults;
        } else if (resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS) {
            component = fewResults;
        } else {
            component = regular;
        }
        return {
            ...mixPanelBasePayload(userData?.role, isExpertListings),
            component,
        };
    };

    const openProjectPartner = () => {
        reportBigQueryBannerClick(
            BI_EVENT_TYPES.CLICKED_ON_FIVERR_PRO_FIND_PROJECT_PARTNER,
            isExpertListings,
            resultsCount,
            hasActiveFilters,
            position
        );

        tracker.trackProjectPartnersClick(mixPanelPayload());
        const proOrFullListingIndex = Number(isExpertListings);
        const generalEventSource = getBqEventSource(isExpertListings);
        const source = customSource ? customSource[proOrFullListingIndex] : generalEventSource;
        const pageSlug = customHref || 'project-partner';
        const href = pathfinder('content_pages_page', { page_slug: pageSlug }, { query: { source } });
        getWindow().open(href, '_blank');
    };

    return (
        <>
            <div
                className={classnames(styles.wrapper, {
                    [styles.noResultsPage]: noResultsPage,
                    [styles.fullCatalogContainer]: fullCatalogPage,
                })}
                ref={mainContainerRef}
            >
                <div className={`p-t-24 p-b-24 p-l-32 p-r-40 flex flex-between flex-col ${styles.bodyWrapper}`}>
                    <div className="t-a-left">
                        <div className={`m-b-4 ${styles.tagWrapper}`}>
                            <ProjectManagementTag />
                        </div>
                        <h5 className={styles.title}>
                            {customTitle ? (
                                <I18n k={`${T_PREFIX}.project_manage.title_by_category.${customTitle}`} />
                            ) : (
                                <I18n k={`${T_PREFIX}.project_manage.title`} />
                            )}
                        </h5>
                        <div className={`co-grey-1000 tbody-6 ${styles.description}`}>
                            <I18n k={`${T_PREFIX}.project_manage.description`} />
                        </div>
                    </div>
                    <div className={`flex flex-items-center ${styles.buttonWrapper}`}>
                        <Button size="sm" className={styles.buttonLink} onClick={openProjectPartner}>
                            <I18n k={`${T_PREFIX}.project_manage.button`} />
                            {noResultsPage && <ArrowRightIcon />}
                        </Button>
                    </div>
                </div>
                <div className={styles.imageWrapper}>
                    <img
                        src={PROJECT_PARTNER_IMAGE}
                        className={styles.image}
                        alt="project partner background"
                        width="257px"
                        height="164px"
                    />
                    <div className={styles.projectManagementTagWrapper}>
                        <ProjectManagementTag />
                    </div>
                </div>
            </div>
        </>
    );
};

const ProjectManagementTag = () => (
    <div className={styles.projectManagementTag}>
        <UnionIcon size={12} color="black" />
        <span className="tbody-6 co-grey-1200 m-l-4" style={{ marginBottom: '-1px' }}>
            <I18n k={`${T_PREFIX}.project_manage.tag`} />
        </span>
    </div>
);
