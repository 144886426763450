import { RequestContext } from '@fiverr-private/perseus';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { isActive } from '@fiverr-private/rollout_js';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';

const sellersCountriesCodes = ['IN', 'BD', 'PK', 'ID', 'MA', 'NG', 'LK', 'KE'];

const isEligibleForAllocation = () => {
    const {
        experience: { isBusiness },
        isMobile,
        locale,
        countryCode,
    } = RequestContext;

    return isBusiness && !isMobile && locale === DEFAULT_FIVERR_LOCALE && !sellersCountriesCodes.includes(countryCode);
};

const inRollout = async () => {
    const { userId } = RequestContext;

    return userId ? await isActive(ROLLOUTS.MUSTANGS_BFF_IN_PRO_LISTINGS_TEST, userId) : false;
};

export const briefFloatingForm = {
    id: EXPERIMENTS.MUSTANGS_BFF_IN_PRO_LISTINGS,
    experimentType: EXPERIMENT_TYPE.USER,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
