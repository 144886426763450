import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DeliveredAssetTag from '../DeliveredAssetTag';
import { trackGigForClickType, createClickedElement } from '../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE, PROMOTED_GIGS } from '../../constants';
import Player from '../Player';
import SlideImage from '../SlideImage';
import { IMAGE_TYPE } from '../../../utils/assetsHelper/constants';
import { AssetType } from '../../../types';
import { useObserveOnEnterAndLeave } from '../../../../hooks/useObserveOnEnterAndLeave';

const SlideItem = ({
    asset,
    width,
    active,
    lazy,
    showTooltip,
    toggleTooltip,
    showGalleryDecisionIndicators,
    gigType,
    trackEvent,
    playOnHover,
    isHoveringSlider,
    showPlayerControls,
    showAudioRemainingTime,
    showNewImageRatio,
    muted,
    isAutoPlay,
}) => {
    const isImageAsset = asset.type === IMAGE_TYPE;
    const itemClass = classNames('slide-item', { active }, { 'with-gradient': showGalleryDecisionIndicators });
    const [shouldAutoPlay, setShouldAutoPlay] = useState(false);

    const onEnter = useCallback(() => setShouldAutoPlay(true), []);
    const onLeave = useCallback(() => setShouldAutoPlay(false), []);
    const slideItemRef = useObserveOnEnterAndLeave({
        shouldObserve: isAutoPlay,
        onEnter,
        onLeave,
    });

    return (
        <div
            ref={slideItemRef}
            className={itemClass}
            style={{ width }}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement)}
        >
            {showGalleryDecisionIndicators && asset.deliveredAsset && active && gigType !== PROMOTED_GIGS && (
                <DeliveredAssetTag showTooltip={showTooltip} toggleTooltip={toggleTooltip} />
            )}
            {isImageAsset ? (
                <SlideImage showNewImageRatio={showNewImageRatio} asset={asset} lazy={lazy} trackEvent={trackEvent} />
            ) : (
                <Player
                    showNewImageRatio={showNewImageRatio}
                    asset={asset}
                    lazy={lazy}
                    trackEvent={trackEvent}
                    playOnHover={playOnHover}
                    isHoveringSlider={isHoveringSlider}
                    showPlayerControls={showPlayerControls}
                    showAudioRemainingTime={showAudioRemainingTime}
                    active={active}
                    muted={muted}
                    autoPlay={shouldAutoPlay}
                />
            )}
        </div>
    );
};

SlideItem.propTypes = {
    asset: AssetType.isRequired,
    width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    active: PropTypes.bool,
    lazy: PropTypes.bool,
    showTooltip: PropTypes.bool,
    toggleTooltip: PropTypes.func,
    trackEvent: PropTypes.func,
    showGalleryDecisionIndicators: PropTypes.bool,
    showNewImageRatio: PropTypes.bool,
    gigType: PropTypes.string,
    playOnHover: PropTypes.bool,
    isHoveringSlider: PropTypes.bool,
    showPlayerControls: PropTypes.bool,
    showAudioRemainingTime: PropTypes.bool,
    muted: PropTypes.bool,
    isAutoPlay: PropTypes.bool,
};

SlideItem.defaultProps = {
    lazy: false,
    gigType: '',
};

export const clickedElement = createClickedElement(ELEMENTS_NAME.GIG_IMAGE, ELEMENTS_TYPE.LINK);

export default SlideItem;
