import React from 'react';
import { bool, string, number } from 'prop-types';
import { Avatar } from '@fiverr-private/fit';
import { SellerRepresentative } from '../../../../constants/seller';
import styles from './index.module.scss';

export const SellerAvatar = ({ username, displayName, imgSrc, isOnline, type, size }) => {
    const name = displayName ? displayName : username;
    const avatarShape = type === SellerRepresentative.AGENCY ? Avatar.SHAPES.SQUARE : Avatar.SHAPES.CIRCLE;

    return (
        <Avatar
            containerClassName={styles.avatar}
            src={imgSrc}
            shape={avatarShape}
            username={name}
            isOnline={isOnline}
            showOnlineIndicator={isOnline}
            size={size}
        />
    );
};

SellerAvatar.propTypes = {
    username: string,
    displayName: string,
    imgSrc: string,
    isOnline: bool,
    type: SellerRepresentative,
    size: number,
};
