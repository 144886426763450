import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isArray, isEmpty } from 'lodash';
import { URI } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import {
    CLEARED_FILTERS_COOKIE_NAME,
    FILTER,
    FILTERS_LOCATION,
    setCookie,
    deregisterStickyFilter,
} from '@fiverr-private/listing_lib';
import { buildServiceOptionTitle } from '../../../../service/customFilters';
import { BI_SOURCES } from '../../../../utils/constants';
import { filterNavigation, shouldFilterNavigate } from '../../../../service/clientNavigation';
import { filterAndSort } from '../../../common/floating_topbar/utils';
import { TOP_GROUPING } from '../../../../config/filters';
import { FilterProvider } from '../../../context/filterContext';
import { getNscFilterTags } from '../../../generic_listings/TagFilters/utils';
import { FILTER_DISPLAY_TYPES } from '../../../common/floating_topbar/menus/consts';
import { INCLUDED_QUERIES } from '../../../common/ExplicitLanguagePair/constants';
import { useBusinessListingsContext } from '../index';
import { ActiveFilters } from '../Types';
import { checkActiveFiltersEmpty } from '../FiltersUtils';
import CollapsibleFiltersGroup from './CollapsibleFiltersGroup';
import { ToggleFilter } from './filter_components';
import VisualScFilter from './filter_components/VisualFilters/VisualScFilter';
import NestedSubcategoriesFilter from './filter_components/VisualFilters/NestedSubcategoriesFilter';
import VisualFilters from './filter_components/VisualFilters';
import { SUBCATEGORY_FILTER_VISUAL_URL_PARAM } from './filter_components/utils';
import { Triggers, useSidebarFiltersContext } from './Context';
import CollapsibleSection from './CollapsibleSection';

import styles from './index.module.scss';

export const SidebarFilters = ({ sidebarContainerRef }) => {
    const { experience: { isBusiness } = {}, url = '' } = getContext();
    const {
        rollouts,
        filters,
        activeFilters,
        visualFilters,
        subCategory,
        categoryIds,
        subcategoryName,
        dominateSubCategoryId,
        significantLeafCategories = [],
        displayQuery,
        isExpertListings,
    } = useBusinessListingsContext();

    const { applyEvent, clearAllEvent, activeTrigger, setNewActiveFilters } = useSidebarFiltersContext();

    const inMustangsDefaultFiltersRollout = rollouts[ROLLOUTS.MUSTANGS_CUSTOM_DEFAULT_FILTERS] ?? false;

    const hourlyRateRolloutActive = !!rollouts[ROLLOUTS.ZEBRAS_HOURLY_RATES];

    const hourlyRateFilter = filters.find((filter) => filter.id === FILTER.HAS_HOURLY.ID);
    const sellerOnlineFilter = filters.find((filter) => filter.id === FILTER.IS_SELLER_ONLINE.ID);
    const sellerOnlineFilterActive = activeFilters[FILTER.IS_SELLER_ONLINE.ID] === 'true';
    const sellerOnlineFilterResults = sellerOnlineFilter?.options?.[0].count ?? -1;
    const sellerOnlineFilterHasResults = sellerOnlineFilterResults > 0;
    const shouldDisplaySellerOnlineFilter =
        !!sellerOnlineFilter && (sellerOnlineFilterActive || sellerOnlineFilterHasResults);

    const agencyFilter = filters.find((filter) => filter.id === FILTER.IS_AGENCY.ID);
    const agencyFilterActive = activeFilters[FILTER.IS_AGENCY.ID] === 'true';
    const agencyFilterResults = agencyFilter?.options?.[0].count ?? -1;
    const agencyFilterHasResults = agencyFilterResults > 0;
    const shouldDisplayAgencyFilter = !!agencyFilter && (agencyFilterActive || agencyFilterHasResults);

    const consultationFilterId = FILTER.SERVICE_OFFERINGS.OFFER_CONSULTATION;
    const serviceOfferingFilter = filters.find((filter) => filter.id === FILTER.SERVICE_OFFERINGS.ID);
    const offerConsultationFilter = serviceOfferingFilter?.options?.find(
        (service) => service.id === consultationFilterId
    );
    const offerConsultationFilterResults = offerConsultationFilter?.count ?? -1;
    const offerConsultationFilterHasResults = offerConsultationFilterResults > 0;
    const offerConsultationFilterActive = activeFilters[consultationFilterId]?.[0] === 'true';
    const shouldDisplayOfferConsultationFilter =
        !!offerConsultationFilter && (offerConsultationFilterActive || offerConsultationFilterHasResults);

    const [ongoingNavigation, setOngoingNavigation] = useState(false);

    const disableClicks = () => setOngoingNavigation(true);

    const applyFilters = useCallback(
        (activeFiltersToApply: ActiveFilters, trigger: Triggers = Triggers.FILTER_COMPONENT_CLICK) => {
            setNewActiveFilters(activeFiltersToApply);

            if (activeTrigger !== trigger) {
                return;
            }

            // TODO: Clean after default filters test is done
            if (inMustangsDefaultFiltersRollout && isEmpty(activeFiltersToApply)) {
                setCookie({
                    name: CLEARED_FILTERS_COOKIE_NAME,
                    value: true,
                    expirationDays: 1,
                    domain: null,
                });
            }

            if (shouldFilterNavigate(activeFiltersToApply, activeFilters) && !ongoingNavigation) {
                filterNavigation(
                    activeFiltersToApply,
                    activeFilters,
                    FILTERS_LOCATION.BUSINESS_SIDE_FILTERS,
                    BI_SOURCES.BUSINESS_SIDE_FILTERS,
                    'sidebar-filters',
                    null,
                    {}
                );
                disableClicks();
            }
        },
        [setNewActiveFilters, activeTrigger, inMustangsDefaultFiltersRollout, ongoingNavigation, activeFilters]
    );

    useEffect(() => {
        applyEvent.push(applyFilters);
    }, [applyEvent, applyFilters]);

    const clearAll = useCallback(() => {
        if (!window?.location || checkActiveFiltersEmpty(activeFilters)) {
            setNewActiveFilters({});
            return;
        }

        deregisterStickyFilter();
        const uri = new URI(window.location.href);

        uri.removeParams('sub_category', 'nested_sub_category', 'ref');

        // TODO: Clean after default filters test is done
        if (inMustangsDefaultFiltersRollout) {
            setCookie({
                name: CLEARED_FILTERS_COOKIE_NAME,
                value: true,
                expirationDays: 1,
                domain: null,
            });
        }

        disableClicks();

        window.location.href = uri.href;
    }, [activeFilters, setNewActiveFilters, inMustangsDefaultFiltersRollout]);

    useEffect(() => {
        clearAllEvent.push(clearAll);
    }, [clearAllEvent, clearAll]);

    const translationFilter = filters.find(
        ({ id }) => id === FILTER.LANGUAGES_PAIR.ID || INCLUDED_QUERIES.includes(displayQuery)
    );

    const { serviceOptionKey, service } = buildServiceOptionTitle(subCategory);
    const sellerFilterTitle = isBusiness ? 'expert' : 'seller';

    const uri = new URI(url);
    const scInVisualContext = !!uri.params[SUBCATEGORY_FILTER_VISUAL_URL_PARAM];

    const onClickNavigationActive = activeTrigger === Triggers.FILTER_COMPONENT_CLICK;

    const shouldDisplayVisualFilters =
        (onClickNavigationActive && !isEmpty(visualFilters) && visualFilters?.shouldDisplayVisualFilters) ||
        !isEmpty(translationFilter);
    const shouldDisplaySubCategoryFilter =
        onClickNavigationActive &&
        !isEmpty(subCategory) &&
        isArray(subCategory.children) &&
        !!subCategory.children[0]?.params;
    const shouldDisplaySubCategoryFilterAsVisual =
        onClickNavigationActive &&
        (significantLeafCategories?.length > 1 || scInVisualContext) &&
        !shouldDisplayVisualFilters &&
        !dominateSubCategoryId;
    // eslint-disable-next-line
    const nestedSubCategoryId = categoryIds?.['nestedSubCategoryId'];
    const nscItems =
        !shouldDisplaySubCategoryFilter &&
        typeof subCategory?.children[0]?.children !== 'undefined' &&
        subCategory?.children[0]?.children?.length > 1 &&
        subCategory?.children[0]?.children;
    const nestedSubCategoriesProps = !nestedSubCategoryId && !isEmpty(nscItems) && getNscFilterTags({ nscItems });
    const shouldDisplayNscFilter = onClickNavigationActive && !!nestedSubCategoriesProps;

    const visualFiltersIds = visualFilters?.visualFiltersList.map((filter) => filter.id) || [];
    const filtersWithoutVisuals = shouldDisplayVisualFilters
        ? filters.filter((filter) => !visualFiltersIds.includes(filter.id))
        : filters;

    const budgetFilters = filterAndSort(filtersWithoutVisuals, TOP_GROUPING.BUDGET).filter(
        ({ display_type }) => display_type === FILTER_DISPLAY_TYPES.INPUT_RANGE
    );
    const deliveryFilters = filterAndSort(filtersWithoutVisuals, TOP_GROUPING.DELIVERY_TIME);

    const hourlyFilterIsActive = !!activeFilters.has_hourly && hourlyRateRolloutActive && !!isExpertListings;
    const budgetFilterIsActive = !!activeFilters.gig_price_range;

    const serviceFilters = [
        ...filterAndSort(filtersWithoutVisuals, TOP_GROUPING.SERVICE).filter(
            ({ id, options }) => options?.length > 0 && id !== FILTER.SERVICE_OFFERINGS.ID
        ),
        ...deliveryFilters,
    ];
    const sellerFilters = filterAndSort(filtersWithoutVisuals, TOP_GROUPING.SELLER);

    const addTopPaddingToServiceFilters =
        !!nestedSubCategoriesProps || shouldDisplaySubCategoryFilter || shouldDisplayVisualFilters;
    const addTopPaddingToSellerFilters = addTopPaddingToServiceFilters || serviceFilters.length > 0;

    return (
        <FilterProvider
            activeFilters={activeFilters}
            filters={filters}
            navigationCondition={shouldFilterNavigate}
            applyNavigation={applyFilters}
        >
            <div className={styles.container}>
                <div
                    ref={sidebarContainerRef}
                    className={classNames(styles.filters, { [styles.disableClicks]: ongoingNavigation })}
                >
                    {shouldDisplayNscFilter && (
                        <NestedSubcategoriesFilter
                            items={nestedSubCategoriesProps.tags}
                            subcategoryName={subcategoryName}
                            disableClicks={disableClicks}
                        />
                    )}
                    {shouldDisplaySubCategoryFilter && shouldDisplaySubCategoryFilterAsVisual && (
                        <VisualScFilter
                            activeFilters={activeFilters}
                            subCategory={subCategory}
                            significantLeafCategories={significantLeafCategories}
                            disableClicks={disableClicks}
                        />
                    )}
                    {shouldDisplayVisualFilters && (
                        <VisualFilters
                            visualFilters={visualFilters}
                            activeFilters={activeFilters}
                            filters={filters}
                            subCategory={subCategory}
                            translationFilter={translationFilter}
                            applyFilters={applyFilters}
                        />
                    )}
                    {shouldDisplaySubCategoryFilter && !shouldDisplaySubCategoryFilterAsVisual && (
                        <div
                            className={classNames('p-l-16', 'p-r-16', {
                                'm-t-8': shouldDisplayVisualFilters,
                            })}
                        >
                            <CollapsibleSection
                                title={translate('search_perseus.filter_builder.sub_category.category')}
                                filter={subCategory}
                                disableClicks={disableClicks}
                                addTopPadding={shouldDisplayVisualFilters}
                                sidebarContainerRef={sidebarContainerRef}
                            />
                        </div>
                    )}

                    {serviceFilters.length > 0 && (
                        <CollapsibleFiltersGroup
                            title={translate(serviceOptionKey, { params: { service } })}
                            filters={serviceFilters}
                            addTopPadding={addTopPaddingToServiceFilters}
                            sidebarContainerRef={sidebarContainerRef}
                        />
                    )}

                    {!!hourlyRateFilter && !!hourlyRateRolloutActive && !!isExpertListings && (
                        <ToggleFilter
                            filter={hourlyRateFilter}
                            text={hourlyRateFilter.alias}
                            activeFilters={activeFilters}
                            disabled={budgetFilterIsActive}
                            isNew
                        />
                    )}
                    <div className={classNames('p-r-16', 'p-l-16')}>
                        {budgetFilters.length > 0 &&
                            budgetFilters.map((filter) => (
                                <CollapsibleSection
                                    isDisabled={hourlyFilterIsActive}
                                    key={`${filter.id}-budget-filters`}
                                    title={filter.alias}
                                    filter={filter}
                                    sidebarContainerRef={sidebarContainerRef}
                                />
                            ))}
                    </div>

                    {sellerFilters.length > 0 && (
                        <CollapsibleFiltersGroup
                            title={translate(`search_perseus.filter.${sellerFilterTitle}`)}
                            filters={sellerFilters}
                            addTopPadding={addTopPaddingToSellerFilters}
                            sidebarContainerRef={sidebarContainerRef}
                        />
                    )}

                    {shouldDisplaySellerOnlineFilter && (
                        <ToggleFilter
                            filter={sellerOnlineFilter}
                            text={translate('search_perseus.business_listings.sidebar_filters.is_seller_online.title')}
                            activeFilters={activeFilters}
                        />
                    )}

                    {shouldDisplayAgencyFilter && (
                        <ToggleFilter
                            filter={agencyFilter}
                            text={translate('search_perseus.business_listings.sidebar_filters.is_agency.title')}
                            activeFilters={activeFilters}
                        />
                    )}

                    {shouldDisplayOfferConsultationFilter && (
                        <ToggleFilter
                            filter={offerConsultationFilter}
                            text={translate(
                                'search_perseus.business_listings.sidebar_filters.offer_consultation.title'
                            )}
                            activeFilters={activeFilters}
                        />
                    )}
                </div>
            </div>
        </FilterProvider>
    );
};
