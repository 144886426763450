export const BI_CONSTS = {
    BUTTON: 'button',
    LINK: 'link',
    CHECKBOX: 'checkbox',
    USER_ACTIONS: 'user_actions',
    CLICKED_ON_FILTER_GROUP: 'clicked_on_filter_group',
    CLICKED_ON_DRAWER_GROUP: 'clicked_on_drawer_group',
    WEBSITE_ACTIONS: 'website_actions',
    BUYERS: 'buyers',
    CATERPILLARS: 'caterpillars',
    CLICK: 'click',
    HOVER: 'hover',
    MIXPANEL_PAGE_NAME: 'Sub Category',
    BIGQUERY_PAGE_NAME: 'sub_category',
    DRAWER: 'drawer',
};

export const DOMAIN = 'buyers';

export const SUB_DOMAIN = 'caterpillars';

export const BI_EVENT_GROUPS = {
    PAGE_VIEW: 'page_view',
    USER_ACTION: 'user_action',
    USER_ACTIONS: 'user_actions',
    USER_IMPRESSION: 'user_impressions',
    BANNER_IMPRESSION: 'banner_impression',
};

export const BI_EVENTS_SUB_GROUPS = {
    WEBSITE_ACTIONS: 'website_actions',
    CONTENT_IMPRESSION: 'content_impression',
    FIVERR_PRO: 'fiverr_pro',
};

export const BI_PAGE_NAMES = {
    LISTINGS: 'listings_page',
    ROLES_LISTINGS: 'profession_listings_page',
};

export const BI_ACTION_TYPES = {
    CLICK: 'click',
    IMPRESSION: 'impression',
};

export const BI_EVENTS_ENTITY = {
    CLIENT: 'client',
};
