export const IMAGE_TYPE = 'image';
export const VIDEO_TYPE = 'video';
export const AUDIO_TYPE = 'audio';
export const MOBILE_SIZE = 'mobile';
export const LARGE_SIZE = 'large';
export const IMAGES_MOBILE_RESOLUTION = 't_mobile_web_2';
export const IMAGES_RESOLUTION = 't_gig_cards_web';
export const IMAGES_RESOLUTION_X2 = 't_gig_cards_web_x2';
export const DELIVERIES_NEW_RESOLUTION = 't_gig_card_delivery_image_1x';
export const DELIVERIES_NEW_RESOLUTION_X2 = 't_gig_card_delivery_image_2x';
export const PROFILE_IMAGE_RESOLUTION = 't_profile_thumb';
export const AGENCY_PROFILE_IMAGE_RESOLUTION = 't_profile_original';

export const ASSETS_SIZES = {
    [MOBILE_SIZE]: {
        src: IMAGES_MOBILE_RESOLUTION,
    },
    [LARGE_SIZE]: {
        src: IMAGES_RESOLUTION,
        srcSet: [IMAGES_RESOLUTION, IMAGES_RESOLUTION_X2],
    },
};
export const PLACEHOLDER = 'PLACEHOLDER';

export const MEDIA_ASSETS_CONFIG = {
    [VIDEO_TYPE]: {
        dom: 'video',
        format: 'mp4',
    },
    [AUDIO_TYPE]: {
        dom: 'audio',
        format: 'mp3',
    },
};
