import { isEmpty } from 'lodash';

export const transformRecurringOptions = ({ gig }) => {
    const { recurring_options: rawRecurringOptions } = gig;
    let recurringOptions;

    if (!isEmpty(rawRecurringOptions)) {
        const recurringDiscountPercentage = Math.min(
            ...rawRecurringOptions.map(({ discount_percentage = 0 }) => discount_percentage)
        );
        if (recurringDiscountPercentage > 0 && recurringDiscountPercentage < 100) {
            recurringOptions = { recurringDiscountPercentage };
        }
    }

    return recurringOptions;
};
