import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trackGigForClickType } from '../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../constants';
import { DIRECTIONS, ARROWS_NAME } from '../Slider/constants';
import './index.scss';

const SliderArrow = ({ direction, onClick, onMouseEnter, onMouseLeave, trackEvent }) => {
    const arrowClassNames = classNames('arrow', { [direction]: true });

    return (
        <span
            className={arrowClassNames}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(direction))}
            onClick={(e) => onClick(e, direction)}
        />
    );
};

SliderArrow.propTypes = {
    direction: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
};

const getArrowMetaFromDirection = (direction) =>
    direction === DIRECTIONS.NEXT ? ARROWS_NAME.RIGHT_ARROW : ARROWS_NAME.LEFT_ARROW;

export const clickedElement = (direction) => ({
    name: ELEMENTS_NAME.GALLERY_NEXT,
    type: ELEMENTS_TYPE.BUTTON,
    metadata: getArrowMetaFromDirection(direction),
});

export default SliderArrow;
