import React, { useState } from 'react';
import { debounce, once } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { LazyComponent } from '@fiverr-private/ui_utils';
import { retryDecorator } from '../../utils/retryDecorator';
import { getResourceCollections } from '../../utils/getResourceCollections';
import { renderSaveToListTrigger } from '../SaveToListButton/SaveToListButton';
import { SaveToListPopoverProps } from './SaveToListPopover';

const ERROR_DEBOUNCE_TIME = 500;

const importSaveToListPopover = once(
    retryDecorator(
        async () => {
            const { SaveToListPopover } = await import(
                /* webpackChunkName: "SaveToListPopover" */ './SaveToListPopover'
            );

            return { default: SaveToListPopover };
        },
        {
            metricName: 'save_to_list_popover',
        }
    )
);

const logLazyLoadError = debounce((error: Error) => {
    logger.warn(error as Error, { description: 'Error while loading SaveToListPopover - (Probably ChunkLoadError)' });
}, ERROR_DEBOUNCE_TIME);

type LazySaveToListPopoverProps = Omit<SaveToListPopoverProps, 'initialIsOpen'>;

export const LazySaveToListPopover = (props: LazySaveToListPopoverProps) => {
    const { renderTrigger = renderSaveToListTrigger, resourceId, resourceType, initialCollections } = props;
    const [initialIsOpen, setInitialIsOpen] = useState(false);
    const inCollections = initialCollections
        ? getResourceCollections({ id: resourceId, type: resourceType }, initialCollections)
        : [];

    const trigger = renderTrigger({ inCollections, isLoading: initialIsOpen });

    const handleError = (error) => {
        logLazyLoadError(error);
    };

    const handleClickPlaceholder = !initialIsOpen ? () => setInitialIsOpen(true) : undefined;

    return (
        <LazyComponent
            lazyImport={importSaveToListPopover}
            onError={handleError}
            componentProps={{
                ...props,
                initialIsOpen,
            }}
            placeholder={<div onClick={handleClickPlaceholder}>{trigger}</div>}
        />
    );
};
