import React from 'react';

export const getTranslationTemplates = (templateKeys) => {
    if (!templateKeys) {
        return;
    }

    const result = {};

    Object.entries(templateKeys).forEach(([key, keyStyle]) => {
        result[key] = (text) => <span className={keyStyle}>{text}</span>;
    });

    return result;
};
