import React from 'react';
import './index.scss';

const LoadingGigCard = () => (
    <div className="loading-card">
        <div className="image-placeholder" />
        <div className="seller-placeholder" />
        <div className="content-placeholder">
            <div />
            <div />
        </div>
        <footer />
    </div>
);

export default LoadingGigCard;
