import React, { useContext } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { AppContext } from '../../../../context/listingsContext';
import { BASE_KEY } from '../constants';

export const useSellersWhoSpeakRecommendationsTexts = (assumedLanguage) => {
    const { isDefaultLocale } = useContext(AppContext);

    const title = <I18n k={`${BASE_KEY}.title`} />;
    const descriptionKey = assumedLanguage ? `description_${assumedLanguage}` : 'description';
    const description = isDefaultLocale ? null : <I18n k={`${BASE_KEY}.${descriptionKey}`} />;

    return {
        title,
        description,
    };
};
