import React from 'react';
import { arrayOf, object, string } from 'prop-types';
import HighlightedRecommendations from '../HighlightedRecommendations';
import { TROPHY_GIF_PATH, SOURCE_COMPONENT } from './constants';
import { useSellersWhoSpeakRecommendationsTexts } from './hooks';
import './styles.scss';

const SellersWhoSpeakRecommendations = ({ listings, gigs = [], assumedLanguage = '', isLoading = false }) => {
    const { title, description } = useSellersWhoSpeakRecommendationsTexts(assumedLanguage);

    const componentNameWithLanguage = assumedLanguage ? `${SOURCE_COMPONENT}_${assumedLanguage}` : SOURCE_COMPONENT;

    return (
        <HighlightedRecommendations
            className="sellers-who-speak-recommendations"
            title={title}
            description={description}
            listings={listings}
            gigs={gigs}
            componentName={componentNameWithLanguage}
            displayISpeak={true}
            shouldSendRecommendations={false}
            iconPath={TROPHY_GIF_PATH}
            isLoading={isLoading}
        />
    );
};

SellersWhoSpeakRecommendations.propTypes = {
    listings: object,
    gigs: arrayOf(object),
    assumedLanguage: string,
};

export { SellersWhoSpeakRecommendations };
