export const DIRECTIONS = {
    PREV: 'prev',
    NEXT: 'next',
};

export const ARROWS_NAME = {
    RIGHT_ARROW: 'right',
    LEFT_ARROW: 'left',
};

export const MODES = {
    SLIDER: 'slider',
    PREVIEW: 'preview',
};

export const INDICATOR_DOTS_NUMBER = 4;

export const DOTS_STATUS = {
    EDGE: 'edge',
    HIDE: 'hide',
};

export const SLIDER_PREVIEW_WIDTH = 'auto';

export const HOVER_EVENT_TYPES = {
    MOUSE_ENTER: 'mouseenter',
    MOUSE_LEAVE: 'mouseleave',
};
