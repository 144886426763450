import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import { useAppContext } from '../../components/context/listingsContext';

export const useInBriefFloatingFormExperiment = (): boolean => {
    const { pageCtxId } = getContext();
    const { clientExperimentsGroups } = useAppContext();

    const experimentGroup = Number(clientExperimentsGroups[EXPERIMENTS.MUSTANGS_BFF_IN_PRO_LISTINGS]);
    const shouldAllocate = experimentGroup > 0;
    const inExperiment = experimentGroup > 1;

    if (shouldAllocate) {
        bqEvent({
            type: 'listings_client_experiment_active',
            group: BI_EVENT_GROUPS.USER_ACTIONS,
            pageCtxId,
            page: {
                name: BI_PAGE_NAMES.LISTINGS,
            },
            payload: {
                ab_test: [
                    {
                        experiment: EXPERIMENTS.MUSTANGS_BFF_IN_PRO_LISTINGS,
                        group: experimentGroup,
                    },
                ],
            },
        });

        allocateToClientExperiment(EXPERIMENTS.MUSTANGS_BFF_IN_PRO_LISTINGS, clientExperimentsGroups);
    }

    return inExperiment;
};
