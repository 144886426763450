import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Image } from '@fiverr-private/media';
import { GradientBadge } from '@fiverr-private/go_shared_ui';
import { Box } from '@fiverr-private/theme';
import { AiModel } from '../../BasicGigCard.types';

import topRightMask from '../../../../../assets/mask_top_left.svg';

interface GradientBadgeProps {
    models: AiModel[];
    toggleBlackComponent: () => void;
}

export const GradientBadgeWrapper: React.FC<GradientBadgeProps> = ({ models, toggleBlackComponent }) => (
    <Stack position="absolute" width={40} height={40} left={0} top={0} zIndex="2">
        <Image position="absolute" src={topRightMask} alt="gradiant wrapper" width={97} height={97} />
        <Box width={40} as="button" onClick={toggleBlackComponent}>
            <GradientBadge colors={models[0].theme.colors} degrees="45" withHover />
        </Box>
    </Stack>
);
