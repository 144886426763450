import React from 'react';
import { FILTER } from '@fiverr-private/listing_lib';
import { getContext } from '@fiverr-private/fiverr_context';
import { NewBadge } from '@fiverr-private/badges';
import Toggle from '../toggle';
import { AIDeliveryToggleProps } from './AIDeliveryToggle.types';

export const AIDeliveryToggle = ({ filter, ...rest }: AIDeliveryToggleProps) => {
    const { isMobile } = getContext();

    if (!filter) return null;

    return (
        <Toggle
            selectedValue={FILTER.AI_DELIVERY.SELECTED_VALUE}
            title="search_perseus.filters.global.ai_delivery.alias"
            badge={isMobile ? null : <NewBadge />}
            shouldBeAIToggle={true}
            {...filter}
            {...rest}
        />
    );
};
