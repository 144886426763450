import React from 'react';
import { array, func, string } from 'prop-types';
import classNames from 'classnames';
import { Base } from '@fiverr-private/modal/src';
import { translate } from '@fiverr-private/i18n-react';
import view_all from '../filter_tile/images/view_all.svg';
import { TILE_TYPES } from '../../tiles_carousel/constants';
import FilterListModal from './modals/filter_list_modal';
import ScListModal from './modals/sc_list_modal';
import { LIST_TYPES } from './constants';

const LIST_TYPE_MAPPER = {
    [LIST_TYPES.SUBCATEGORIES]: ScListModal,
    [LIST_TYPES.FILTERS]: FilterListModal,
};

const ViewAllTile = ({ tileSize, items, onOpen, listType, tileType }) => {
    const ListComponent = LIST_TYPE_MAPPER[listType];
    const isChipTile = tileType === TILE_TYPES.CHIP_TILE;

    const alias = translate('search_perseus.filter_tiles.view_all');

    return (
        <Base
            name="search_perseus listings-perseus list-modal"
            onOpen={onOpen}
            trigger={({ open }) => (
                <button className={classNames('view-all-tile', tileType, tileSize)} onClick={() => open()}>
                    <div
                        className={classNames({
                            'chip-tile-icon-wrapper': isChipTile,
                        })}
                    >
                        <img src={view_all} alt={alias} />
                    </div>
                    <span
                        className={classNames({
                            'chip-tile-text': isChipTile,
                        })}
                    >
                        {alias}
                    </span>
                </button>
            )}
        >
            {({ close }) => <ListComponent close={close} items={items} />}
        </Base>
    );
};

ViewAllTile.propTypes = {
    tileSize: string,
    items: array,
    onOpen: func,
    listType: string,
    tileType: string.isRequired,
};

export default ViewAllTile;
