import React from 'react';
import classnames from 'classnames';
import { Section } from '@fiverr-private/fit';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import { BriefFloatingFormTrigger, ListingFlow } from '@fiverr-private/dm_brief_creation';
import { useInBriefFloatingFormExperiment } from '../../../../hooks/useInBriefFloatingFormExperiment';
import { LISTINGS_PAGE } from '../../../../constants';
import { useAppContext } from '../../../context/listingsContext';
import AssetsVerifier from '../../AssetsVerifier';
import { ProOrientedSearchModal } from '../../ProOrientedSearchModal';
import Header from '../Header';
import { useBusinessListingsContext } from '../Context';

import styles from './index.module.scss';

const BusinessLayout = ({ children }) => {
    const { flow, categoryIds, query, listingQuery, significantLeafCategories, dominateSubCategoryId, currency } =
        useAppContext();
    const inBffExperiment = useInBriefFloatingFormExperiment();

    const { rollouts, resultsCount } = useBusinessListingsContext();
    const inMustangsVerifyAssetsRollout = rollouts[ROLLOUTS.MUSTANGS_VERIFY_ASSETS];

    const shouldShowBff = inBffExperiment && resultsCount > 0 && Object.values(ListingFlow).includes(flow);
    const leafCategory = categoryIds.nestedSubCategoryId || categoryIds.subCategoryId;
    const dlc = significantLeafCategories?.[0] || dominateSubCategoryId;

    return (
        <Section className={classnames(styles.main, 'listings-perseus')}>
            <Header />
            <div className="flex flex-items-start">{children}</div>
            {inMustangsVerifyAssetsRollout && <AssetsVerifier />}
            <ProOrientedSearchModal />
            {shouldShowBff && (
                <BriefFloatingFormTrigger
                    listingFlow={flow}
                    leafCategory={Number(leafCategory)}
                    searchQuery={query || listingQuery}
                    dlc={Number(dlc)}
                    source={LISTINGS_PAGE}
                    currency={currency}
                />
            )}
        </Section>
    );
};

export default BusinessLayout;
