import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { FilterIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import { Button } from '@fiverr-private/button';
import { Breadcrumb, BreadcrumbsLink, BreadcrumbsHomeIcon } from '@fiverr-private/navigation';
import ListingsToggle from '../../ListingsToggle';
import { useBusinessListingsContext } from '../Context';
import FiltersDrawer from '../FiltersDrawer';
import { countActiveFilters } from '../FiltersUtils';
import styles from './index.module.scss';

const T_PREFIX = 'search_perseus.business_listings.title';

const FiltersButton = ({ activeFiltersCount }) => {
    const { isTouch } = getContext();

    return (
        <>
            <Button
                className={classnames(styles.filtersButton, 'm-r-24')}
                intent="secondary"
                variant="outline"
                size="lg"
            >
                <FilterIcon color={grey_1200} />
                <I18n k={'search_perseus.filter.filter'} />
                {activeFiltersCount > 0 && ` (${activeFiltersCount})`}
            </Button>
            <div className={classnames(styles.filtersButtonMobile, 'm-r-8', { [styles.enlargedHitArea]: isTouch })}>
                <FilterIcon color={grey_1200} size={20} />
                {activeFiltersCount > 0 && (
                    <span className="co-text-darkest text-bold m-l-8">{`(${activeFiltersCount})`}</span>
                )}
            </div>
        </>
    );
};

const Title = ({ activeFilters, isExpertListings, resultsCount }) => {
    const hasActiveFilters = !isEmpty(activeFilters);

    if (!hasActiveFilters && resultsCount === 0) {
        return null;
    }

    return (
        <div className={classnames(styles.title, 'tbody-4 co-text-darkest')}>
            {isExpertListings ? <I18n k={`${T_PREFIX}.experts`} /> : <I18n k={`${T_PREFIX}.services`} />}
        </div>
    );
};

const Header = () => {
    const { isExpertListings, isSearch, breadcrumbs, activeFilters, resultsCount } = useBusinessListingsContext();

    const activeFiltersCount = countActiveFilters(activeFilters);

    const [home, ...steps] = breadcrumbs || [];

    return (
        <>
            {!isSearch && breadcrumbs && (
                <Breadcrumb>
                    <BreadcrumbsLink href={home.url}>
                        <BreadcrumbsHomeIcon />
                    </BreadcrumbsLink>
                    {steps.slice(0, -1).map((breadcrumb) => (
                        <BreadcrumbsLink key={breadcrumb.url} href={breadcrumb.url}>
                            {breadcrumb.title}
                        </BreadcrumbsLink>
                    ))}
                </Breadcrumb>
            )}
            <div className={classnames(styles.mainHeaderContent, 'flex flex-items-center')}>
                <FiltersDrawer>
                    <FiltersButton activeFiltersCount={activeFiltersCount} />
                </FiltersDrawer>
                <div className={styles.toggle}>
                    <ListingsToggle isExpertListings={isExpertListings} />
                </div>
                <Title activeFilters={activeFilters} isExpertListings={isExpertListings} resultsCount={resultsCount} />
            </div>
        </>
    );
};

export default Header;
