import React from 'react';
import { IconButton } from '@fiverr-private/button';
import { HeartIcon, HeartSolidIcon } from '@fiverr-private/icons';

/**
 * Retrieves the collect wrapper props.
 * @param {Object} props - The props for the collect wrapper.
 * @param {string} props.id - The ID of the gig.
 * @param {Object} props.collectProps - The collect props object.
 * @returns {Object} - An object containing the collect wrapper props.
 * @property {string} id - The ID of the gig.
 * @property {Object} collectProps - The collect props object.
 * @property {ReactNode} collectedIcon - The icon for the collected state.
 * @property {ReactNode} notCollectedIcon - The icon for the not collected state.
 */
export const getCollectWrapperProps = (props) => {
    const { id, collectProps = {}, triggerData = {} } = props;

    const collectedIcon = (
        <IconButton aria-label="Saved" intent="secondary" variant="outline">
            <HeartSolidIcon color="currentColor" />
        </IconButton>
    );
    const notCollectedIcon = (
        <IconButton aria-label="Save" intent="secondary" variant="outline">
            <HeartIcon color="currentColor" />
        </IconButton>
    );

    return { id, collectProps, collectedIcon, notCollectedIcon, triggerData };
};
