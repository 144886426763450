import React, { Fragment, useContext, useMemo, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Container, Stack } from '@fiverr-private/layout_components';
import { LISTING_SOURCES } from '@fiverr-private/logo_maker_marketplace';
import { EmptySearchResults, NoResultsClearFilters, ProFilteredListingsTitle } from '@fiverr-private/listing_results';
import { Pagination } from '@fiverr-private/listing_pagination';
import { SearchHeader } from '@fiverr-private/listings_header';
import { DEFAULT_LOCALE } from '../../../../shared/constants';
import { SearchPropTypes } from '../../types/index';
import LogoMakerBanner from '../common/logoMakerBanner';
import { DynamicGigListings, MobileFilters, RecommendationsCarousels, SortBy } from '../common';
import { NumberOfResults } from '../common/NumberOfResults/NumberOfResults';
import { TopBarFilters } from '../common/floating_topbar/TopBarFilters';
import StickyComponent from '../hoc/stickyComponent';
import { FiProEntryBanner } from '../common/banners/FipProEntryBanner';
import MachineTranslationButtonWrapper from '../common/machine_translation_button_wrapper';
import { MINIMUM_TILES_LENGTH } from '../common/tiles_carousel/constants';
import LeafCategoriesCarousel from '../common/LeafCategoriesCarousel';
import TopFiltersCarousel from '../common/top_filters_carousel';
import RepeatedBuyersRecommendations from '../common/recommendations/RepeatedBuyers';
import FtbFriendlyRecommendations from '../common/recommendations/FtbFriendly';
import { ExpressDeliveryBanner } from '../common/recommendations/ExpressDelivery';
import { SellersWhoSpeakRecommendations } from '../common/recommendations/SellersWhoSpeak';
import { isSupportedFiverrLanguage } from '../common/recommendations/SellersWhoSpeak/utils';
import { AppContext } from '../context/listingsContext';
import { getLeafCategoryTilesData } from '../common/LeafCategoriesCarousel/utils';
import { LISTINGS_TYPES, MAX_NON_HEAD_QUERY_LENGTH, TRACKING_NAMES } from '../../constants';
import ExplicitLanguagePair from '../common/ExplicitLanguagePair';
import { shouldShowExplicitLanguageFilter } from '../common/ExplicitLanguagePair/utils';
import { getFilterTagsProps } from '../generic_listings/TagFilters/utils';
import PromoteBanner from '../common/promoteBanner';
import LogoMakerFork from '../common/LogoMakerFork';
import { useReportEligibility } from '../../utils/reportEligibility';
import { FLOWS } from '../../utils/constants';
import {
    useCountFlowView,
    usePersonalizationData,
    usePromotedVideoStripListingRowObserver,
    useAllocateAgenciesInListingsGDRowObserver,
} from '../../hooks';
import PricingFactorDropdown from '../common/floating_topbar/menus/content/pricing_factor_dropdown';
import { ActiveFilters } from '../common/ActiveFilters/ActiveFilters';
import { ExposedFilters } from '../common/side_filters/ExposedFilters/ExposedFilters';
import Modalities from '../common/modalities/Modalities';
import { ListingsImpressionContainer } from '../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { sendBigQueryReport } from '../../../../shared/utils/sendBigQueryReport/sendBigQueryReport';
import { HourlyRateBannerResolverWithWrapper } from '../common/banners/HourlyRateBanners';
import useAllocateAdsPlacementsRowObserver from '../../hooks/useAllocateAdsPlacementsRowObserver';
import { checkStringWordsLessThan } from './related_search/utils';
import SearchResultsCounter from './search_results_counter';
import RelatedSearch from './related_search';

const Search = (props) => {
    const [showLMFlow, setShowLMFlow] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {
        isTouch,
        listings: [listings],
        dominateSubCategoryId,
        appFilters,
        activeFilters = {},
        significantLeafCategories = [],
        source,
        displayQuery,
        logoMakerConfig = {},
        shouldShowHourlyRateBanners,
        shouldShowExpressDelivery,
        shouldShowProEntryBanner,
        shouldShowPromoteBanner,
        assumedLanguage,
        inFetchPersonalizationContentRollout,
        relatedSearches,
        flow,
        inOwlsSideFiltersTest,
        isLoadingListings,
        rollouts,
        listingAttributes,
        pagination,
        displayData,
        currency,
    } = useContext(AppContext);

    const { sorting, topBarSorting, loggedIn, flowName } = props;

    const {
        gigs,
        choiceModalities,
        recommendations,
        sellersWhoSpeak,
        showListViewCards,
        context: { categoryIds, sessionLocale, sourceComponent, sourcePage },
        isHeadQuery,
    } = listings;

    const {
        selectedFilters,
        filters,
        visualFilters,
        subCategory = {},
        selectedFiltersHierarchy,
        shownExposedFilter,
    } = appFilters;
    const { children: leafCategories = [] } = subCategory;
    const noGigs = !gigs.length > 0;
    const isEmptySearch = isEmpty(activeFilters) && noGigs;
    const hasFilters = filters?.length > 0;
    const selectedSorting = topBarSorting.find((sort) => sort.selected) || topBarSorting[0];
    const leafCategoryTiles = getLeafCategoryTilesData(significantLeafCategories, leafCategories);
    const hasMinimumScTiles = leafCategoryTiles.length >= MINIMUM_TILES_LENGTH;
    const shouldShowPagination = !noGigs;
    const shouldShowModalities = !isEmpty(choiceModalities) && !isEmpty(choiceModalities.buckets);
    const shouldShowRepeatedBuyers = !isEmpty(recommendations) && !isEmpty(recommendations.repeatedBuyers);
    const shouldShowSellersWhoSpeak = !isEmpty(sellersWhoSpeak) && !!isSupportedFiverrLanguage(assumedLanguage);
    const shouldShowFtbFriendly = !isEmpty(recommendations) && !isEmpty(recommendations.ftbFriendly);
    const shouldShowExposedFilters = inOwlsSideFiltersTest && !isTouch;
    const shouldShowNumberOfResults = !inOwlsSideFiltersTest;
    const shouldShowSorter = !inOwlsSideFiltersTest;

    const {
        searchComponentData: { modifiedQueryString, nonTranslatedQuery, searchInsteadUrl, reasonForModification },
    } = displayData;
    const userIsInSearchResultArea = modifiedQueryString || nonTranslatedQuery;

    let selectedScName = subCategory ? subCategory.alias : '';

    if (subCategory && !subCategory.selected) {
        const [item] = leafCategories.filter((item) => item.selected);
        selectedScName = item ? item.alias : selectedScName;
    }

    const displayTranslationButton = sessionLocale !== DEFAULT_LOCALE;
    const listingsType = LISTINGS_TYPES.SEARCH;

    const logoMakerProps = {
        source,
        showListViewCards,
        page: TRACKING_NAMES.SEARCH_PAGE,
        listingsType,
        query: displayQuery,
        sourcePage,
        sourceComponent,
        logoMakerConfig,
    };

    const filterTagsProps = useMemo(
        () =>
            getFilterTagsProps({
                isSearch: true,
                displayQuery,
                dominateSubCategoryId,
                significantLeafCategories,
                appFilters,
                categoryIds,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const shouldRelatedSearchDisplayed =
        isHeadQuery || checkStringWordsLessThan(displayQuery, MAX_NON_HEAD_QUERY_LENGTH);
    const shouldShowVisualFilters = visualFilters?.shouldDisplayVisualFilters;
    const shouldShowScCarousel =
        !shouldShowVisualFilters && !shouldShowModalities && hasMinimumScTiles && !dominateSubCategoryId;
    const shouldShowRelatedSearch =
        shouldRelatedSearchDisplayed &&
        !shouldShowScCarousel &&
        !shouldShowVisualFilters &&
        sessionLocale === DEFAULT_LOCALE &&
        !isEmpty(relatedSearches);
    const shouldShowLogoMakerBanner = (() => {
        const { banner: { showBannerInSearch } = {} } = logoMakerConfig || {};

        return showBannerInSearch && !shouldShowExpressDelivery;
    })();

    const { personalizedContent } = usePersonalizationData({
        flow,
        shouldRun: inFetchPersonalizationContentRollout && loggedIn,
    });

    useReportEligibility({ visualFilters, filterTagsProps, isSearch: true, shownExposedFilter });
    useCountFlowView(FLOWS.SEARCH);
    const promotedVideoStripListingRowObserver = usePromotedVideoStripListingRowObserver();
    const agenciesInListingsGDRowObserver = useAllocateAgenciesInListingsGDRowObserver();
    const adsPlacementRowObserver = useAllocateAdsPlacementsRowObserver();

    const banners = [];
    shouldShowHourlyRateBanners && banners.push(<HourlyRateBannerResolverWithWrapper key="HourlyRateBannerResolver" />);
    shouldShowLogoMakerBanner && banners.push(<LogoMakerBanner {...logoMakerProps} />);
    shouldShowExpressDelivery && banners.push(<ExpressDeliveryBanner />);

    useEffect(() => {
        sendBigQueryReport('listing_search_page');
    }, []);

    const listingKey = useMemo(() => JSON.stringify(listingAttributes), [listingAttributes]);

    return (
        <Stack
            position="relative"
            marginX="auto"
            gap="0"
            className={classNames('search-page', {
                'top-filters': !isTouch,
                'with-logo-maker': showLMFlow,
            })}
        >
            <Container
                flex="1"
                backgroundColor="white"
                borderRadius="lg"
                minWidth="0"
                className={classNames('listing-layout')}
            >
                {!isEmptySearch ? (
                    <>
                        {isTouch && userIsInSearchResultArea && (
                            <SearchResultsCounter paginationData={pagination} displayData={displayData} />
                        )}

                        {shouldShowRelatedSearch && <RelatedSearch />}

                        {!isTouch && (
                            <Container className="layout-row" marginTop="8">
                                <SearchHeader
                                    query={listingAttributes.query}
                                    modifiedQueryString={modifiedQueryString}
                                    searchInsteadUrl={searchInsteadUrl}
                                    nonTranslatedQuery={nonTranslatedQuery}
                                    reasonForModification={reasonForModification}
                                />
                            </Container>
                        )}

                        <LogoMakerFork
                            listingsType={listingsType}
                            listingSource={LISTING_SOURCES.SEARCH}
                            searchQuery={displayQuery}
                            showLMFlow={showLMFlow}
                            setShowLMFlow={setShowLMFlow}
                        />

                        {!showLMFlow && (
                            <>
                                {shouldShowExposedFilters ? (
                                    <div className="layout-row">
                                        <ExposedFilters />
                                    </div>
                                ) : (
                                    <Fragment>
                                        {!isTouch && (
                                            <>
                                                {shouldShowVisualFilters && (
                                                    <div className="layout-row">
                                                        <TopFiltersCarousel
                                                            visualFilters={visualFilters}
                                                            subCategoryId={dominateSubCategoryId}
                                                        />
                                                    </div>
                                                )}
                                                {shouldShowScCarousel && (
                                                    <div className="layout-row">
                                                        <LeafCategoriesCarousel leafCategoryTiles={leafCategoryTiles} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {shouldShowExplicitLanguageFilter({ isTouch, displayQuery }) && (
                                            <div className="layout-row">
                                                <ExplicitLanguagePair filters={filters} />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                                {!isTouch && (
                                    <>
                                        {hasFilters && (
                                            <TopBarFilters
                                                filters={filters}
                                                selectedFilters={selectedFilters}
                                                activeFilters={activeFilters}
                                                selectedFiltersHierarchy={selectedFiltersHierarchy}
                                                subCategory={subCategory}
                                                noGigs={noGigs}
                                                fromSearchFlow={true}
                                                isDrawerOpen={isDrawerOpen}
                                                setIsDrawerOpen={setIsDrawerOpen}
                                                topBarSorting={topBarSorting}
                                                selectedSorting={selectedSorting}
                                                numOfResult={pagination?.total}
                                                rollouts={rollouts}
                                                variant="base"
                                            />
                                        )}

                                        <ActiveFilters
                                            setIsDrawerOpen={setIsDrawerOpen}
                                            selectedScName={selectedScName}
                                        />

                                        {!noGigs && (
                                            <div className="layout-row flex-items-end flex-between">
                                                <div className="flex flex-col">
                                                    {displayTranslationButton && (
                                                        <MachineTranslationButtonWrapper
                                                            gigsListings={[listings.gigs]}
                                                        />
                                                    )}
                                                    <div className="flex flex-items-center flex-wrap">
                                                        {shouldShowNumberOfResults && (
                                                            <NumberOfResults resultsCount={pagination?.total} />
                                                        )}
                                                        <PricingFactorDropdown
                                                            showSeparator={shouldShowNumberOfResults}
                                                        />
                                                    </div>
                                                </div>

                                                {shouldShowSorter && (
                                                    <SortBy title={selectedSorting.alias} data={topBarSorting} />
                                                )}
                                            </div>
                                        )}
                                        {shouldShowPromoteBanner && (
                                            <div className="layout-row content-row">
                                                {' '}
                                                <PromoteBanner />{' '}
                                            </div>
                                        )}
                                    </>
                                )}

                                {isTouch && hasFilters && (
                                    <>
                                        <StickyComponent>
                                            <MobileFilters
                                                activeFilters={activeFilters}
                                                sorts={topBarSorting}
                                                filters={filters}
                                                flowName={flowName}
                                            />
                                        </StickyComponent>
                                        {displayTranslationButton && (
                                            <MachineTranslationButtonWrapper
                                                gigsListings={[listings.gigs]}
                                                showSeparator={false}
                                            />
                                        )}
                                    </>
                                )}
                                <ListingsImpressionContainer
                                    rowObservers={[
                                        promotedVideoStripListingRowObserver,
                                        agenciesInListingsGDRowObserver,
                                        adsPlacementRowObserver,
                                    ]}
                                    key={listingKey}
                                >
                                    {shouldShowModalities && (
                                        <div className="layout-row modalities-row">
                                            <Modalities />
                                        </div>
                                    )}
                                    {shouldShowRepeatedBuyers && (
                                        <div className="layout-row repeated-buyers-recommendations-row">
                                            <RepeatedBuyersRecommendations
                                                listings={listings}
                                                gigs={recommendations.repeatedBuyers}
                                                isLoading={isLoadingListings}
                                            />
                                        </div>
                                    )}
                                    {shouldShowSellersWhoSpeak && (
                                        <div className="layout-row sellers-who-speak-recommendations-row">
                                            <SellersWhoSpeakRecommendations
                                                listings={listings}
                                                assumedLanguage={assumedLanguage}
                                                gigs={sellersWhoSpeak}
                                                isLoading={isLoadingListings}
                                            />
                                        </div>
                                    )}
                                    {shouldShowFtbFriendly && (
                                        <div className="layout-row ftb-friendly-recommendations-row">
                                            <FtbFriendlyRecommendations
                                                listings={listings}
                                                gigs={recommendations.ftbFriendly}
                                                isLoading={isLoadingListings}
                                            />
                                        </div>
                                    )}
                                    <div className="layout-row content-row">
                                        <ProFilteredListingsTitle
                                            displayData={displayData}
                                            categoryIds={categoryIds}
                                            activeFilters={activeFilters}
                                        />
                                        {shouldShowProEntryBanner && <FiProEntryBanner />}
                                        {noGigs ? (
                                            <NoResultsClearFilters
                                                displayData={displayData}
                                                listingAttributes={listingAttributes}
                                                flowName={'gigSearchFlow'}
                                            />
                                        ) : (
                                            <div className="content">
                                                <DynamicGigListings
                                                    isLoading={isLoadingListings}
                                                    listings={listings}
                                                    banners={banners}
                                                />
                                            </div>
                                        )}

                                        {shouldShowPagination && (
                                            <Container
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                paddingY={{ default: '6', sm: '12' }}
                                            >
                                                <Pagination
                                                    pagination={pagination}
                                                    listingAttributes={listingAttributes}
                                                    flowName={flowName}
                                                />
                                            </Container>
                                        )}
                                    </div>
                                </ListingsImpressionContainer>
                            </>
                        )}
                    </>
                ) : (
                    <EmptySearchResults currency={currency} rollouts={rollouts} />
                )}
                {!isTouch && loggedIn && (
                    <RecommendationsCarousels
                        sorting={sorting}
                        activeFilters={activeFilters}
                        loggedIn={loggedIn}
                        shouldFetchData={!inFetchPersonalizationContentRollout}
                        personalizedContent={personalizedContent}
                    />
                )}
            </Container>
        </Stack>
    );
};

Search.propTypes = SearchPropTypes;

export default Search;
