import { useState, useEffect } from 'react';
import { reportNotableClientsHidden } from '@fiverr-private/seller_presence';

export const DISPLAY_BREAK_POINT = {
    FULL: 'full',
    NO_LABEL: 'no_label',
    SINGLE: 'single',
    NONE: 'none',
};

export const BREAK_POINTS = [
    { id: DISPLAY_BREAK_POINT.FULL, width: 309 },
    { id: DISPLAY_BREAK_POINT.SINGLE, width: 280 },
    { id: DISPLAY_BREAK_POINT.NO_LABEL, width: 250 },
];

const breakPointByWidth = (cardWidth = 0) => {
    for (let i = 0; i < BREAK_POINTS.length; i++) {
        if (cardWidth > BREAK_POINTS[i].width) {
            return BREAK_POINTS[i].id;
        }
    }
    return DISPLAY_BREAK_POINT.NONE;
};

const messageByPoint = (point) => {
    if (point === DISPLAY_BREAK_POINT.FULL) {
        return null;
    }
    if (point === DISPLAY_BREAK_POINT.NONE) {
        return 'notable_clients_fully_hidden';
    }

    return 'notable_clients_only_label_hidden';
};

const useNotableClientsDisplay = (currentContainer, currentPage) => {
    const [point, setPoint] = useState(DISPLAY_BREAK_POINT.NONE);
    const [hideEventSent, setHideEventSent] = useState(false);

    useEffect(() => {
        if (!currentContainer) {
            return;
        }
        const resizeHandler = () => {
            const bp = breakPointByWidth(currentContainer?.clientWidth);

            setPoint(bp);

            return bp;
        };

        window.addEventListener('resize', resizeHandler);

        const selectedBP = resizeHandler();
        const metadata = messageByPoint(selectedBP);

        if (!hideEventSent) {
            if (metadata) {
                reportNotableClientsHidden({ origin: currentPage, metadata });
            }
            setHideEventSent(true);
        }

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContainer]);

    return point;
};

export default useNotableClientsDisplay;
