import React from 'react';
import { node } from 'prop-types';

const DefaultWrapper = ({ children }) => <>{children}</>;

DefaultWrapper.propTypes = {
    children: node,
};

export default DefaultWrapper;
