import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { LinkCard, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { translate } from '@fiverr-private/i18n-react';
import { Button, IconButton, GoArrowRightIcon, ModelGradient, GoTimesIcon } from '@fiverr-private/go_shared_ui';
import { SellerAvatar } from '../../../Components/SellerAvatar';
import { MAP_SELLER_LEVEL_TO_BADGE } from '../../../../../GigCardListings/utils/gigBadges/constants';
import { createClickedElement, EVENT_TYPES } from '../../../../utils/trackGig';
import { BasicAiGigCardProps } from './BasicAiGigCard.types';

import { blackOverlay, fadeInOverlay, sellerDetails } from './BasicAiGigCard.ve.css';

const BasicAiGigCard: React.FC<BasicAiGigCardProps> = ({
    sellerInfoProps,
    models,
    trackEvent,
    isBlackComponentOpen,
    setBlackComponentOpen,
}) => {
    if (isEmpty(models) || !isBlackComponentOpen) {
        return null;
    }

    const { isMobile } = getContext();

    const model = models[0];
    const LevelBadge = MAP_SELLER_LEVEL_TO_BADGE[sellerInfoProps?.level];

    const buttonText = translate('gig_listings.basic_ai_card.start_generating');
    const sellerName = translate('gig_listings.basic_ai_card.by', { params: { name: sellerInfoProps.displayName } });

    const url = pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
        seller_username: sellerInfoProps?.name || '',
        model_slug: model?.id || '',
    });

    const sendBiClickEvent = () => {
        const elementName = 'ai_model_start_generating';
        const clickedElement = createClickedElement(elementName);
        trackEvent(EVENT_TYPES.GIG_CARD_CLICK, clickedElement);
    };

    return (
        <Stack
            className={classNames(blackOverlay, { 'open-overlay': isBlackComponentOpen })}
            backgroundColor="black"
            position="absolute"
            top={0}
            left={0}
            zIndex="2"
            direction="column"
            borderRadius="2xl"
            gap="0"
        >
            <Stack className={classNames(sellerDetails)} direction="column" gap="2">
                {isMobile && (
                    <IconButton
                        position="absolute"
                        top={8}
                        right={8}
                        shape="square"
                        variant="secondary"
                        onClick={() => setBlackComponentOpen(false)}
                    >
                        <GoTimesIcon color="white" size={20} />
                    </IconButton>
                )}
                <Text color="white" fontWeight="semibold">
                    {model.name}
                </Text>
                <Stack direction="row" gap="2" alignItems="center" marginBottom="2">
                    <SellerAvatar
                        imgSrc={sellerInfoProps.imgSrc}
                        displayName={sellerInfoProps.displayName}
                        username={sellerInfoProps.username}
                        isOnline={sellerInfoProps.isOnline}
                        type={sellerInfoProps.type}
                    />
                    <Text size="b_sm" fontWeight="semibold" color="white">
                        {sellerName}
                    </Text>
                    {!!LevelBadge && <LevelBadge />}
                </Stack>
            </Stack>
            <LinkCard position="relative" height="100%" borderColor="transparent" href={url}>
                <ModelGradient {...model.theme} animate="hover" rotate={0} scale="s" />
                <Button
                    className={classNames(fadeInOverlay)}
                    position="absolute"
                    top="50%"
                    left="50%"
                    variant="primary"
                    onClick={() => sendBiClickEvent()}
                    style={{
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {buttonText}
                    <GoArrowRightIcon />
                </Button>
            </LinkCard>
        </Stack>
    );
};

export default BasicAiGigCard;
