export const ALLOCATIONS = {
    CONTROL: '1',
    TEST_B: '2',
    TEST_C: '3',
    TEST_D: '4',
};

export const EXPERIMENTS = {
    CAT_LISTINGS_PRICE_BUCKETS: 3456,
    CARIBOU_RATING_SCORE_UPDATES: 4268,
    HAWKS_OVERLAY_BADGES: 4462,
    AGENCIES_IN_LISTINGS_V2_PT: 4480,
};

export const ROLLOUTS = {
    QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION: 'qualas_new_level_system_consumers_migration',
};
