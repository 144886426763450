import React from 'react';
import { useComplianceContext } from '@fiverr-private/compliance';
import SellerBadge from '../../../SellerBadge';
import { useExpertCardContext } from '../../../../contexts';
import SellerRating from '../SellerRating';

export const BadgeAndRating = () => {
    const { seller } = useExpertCardContext();
    const { badges, sellerLevel, id, username } = seller;
    // @TODO change it when badge is SSR
    let showComplianceBadge = false;

    const complianceContext = useComplianceContext();
    if (complianceContext) {
        const sellerComplianceState = complianceContext.getSellerComplianceState(id);

        if (!sellerComplianceState.hasComplianceProcess || sellerComplianceState.compliaceRank === 0) {
            showComplianceBadge = false;
        } else {
            showComplianceBadge = true;
        }
    }

    return (
        <>
            <SellerBadge
                badges={badges}
                sellerLevel={sellerLevel}
                sellerId={id}
                sellerUserName={username}
                showComplianceBadge={showComplianceBadge}
            />
            <SellerRating />
        </>
    );
};
