const MULTI = 'multi';
const SINGLE = 'single';
const PAIR = 'pair';

const QUAILTY_TRANSLATIONS = {
    ID: '108',
    SLUG: 'quality-translation-services',
};

const DISPLAY_TYPES = {
    PRO: 'pro',
    RADIO: 'radio',
    INPUT_RANGE: 'input_range',
    CHECKBOX: 'checkbox',
    TOGGLE: 'toggle',
    TOGGLE_DETAILED: 'toggle_detailed',
    CHECKBOX_GROUP: 'checkbox_group',
    SELECT: 'select',
    MULTI_PAIRS_SELECT: 'multi_pairs_select',
    AUTO_COMPLETE: 'autocomplete',
    NUMBER_INPUT: 'number_input',
    PRICE_BUCKETS: 'price_buckets',
};

const TAG_TYPES = {
    NEW: 'new',
};

export const FILTER = {
    PRO: {
        ID: 'pro',
        SORT: 0,
        FILTER_GROUP_ORDER: 3,
        SELECTOR: SINGLE,
        DISPLAY_TYPE: DISPLAY_TYPES.PRO,
        SELECTED_VALUE: 'any',
        SELECTED_VALUE_ONLY: 'true',
        COOKIE_NAME: 'is_pro_toggled',
        HIDE_IN_BUSINESS_MOBILE: true,
    },
    AI_DELIVERY: {
        ID: 'ai_delivery',
        SORT: 1,
        SELECTOR: SINGLE,
        DISPLAY_TYPE: DISPLAY_TYPES.TOGGLE,
        SELECTED_VALUE: 'any',
        SELECTED_VALUE_ONLY: 'true',
    },
    HAS_HOURLY: {
        ID: 'has_hourly',
        SORT: 2,
        SELECTOR: SINGLE,
        DISPLAY_TYPE: DISPLAY_TYPES.TOGGLE_DETAILED,
        TAGS: {
            true: TAG_TYPES.NEW,
        },
        FILTER_GROUP_ORDER: 2,
    },
    DELIVERY_TIME: {
        ID: 'delivery_time',
        SORT: 2,
        FILTER_GROUP_ORDER: 0,
        DISPLAY_TYPE: DISPLAY_TYPES.RADIO,
        SELECTOR: SINGLE,
        DEFAULT_FILTER: {
            min: 0,
            max: 10,
            count: 0,
        },
        DEFAULT_IDS: ['1', '3', '7', ''],
        IS_PRICE_RELATED: true,
    },
    /** Profession Based Filter */
    PROFESSION: {
        ID: 'profession',
        FILTER_GROUP_ORDER: 1,
        SELECTOR: MULTI,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX_GROUP,
        MAX_VISIBLE_OPTIONS: 10,
    },
    /** Profession Based Filter */
    PROFICIENCIES: {
        ID: 'proficiencies',
        FILTER_GROUP_ORDER: 2,
        SELECTOR: MULTI,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX_GROUP,
    },
    /** Profession Based Filter */
    OFFERED_SERVICE: {
        ID: 'offered_service',
        FILTER_GROUP_ORDER: 3,
        SELECTOR: MULTI,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX_GROUP,
    },
    GIG_PRICE_RANGE: {
        ID: 'gig_price_range',
        DISPLAY_TYPE: DISPLAY_TYPES.INPUT_RANGE,
        OPTION_ID: 'gig_price_range_option',
        SORT: 4,
        FILTER_GROUP_ORDER: 1,
        SELECTOR: SINGLE,
        DEFAULTS: {
            MIN: 0,
            MAX: 50000,
        },
        IS_PRICE_RELATED: true,
    },
    PRICE_BUCKETS: {
        ID: 'price_buckets',
        SELECTOR: SINGLE,
        DISPLAY_TYPE: DISPLAY_TYPES.NUMBER_INPUT,
        IS_PRICE_RELATED: true,
    },
    IS_SELLER_ONLINE: {
        ID: 'is_seller_online',
        BUSINESS_ID: 'is_freelancer_online',
        SORT: 11,
        FILTER_GROUP_ORDER: 4,
        SELECTOR: SINGLE,
        DISPLAY_TYPE: 'checkbox',
    },
    SERVICE_OFFERINGS: {
        ID: 'service_offerings',
        SELECTOR: MULTI,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX_GROUP,
        DEFAULT_IDS: ['subscription', 'offer_consultation'],
        SUBSCRIPTION: 'subscription',
        OFFER_CONSULTATION: 'offer_consultation',
        HIDE_IN_BUSINESS_MOBILE: true,
    },
    CUSTOM_FILTER: {
        ID: 'customFilter',
        SORT: 5,
        SELECTOR: MULTI,
    },
    PACKAGE_INCLUDES: {
        ID: 'package_includes',
        SORT: 6,
        SELECTOR: MULTI,
        IS_PRICE_RELATED: true,
    },
    IS_AGENCY: {
        ID: 'is_agency',
        SORT: 13,
        SELECTOR: SINGLE,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX,
        FILTER_GROUP_ORDER: 3,
        TAGS: {
            true: TAG_TYPES.NEW,
        },
    },
    SELLER_LEVEL: {
        ID: 'seller_level',
        BUSINESS_ID: 'freelancer_level',
        SORT: 7,
        FILTER_GROUP_ORDER: 2,
        SELECTOR: MULTI,
        DEFAULT_IDS: ['top_rated_seller', 'level_two_seller', 'level_one_seller', 'na'],
    },
    SELLER_TIER: {
        ID: 'seller_tier',
        SORT: 7,
        FILTER_GROUP_ORDER: 1,
        SELECTOR: MULTI,
        DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX,
        DEFAULT_IDS: ['pro', 'top_rated_seller', 'level_two_seller', 'level_one_seller', 'na'],
    },
    SELLER_LANGUAGE: {
        ID: 'seller_language',
        BUSINESS_ID: 'freelancer_language',
        SORT: 8,
        SELECTOR: MULTI,
        WHITELIST: {
            any: true,
            en: true,
            es: true,
            fr: true,
            zh: true,
            de: true,
            ar: true,
            bn: true,
            my: true,
            nl: true,
            gu: true,
            he: true,
            hi: true,
            it: true,
            jp: true,
            jw: true,
            kn: true,
            kr: true,
            ml: true,
            mr: true,
            or: true,
            pa: true,
            fa: true,
            pl: true,
            'pt-BR': true,
            pt: true,
            ru: true,
            ta: true,
            te: true,
            th: true,
            tr: true,
            uk: true,
            ur: true,
            vi: true,
        },
    },
    SELLER_LOCATION: {
        ID: 'seller_location',
        COOKIE_NAME: 'seller_location_filter',
        BUSINESS_ID: 'freelancer_location',
        SORT: 12,
        SELECTOR: MULTI,
        HIDE_IN_BUSINESS_MOBILE: true,
        FIRST_VIEW: {
            1: [],
            2: ['US'],
            3: ['GB'],
            4: ['CA'],
        },
        WHITELIST: {
            AD: true,
            AE: true,
            AF: true,
            AG: true,
            AI: true,
            AL: true,
            AM: true,
            AN: true,
            AO: true,
            AQ: true,
            AR: true,
            AS: true,
            AT: true,
            AU: true,
            AW: true,
            AX: true,
            AZ: true,
            BA: true,
            BB: true,
            BD: true,
            BE: true,
            BF: true,
            BG: true,
            BH: true,
            BI: true,
            BJ: true,
            BL: true,
            BM: true,
            BN: true,
            BO: true,
            BQ: true,
            BR: true,
            BS: true,
            BT: true,
            BW: true,
            BY: true,
            BZ: true,
            CA: true,
            CC: true,
            CD: true,
            CF: true,
            CG: true,
            CH: true,
            CI: true,
            CK: true,
            CL: true,
            CM: true,
            CN: true,
            CO: true,
            CR: true,
            CU: true,
            CV: true,
            CX: true,
            CY: true,
            CZ: true,
            DE: true,
            DJ: true,
            DK: true,
            DM: true,
            DO: true,
            DZ: true,
            EC: true,
            EE: true,
            EG: true,
            EH: true,
            ER: true,
            ES: true,
            ET: true,
            FI: true,
            FJ: true,
            FK: true,
            FM: true,
            FO: true,
            FR: true,
            GA: true,
            GB: true,
            GD: true,
            GE: true,
            GF: true,
            GG: true,
            GH: true,
            GI: true,
            GL: true,
            GM: true,
            GN: true,
            GP: true,
            GQ: true,
            GR: true,
            GS: true,
            GT: true,
            GU: true,
            GW: true,
            GY: true,
            HK: true,
            HN: true,
            HR: true,
            HT: true,
            HU: true,
            ID: true,
            IE: true,
            IL: true,
            IM: true,
            IN: true,
            IO: true,
            IS: true,
            IT: true,
            JE: true,
            JM: true,
            JO: true,
            JP: true,
            KE: true,
            KG: true,
            KH: true,
            KI: true,
            KM: true,
            KN: true,
            KP: true,
            KR: true,
            KW: true,
            KY: true,
            KZ: true,
            LA: true,
            LC: true,
            LI: true,
            LK: true,
            LR: true,
            LS: true,
            LT: true,
            LU: true,
            LV: true,
            LY: true,
            MA: true,
            MC: true,
            MD: true,
            ME: true,
            MF: true,
            MG: true,
            MH: true,
            MK: true,
            ML: true,
            MM: true,
            MN: true,
            MO: true,
            MP: true,
            MQ: true,
            MR: true,
            MS: true,
            MT: true,
            MU: true,
            MV: true,
            MW: true,
            MX: true,
            MY: true,
            MZ: true,
            NA: true,
            NC: true,
            NE: true,
            NF: true,
            NG: true,
            NI: true,
            NL: true,
            NO: true,
            NP: true,
            NR: true,
            NU: true,
            NZ: true,
            OM: true,
            PA: true,
            PE: true,
            PF: true,
            PG: true,
            PH: true,
            PK: true,
            PL: true,
            PM: true,
            PN: true,
            PR: true,
            PS: true,
            PT: true,
            PW: true,
            PY: true,
            QA: true,
            RE: true,
            RO: true,
            RS: true,
            RU: true,
            RW: true,
            SA: true,
            SB: true,
            SC: true,
            SD: true,
            SE: true,
            SG: true,
            SH: true,
            SI: true,
            SJ: true,
            SK: true,
            SL: true,
            SM: true,
            SN: true,
            SO: true,
            SR: true,
            SS: true,
            ST: true,
            SV: true,
            SX: true,
            SZ: true,
            TC: true,
            TD: true,
            TG: true,
            TH: true,
            TJ: true,
            TK: true,
            TL: true,
            TM: true,
            TN: true,
            TO: true,
            TR: true,
            TT: true,
            TV: true,
            TW: true,
            TZ: true,
            UA: true,
            UG: true,
            US: true,
            UY: true,
            UZ: true,
            VA: true,
            VC: true,
            VE: true,
            VG: true,
            VI: true,
            VN: true,
            VU: true,
            WF: true,
            WS: true,
            XK: true,
            YE: true,
            YT: true,
            ZA: true,
            ZM: true,
            ZW: true,
        },
    },
    NESTED_SUB_CATEGORIES: {
        ID: 'nested_sub_categories',
        SORT: 9,
    },
    SUB_CATEGORIES: {
        ID: 'sub_categories',
        SORT: 10,
    },
    LANGUAGES_PAIR: {
        ID: 'languages_pair',
        SORT: 4,
        DISPLAY_TYPE: DISPLAY_TYPES.MULTI_PAIRS_SELECT,
        SELECTOR: PAIR,
        INITIAL: ['*', '*'],
        DEFAULT: '*',
        OPTION_TYPES: {
            FROM: 'fromOptions',
            TO: 'toOptions',
        },
        TYPES: {
            FROM: 'from',
            TO: 'to',
        },
        SUB_CATEGORY_ID: QUAILTY_TRANSLATIONS.ID,
        SIDE_FILTER_ID: {
            FROM: 'languages_pair_from',
            TO: 'languages_pair_to',
        },
    },
    CITY: {
        ID: 'city',
        SELECTOR: MULTI,
        GROUP: {
            ID: 'popular',
            SORTED_VALUES: {
                1: ['new_york', 'new_york_city'],
                2: ['los_angeles'],
                3: ['london'],
                4: ['paris'],
            },
        },
    },
    PRICING_FACTOR: {
        ID: 'pricing_factor',
        DISPLAY_TYPE: DISPLAY_TYPES.SELECT,
        SELECTOR: SINGLE,
        IS_PRICE_RELATED: true,
    },
};

export const filterSelectorsMap = Object.keys(FILTER).reduce((map, filterKey) => {
    if (FILTER[filterKey]) {
        map[FILTER[filterKey].ID] = FILTER[filterKey].SELECTOR;
    }
    return map;
}, {});
