import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { LISTINGS_PAGE } from '../../../../constants';
import { BI_EVENT_GROUPS, BI_ACTION_TYPES } from '../../../../../../shared/services/BIEvents/constants';

export const reportPjmBannerImpressionEvent = () => {
    const { userId, userGuid, pageCtxId } = getContext();

    bigQuery.send({
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        event_triggered_by: BI_ACTION_TYPES.IMPRESSION,
        action: { type: BI_ACTION_TYPES.IMPRESSION },
        type: 'pjm_marketing_listing_banner_imp',
        user: { id: userId, uid: userGuid },
        page: {
            ctx_id: pageCtxId,
            name: LISTINGS_PAGE,
        },
        component: {
            type: 'Banner',
        },
    });
};

export const reportPjmBannerClickEvent = (componentName: 'find_out_more' | 'dismiss') => {
    const { userId, userGuid, pageCtxId } = getContext();

    bigQuery.send({
        group: BI_EVENT_GROUPS.USER_ACTIONS,
        event_triggered_by: BI_ACTION_TYPES.CLICK,
        action: { type: BI_ACTION_TYPES.CLICK },
        type: 'pjm_marketing_listing_banner_click',
        user: { id: userId, uid: userGuid },
        page: {
            ctx_id: pageCtxId,
            name: LISTINGS_PAGE,
            component: {
                name: componentName,
            },
        },
        component: {
            type: 'Banner',
        },
    });
};
