module.exports = {
    EVENT_TYPES: {
        GIG_CLICK: 'gig.click',
        CAROUSEL_SCROLL: 'carousel.scroll',
        GIG_CARD_CLICK: 'clicked_on_gig_card',
        CAROUSEL_DELAY_IMPRESSION: 'carousel_delay_impression',
    },
    STATS_PREFIX: 'packages.gig_listings_package',
    MT_SUCCESS: 'machine_translation_ssr.success',
    MT_FAILURE: 'machine_translation_ssr.failure',
    MT_EXECUTION_TIME: 'machine_translation_ssr.execution_time',
    GO_LISTINGS_EXECUTION_TIME: 'go_listings.execution_time',
    GO_LISTINGS_FAILURE: 'go_listings.failure',
    TL_EXECUTION_TIME: 'translation_layers.execution_time',
    TL_SUCCESS: 'translation_layers.success',
    TL_FAILURE: 'translation_layers.failure',
    TL_TIMEOUT: 'translation_layers.timeout',
};
