import { useContext } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import useListingsPositionObserver from '../useListingsPositionObserver';
import { AppContext } from '../../components/context/listingsContext';
import { POSITION_TO_ALLOCATE_TO_ADS_PLACEMENTS } from '../../clientExperiments/adsPlacements/constants';

const useAllocateAdsPlacementsRowObserver = () => {
    const { pageCtxId, abTests } = getContext();
    const { clientExperimentsGroups } = useContext(AppContext);

    return useListingsPositionObserver(
        {
            shouldObserve: true,
            position: POSITION_TO_ALLOCATE_TO_ADS_PLACEMENTS,
        },
        () => {
            const experimentGroup = Number(clientExperimentsGroups[EXPERIMENTS.ADS_PLACEMENT]);
            if (experimentGroup > 0) {
                bqEvent({
                    type: 'listings_client_experiment_active',
                    group: BI_EVENT_GROUPS.USER_ACTIONS,
                    pageCtxId,
                    page: {
                        name: BI_PAGE_NAMES.LISTINGS,
                    },
                    payload: {
                        ab_test: [
                            {
                                experiment: EXPERIMENTS.ADS_PLACEMENT,
                                group: experimentGroup,
                            },
                        ],
                    },
                });

                if (!abTests[EXPERIMENTS.ADS_PLACEMENT]) {
                    allocateToClientExperiment(EXPERIMENTS.ADS_PLACEMENT, clientExperimentsGroups);
                }
            }
        }
    );
};
export default useAllocateAdsPlacementsRowObserver;
