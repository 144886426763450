import PropTypes from 'prop-types';
import React from 'react';
import { URI } from '@fiverr-private/futile';
import { OPEN_GIG_SAME_TAB_TARGET } from '../constants';
import { useGigListingsContext } from '../../../GigCardListings/context/GigListingsContext';
import { useCardContext } from '../../../GigCardListings/context/CardContext';

const ContextualLink = ({
    href,
    title = '',
    rel = '',
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    className,
    target,
    ariaLabel,
    children,
}) => {
    const { urlTarget } = useGigListingsContext();
    const { impressionId } = useCardContext();
    const linkTarget = urlTarget || target || OPEN_GIG_SAME_TAB_TARGET;
    const contextualUrl = new URI(href).addParams({
        imp_id: impressionId,
    }).href;

    return (
        <a
            href={contextualUrl}
            target={linkTarget}
            aria-label={ariaLabel}
            className={className}
            title={title}
            rel={rel}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </a>
    );
};

ContextualLink.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    rel: PropTypes.any,
    title: PropTypes.any,
    href: PropTypes.any,
    target: PropTypes.any,
    ariaLabel: PropTypes.string,
};

export default ContextualLink;
