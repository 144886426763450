import React, { useContext, useMemo } from 'react';
import { noop } from 'lodash';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { AppContext } from '../../../apps/listings/components/context/listingsContext';
import { getListingsEnrichment } from '../../../apps/listings/components/common/listings/impressionEnrichment';
import { isEligibleForNewImpressions } from './utils/isEligibleForNewImpressions/isEligibleForNewImpressions';
import { sendImpressionEvent } from './utils/sendImpressionEvent/sendImpressionEvent';
import { ImpressionSchema } from './types/impressionSchema';
import { clearLastlyAppliedFilters } from './utils/clearLastlyAppliedFilters/clearLastlyAppliedFilters';

interface ImpressionContext {
    onImpression: (impressionData: ImpressionSchema) => void;
}

export const ListingsImpressionContext = React.createContext<ImpressionContext>({
    onImpression: noop,
});

interface ListingsImpressionContainerProps {
    rowObservers: ((props?: any) => void)[];
}

export const ListingsImpressionProvider: React.FC<ImpressionContext> = ({ onImpression = noop, children }) => (
    <ListingsImpressionContext.Provider value={{ onImpression }}>{children}</ListingsImpressionContext.Provider>
);

/**
 * Container to handle supply Impression Data to Observers (consumers)
 */
export const ListingsImpressionContainer: React.FC<ListingsImpressionContainerProps> = ({
    children,
    rowObservers = [],
}): React.JSX.Element | null => {
    const {
        appFilters,
        listings: [listings],
        inReportNewImpressionsRollout,
        activeFilters,
        activeExperiments,
        searchComponentData,
    } = useContext(AppContext);

    const shouldUseNewImpressions = isEligibleForNewImpressions({
        inReportNewImpressionsRollout,
        currentPage: listings?.context?.currentPage,
    });

    if (!shouldUseNewImpressions) {
        clearLastlyAppliedFilters();
    }

    const onImpression = (impressionData: ImpressionSchema) => {
        if (shouldUseNewImpressions) {
            sendImpressionEvent(impressionData).catch(noop);
        }

        rowObservers.forEach((observer) => observer(impressionData));
    };

    const listingsImpressionEnrichment = useMemo(
        () =>
            shouldUseNewImpressions
                ? getListingsEnrichment({
                      listings,
                      appFilters,
                      activeFilters,
                      activeExperiments,
                      searchComponentData,
                  })
                : {},
        [listings, appFilters, activeFilters, activeExperiments, searchComponentData, shouldUseNewImpressions]
    );

    const [containerRef, containerId] = useImpressionContainer();
    useImpressionItem({
        selfRef: containerRef,
        selfId: containerId,
        enrichment: listingsImpressionEnrichment,
        sendImpressionToBQ: false,
        intersectionThreshold: 0,
        onImpression,
    });

    if (!children) {
        return null;
    }

    return (
        <ListingsImpressionProvider onImpression={onImpression}>
            <div ref={containerRef}>{children}</div>
        </ListingsImpressionProvider>
    );
};
