import { ASSETS_SIZES, MOBILE_SIZE, LARGE_SIZE, IMAGE_TYPE, PLACEHOLDER } from './constants';

const DEFAULT_ASSET = {
    type: IMAGE_TYPE,
    image: {},
};

const createImageAsset = (asset, size, useSrcSet) => {
    const cloudUrl = asset.cloudBaseUrl;
    const assetSizeTemplate = ASSETS_SIZES[size];

    const assetImage = {};
    assetImage.src = cloudUrl.replace(PLACEHOLDER, assetSizeTemplate.src);

    if (useSrcSet && assetSizeTemplate.srcSet) {
        assetImage.srcSet = assetSizeTemplate.srcSet
            .map((srcSet, index) => `${cloudUrl.replace(PLACEHOLDER, srcSet)} ${index + 1}x`)
            .join(', ');
    }

    return assetImage;
};

export const prepareAssets = (assets = [], isTouch = false, title) => {
    if (assets.length === 0) {
        return [DEFAULT_ASSET];
    }

    return assets.map((asset) => {
        const isImageAsset = asset.type === IMAGE_TYPE;

        const _asset = {
            type: asset.type,
            title,
            id: asset.id,
            deliveredAsset: asset.deliveredAsset,
            image: createImageAsset(asset, isTouch ? MOBILE_SIZE : LARGE_SIZE, isImageAsset),
        };

        if (asset.type !== IMAGE_TYPE) {
            _asset.streamUrl = asset.streamUrl;
        }

        return _asset;
    });
};

export const prepareFirstImageAsset = (assets, isTouch = true, title) => {
    const asset = assets.find(isImageAsset) || assets[0];

    if (!asset) {
        return DEFAULT_ASSET;
    }

    return {
        type: asset.type,
        title,
        id: asset.id,
        image: createImageAsset(asset, isTouch ? MOBILE_SIZE : LARGE_SIZE, true),
    };
};

const isImageAsset = (asset) => asset.type === IMAGE_TYPE;

export const calculateVideoProgress = (currentTime, duration, withRemainingTime) => {
    const totalPercentage = 2 * Math.PI.toFixed(2) * 14,
        percentage = (currentTime * totalPercentage) / duration;

    const result = {
        progress: `${percentage} 100`,
    };

    if (withRemainingTime) {
        // Adding an extra second to prevent playback timer from showing '0:00'
        const remainingTime = duration - currentTime + 1,
            minutes = Math.floor(remainingTime / 60),
            seconds = Math.floor(remainingTime - minutes * 60);

        result.remainingPlaytime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return result;
};

export const overrideImageTransformation = (imageUrl, originalTransformation, newTransformation) =>
    imageUrl.replace(originalTransformation, newTransformation);
