import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { SearchResultsCounterTypes } from '../../../types';
import ModifiedResult from './modifiedResult';
import TranslatedResult from './translatedResult';
import './index.scss';

const SearchResultsCounter = ({ paginationData, displayData }) => {
    const { page, page_size, total } = paginationData;
    const { query, modifiedQueryString, searchInsteadUrl, nonTranslatedQuery, reasonForModification } =
        displayData.searchComponentData;

    const startNumber = 1 + (page - 1) * page_size,
        endNumber = Math.min(page_size * page, total);
    const phraseStart =
        total === 0
            ? '0'
            : `${startNumber}-${endNumber} ${translate('search_perseus.search_results_counter.of')} ${total}`;

    return (
        <div className="search-results-counter layout-row">
            <div className="title">
                <p>
                    {modifiedQueryString && (
                        <ModifiedResult
                            query={query}
                            reasonForModification={reasonForModification}
                            searchInsteadUrl={searchInsteadUrl}
                            modifiedQueryString={modifiedQueryString}
                            phraseStart={phraseStart}
                        />
                    )}
                    {nonTranslatedQuery && (
                        <TranslatedResult
                            query={nonTranslatedQuery}
                            modifiedQueryString={query}
                            phraseStart={phraseStart}
                        />
                    )}
                </p>
            </div>
        </div>
    );
};

SearchResultsCounter.propTypes = SearchResultsCounterTypes;

export default SearchResultsCounter;
