/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import LazyTooltip from './PentaTooltip';

import styles from './PentaBadge.module.scss';

export const PentaBadge = ({
    color,
    backgroundColor,
    tooltipContent,
    showTooltip = true,
    tooltipPosition = 'top',
    dataTestId,
    appendTooltipToBody,
    fontVariant = 'normal',
    disableTooltipFlip,
    className = '',
    children,
}) => {
    const props = {
        className: classNames(className, styles.badge, { [styles.allCaps]: fontVariant === 'all_caps' }),
        style: { color, backgroundColor },
        'data-testid': dataTestId,
        children,
    };

    if (tooltipContent && showTooltip) {
        return (
            <LazyTooltip
                disableFlip={disableTooltipFlip}
                appendToBody={appendTooltipToBody}
                content={tooltipContent}
                position={tooltipPosition}
            >
                <div {...props} />
            </LazyTooltip>
        );
    }

    return <div {...props} />;
};

PentaBadge.displayName = 'PentaBadge';
