import React from 'react';
import classNames from 'classnames';
import { grey_1100 } from '@fiverr-private/sass/helpers';
import { UnorderedList, ListItem } from '@fiverr-private/list';
import { CheckIcon } from '@fiverr-private/icons';
import { Text } from '@fiverr-private/typography';
import { Container, Stack, Layout } from '@fiverr-private/layout_components';
import { GigCardType } from '../../../../types';
import Price from '../../../Components/Price';
import { trackGigForClickType, createClickedElement } from '../../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../../Components/constants';
import ContextualLink from '../../../../Components/ContextualLink';
import { useCardContext } from '../../../../../GigCardListings/context/CardContext';
import commonStyles from '../../../../styles/utils.module.scss';
import { useMediaProps, getPriceProps } from '../../../BasicGigCard/utils';
import { getGigTitleProps } from '../../utils/getGigTitleProps';
import GigTitle from '../../../Components/GigTitle';
import Media from '../../../../Components/Media';
import styles from './ServiceInfo.module.scss';

const AgencyHighlightsClickedElement = createClickedElement(ELEMENTS_NAME.HIGHLIGHTS, ELEMENTS_TYPE.LINK);

const ServiceInfo = (props) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const { urlTarget: gigUrlTarget } = props;
    const { url, urlTarget: agencyUrlTarget, highlights = [] } = props.seller;
    const urlTarget = agencyUrlTarget ?? gigUrlTarget;

    const gigTitleProps = getGigTitleProps({ ...props, className: 'p-t-0' });
    const priceProps = getPriceProps(props);
    const mediaProps = useMediaProps(props);

    return (
        <Stack direction={{ default: 'columnReverse', sm: 'row' }} gap="4" width="100%">
            <Stack flex="1" width="100%">
                <Stack direction="column" gap="4" alignItems="flexStart" width="100%">
                    <Container
                        width="100%"
                        height="fit-content"
                        className={classNames('media-wrapper', commonStyles.zIndex2)}
                    >
                        <Media {...mediaProps} />
                    </Container>
                    <Layout hidden={{ default: false, sm: true }}>
                        <GigTitle {...gigTitleProps} titleClassName={classNames('tbody-6', 'text-normal')} />
                    </Layout>
                    <Stack alignItems="flexStart" width="100%">
                        <Price
                            {...priceProps}
                            className={classNames(styles.priceWrapper, 'm-t-0')}
                            textClassName="text-semi-bold"
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack flex="1">
                <Stack
                    direction="column"
                    gap={{ default: '1', sm: '3' }}
                    alignItems="flexStart"
                    width="100%"
                    height="100%"
                    position="relative"
                >
                    <ContextualLink
                        href={url}
                        target={urlTarget}
                        className={classNames('agency-contextual-link', commonStyles.zIndex1)}
                        onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, AgencyHighlightsClickedElement)}
                    />
                    <Layout hidden={{ default: true, sm: false }}>
                        <GigTitle {...gigTitleProps} titleClassName={classNames('tbody-5', 'text-semi-bold')} />
                    </Layout>

                    <UnorderedList paddingLeft="0" height="fit-content" gap={{ default: '1', sm: '2' }}>
                        {highlights.map((highlight) => (
                            <ListItem key={highlight} display="flex" alignItems="flexStart" gap="2">
                                <Container>
                                    <CheckIcon size={12} color={grey_1100} />
                                </Container>
                                <Text className={styles.highlight} size="b_sm">
                                    {highlight}
                                </Text>
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Stack>
            </Stack>
        </Stack>
    );
};

ServiceInfo.propTypes = GigCardType;

export default ServiceInfo;
