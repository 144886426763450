import React from 'react';
import { translate, I18n } from '@fiverr-private/i18n-react';
import { VettedProBadge } from '@fiverr-private/badges';
import { Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { ActiveFilters, InitialProps, CategoryTree } from '@fiverr-private/listing_types';
import { isProFilterActive } from '../../utils/isProFilterActive/isProFilterActive';

const calcTitleParameter = (displayQuery?: string, subCategoryId?: string, nestedSubCategoryId?: string): string => {
    if (displayQuery) {
        return displayQuery;
    }

    if (subCategoryId) {
        let key = `sub_categories.sub_category_${subCategoryId}`;
        key += nestedSubCategoryId ? `.nested_sub_cat_${nestedSubCategoryId}` : '';
        return translate(`${key}.name`);
    }

    return '';
};

interface ProFilteredListingsTitleProps {
    displayData: InitialProps['displayData'];
    categoryIds?: CategoryTree;
    activeFilters?: ActiveFilters;
}

export const ProFilteredListingsTitle = ({
    displayData,
    categoryIds,
    activeFilters,
}: ProFilteredListingsTitleProps) => {
    const { subCategoryId, nestedSubCategoryId } = categoryIds || {};
    const displayQuery =
        displayData?.searchComponentData?.modifiedQueryString || displayData?.searchComponentData?.query;

    const titleParameter = calcTitleParameter(displayQuery, subCategoryId, nestedSubCategoryId);
    const proFilterIsActive = isProFilterActive(activeFilters);

    if (!titleParameter || !proFilterIsActive) {
        return null;
    }

    return (
        <Container paddingY="2.5">
            <Text size="b_lg">
                <Container as="span" position="relative" display="inlineFlex" top="3px" marginRight="2">
                    <VettedProBadge variant="compact" />
                </Container>
                <I18n
                    k="listing_results.pro_filtered.additional_title"
                    params={{ query: titleParameter }}
                    templates={{
                        strong: (text: string | undefined) => (
                            <Text size="b_lg" as="span" fontWeight="bold">
                                {text}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Container>
    );
};
