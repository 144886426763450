import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { noop } from 'lodash';
import { isBrowser } from '../util';
import { getStatusCode, MaxiosError, measureOnFailure, measureOnSuccess } from './maxios.utils';
import { DEFAULT_REQUEST_TIMEOUT_MS, SEARCH_PERSEUS_CLIENT_NAME } from './maxios.consts';

export type CustomAxiosConfig = AxiosRequestConfig & {
    serviceName: string;
    routeKey: string;
    startedAt: number;
    identifier: string;
    shouldReportRequestToBQ?: boolean;
    reportToBQCallback?: (config, status) => void;
};

export const handleRequestOnFulfilled = (config: Partial<CustomAxiosConfig> = {}) => {
    const isFromBrowser = isBrowser();
    const { serviceName, routeKey, timeout, ...axiosConfig } = (config || {}) as CustomAxiosConfig;
    const name: string = isFromBrowser ? SEARCH_PERSEUS_CLIENT_NAME : serviceName;

    if (!name || !routeKey) {
        throw new MaxiosError();
    }

    const requestTimeout = isFromBrowser ? timeout : timeout || DEFAULT_REQUEST_TIMEOUT_MS;

    return {
        ...axiosConfig,
        startedAt: Date.now(),
        timeout: requestTimeout,
        identifier: `${name}.${routeKey}`,
    };
};
export const handleRequestOnRejected = (error) => Promise.reject(error);

export const handleResponseOnFulfilled = (response: AxiosResponse) => {
    const {
        identifier,
        startedAt,
        shouldReportRequestToBQ = false,
        reportToBQCallback = noop,
    } = (response?.config as CustomAxiosConfig) || {};
    measureOnSuccess(identifier, startedAt, response?.status);

    if (shouldReportRequestToBQ) {
        reportToBQCallback(response.config, response.status);
    }

    return response;
};
export const handleResponseOnRejected = (error: AxiosError) => {
    const {
        identifier,
        startedAt,
        shouldReportRequestToBQ = false,
        reportToBQCallback = noop,
    } = (error.config as CustomAxiosConfig) || {};
    const status = getStatusCode(error);
    measureOnFailure(identifier, startedAt, status);

    if (shouldReportRequestToBQ) {
        reportToBQCallback(error.config, status);
    }

    throw error;
};

/**
 * Intercept all axios requests and responses for monitoring successes and failures
 */
export const monitoringInterceptor = (axiosInstance: AxiosInstance) => {
    if (!axiosInstance) {
        return;
    }

    axiosInstance.interceptors.request.use(handleRequestOnFulfilled, handleRequestOnRejected);
    axiosInstance.interceptors.response.use(handleResponseOnFulfilled, handleResponseOnRejected);
};

/**
 * maxios is an axios instance which supports extended axios config. It allows us to make axios requests
 * and get metrics that can help us investigate the behavior of each request without sending
 * the metrics manually.
 *
 * routeKey - path unique id (pathfinder value) - for AJAX routes
 * axiosConfig - external configuration object - for external requests on the server
 */
const axiosInstanceSingleton = axios.create();
monitoringInterceptor(axiosInstanceSingleton);
export const maxios: AxiosInstance | any = axiosInstanceSingleton; // fixme remove any
