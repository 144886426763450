export const GIG_CARD = 'gig_card';
export const BASIC_GIG_CARD = 'basic_gig_card';
export const SELLER_FOCUSED_GIG_CARD = 'seller_focused_card';
export const LIST_VIEW_CARD = 'list_view_card';
export const LIST_VIEW_CARD_SMALL = 'list_view_card_small';
export const SMALL_GIG_CARD = 'small_gig_card';
export const DEFAULT_CARD = 'default_card';
export const AGENCY_CARD = 'agency_card';
export const AGENCY_CARD_V2 = 'agency_card_v2';
export const VIDEO_STRIP_CARD = 'video_strip_card';

export const SMALL_CARD_CLASS = 'sml-card-layout';
export const DEFAULT_CARD_CLASS = 'gig-card-layout';
export const DOUBLE_CARD_CLASS = 'double-card-layout';
export const VIDEO_STRIP_CARD_CLASS = 'video-strip-card-layout';

export const SCOPE_CLASS = 'gig_listings-package';

export const LISTINGS_CONTAINER_GRID_VIEW = 'grid-view';
export const LISTINGS_CONTAINER_LIST_VIEW = 'list-view';
export const LISTINGS_CONTAINER_GRID_VIEW_SM = 'grid-container-size-sm';
