import React from 'react';
import { node, string, bool } from 'prop-types';
import { Tooltip } from '@fiverr-private/tooltip';

const TooltipWrapper = (props) => {
    const { shouldRenderTooltip, children, className, ...rest } = props;

    if (!shouldRenderTooltip) {
        return children;
    }

    return (
        <div className={className}>
            <Tooltip {...rest}>{children}</Tooltip>
        </div>
    );
};

TooltipWrapper.propTypes = {
    children: node,
    className: string,
    content: string,
    appendToBody: bool,
    position: string,
    shouldRenderTooltip: bool,
};

export default TooltipWrapper;
