export const GIG_ENGINE_TYPES = {
    RELAXED: 'relaxed',
    LIMITED_EDITION: 'limited_edition',
    STOREFRONT: 'storefront',
    FEATURED: 'featured',
    PROMOTED: 'promoted',
    FIVERR_LEARN: 'fiverr_learn',
    LETOR: 'letor',
    LETOR_FIXED_PRICING: 'letor_fixed_pricing',
    STUDIO: 'studio',
    AGENCY: 'agency',
    FIVERR_CHOICE: 'fiverr_choice',
    PROMOTED_GIGS: 'promoted_gigs',
    CHOICE_MODALITY: 'choice_modality',
    FIXED_PRICING: 'fixed_pricing',
    LIQUID_ENGINE: 'liquid_engine',
    BUY_IT_AGAIN: 'buy_it_again',
    PRO: 'pro',
    AVERAGE_SELLING_PRICE: 'average_selling_price',
    RECOMMENDATION_FTB_FRIENDLY: 'recommendation_ftb_friendly',
    RECOMMENDATION_EXPRESS_DELIVERY: 'recommendation_express_delivery',
    RECOMMENDATION_RETURNING_BUYERS: 'recommendation_returning_buyers',
    LOCALIZATION_SELLERS: 'localization_sellers',
    VIDEO_STRIP_GIG_TYPE: 'video_strip',
};

export const GIG_ENGINE_TYPES_MAPPER = {
    [GIG_ENGINE_TYPES.RELAXED]: 'ENGINE_RELAXED',
    [GIG_ENGINE_TYPES.LIMITED_EDITION]: 'ENGINE_LIMITED_EDITION',
    [GIG_ENGINE_TYPES.PRO]: 'ENGINE_PRO',
    [GIG_ENGINE_TYPES.STOREFRONT]: 'ENGINE_STOREFRONT',
    [GIG_ENGINE_TYPES.LIQUID_ENGINE]: 'ENGINE_LIQUID_ENGINE',
    [GIG_ENGINE_TYPES.FEATURED]: 'ENGINE_FEATURED',
    [GIG_ENGINE_TYPES.PROMOTED]: 'ENGINE_PROMOTED',
    [GIG_ENGINE_TYPES.FIVERR_LEARN]: 'ENGINE_FIVERR_LEARN',
    [GIG_ENGINE_TYPES.FIXED_PRICING]: 'ENGINE_FIXED_PRICING',
    [GIG_ENGINE_TYPES.LETOR]: 'ENGINE_LETOR',
    [GIG_ENGINE_TYPES.STUDIO]: 'ENGINE_STUDIO',
    [GIG_ENGINE_TYPES.AGENCY]: 'ENGINE_AGENCY',
    [GIG_ENGINE_TYPES.FIVERR_CHOICE]: 'ENGINE_FIVERR_CHOICE',
    [GIG_ENGINE_TYPES.LETOR_FIXED_PRICING]: 'ENGINE_LETOR_FIXED_PRICING',
    [GIG_ENGINE_TYPES.PROMOTED_GIGS]: 'ENGINE_PROMOTED_GIGS',
    [GIG_ENGINE_TYPES.CHOICE_MODALITY]: 'ENGINE_CHOICE_MODALITY',
    [GIG_ENGINE_TYPES.AVERAGE_SELLING_PRICE]: 'ENGINE_AVERAGE_SELLING_PRICE',
    [GIG_ENGINE_TYPES.RECOMMENDATION_FTB_FRIENDLY]: 'ENGINE_RECOMMENDATION_FTB_FRIENDLY',
    [GIG_ENGINE_TYPES.RECOMMENDATION_EXPRESS_DELIVERY]: 'ENGINE_RECOMMENDATION_EXPRESS_DELIVERY',
    [GIG_ENGINE_TYPES.RECOMMENDATION_RETURNING_BUYERS]: 'ENGINE_RECOMMENDATION_RETURNING_BUYERS',
    [GIG_ENGINE_TYPES.LOCALIZATION_SELLERS]: 'ENGINE_LOCALIZATION_SELLERS',
    [GIG_ENGINE_TYPES.VIDEO_STRIP_GIG_TYPE]: 'ENGINE_VIDEO_STRIP_GIG_TYPE',
    DEFAULT: 'ENGINE_DEFAULT',
};
