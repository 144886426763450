import React from 'react';
import className from 'classnames';
import PropTypes from 'prop-types';
import ContextualLink from '../../../../Components/ContextualLink';
import { createClickedElement, trackGigForClickType } from '../../../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../../Components/constants';
import { useCardContext } from '../../../../../GigCardListings/context/CardContext';

import styles from '../../../../styles/utils.module.scss';

const ServiceName = ({ serviceName, url, urlTarget }) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();

    if (!serviceName) {
        return null;
    }

    const clickedElement = createClickedElement(ELEMENTS_NAME.SERVICE_NAME, ELEMENTS_TYPE.LINK, serviceName);

    return (
        <ContextualLink
            href={url}
            target={urlTarget}
            className={className(styles.zIndex1, styles.widthFitContent)}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement)}
        >
            <h6 className={className('tbody-5', 'm-b-4', 'co-text-darkest', 'text-semi-bold', styles.ellipsis)}>
                {serviceName}
            </h6>
        </ContextualLink>
    );
};

ServiceName.propTypes = {
    serviceName: PropTypes.string,
    url: PropTypes.string,
    urlTarget: PropTypes.string,
};

export default ServiceName;
