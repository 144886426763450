import React, { useEffect, useState } from 'react';
import withTransformProps from '../../hoc/withTransformProps';
import { smallCardTransformer } from '../../utils/transformers/smallCard';
import { enrichGigTracker } from '../../utils';
import { GigCardSmallType } from '../../types';
import { useGigPageLinkHoverState } from '../BasicGigCard/utils';
import GigWrapper from '../../Components/GigWrapper/GigWrapper';
import Media from '../../Components/Media';
import CollectWrapper from '../Components/CollectWrapper';
import GigTitle from '../Components/GigTitle';
import BadgeWrapper from './components/BadgeWrapper';
import ClearButton from './components/ClearButton';
import {
    getGigWrapperProps,
    getCollectWrapperProps,
    getMediaProps,
    getClearButtonProps,
    getGigTitleProps,
    getBadgeProps,
} from './utils';

import './index.scss';

const BasicGigCardSml = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState({});

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(props.tracker, props));
    }, [props]);

    const gigPageLinkHoverState = useGigPageLinkHoverState();
    const gigWrapperProps = getGigWrapperProps(props, enrichedTracker);
    const mediaProps = getMediaProps(props, gigPageLinkHoverState);
    const collectWrapperProps = getCollectWrapperProps(props);
    const clearButtonProps = getClearButtonProps(props);
    const gigTitleProps = getGigTitleProps(props, gigPageLinkHoverState);
    const badgeProps = getBadgeProps(props);

    return (
        <GigWrapper {...gigWrapperProps}>
            <Media {...mediaProps} />
            <CollectWrapper {...collectWrapperProps} />
            <ClearButton {...clearButtonProps} />
            <GigTitle {...gigTitleProps} />
            <BadgeWrapper {...badgeProps} />
        </GigWrapper>
    );
};

BasicGigCardSml.propTypes = GigCardSmallType;

export default withTransformProps(smallCardTransformer)(BasicGigCardSml);
