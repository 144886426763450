import { logger, mixpanel } from '@fiverr-private/obs';
import { removeEmptyEntries } from '../../../GigCardListings/utils/removeEmptyEntries';

export const sendMixPanelEvent = ({ eventName, eventPayload }) => {
    try {
        if (!window.MixpanelWrapper) {
            mixpanel.track(eventName, eventPayload);
        } else {
            window.MixpanelWrapper.trackEvent(eventName, removeEmptyEntries(eventPayload));
        }
    } catch (e) {
        logger.error(e, { message: `Failed to report event to MixPanel - ${e.message}`, action: 'sendMixPanelEvent' });
    }
};
