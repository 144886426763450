import React from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { bus, MUTE_PLAYERS, UNMUTE_PLAYERS } from '../../../../../utils';

import controlsStyles from '../index.module.scss';
import styles from './index.module.scss';

const getStateStyle = (muted) => (muted ? styles.off : styles.on);

const VolumeControl = ({ playerRef, muted, onClick = noop }) => {
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        onClick();

        const muted = !playerRef.current.muted;

        playerRef.current.muted = muted;
        muted ? bus.emit(MUTE_PLAYERS) : bus.emit(UNMUTE_PLAYERS);
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div
            className={classNames(controlsStyles.control, styles.volumeControl, getStateStyle(muted))}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
        ></div>
    );
};

VolumeControl.propTypes = {
    playerRef: object,
    muted: bool,
    onClick: func,
};

export default VolumeControl;
