import { FILTER } from '@fiverr-private/listing_lib';
import { getFilterById } from '../util';

export const getAiDeliveryFilter = (filters) => {
    const aiDeliveryFilter = getFilterById(filters, FILTER.AI_DELIVERY.ID);

    if (!aiDeliveryFilter) return null;

    return { ...aiDeliveryFilter, selectedValue: ['true'] };
};
