const { RequestContext } = require('@fiverr-private/perseus');
const { getShortLanguageCode, SUPPORTED_FIVERR_LOCALES_KEYS } = require('@fiverr-private/localization');
const { MT_CONTEXTS } = require('@fiverr-private/machine_translation');
const { ROLLOUTS } = require('@fiverr-private/listing_experiments');
const { FLOWS } = require('../utils/constants');

const SUB_CATEGORY_SUB_HEADER = { component: { name: 'sub_category_sub_header' } };
const SEARCH_SUB_HEADER = { component: { name: 'search_sub_header' } };
const FLOWS_BI_DATA_UGC_MAPPER = {
    [FLOWS.SEARCH]: { name: 'search_gigs', ...SEARCH_SUB_HEADER },
    [FLOWS.BUSINESS_SEARCH]: { name: 'business_search', ...SEARCH_SUB_HEADER },
    [FLOWS.CATEGORY]: { name: 'sub_category', ...SUB_CATEGORY_SUB_HEADER },
    [FLOWS.BUSINESS_CATEGORIES]: { name: 'business_categories', ...SUB_CATEGORY_SUB_HEADER },
    [FLOWS.PROFESSION_GIGS_SEARCH]: { name: 'profession_gigs_search', ...SEARCH_SUB_HEADER },
    [FLOWS.PROFESSION_ROLES_SEARCH]: { name: 'profession_roles_search', ...SEARCH_SUB_HEADER },
};
const ALLOWED_LOCALES_FOR_CRAWLERS = [
    SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT,
    SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE,
    SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR,
    SUPPORTED_FIVERR_LOCALES_KEYS.ES,
];

const buildTranslationConfig = (
    translateUGC,
    locale,
    userId,
    knownCrawler,
    flow,
    abTests,
    userGuid,
    translationPegasusUrl,
    gigsPhoenixServiceUrl,
    serverTranslationTimeout
) => {
    const isLocaleAllowedForCrawlers = ALLOWED_LOCALES_FOR_CRAWLERS.includes(locale);

    if (!translateUGC || (knownCrawler && !isLocaleAllowedForCrawlers)) {
        return undefined;
    }

    const {
        initialProps: { rollouts = {} },
        pageCtxId,
    } = RequestContext;

    const isLocalCatalogEnabled = rollouts[ROLLOUTS.LIONS_LOCAL_CATALOG_BUYER_SIDE];
    const gigsPhoenixUrl = isLocalCatalogEnabled ? gigsPhoenixServiceUrl : null;

    return {
        translationPegasusUrl,
        gigsPhoenixUrl,
        locale,
        knownCrawler,
        sourceInfo: {
            ...FLOWS_BI_DATA_UGC_MAPPER[flow],
            user: {
                id: userId,
            },
            userGuid,
            page: {
                ctxId: pageCtxId,
            },
            context: MT_CONTEXTS.GIG_TITLE,
        },
        allowTranslationForCrawlers: true,
        abTests,
        serverTranslationTimeout,
    };
};

const createBiData = ({ userId = '', userGuid = '', flow, pageCtxId }) => ({
    ...FLOWS_BI_DATA_UGC_MAPPER[flow],
    user: {
        id: userId,
    },
    userGuid,
    page: {
        ctxId: pageCtxId,
    },
    context: MT_CONTEXTS.GIG_TITLE,
});

const createSearchQueryBiData = ({ userId = '', userGuid = '', browserLanguage = '', locale, pageCtxId }) => ({
    name: 'search_gigs',
    component: { name: 'search_query' },
    context: MT_CONTEXTS.SEARCH,
    user: {
        id: userId,
    },
    localization: {
        experienceLocale: getShortLanguageCode(locale),
        browserLanguage: getShortLanguageCode(browserLanguage),
    },
    page: {
        ctxId: pageCtxId,
    },
    userGuid,
});

module.exports = {
    buildTranslationConfig,
    createBiData,
    createSearchQueryBiData,
};
