import React, { useContext } from 'react';
import classNames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { filterType } from '../../../../../../../types';
import Toggle, { TogglePropTypes } from '../toggle';
import { getOptionById } from '../../../../../util';
import { AppContext } from '../../../../../../context/listingsContext';

const LanguageSellerToggle = ({ filter, className, ...rest }) => {
    const { assumedLanguage } = useContext(AppContext);

    if (!getOptionById(filter, assumedLanguage)) {
        return null;
    }

    return (
        <Toggle
            className={classNames('language-filter', className)}
            position={FILTERS_LOCATION.FLOATING_TOP_RIGHT}
            title="search_perseus.filters.global.is_language_seller.title"
            text="search_perseus.filters.global.is_language_seller.text"
            params={{
                language: translate(`search_perseus.filters.global.seller_language.values.${assumedLanguage}`),
            }}
            {...filter}
            {...rest}
        />
    );
};

LanguageSellerToggle.propTypes = {
    filter: filterType,
    ...TogglePropTypes,
};

export default LanguageSellerToggle;
