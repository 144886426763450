import React, { useContext } from 'react';
import classNames from 'classnames';
import { FILTER } from '@fiverr-private/listing_lib';
import { AppContext } from '../../../../../context/listingsContext';
import { getFilterById } from '../../../../util';
import { BI_SOURCES } from '../../../../../../utils/constants';
import BusinessProToggle from './business_pro_toggle';
import ProToggle from './pro_toggle';
import SellerOnlineToggle from './seller_online_toggle';
import { toggleFiltersStyles } from './toggleFilters.ve.css';
import { AIDeliveryToggle } from './ai_delivery_toggle/AIDeliveryToggle';

const ToggleFilters = ({ filters }) => {
    const {
        isBusiness,
        inCaterpillarsToggleRemovalTestWithProRemoved,
        userData: { inShowOnlineToggleList } = {},
        inOwlsSideFiltersTest,
    } = useContext(AppContext);

    const ContextualToggle = () => {
        if (isBusiness) {
            return <BusinessProToggle {...getFilterById(filters, FILTER.PRO.ID)} source={BI_SOURCES.TOGGLE_FILTERS} />;
        } else if (!inCaterpillarsToggleRemovalTestWithProRemoved) {
            return (
                <ProToggle tooltip filter={getFilterById(filters, FILTER.PRO.ID)} source={BI_SOURCES.TOGGLE_FILTERS} />
            );
        }

        return null;
    };

    return (
        <div
            className={classNames('togglers-wrapper', 'flex', 'flex-items-baseline', {
                [toggleFiltersStyles]: inOwlsSideFiltersTest,
            })}
        >
            <ContextualToggle />
            <AIDeliveryToggle
                filter={getFilterById(filters, FILTER.AI_DELIVERY.ID)}
                source={BI_SOURCES.TOGGLE_FILTERS}
            />
            {inShowOnlineToggleList && (
                <SellerOnlineToggle
                    tooltip
                    filter={getFilterById(filters, FILTER.IS_SELLER_ONLINE.ID)}
                    source={BI_SOURCES.TOGGLE_FILTERS}
                />
            )}
        </div>
    );
};

export default ToggleFilters;
