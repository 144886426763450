import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import number from '../../../../../shared/utils/formatting/number';
import { calculateNumberOfResult } from '../util/calculateNumberOfResult';

import './index.scss';

const NumberOfResultsText = ({ resultsCount }) => {
    const { experience: { isBusiness } = {} } = getContext();

    if (!resultsCount) {
        return null;
    }

    if (isBusiness) {
        const total = number(resultsCount);

        return <I18n k={'search_perseus.number_of_results'} params={{ total }} />;
    }

    const total = calculateNumberOfResult(resultsCount);
    return <I18n k={'search_perseus.number_of_results'} params={{ total }} />;
};

export const NumberOfResults: React.FC<{ resultsCount: number }> = ({ resultsCount }) => (
    <Text className="number-of-results tbody-5" fontWeight="normal" as="p">
        <NumberOfResultsText resultsCount={resultsCount} />
    </Text>
);
