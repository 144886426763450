import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Stack } from '@fiverr-private/layout_components';
import { GigCardType } from '../../types';
import GigWrapper from '../../Components/GigWrapper/GigWrapper';
import { AGENCY_CARD_V2, DOUBLE_CARD_CLASS } from '../../../GigCardListings/utils/constants';
import LoadingGigDoubleV2Card from '../../loading/LoadingGigDoubleV2Card';
import ContextualLink from '../../Components/ContextualLink';
import { enrichGigTracker, CARD_TYPE, createClickedElement, trackGigForClickType } from '../../utils/trackGig';
import withTransformProps from '../../hoc/withTransformProps';
import { cardTransformer } from '../../utils/transformers';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../Components/constants';
import commonStyles from '../../styles/utils.module.scss';
import AgencyInfo from './components/AgencyInfo/AgencyInfo';
import ServiceInfo from './components/ServiceInfo/ServiceInfo';

import './index.scss';

const AgencyCardClickedElement = createClickedElement(ELEMENTS_NAME.WRAPPER, ELEMENTS_TYPE.LINK);

export const AgencyV2Card = (props) => {
    const [enrichedTracker, setEnrichedTracker] = useState({});

    const { uId, urlTarget, isPro, isUnavailable, isLoading, tracker, badge, seller, id, collectProps } = props;
    const { ...propsWithoutGigUrl } = props;
    const { trackEvent } = enrichedTracker;
    const { rating: { score, count } = {}, url: agencyUrl } = seller;
    const ratingProps = {
        score,
        hasRating: count > 0,
        count,
    };

    useEffect(() => {
        setEnrichedTracker(enrichGigTracker(tracker, props, CARD_TYPE.AGENCY_CARD_V2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GigWrapper
            uId={uId}
            type={AGENCY_CARD_V2}
            isPro={isPro}
            isUnavailable={isUnavailable}
            isLoading={isLoading}
            layoutClass={DOUBLE_CARD_CLASS}
            classes={['agency-card-v2']}
            LoadingComponent={LoadingGigDoubleV2Card}
            gig={props}
            enrichedTracker={enrichedTracker}
        >
            <ContextualLink
                href={agencyUrl}
                target={urlTarget}
                className={classNames('agency-contextual-link', commonStyles.zIndex1)}
                onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, AgencyCardClickedElement)}
            />
            <Stack
                width="100%"
                borderStyle="solid"
                borderColor="grey_400"
                borderWidth="sm"
                gap={{ default: '4', xl: '5' }}
                direction="column"
                padding={{ default: '5', sm: '4', xl: '5' }}
                position="relative"
                overflow="hidden"
                borderRadius="lg"
            >
                <AgencyInfo
                    badge={badge}
                    id={id}
                    collectProps={collectProps}
                    seller={seller}
                    ratingProps={ratingProps}
                    url={agencyUrl}
                    urlTarget={urlTarget}
                />
                <ServiceInfo {...propsWithoutGigUrl} url={agencyUrl} />
            </Stack>
        </GigWrapper>
    );
};

AgencyV2Card.propTypes = GigCardType;

export default withTransformProps(cardTransformer)(AgencyV2Card);
