import React, { useRef, useState, useEffect } from 'react';
import { node, object, string } from 'prop-types';
import classNames from 'classnames';
import { useIsClient } from '@fiverr-private/hooks';
import Attribute from './Attribute';

import styles from './index.module.scss';

const Container = ({ children, containerRef }) => (
    <div ref={containerRef} className={classNames(styles.attributesWrapper, 'm-t-16')}>
        {children}
    </div>
);

Container.propTypes = {
    children: node,
    containerRef: object,
};

const GigAttributes = ({ gigAttributes, url, urlTarget }) => {
    const containerRef = useRef(null);
    const [hiddenAttributes, setHiddenAttributes] = useState([]);
    const [containerWidth, setContainerWidth] = useState(0);
    const isClient = useIsClient();

    useEffect(() => {
        const resizeObserver = new ResizeObserver(([entry]) => {
            setContainerWidth(entry.contentRect.width);
            setHiddenAttributes([]);
        });

        containerRef.current && resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const notifyHiddenAttribute = (attributeValue) => {
        setHiddenAttributes((prevState) => [...prevState, attributeValue]);
    };

    if (!gigAttributes) {
        return <Container ref={containerRef} />;
    }

    const { attributeKey, attributeValues } = gigAttributes;
    const lastVisibleAttributeIndex = attributeValues.length - 1 - hiddenAttributes.length;

    return (
        <Container containerRef={containerRef}>
            {attributeValues.map((attributeValue, index) => (
                <Attribute
                    key={`${attributeValue.alias}_${index}`}
                    attributeValue={attributeValue}
                    metadataId={attributeKey}
                    url={url}
                    containerWidth={containerWidth}
                    urlTarget={urlTarget}
                    containerRef={containerRef}
                    hiddenAttributes={lastVisibleAttributeIndex === index && isClient ? hiddenAttributes : []}
                    notifyHiddenAttribute={notifyHiddenAttribute}
                />
            ))}
        </Container>
    );
};

GigAttributes.propTypes = {
    gigAttributes: object,
    url: string,
    urlTarget: string,
};

export default GigAttributes;
