import React, { PureComponent, createRef } from 'react';
import { findIndex, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SlideItem from '../SlideItem';
import SliderArrow from '../SliderArrow';
import SlidesIndicator from '../SlidesIndicator';
import { bus, MUTE_PLAYERS, PAUSE_INTERNAL_PLAYERS, UNMUTE_PLAYERS } from '../../../utils';
import { AssetType, SellerType } from '../../../types';
import { mixPanelSliderArrowClick } from '../../../utils/trackGig';
import CardContext from '../../../../GigCardListings/context/CardContext';
import { DIRECTIONS, HOVER_EVENT_TYPES, MODES, SLIDER_PREVIEW_WIDTH } from './constants';

import './index.scss';

class Slider extends PureComponent {
    static getInitialSlideIndex = ({ gigAttachmentIds, slides }) => {
        // If gig attachmentIds is not exist then set initial index at 0.
        if (isEmpty(gigAttachmentIds)) {
            return 0;
        }

        const attachmentIndex = findIndex(slides, { id: gigAttachmentIds[0] });

        // If passed attachmentId doesn't exist in the slides then set initial index at 0.
        if (attachmentIndex === -1) {
            return 0;
        }

        return attachmentIndex;
    };

    constructor(props) {
        super(props);

        this.state = {
            page: Slider.getInitialSlideIndex(props),
            slideWidth: SLIDER_PREVIEW_WIDTH,
            animating: false,
            mode: MODES.PREVIEW,
            initialized: false,
            arrowDirection: '',
            muted: !!props.playOnHover,
        };

        this.slider = createRef();

        this.changeSlide = this.changeSlide.bind(this);
        this.toggleSlidesAnimation = this.toggleSlidesAnimation.bind(this);
        this.hoverToggle = this.hoverToggle.bind(this);
        this.mutePlayer = this.mutePlayer.bind(this);
        this.unmutePlayer = this.unmutePlayer.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        // if the current page is greater than the number of slides, reset the page to 0
        if (state.page >= props.slides.length) {
            return { page: Slider.getInitialSlideIndex(props) };
        }
    }

    componentDidMount() {
        bus.on(MUTE_PLAYERS, this.mutePlayer);
        bus.on(UNMUTE_PLAYERS, this.unmutePlayer);
    }

    componentWillUnmount() {
        bus.off(MUTE_PLAYERS, this.mutePlayer);
        bus.off(UNMUTE_PLAYERS, this.unmutePlayer);
    }

    static contextType = CardContext;

    hoverToggle(event) {
        const { type } = event;

        if (type === HOVER_EVENT_TYPES.MOUSE_ENTER) {
            this.setState({ isHovering: true });
        } else if (type === HOVER_EVENT_TYPES.MOUSE_LEAVE) {
            this.setState({ isHovering: false });
        }
    }

    mutePlayer() {
        this.setState({ muted: true });
    }

    unmutePlayer() {
        this.setState({ muted: false });
    }

    setSliderMode(mode) {
        const sliderMode = mode === MODES.SLIDER;
        const slideWidth = sliderMode ? this.slider.current?.clientWidth : SLIDER_PREVIEW_WIDTH;

        this.setState({ mode, slideWidth, initialized: true });
    }

    toggleSlidesAnimation(animate) {
        this.setState({
            animating: animate,
        });
    }

    changeSlide(e, direction) {
        // Preventing navigation
        e.preventDefault();
        e.stopPropagation();

        const step = direction === DIRECTIONS.NEXT ? 1 : -1,
            totalPages = this.props.slides.length;

        let currentPage = this.state.page;

        switch (currentPage + step) {
            case -1:
                currentPage = totalPages - 1;
                break;
            case totalPages:
                currentPage = 0;
                break;
            default:
                currentPage = currentPage + step;
                break;
        }

        this.setState({
            page: currentPage,
            arrowDirection: direction,
        });

        mixPanelSliderArrowClick(this.props.gigId, direction, this.props.seller);

        bus.emit(PAUSE_INTERNAL_PLAYERS, this.context.gigId);
    }

    render() {
        const {
            slides,
            isTouch,
            lazyLoad,
            showGalleryDecisionIndicators,
            showTooltip,
            toggleTooltip,
            gigType,
            trackEvent,
            playOnHover,
            isAutoPlay,
            showPlayerControls,
            showAudioRemainingTime,
            showNewImageRatio,
        } = this.props;
        const { page, mode, animating, slideWidth, initialized, arrowDirection, isHovering, muted } = this.state;

        const sliderMode = mode === MODES.SLIDER;
        const showSliderArrows = !isTouch && slides.length > 1;

        const slidesProps = {
            className: classNames('slides', {
                animating,
                preview: !sliderMode,
            }),
        };

        if (sliderMode) {
            slidesProps.style = {
                width: slides.length * slideWidth,
                transform: `translateX(-${page * slideWidth}px)`,
            };
        }

        const desktopEvents = !isTouch
            ? {
                  onMouseEnter: (event) => {
                      this.setSliderMode(MODES.SLIDER);
                      this.hoverToggle(event);
                  },
                  onMouseLeave: (event) => {
                      this.setSliderMode(MODES.PREVIEW);
                      this.hoverToggle(event);
                  },
              }
            : {};

        const indexedSlides = slides.map((slide, index) => ({ ...slide, index }));

        const isFirstSlide = page === 0;
        const isLastSlide = page === slides.length - 1;

        return (
            <div
                className={classNames('slider', { 'first-slide': isFirstSlide, 'last-slide': isLastSlide })}
                ref={this.slider}
                {...desktopEvents}
            >
                {showSliderArrows && (
                    <SliderArrow
                        direction={DIRECTIONS.NEXT}
                        onClick={this.changeSlide}
                        trackEvent={trackEvent}
                        onMouseEnter={() => this.toggleSlidesAnimation(true)}
                        onMouseLeave={() => this.toggleSlidesAnimation(false)}
                    />
                )}

                <div {...slidesProps}>
                    {slides.map(
                        (asset, index) =>
                            (sliderMode || page === index) && (
                                <SlideItem
                                    key={`slide-${index}`}
                                    active={page === index}
                                    lazy={!sliderMode && !initialized && lazyLoad}
                                    width={slideWidth}
                                    asset={asset}
                                    showTooltip={showTooltip}
                                    toggleTooltip={toggleTooltip}
                                    gigType={gigType}
                                    showGalleryDecisionIndicators={showGalleryDecisionIndicators}
                                    trackEvent={trackEvent}
                                    playOnHover={playOnHover}
                                    isAutoPlay={isAutoPlay}
                                    isHoveringSlider={isHovering}
                                    showPlayerControls={showPlayerControls}
                                    showAudioRemainingTime={showAudioRemainingTime}
                                    showNewImageRatio={showNewImageRatio}
                                    muted={muted}
                                />
                            )
                    )}
                </div>

                {showGalleryDecisionIndicators && (
                    <SlidesIndicator currentPage={page} direction={arrowDirection} slides={indexedSlides} />
                )}

                {showSliderArrows && (
                    <SliderArrow
                        direction={DIRECTIONS.PREV}
                        onClick={this.changeSlide}
                        trackEvent={trackEvent}
                        onMouseEnter={() => this.toggleSlidesAnimation(true)}
                        onMouseLeave={() => this.toggleSlidesAnimation(false)}
                    />
                )}
            </div>
        );
    }
}

Slider.propTypes = {
    slides: PropTypes.arrayOf(AssetType).isRequired,
    gigId: PropTypes.number,
    gigType: PropTypes.string,
    isTouch: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    showTooltip: PropTypes.bool,
    toggleTooltip: PropTypes.func,
    trackEvent: PropTypes.func,
    seller: SellerType,
    showGalleryDecisionIndicators: PropTypes.bool,
    showNewImageRatio: PropTypes.bool,
    gigAttachmentIds: PropTypes.arrayOf(PropTypes.string),
    playOnHover: PropTypes.bool,
    isAutoPlay: PropTypes.bool,
    showPlayerControls: PropTypes.bool,
    showAudioRemainingTime: PropTypes.bool,
};

Slider.defaultProps = {
    gigType: '',
};

Slider.contextTypes = {
    gigId: PropTypes.number.isRequired,
};

export default Slider;
