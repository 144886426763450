import React from 'react';
import { theme } from '@fiverr-private/theme';
import { getContext } from '@fiverr-private/fiverr_context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { SellerRepresentative } from '../../constants/seller';
import { renderGigByExperience } from '../../GigCardListings/utils/gigByExperience';
import { EntriesBasePropTypes } from '../../GigCardListings/types/types';
import { GigCardType } from '../../GigCards/types';
import AgencyV2Card from '../../GigCards/ModernGigCards/AgencyV2Card';
import VideoStripGigCard from '../../GigCards/ModernGigCards/VideoStripGigCard';
import { GIG_ENGINE_TYPES } from '../../GigCardListings/utils/gigTypes/constants';

export const RenderGigForGigCardListings = (props) => {
    const { isExperiential, isNonExperiential, type, seller } = props;
    const { isMobile } = getContext();
    const { width } = useWindowSize();
    const relevantScreenSizeForAgencyCard = width < theme.numericBreakpoints.sm || width >= theme.numericBreakpoints.lg;

    if (type === 'agency' && seller.type === SellerRepresentative.AGENCY && relevantScreenSizeForAgencyCard) {
        return <AgencyV2Card {...props} />;
    }

    if (type === GIG_ENGINE_TYPES.VIDEO_STRIP_GIG_TYPE && !isMobile) {
        return <VideoStripGigCard {...props} />;
    }

    return renderGigByExperience(isNonExperiential, isExperiential)(props);
};

RenderGigForGigCardListings.propTypes = {
    ...GigCardType,
    ...EntriesBasePropTypes,
};
