import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger, stats } from '@fiverr-private/obs';
import { Impressionable } from '@fiverr-private/impressionable';
import { SCOPE_CLASS } from '../../utils/constants';
import enrichImpressionData from '../../externalUtils/getImpressionData';

const DOMAttribute = 'data-gig-id';
const DOMSelector = '.gig-wrapper-impressions:not(.skeleton)';
const NestedDOMSelector = `.${SCOPE_CLASS} .${SCOPE_CLASS} ${DOMSelector}`;

const sendGigImpressionMonitor = (event) => {
    try {
        const { isTouch } = getContext();
        const platform = isTouch ? 'mobile_web' : 'web';
        const component = _.get(event, 'item_context.source_component', 'default');
        const page = _.get(event, 'item_context.source_page', 'default').replace(/#/i, '_');
        const action = _.get(event, 'item_context.source_page_controller_action', 'default').replace(/#/i, '_');
        const context = _.get(event, 'item_context.type', 'default');
        const type = _.get(event, 'type', 'default');

        stats.count(
            'services.tracking_service',
            `impression.${platform}.${page}.${action}.${component}.${type}.${context}`,
            1
        );
    } catch (error) {
        logger.error(error, { message: `Failed to report impression event to Grafana - ${error.message}` });
    }
};

/* eslint react/prop-types: 0 */
const withImpressions = (WrappedComponent) => {
    class WithImpressions extends React.Component {
        constructor(props) {
            super(props);

            this.getImpressionContainer = this.getImpressionContainer.bind(this);
            this.getExcludeDOMSelector = this.getExcludeDOMSelector.bind(this);
            this.enrichData = this.enrichData.bind(this);
            this.collectImpressionable = this.collectImpressionable.bind(this);
        }

        componentDidMount() {
            this.createImpressionable();
        }

        componentDidUpdate() {
            this.impressionable.die();
            this.createImpressionable();
        }

        createImpressionable() {
            const { gigs } = this.props;
            this.impressionable = new Impressionable({
                DOMSelector,
                DOMAttribute,
                excludeDOMSelector: this.getExcludeDOMSelector(),
                dataIdentifier: 'uId',
                container: this.getImpressionContainer(),
                data: gigs,
                enrich: this.enrichData,
                onImpression: sendGigImpressionMonitor,
            });
        }

        collectImpressionable() {
            if (this.impressionable.unimpressed.length > 0) {
                this.impressionable.collect();
            }
        }

        getExcludeDOMSelector() {
            const { banners } = this.props;

            return _.isEmpty(banners) ? null : NestedDOMSelector;
        }

        enrichData(gig) {
            const { gigImpression } = this.props;

            return enrichImpressionData({ gig, gigImpression });
        }

        getImpressionContainer() {
            // eslint-disable-next-line react/no-find-dom-node
            return ReactDOM.findDOMNode(this.props.container || this.container);
        }

        render() {
            return (
                <WrappedComponent
                    ref={(el) => {
                        this.container = el;
                        return el;
                    }}
                    {...this.props}
                    collectImpressionable={this.collectImpressionable}
                    getContainerRef={this.getImpressionContainer}
                />
            );
        }
    }

    return WithImpressions;
};

withImpressions.propTypes = {
    banners: PropTypes.array,
    gigs: PropTypes.array,
    gigImpression: PropTypes.object,
    container: PropTypes.node,
};

export default withImpressions;
