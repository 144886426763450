import { isEmpty } from 'lodash';
import { RawRoleId, RoleMetadata } from '../rawRolesListingResult/rawRoleListingsResult';
import { Gig } from './Gig/Gig';
import { Recommendations } from './Recommendations';
import { ChoiceModalities } from './ChoiceModalities';
import { PromotedGigsBanner } from './PromotedGigsBanner';
import { ImpressionEnrichment } from './ImpressionEnrichment';
import { ListingResultLegacyContext } from './ListingResultLegacyContext';
import { ListingResultFeatures } from './ListingResultFeatures';
import { Role } from './Role/Role';

export interface GigsListingResult {
    gigs: Gig[];
    proData?: Gig[];
    sellersWhoSpeak?: Gig[];
    promotedGigsBanner?: PromotedGigsBanner;
    choiceModalities?: ChoiceModalities;
    recommendations?: Recommendations;
    showTranslatedUGC: boolean;
    hidePrefix: boolean;
    translationStatus: number;
    isHeadQuery: boolean;
    isNonExperiential: boolean;
    showListViewCards: boolean;
    impressionEnrichment: Partial<ImpressionEnrichment>;
    context: Partial<ListingResultLegacyContext>;
    features: Partial<ListingResultFeatures>;
}

interface RoleToFetch {
    seller_role_id: string;
    seller_id: string;
    original_role: RawRoleId;
    display_role: RawRoleId;
    metadata: RoleMetadata[];
    position: number;
}
type RolesToFetch = RoleToFetch[];
export type ChunkedRolesToFetch = RolesToFetch[];

export interface RolesListingResult {
    roles: Role[];
    chunkedRolesToFetch: ChunkedRolesToFetch;
    showTranslatedUGC?: boolean;
}

export type ListingResult = GigsListingResult | RolesListingResult;

export const isGigsListingResult = (data: any): data is GigsListingResult => !isEmpty(data) && 'gigs' in data;
export const isRolesListingResult = (data: any): data is RolesListingResult => !isEmpty(data) && 'roles' in data;
